var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UploadingList = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _connectionContext = _interopRequireDefault(require("../../../connectionContext"));
var _Uploads = require("../../../file/upload/Uploads");
var _I18n = require("../../i18n/I18n");
var _Routing = require("../../routing/Routing");
var _ThemeManager = require("../../ThemeManager");
var _Alert = require("../Alert");
var _ContainedButton = require("../button/ContainedButton");
var _ImageEditor = require("../lightbox/ImageEditor");
var _ListItem = require("../ListItem");
var _MaterialText = require("../text/MaterialText");
var _UploadTypes = require("./UploadTypes");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var bytes = require('bytes');
var UploadingList = exports.UploadingList = function (_PureComponent) {
  function UploadingList(props) {
    var _this;
    (0, _classCallCheck2.default)(this, UploadingList);
    _this = _callSuper(this, UploadingList, [props]);
    _this.alertOpen = false;
    _this.renderProgressBar = function (progress) {
      var error = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var connectedToServer = _this.context.connectedToServer;
      if (!connectedToServer) {
        return (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.Overline,
          children: _I18n.I18n.m.getMessage('uploadNoConnectionHint')
        });
      }
      var p = progress == null || error ? 0 : progress;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          flexDirection: 'row',
          overflow: 'visible',
          position: 'relative'
        },
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            height: 7,
            backgroundColor: error ? _ThemeManager.ThemeManager.style.brandDanger : '#EBEBEB',
            borderRadius: 10
          },
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              height: 7,
              borderRadius: 10,
              width: `${p}%`,
              backgroundColor: _ThemeManager.ThemeManager.style.progressBarColor
            }
          })
        })
      });
    };
    _this.saveEditedImage = function (f) {
      return function () {
        var _ref = (0, _asyncToGenerator2.default)(function* (e, _e) {
          try {
            var uploading = _this.state.uploading;
            var findUploading = uploading.find(function (e) {
              return e.id === f.id;
            });
            if (!findUploading) {
              _Routing.Routing.instance.alert.post({
                text: `${_I18n.I18n.m.getMessage('cantEditImageAlreadyUploaded')}`
              });
            } else if (findUploading.progress != null && findUploading.progress > 0) {
              _Routing.Routing.instance.alert.post({
                text: `${_I18n.I18n.m.getMessage('cantEditImageAlreadyUploading')}`
              });
            } else if (e.path != null && f.preview != null) {
              var buttons = [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                backgroundColor: "#FFFFFF",
                textColor: _ThemeManager.ThemeManager.style.brandPrimary,
                onPress: function onPress() {
                  var _Alert$instance;
                  (_Alert$instance = _Alert.Alert.instance) == null || _Alert$instance.close(function () {
                    _this.removeImageNow(e.path).catch(function (err) {
                      return void 0;
                    });
                  });
                },
                title: _I18n.I18n.m.getMessage('discard')
              }, "close"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                onPress: function onPress() {
                  var _Alert$instance2;
                  (_Alert$instance2 = _Alert.Alert.instance) == null || _Alert$instance2.close(function () {
                    _this.uploadEditedImage(f, e.path).catch(function (err) {
                      _Routing.Routing.instance.alert.post({
                        text: `${_I18n.I18n.m.getMessage('couldNotBeSaved')}`
                      });
                    });
                  });
                },
                title: _I18n.I18n.m.getMessage('saveCopy')
              }, "saveCopy"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                onPress: function onPress() {
                  var _Alert$instance3;
                  (_Alert$instance3 = _Alert.Alert.instance) == null || _Alert$instance3.close(function () {
                    _this.uploadEditedImage(f, e.path, true).catch(function (err) {
                      _Routing.Routing.instance.alert.post({
                        text: `${_I18n.I18n.m.getMessage('couldNotBeSaved')}`
                      });
                    });
                  });
                },
                title: _I18n.I18n.m.getMessage('save')
              }, "save")];
              _Routing.Routing.instance.alert.post({
                title: _I18n.I18n.m.getMessage('photoEditorSaveTitle'),
                text: _I18n.I18n.m.getMessage('photoEditorSaveText'),
                buttons: buttons
              });
            }
          } catch (err) {}
        });
        return function (_x, _x2) {
          return _ref.apply(this, arguments);
        };
      }();
    };
    _this.uploadEditedImage = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(function* (f, newImage) {
        var replace = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var d = Object.assign({}, f.data);
        _Uploads.Uploads.addUpload(f.targetUrl, {
          path: newImage
        }, f.type, d).catch(function (err) {
          return void 0;
        });
        if (replace) _this.cancelUploadNow(f)();
      });
      return function (_x3, _x4) {
        return _ref2.apply(this, arguments);
      };
    }();
    _this.removeImageNow = function () {
      var _ref3 = (0, _asyncToGenerator2.default)(function* (newImage) {
        var rn = yield import('react-native-blob-util');
        var fs = rn.default.fs;
        yield fs.unlink(newImage);
      });
      return function (_x5) {
        return _ref3.apply(this, arguments);
      };
    }();
    _this.cancelUpload = function (f) {
      return function () {
        _this.alertOpen = true;
        _Routing.Routing.instance.alert.post({
          text: _I18n.I18n.m.getMessage('cancelUpload'),
          buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            onPress: _this.closeAlert,
            title: _I18n.I18n.m.getMessage('no'),
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }, "no"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            onPress: _this.cancelUploadNow(f),
            title: _I18n.I18n.m.getMessage('yes'),
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }, "yes")]
        });
      };
    };
    _this.cancelUploadNow = function (f) {
      return function () {
        var _Alert$instance4;
        (_Alert$instance4 = _Alert.Alert.instance) == null || _Alert$instance4.close();
        _this.alertOpen = false;
        _Uploads.Uploads.cancelUpload(f.id);
      };
    };
    _this.checkError = function (f) {
      return function () {
        if (_this.alertOpen) return;
        var e = f['error'];
        if (e != null) {
          if (typeof e === 'string') {
            try {
              e = JSON.parse(e);
            } catch (err) {}
          }
          var text = '';
          if (e['name'] != null && e['name'] === 'notFound') {
            text = _I18n.I18n.m.getMessage('uploadEndpointNotFound');
          } else if (e['errorMessage'] != null) {
            text = e['errorMessage'];
          } else if (e['messageCode'] != null) {
            text = _I18n.I18n.m.getMessage(e['messageCode']);
          } else {
            text = e.toString();
          }
          if (e['statusCode'] === 413 || e['status'] === 413 || e['errorMessage'] === 'MulterError: File too large') {
            text = _I18n.I18n.m.getMessage('fileTooLarge', {
              maxSize: bytes(_Uploads.Uploads.maxUploadFileSize)
            });
          }
          if (e['statusCode'] === 503 || e['status'] === 503) {
            text = _I18n.I18n.m.getMessage('uploadEndpointNotFound');
          }
          _Routing.Routing.instance.alert.post({
            text: text,
            buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              onPress: _this.discardFile(f),
              title: _I18n.I18n.m.getMessage('discard'),
              backgroundColor: "transparent",
              textColor: _ThemeManager.ThemeManager.style.brandPrimary
            }, "dis"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              onPress: _this.tryAgainFile(f),
              title: _I18n.I18n.m.getMessage('tryAgain'),
              backgroundColor: "transparent",
              textColor: _ThemeManager.ThemeManager.style.brandPrimary
            }, "again"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              onPress: _this.closeAlert,
              title: _I18n.I18n.m.getMessage('close'),
              backgroundColor: "transparent",
              textColor: _ThemeManager.ThemeManager.style.brandPrimary
            }, "close")]
          });
        }
        _this.alertOpen = true;
      };
    };
    _this.closeAlert = function (_e) {
      var _Alert$instance5;
      _this.alertOpen = false;
      (_Alert$instance5 = _Alert.Alert.instance) == null || _Alert$instance5.close();
    };
    _this.discardFile = function (file) {
      return function (_e) {
        var _Alert$instance6;
        _this.alertOpen = false;
        var onUploading = _this.props.onUploading;
        (_Alert$instance6 = _Alert.Alert.instance) == null || _Alert$instance6.close();
        _Uploads.Uploads.instance.removeUpload(file.id).catch(function (err) {
          return void 0;
        });
        _this.setState(function (prevState) {
          var ind = prevState.uploading.findIndex(function (val) {
            return val.id === file.id;
          });
          if (ind === -1) return null;
          prevState.uploading.splice(ind, 1);
          if (onUploading != null) onUploading(prevState.uploading, true);
          return {
            uploading: (0, _toConsumableArray2.default)(prevState.uploading)
          };
        });
      };
    };
    _this.onChanged = function (e) {
      var onUploading = _this.props.onUploading;
      if (e.event === 'onPosted' || e.event === 'onDeleted') {
        if (e.options != null && (_this.props.type == null || e.options['type'] === _this.props.type)) {
          var f = e.options;
          _this.setState(function (prevState) {
            var ind = prevState.uploading.findIndex(function (val) {
              return val.id === f.id;
            });
            if (e.event === 'onPosted' && ind === -1) {
              prevState.uploading.push({
                name: f.name,
                targetUrl: f.targetUrl,
                preview: f.preview,
                progress: 0,
                id: f.id,
                data: f.data,
                error: f.error,
                type: f.type
              });
            } else if (e.event === 'onDeleted' && ind > -1) {
              prevState.uploading.splice(ind, 1);
            }
            if (onUploading != null) onUploading(prevState.uploading);
            return {
              uploading: (0, _toConsumableArray2.default)(prevState.uploading)
            };
          });
        }
      }
    };
    _this.tryAgainFile = function (file) {
      return function (_e) {
        var _Alert$instance7;
        _this.alertOpen = false;
        (_Alert$instance7 = _Alert.Alert.instance) == null || _Alert$instance7.close();
        _Uploads.Uploads.instance.tryUploadAgain(file.id).catch(function (err) {
          return void 0;
        });
      };
    };
    _this.uploadProgress = function (progress) {
      var _this$props = _this.props,
        type = _this$props.type,
        onUploaded = _this$props.onUploaded,
        alertErrors = _this$props.alertErrors,
        onUploading = _this$props.onUploading;
      if (type == null || progress.tempFile.type === type) {
        if (progress.finished && progress.tempFile.error == null) {
          _this.setState(function (prevState) {
            var ind = prevState.uploading.findIndex(function (val) {
              return val.id === progress.tempFile.id;
            });
            if (ind === -1) return null;
            if (onUploaded != null) {
              onUploaded({
                preview: prevState.uploading[ind].preview,
                data: prevState.uploading[ind].data,
                type: prevState.uploading[ind].type,
                name: prevState.uploading[ind].name,
                targetUrl: `${prevState.uploading[ind].targetUrl}/${prevState.uploading[ind].id}`
              });
            }
            prevState.uploading.splice(ind, 1);
            if (onUploading != null) onUploading(prevState.uploading);
            return {
              uploading: (0, _toConsumableArray2.default)(prevState.uploading)
            };
          });
        } else if (progress.canceled) {
          _this.setState(function (prevState) {
            var ind = prevState.uploading.findIndex(function (val) {
              return val.id === progress.tempFile.id;
            });
            if (ind === -1) return null;
            prevState.uploading.splice(ind, 1);
            if (onUploading != null) onUploading(prevState.uploading, true);
            return {
              uploading: (0, _toConsumableArray2.default)(prevState.uploading)
            };
          });
        } else if (progress.tempFile.error != null) {
          _this.setState(function (prevState) {
            var ind = prevState.uploading.findIndex(function (val) {
              return val.id === progress.tempFile.id;
            });
            if (ind === -1) {
              var u = {
                name: progress.tempFile.name,
                progress: progress.percent,
                targetUrl: progress.tempFile.targetUrl,
                id: progress.tempFile.id,
                preview: progress.tempFile.preview,
                type: progress.tempFile.type,
                data: progress.tempFile.data,
                error: progress.tempFile.error
              };
              prevState.uploading.push(u);
              var connectedToServer = _this.context.connectedToServer;
              if (connectedToServer && alertErrors) _this.checkError(u)();
            } else {
              prevState.uploading[ind] = Object.assign({}, prevState.uploading[ind], {
                error: progress.tempFile.error
              });
              if (alertErrors) _this.checkError(prevState.uploading[ind])();
            }
            if (onUploading != null) onUploading(prevState.uploading, true);
            return {
              uploading: (0, _toConsumableArray2.default)(prevState.uploading)
            };
          });
        } else {
          _this.setState(function (prevState) {
            var ind = prevState.uploading.findIndex(function (val) {
              return val.id === progress.tempFile.id;
            });
            if (ind === -1) {
              prevState.uploading.push({
                name: progress.tempFile.name,
                progress: progress.percent,
                targetUrl: progress.tempFile.targetUrl,
                id: progress.tempFile.id,
                preview: progress.tempFile.preview,
                type: progress.tempFile.type,
                data: progress.tempFile.data,
                error: undefined
              });
            } else {
              prevState.uploading[ind] = Object.assign({}, prevState.uploading[ind], {
                progress: progress.percent,
                error: undefined
              });
            }
            if (onUploading != null) onUploading(prevState.uploading);
            return {
              uploading: (0, _toConsumableArray2.default)(prevState.uploading)
            };
          });
        }
      }
    };
    _this.state = {
      uploading: []
    };
    return _this;
  }
  (0, _inherits2.default)(UploadingList, _PureComponent);
  return (0, _createClass2.default)(UploadingList, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;
      _Uploads.Uploads.instance.evtProgressProgress.attach(this.uploadProgress);
      var _this$props2 = this.props,
        type = _this$props2.type,
        onUploading = _this$props2.onUploading;
      _Uploads.Uploads.instance.onChange.attach(this.onChanged);
      _Uploads.Uploads.getUploads(type).then(function (files) {
        var uploading = [];
        if (files.length > 0) {
          for (var f of files) {
            uploading.push({
              name: f.name,
              targetUrl: f.targetUrl,
              preview: f.preview,
              progress: 0,
              id: f.id,
              data: f.data,
              error: f.error,
              type: f.type
            });
          }
        }
        if (onUploading != null) onUploading(uploading);
        _this2.setState({
          uploading: uploading
        });
      }).catch(function (err) {
        return void 0;
      });
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _Uploads.Uploads.instance.evtProgressProgress.detach(this.uploadProgress);
      _Uploads.Uploads.instance.onChange.detach(this.onChanged);
    }
  }, {
    key: "render",
    value: function render() {
      var _this3 = this;
      var uploading = this.state.uploading;
      var _this$props3 = this.props,
        canCancel = _this$props3.canCancel,
        itemHeight = _this$props3.itemHeight,
        disablePreview = _this$props3.disablePreview,
        smallPreview = _this$props3.smallPreview,
        fileInfo = _this$props3.fileInfo;
      var listItems = [];
      var _loop = function _loop() {
        var f = uploading[i];
        var onPressIcon = f.error != null ? _this3.checkError(f) : canCancel ? _this3.cancelUpload(f) : undefined;
        var iconRight = f.error != null ? [{
          icon: 'alert-circle-outline',
          color: _ThemeManager.ThemeManager.style.brandDanger,
          onPress: onPressIcon
        }] : canCancel ? [{
          icon: 'close',
          onPress: onPressIcon
        }] : [{
          icon: 'progress-upload'
        }];
        var connectedToServer = _this3.context.connectedToServer;
        if (!f.error && f.preview != null && !connectedToServer && _reactNativeWeb.Platform.OS !== 'web') {
          iconRight.unshift({
            icon: 'pencil-outline',
            color: _ThemeManager.ThemeManager.style.defaultIconColor,
            onPress: function onPress() {
              var connectedToServer = _this3.context.connectedToServer;
              if (!f.error && f.preview != null && !connectedToServer && _reactNativeWeb.Platform.OS !== 'web') {
                _ImageEditor.ImageEditor.openImageEditor(f.preview, f.name, _this3.saveEditedImage(f)).catch(function (err) {});
              }
            }
          });
        }
        if (fileInfo) {
          iconRight.unshift({
            icon: 'information-outline',
            color: _ThemeManager.ThemeManager.style.defaultIconColor,
            onPress: function onPress() {
              fileInfo(f);
            }
          });
        }
        listItems.push((0, _jsxRuntime.jsx)(_ListItem.ListItem, {
          title: f.name,
          thumbnail: disablePreview ? undefined : _UploadTypes.UploadTypes.getThumbnailForFileName(f, undefined, {
            id: f.id,
            editSettings: f.preview != null && _reactNativeWeb.Platform.OS !== 'web' ? {
              saveEditedFunction: function saveEditedFunction(e) {
                _this3.saveEditedImage(f)(e).catch(function (err) {
                  return void 0;
                });
              },
              originalname: f.name,
              targetUrl: f.preview.toString()
            } : undefined
          }, false, !smallPreview),
          iconRight: iconRight,
          height: itemHeight,
          children: _this3.renderProgressBar(uploading[i]['progress'], f.error != null)
        }, `uploadingFileLI${f.id}`));
      };
      for (var i = 0; i < uploading.length; i += 1) {
        _loop();
      }
      return listItems;
    }
  }]);
}(_react.PureComponent);
UploadingList.contextType = _connectionContext.default;
UploadingList.defaultProps = {
  canCancel: true
};