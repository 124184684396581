var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TableOdata = TableOdata;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _ReadModels = require("cqrs-core/build/src/core/query/ReadModels");
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _I18n = require("../i18n/I18n");
var _ThemeManager = require("../ThemeManager");
var _Icon = require("./Icon");
var _Picker = require("./Picker");
var _SearchBar = require("./SearchBar");
var _Table = require("./Table");
var _TableRow = require("./TableRow");
var _MaterialText = require("./text/MaterialText");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function TableOdata(props) {
  var flatHeight = props.maxHeight - 54;
  var initOnPage = (0, _react.useRef)(props.maxHeight === 0 ? 20 : Math.floor(flatHeight / _TableRow.RAW_HEIGHT));
  var _useState = (0, _react.useState)(props.search || ''),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    search = _useState2[0],
    setSearch = _useState2[1];
  var _useState3 = (0, _react.useState)(0),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    counted = _useState4[0],
    setCounted = _useState4[1];
  var _useState5 = (0, _react.useState)(0),
    _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
    update = _useState6[0],
    setUpdate = _useState6[1];
  var _useState7 = (0, _react.useState)(0),
    _useState8 = (0, _slicedToArray2.default)(_useState7, 2),
    currentPage = _useState8[0],
    setCurrentPage = _useState8[1];
  var _useState9 = (0, _react.useState)(initOnPage.current),
    _useState10 = (0, _slicedToArray2.default)(_useState9, 2),
    onPage = _useState10[0],
    setOnPage = _useState10[1];
  var _useState11 = (0, _react.useState)(undefined),
    _useState12 = (0, _slicedToArray2.default)(_useState11, 2),
    data = _useState12[0],
    setData = _useState12[1];
  var _useState13 = (0, _react.useState)({
      sortBy: props.sortBy ? props.sortBy : 'id',
      direction: props.sortDirection ? props.sortDirection : 'desc'
    }),
    _useState14 = (0, _slicedToArray2.default)(_useState13, 2),
    order = _useState14[0],
    setOrder = _useState14[1];
  var mounted = true;
  var getFilter = function getFilter() {
    var filter = [];
    if (search) {
      var m = _ReadModels.ReadModels.get(props.readModel);
      if (m != null) {
        m.exampleEntity.dBOptions.forEach(function (_v, k) {
          filter.push(`contains(${k},'${search}')`);
        });
      }
    }
    return filter.length > 0 ? filter.join(' or ') : undefined;
  };
  (0, _react.useEffect)(function () {
    var m = _ReadModels.ReadModels.get(props.readModel);
    if (m != null) {
      m.count(getFilter()).then(function (c) {
        if (c !== counted && mounted) {
          setCounted(c);
        }
      }).catch(function (err) {
        return void 0;
      });
    }
    return function () {};
  }, [search, update]);
  (0, _react.useEffect)(function () {
    var attachKey;
    if (props.eventDispatcher) {
      props.eventDispatcher.attach({
        readModelName: props.readModel,
        callback: function callback() {
          setUpdate(update + 1);
        }
      });
    }
    return function () {
      if (attachKey && props.eventDispatcher) {
        props.eventDispatcher.detach(props.readModel, attachKey);
      }
    };
  });
  (0, _react.useEffect)(function () {
    var flatHeight = props.maxHeight - 54;
    var initOnPageNew = props.maxHeight === 0 ? 20 : Math.floor(flatHeight / _TableRow.RAW_HEIGHT);
    if (initOnPage.current !== initOnPageNew && onPage === initOnPage.current) {
      initOnPage.current = initOnPageNew;
      setOnPage(initOnPageNew);
    }
  }, [props.maxHeight]);
  (0, _react.useEffect)(function () {
    var timeOut = setTimeout(function () {
      var m = _ReadModels.ReadModels.get(props.readModel);
      if (m != null) {
        var orderby = order ? `${order.sortBy.toString()} ${order.direction.toUpperCase()}` : 'id DESC';
        m.get({
          filter: getFilter(),
          orderby: orderby,
          top: onPage,
          skip: currentPage * onPage
        }).then(function (d) {
          if (mounted) return props.convert(d);
          return [];
        }).then(function (d) {
          if (mounted) setData(d);
        }).catch(function (err) {
          return void 0;
        });
      }
    }, 200);
    return function () {
      clearTimeout(timeOut);
    };
  }, [onPage, currentPage, order, search, props.maxHeight, update]);
  var maxPages = Math.ceil(counted / onPage);
  var onChangePage = function onChangePage(d) {
    var n = Number.parseInt(d, 10);
    if (!Number.isNaN(n)) setCurrentPage(n);
  };
  var onChangeRowsPerPage = function onChangeRowsPerPage(d) {
    if (d === 4) {
      setCurrentPage(0);
      setOnPage(counted);
    } else {
      var n = Number.parseInt(d, 10);
      if (!Number.isNaN(n)) {
        setCurrentPage(0);
        if (n === 0) setOnPage(initOnPage.current);else setOnPage((n + 1) * 10);
      }
    }
  };
  var rowsPerPageList = [initOnPage.current.toString(10), '20', '30', '40', 'all'];
  var selectedRowIndex = onPage >= counted ? 4 : onPage === 20 ? 1 : onPage === 30 ? 2 : onPage === 40 ? 3 : 0;
  var pageList = ['1'];
  var selectedPageIndex = 0;
  while (pageList.length < maxPages) {
    if (currentPage === pageList.length) selectedPageIndex = pageList.length;
    pageList.push((pageList.length + 1).toString(10));
  }
  var overWriteSort = function overWriteSort(data) {
    if (order.sortBy !== data.sortBy || order.direction !== data.direction) {
      setOrder(Object.assign({}, data));
    }
  };
  var onSearch = function onSearch(t) {
    if (currentPage !== 0) setCurrentPage(0);
    setSearch(t);
  };
  return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
    style: {
      width: '100%'
    },
    children: [!props.searchField ? null : (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: Object.assign({
        width: '100%',
        flexDirection: 'row',
        padding: 8
      }, _ThemeManager.ThemeManager.style.borderStyle, {
        borderWidth: 0,
        borderBottomWidth: 1
      }),
      children: (0, _jsxRuntime.jsx)(_SearchBar.SearchBar, {
        hoverColor: _ThemeManager.ThemeManager.style.searchBarBackground,
        searchOnChange: onSearch,
        searchBarValue: search != null ? search : ''
      })
    }), (0, _jsxRuntime.jsx)(_Table.Table, Object.assign({}, props, {
      innerPaddingBottom: 0,
      data: data,
      overWriteSort: overWriteSort,
      sortBy: order.sortBy,
      sortDirection: order.direction
    })), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: 16
      },
      children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          paddingRight: 8,
          flexDirection: 'row'
        },
        children: [(0, _jsxRuntime.jsxs)(_MaterialText.MaterialText, {
          centeredBox: true,
          children: [_I18n.I18n.m.getMessage('rowsPerPage'), ":"]
        }), (0, _jsxRuntime.jsx)(_Picker.Picker, {
          selectedIndex: selectedRowIndex,
          list: rowsPerPageList,
          onChange: onChangeRowsPerPage
        })]
      }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          paddingRight: 8,
          flexDirection: 'row'
        },
        children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          centeredBox: true,
          children: _I18n.I18n.m.getMessage('page')
        }), (0, _jsxRuntime.jsx)(_Picker.Picker, {
          selectedIndex: selectedPageIndex,
          list: pageList,
          onChange: onChangePage
        }), (0, _jsxRuntime.jsxs)(_MaterialText.MaterialText, {
          centeredBox: true,
          children: [_I18n.I18n.m.getMessage('pageFrom'), " ", maxPages]
        })]
      }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          flexDirection: 'row'
        },
        children: [(0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: "chevron-left",
          toolTip: "",
          outerSize: 48,
          onPress: currentPage > 0 ? function () {
            return setCurrentPage(currentPage - 1);
          } : undefined
        }), (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: "chevron-right",
          toolTip: "",
          outerSize: 48,
          onPress: currentPage < maxPages - 1 ? function () {
            return setCurrentPage(currentPage + 1);
          } : undefined
        })]
      })]
    })]
  });
}