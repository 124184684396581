var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Zoom = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _BackgroundRenderer = require("./BackgroundRenderer");
var _Menu = require("./Menu");
var _VectorCalc = require("./VectorCalc");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var Zoom = exports.Zoom = function (_Component) {
  function Zoom(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Zoom);
    _this = _callSuper(this, Zoom, [props]);
    _this.initialDistance = 0;
    _this.initialScale = 1;
    _this.isZooming = false;
    _this.maxScale = 8;
    _this.processingPinch = false;
    _this.scaleSpeed = 1.2;
    _this.handleMinus = function () {
      var _this$state = _this.state,
        scaleFactor = _this$state.scaleFactor,
        translateY = _this$state.translateY,
        translateX = _this$state.translateX;
      _this.zoomTo(Math.round(scaleFactor / _this.scaleSpeed * 100) / 100, {
        x: translateX,
        y: translateY
      }, _this.props.animated);
    };
    _this.handlePlus = function () {
      var _this$state2 = _this.state,
        scaleFactor = _this$state2.scaleFactor,
        translateY = _this$state2.translateY,
        translateX = _this$state2.translateX;
      _this.zoomTo(Math.round(scaleFactor * _this.scaleSpeed * 100) / 100, {
        x: translateX,
        y: translateY
      }, _this.props.animated);
    };
    _this.onChanged = function (newsScale, newCenter) {
      var _this$props = _this.props,
        onChanged = _this$props.onChanged,
        area = _this$props.area;
      if (onChanged != null) {
        var newOffset = {
          x: Math.abs(newCenter.x * newsScale - area.width * (newsScale - 1) / 2),
          y: Math.abs(newCenter.y * newsScale - area.height * (newsScale - 1) / 2)
        };
        requestAnimationFrame(function () {
          onChanged(newsScale, newOffset);
        });
      }
    };
    _this.onDoubleClick = function (clickedPoint) {
      var scaleFactor = _this.state.scaleFactor;
      var newScaleFactor = scaleFactor * _this.scaleSpeed * 2;
      if (newScaleFactor > _this.maxScale) {
        newScaleFactor = 1;
      }
      _this.zoomToClick(clickedPoint, newScaleFactor);
    };
    _this.onLayout = function (_e) {
      if (_this.referrer != null) {
        _this.calculateOffset(_this.referrer);
      }
    };
    _this.setRef = function (ref) {
      if (ref != null) {
        _this.referrer = ref;
      }
    };
    var offsetNew = props.offset != null ? props.offset : {
      top: 0,
      left: 0
    };
    _this.state = {
      offset: offsetNew,
      disabled: props.disabled === true,
      scaleFactor: 1,
      translateX: 0,
      translateY: 0,
      animated: false
    };
    _this._selectionPanResponder = _reactNativeWeb.PanResponder.create({
      onStartShouldSetPanResponder: function onStartShouldSetPanResponder(_evt, _gestureState) {
        return !_this.state.disabled;
      },
      onStartShouldSetPanResponderCapture: function onStartShouldSetPanResponderCapture(_evt, _gestureState) {
        return !_this.state.disabled;
      },
      onMoveShouldSetPanResponder: function onMoveShouldSetPanResponder(_evt, _gestureState) {
        return !_this.state.disabled;
      },
      onMoveShouldSetPanResponderCapture: function onMoveShouldSetPanResponderCapture(_evt, _gestureState) {
        return !_this.state.disabled;
      },
      onPanResponderStart: function onPanResponderStart(_evt, _gestureState) {
        var _this$state3 = _this.state,
          translateY = _this$state3.translateY,
          translateX = _this$state3.translateX;
        _this.moveStarted = {
          x: translateX,
          y: translateY
        };
      },
      onPanResponderGrant: function onPanResponderGrant(_evt, _gestureState) {},
      onPanResponderMove: function onPanResponderMove(evt, gestureState) {
        var touches = evt.nativeEvent.touches;
        if (touches.length === 2) {
          var touch1 = touches[0];
          var touch2 = touches[1];
          _this.processingPinch = true;
          _this.processPinch({
            x: touch1.locationX,
            y: touch1.locationY
          }, {
            x: touch2.locationX,
            y: touch2.locationY
          });
        } else if (touches.length === 1) {
          if (_this.moveStarted != null && !_this.processingPinch) {
            var scaleFactor = _this.state.scaleFactor;
            var delta = {
              x: _this.moveStarted.x + gestureState.dx / scaleFactor,
              y: _this.moveStarted.y + gestureState.dy / scaleFactor
            };
            _this.onMove(delta);
          }
        }
        return false;
      },
      onPanResponderRelease: function onPanResponderRelease(_evt, _gestureState) {
        _this.moveStarted = undefined;
        _this.processingPinch = false;
        var offset = _this.state.offset;
        var clickedPoint = {
          x: _gestureState.x0 + offset.left,
          y: _gestureState.y0 + offset.top
        };
        if (_this.lastPress != null) {
          var timeDelta = new Date().getTime() - _this.lastPress.time;
          if (timeDelta < 200) {
            _this.onDoubleClick(clickedPoint);
          }
        }
        _this.isZooming = false;
        _this.lastPress = Object.assign({
          time: new Date().getTime()
        }, clickedPoint);
      },
      onPanResponderTerminationRequest: function onPanResponderTerminationRequest(_evt, _gestureState) {
        return true;
      },
      onShouldBlockNativeResponder: function onShouldBlockNativeResponder(_evt, _gestureState) {
        return true;
      }
    });
    return _this;
  }
  (0, _inherits2.default)(Zoom, _Component);
  return (0, _createClass2.default)(Zoom, [{
    key: "componentDidMount",
    value: function componentDidMount() {}
  }, {
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        collapsable: false,
        ref: this.setRef,
        onLayout: this.onLayout,
        children: [this.renderBackgroundImage(), this.createPanResponder(), this.renderMenu()]
      });
    }
  }, {
    key: "shouldComponentUpdate",
    value: function shouldComponentUpdate(nextProps, _nextState, _nextContext) {
      if (nextProps.zoomTo != null && nextProps.zoomTo !== this.props.zoomTo && nextProps.zoomTo !== _nextState.scaleFactor) {
        this.zoomTo(nextProps.zoomTo, {
          x: _nextState.translateX,
          y: _nextState.translateY
        }, nextProps.animated);
      }
      if (nextProps.positionInParentToChangeLayout != null && nextProps.positionInParentToChangeLayout !== this.props.positionInParentToChangeLayout && this.referrer != null) {
        this.calculateOffset(this.referrer);
      }
      if (this.state.scaleFactor !== _nextState.scaleFactor || this.state.translateX !== _nextState.translateX || this.state.translateY !== _nextState.translateY || this.state.disabled !== _nextState.disabled) {
        return true;
      }
      return this.props.area.width !== nextProps.area.width || this.props.area.height !== nextProps.area.height || this.props.overScroll !== nextProps.overScroll || this.props.children !== nextProps.children;
    }
  }, {
    key: "calculateOffset",
    value: function calculateOffset(ref) {
      var _this2 = this;
      if (this.props.offset != null) {
        return;
      }
      ref.measure(function (_x, _y, _width, _height, pageX, pageY) {
        var x = pageX;
        var y = pageY;
        if (y != null && x != null) {
          var offset = {
            top: (y + _ThemeManager.ThemeManager.style.statusBarHeight) * -1,
            left: x * -1
          };
          _this2.setState({
            offset: offset
          });
        }
      });
    }
  }, {
    key: "createPanResponder",
    value: function createPanResponder() {
      var _this3 = this;
      var disabled = this.state.disabled;
      if (disabled) {
        return null;
      }
      var mouseSupport;
      if (_reactNativeWeb.Platform.OS === 'web') {
        mouseSupport = {
          onWheel: function onWheel(e) {
            try {
              var _this3$state = _this3.state,
                scaleFactor = _this3$state.scaleFactor,
                offset = _this3$state.offset;
              var newScale = scaleFactor;
              if (e != null && e.deltaY != null) {
                if (e.deltaY > 0) {
                  newScale /= _this3.scaleSpeed;
                } else {
                  newScale *= _this3.scaleSpeed;
                }
                var mousePoint = {
                  x: e.clientX + offset.left,
                  y: e.clientY + offset.top
                };
                _this3.zoomToClick(mousePoint, Math.round(newScale * 100) / 100, false);
              }
            } catch (err) {}
          }
        };
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, Object.assign({
        style: {
          position: 'absolute',
          width: this.props.area.width,
          height: this.props.area.height,
          backgroundColor: 'transparent'
        }
      }, this._selectionPanResponder.panHandlers, mouseSupport));
    }
  }, {
    key: "limitZoom",
    value: function limitZoom(newCenter, newScaleFactor) {
      var _this$props2 = this.props,
        area = _this$props2.area,
        overScroll = _this$props2.overScroll;
      var overScrollScaled = overScroll != null ? {
        x: overScroll.x / newScaleFactor,
        y: overScroll.y / newScaleFactor
      } : {
        x: area.width / 2 / newScaleFactor,
        y: area.height / 2 / newScaleFactor
      };
      var x = Math.max(Math.min(newCenter.x, area.width / 2 - overScrollScaled.x), -area.width / 2 + overScrollScaled.x);
      var y = Math.max(Math.min(newCenter.y, area.height / 2 - overScrollScaled.y), -area.height / 2 + overScrollScaled.y);
      var scaleFactor = newScaleFactor;
      if (newScaleFactor <= 1) {
        x = 0;
        y = 0;
        scaleFactor = 1;
      } else if (newScaleFactor > this.maxScale) {
        return this.limitZoom(newCenter, this.maxScale);
      }
      return {
        x: x,
        y: y,
        scaleFactor: scaleFactor
      };
    }
  }, {
    key: "onMove",
    value: function onMove(move) {
      var scaleFactor = this.state.scaleFactor;
      var newCenter = this.limitZoom(move, scaleFactor);
      this.setState({
        translateX: newCenter.x,
        translateY: newCenter.y,
        animated: false
      });
    }
  }, {
    key: "processPinch",
    value: function processPinch(point1, point2) {
      var scaleFactor = this.state.scaleFactor;
      var distance = _VectorCalc.VectorCalc.instance.calcDistance(point1, point2);
      var center = _VectorCalc.VectorCalc.instance.calcCenter(point1, point2);
      if (!this.isZooming) {
        this.isZooming = true;
        this.initialScale = scaleFactor;
        this.initialDistance = distance;
        return;
      }
      var touchZoom = this.initialScale * (distance / this.initialDistance);
      this.zoomToClick(center, touchZoom, false);
    }
  }, {
    key: "renderBackgroundImage",
    value: function renderBackgroundImage() {
      var _this$props3 = this.props,
        area = _this$props3.area,
        onChanged = _this$props3.onChanged;
      var _this$state4 = this.state,
        scaleFactor = _this$state4.scaleFactor,
        translateX = _this$state4.translateX,
        translateY = _this$state4.translateY,
        animated = _this$state4.animated;
      return (0, _jsxRuntime.jsx)(_BackgroundRenderer.BackgroundRenderer, {
        area: area,
        scaleFactor: scaleFactor,
        translateX: translateX,
        translateY: translateY,
        animated: animated,
        onChanged: onChanged != null ? this.onChanged : undefined,
        children: this.props.children
      });
    }
  }, {
    key: "renderMenu",
    value: function renderMenu() {
      var disabled = this.state.disabled;
      var _this$props4 = this.props,
        showZoomButtons = _this$props4.showZoomButtons,
        zoomButtonStyle = _this$props4.zoomButtonStyle;
      if (disabled || !showZoomButtons) {
        return null;
      }
      return (0, _jsxRuntime.jsx)(_Menu.Menu, {
        style: zoomButtonStyle,
        handlePlus: this.handlePlus,
        handleMinus: this.handleMinus,
        disabled: disabled,
        showZoomButtons: showZoomButtons
      });
    }
  }, {
    key: "zoomTo",
    value: function zoomTo(scaleFactor, targetCenter) {
      var animated = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var newCenter = this.limitZoom(targetCenter, scaleFactor);
      this.setState({
        animated: animated,
        scaleFactor: newCenter.scaleFactor,
        translateX: newCenter.x,
        translateY: newCenter.y
      });
    }
  }, {
    key: "zoomToClick",
    value: function zoomToClick(clicked, newScale) {
      var animated = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.props.animated;
      var _this$state5 = this.state,
        scaleFactor = _this$state5.scaleFactor,
        translateX = _this$state5.translateX,
        translateY = _this$state5.translateY;
      if (scaleFactor >= this.maxScale && newScale != null && newScale >= scaleFactor) {
        return;
      }
      var area = this.props.area;
      var newScaleFactor = newScale != null ? newScale : scaleFactor * this.scaleSpeed;
      var clickedArea = {
        x: clicked.x - area.width / 2,
        y: clicked.y - area.height / 2
      };
      var clickedChildren = {
        x: (translateX * scaleFactor - clickedArea.x) / scaleFactor,
        y: (translateY * scaleFactor - clickedArea.y) / scaleFactor
      };
      var clickedFutureChildren = {
        x: (translateX * newScaleFactor - clickedArea.x) / newScaleFactor,
        y: (translateY * newScaleFactor - clickedArea.y) / newScaleFactor
      };
      var diff = {
        x: clickedFutureChildren.x - clickedChildren.x,
        y: clickedFutureChildren.y - clickedChildren.y
      };
      var newCenter = {
        x: translateX - diff.x,
        y: translateY - diff.y
      };
      this.zoomTo(newScaleFactor, newCenter, animated);
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        disabled: false,
        animated: true,
        showZoomButtons: false
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      var newState = Object.assign({}, prevState);
      if (nextProps.disabled != null && nextProps.disabled !== prevState.disabled) {
        newState.disabled = nextProps.disabled;
      }
      return newState;
    }
  }]);
}(_react.Component);