var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WrapperToolTips = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _ToolTipEventHandler = require("../tooltips/ToolTipEventHandler");
var _ToolTipWrapper = require("../tooltips/ToolTipWrapper");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var WrapperToolTips = exports.WrapperToolTips = function (_PureComponent) {
  function WrapperToolTips(props) {
    var _this;
    (0, _classCallCheck2.default)(this, WrapperToolTips);
    _this = _callSuper(this, WrapperToolTips, [props]);
    _this.toolTips = [];
    _this.handleToolTipClose = function (tooltip) {
      var objIndex = _this.toolTips.findIndex(function (obj) {
        return obj.id === tooltip.id;
      });
      if (objIndex > -1) {
        _this.toolTips.splice(objIndex, 1);
      }
      _this.renderToolTips();
    };
    _this.handleToolTipOpen = function (t) {
      _this.toolTips.push(t);
      _this.renderToolTips();
    };
    _this.state = {
      renderedToolTips: null
    };
    return _this;
  }
  (0, _inherits2.default)(WrapperToolTips, _PureComponent);
  return (0, _createClass2.default)(WrapperToolTips, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _ToolTipEventHandler.ToolTipEventHandler.toolTipOpenEvent.attach(this.handleToolTipOpen);
      _ToolTipEventHandler.ToolTipEventHandler.toolTipCloseEvent.attach(this.handleToolTipClose);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _ToolTipEventHandler.ToolTipEventHandler.toolTipOpenEvent.detach(this.handleToolTipOpen);
      _ToolTipEventHandler.ToolTipEventHandler.toolTipCloseEvent.detach(this.handleToolTipClose);
    }
  }, {
    key: "renderToolTips",
    value: function renderToolTips() {
      var renderedToolTips = null;
      if (this.toolTips.length > 0) {
        var t = this.toolTips[this.toolTips.length - 1];
        renderedToolTips = (0, _jsxRuntime.jsx)(_ToolTipWrapper.ToolTipWrapper, {
          t: t
        }, `TT${t.id}`);
      }
      if (this.state.renderedToolTips !== renderedToolTips) this.setState({
        renderedToolTips: renderedToolTips
      });
    }
  }, {
    key: "render",
    value: function render() {
      return this.state.renderedToolTips;
    }
  }]);
}(_react.PureComponent);