var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WrapperAutoCompletes = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _AutoCompleteEvents = require("../forminput/autocomplete/AutoCompleteEvents");
var _AutoCompleteWrapper = require("../forminput/autocomplete/AutoCompleteWrapper");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var WrapperAutoCompletes = exports.WrapperAutoCompletes = function (_PureComponent) {
  function WrapperAutoCompletes(props) {
    var _this;
    (0, _classCallCheck2.default)(this, WrapperAutoCompletes);
    _this = _callSuper(this, WrapperAutoCompletes, [props]);
    _this.autoComplete = null;
    _this.closeAutoComplete = function (_id) {
      _this.autoCompleteTO = setTimeout(function () {
        _this.autoComplete = null;
        _this.renderAutoCompletes();
      }, 195);
    };
    _this.openAutoComplete = function (a) {
      if (_this.autoCompleteTO) clearTimeout(_this.autoCompleteTO);
      _this.autoCompleteTO = setTimeout(function () {
        _this.autoComplete = a;
        _this.renderAutoCompletes();
      }, 195);
    };
    _this.state = {
      renderedAutoCompletes: []
    };
    return _this;
  }
  (0, _inherits2.default)(WrapperAutoCompletes, _PureComponent);
  return (0, _createClass2.default)(WrapperAutoCompletes, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _AutoCompleteEvents.AutoCompleteEvents.closeEvent.attach(this.closeAutoComplete);
      _AutoCompleteEvents.AutoCompleteEvents.openEvent.attach(this.openAutoComplete);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _AutoCompleteEvents.AutoCompleteEvents.closeEvent.detach(this.closeAutoComplete);
      _AutoCompleteEvents.AutoCompleteEvents.openEvent.detach(this.openAutoComplete);
    }
  }, {
    key: "renderAutoCompletes",
    value: function renderAutoCompletes() {
      var renderedAutoCompletes = null;
      if (this.autoComplete != null) {
        var size = this.props.size;
        var a = this.autoComplete;
        renderedAutoCompletes = (0, _jsxRuntime.jsx)(_AutoCompleteWrapper.AutoCompleteWrapper, {
          size: size,
          a: a
        }, `AC${a.id}`);
      }
      this.setState({
        renderedAutoCompletes: renderedAutoCompletes
      });
    }
  }, {
    key: "render",
    value: function render() {
      return this.state.renderedAutoCompletes;
    }
  }]);
}(_react.PureComponent);