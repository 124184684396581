var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../ThemeManager");
var _MaterialText = require("./text/MaterialText");
var _Ripple = require("./utils/Ripple");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var INumberPicker = function (_PureComponent) {
  function INumberPicker(props) {
    var _this;
    (0, _classCallCheck2.default)(this, INumberPicker);
    _this = _callSuper(this, INumberPicker, [props]);
    _this.format = function (value) {
      var _this$props = _this.props,
        formatTime = _this$props.formatTime,
        fractionDigits = _this$props.fractionDigits,
        step = _this$props.step;
      if (!formatTime) {
        var fd = fractionDigits == null ? step === 1 ? 0 : step.toString().split('.')[1].length : undefined;
        try {
          return value.toFixed(fd);
        } catch (e) {
          return value.toString(10) || '0';
        }
      }
      return `${Math.floor(value / 60)}:${(value % 60 < 10 ? '0' : '') + value % 60}`;
    };
    _this.formatInput = function () {
      var _this$state = _this.state,
        input = _this$state.input,
        value = _this$state.value;
      var _this$props2 = _this.props,
        formatTime = _this$props2.formatTime,
        step = _this$props2.step,
        fractionDigits = _this$props2.fractionDigits;
      if (!formatTime) {
        if (step === 1 && fractionDigits == null || fractionDigits === 0) _this.setValue(Number.parseInt(input, 10));else _this.setValue(Number.parseFloat(input));
        return;
      }
      var splitted = input.split(':');
      if (splitted.length === 2) {
        var _value = Number.parseInt(splitted[0], 10) * 60 + parseInt(splitted[1], 10);
        _this.setValue(_value);
      } else if (splitted[0].length > 0) {
        _this.setValue(Number.parseInt(splitted[0], 10));
      } else {
        _this.setState({
          input: _this.format(value)
        });
      }
    };
    var min = _this.props.min;
    var value = props.initValue != null ? props.initValue : Math.max(min, 0);
    _this.state = {
      value: props.initValue != null ? props.initValue : Math.max(min, 0),
      input: _this.format(value),
      test: _this.props.formatTime ? /^[0-9:]*$/ : _this.props.step === 1 ? /^[0-9]*$/ : /^[0-9.]*$/
    };
    return _this;
  }
  (0, _inherits2.default)(INumberPicker, _PureComponent);
  return (0, _createClass2.default)(INumberPicker, [{
    key: "blur",
    value: function blur() {}
  }, {
    key: "clear",
    value: function clear() {}
  }, {
    key: "focus",
    value: function focus() {}
  }, {
    key: "getId",
    value: function getId() {
      return '';
    }
  }, {
    key: "getValue",
    value: function getValue() {
      return this.state.value;
    }
  }, {
    key: "setValue",
    value: function setValue(value) {
      var _this$props3 = this.props,
        onChange = _this$props3.onChange,
        min = _this$props3.min,
        max = _this$props3.max,
        step = _this$props3.step,
        fractionDigits = _this$props3.fractionDigits;
      if (value == null) return;
      var newVal = value;
      var fd = fractionDigits == null ? step === 1 ? 0 : step.toString().split('.')[1].length : undefined;
      try {
        newVal = Number.parseFloat(value.toFixed(fd));
      } catch (e) {
        newVal = value;
      }
      newVal = Math.max(value, min);
      newVal = Math.min(newVal, max);
      this.setState({
        value: newVal,
        input: this.format(newVal)
      }, function () {
        if (onChange) onChange(newVal);
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;
      var _this$props4 = this.props,
        title = _this$props4.title,
        step = _this$props4.step;
      var _this$state2 = this.state,
        input = _this$state2.input,
        value = _this$state2.value,
        test = _this$state2.test;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          height: 48,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            children: title
          })
        }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            height: 36,
            width: 120,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: _ThemeManager.ThemeManager.style.borderColor,
            alignItems: 'center',
            flexDirection: 'row'
          },
          children: [(0, _jsxRuntime.jsx)(_Ripple.Ripple, {
            style: {
              width: 36,
              justifyContent: 'center'
            },
            onPress: function onPress() {
              return _this2.setValue(value - step);
            },
            accessibilityLabel: "minusValue",
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              fixedWidth: "100%",
              textAlign: "center",
              fontSize: 30,
              color: _ThemeManager.ThemeManager.style.black54,
              children: "-"
            })
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              alignSelf: 'center'
            },
            children: (0, _jsxRuntime.jsx)(_reactNativeWeb.TextInput, {
              onBlur: this.formatInput,
              onChange: function onChange(e) {
                return _this2.setState({
                  input: test.test(e.nativeEvent.text) ? e.nativeEvent.text : input
                });
              },
              style: [(0, _MaterialText.getStyleForType)(_MaterialText.MaterialTextTypes.Body1), {
                width: 48,
                textAlign: 'center',
                fontSize: _ThemeManager.ThemeManager.style.getTextInputFontSize()
              }],
              value: input
            })
          }), (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
            style: {
              width: 36,
              justifyContent: 'center'
            },
            onPress: function onPress() {
              return _this2.setValue(value + step);
            },
            accessibilityLabel: "plusValue",
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              fixedWidth: "100%",
              textAlign: "center",
              fontSize: 30,
              color: _ThemeManager.ThemeManager.style.black54,
              children: "+"
            })
          })]
        })]
      });
    }
  }]);
}(_react.PureComponent);
INumberPicker.defaultProps = {
  min: 0,
  max: 5985,
  step: 1,
  formatTime: true
};
var _default = exports.default = INumberPicker;