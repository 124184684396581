var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScaleAnimation = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _Animation2 = _interopRequireDefault(require("./Animation"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ScaleAnimation = exports.ScaleAnimation = function (_Animation) {
  function ScaleAnimation() {
    var _this;
    var toValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 195;
    var direction = arguments.length > 2 ? arguments[2] : undefined;
    (0, _classCallCheck2.default)(this, ScaleAnimation);
    _this = _callSuper(this, ScaleAnimation, [toValue]);
    _this.duration = 195;
    _this.duration = duration;
    _this.direction = direction != null ? direction : 'x';
    return _this;
  }
  (0, _inherits2.default)(ScaleAnimation, _Animation);
  return (0, _createClass2.default)(ScaleAnimation, [{
    key: "createAnimations",
    value: function createAnimations() {
      var scaleProp = 'scale';
      if (this.direction === 'x') {
        scaleProp = 'scaleX';
      } else if (this.direction === 'y') {
        scaleProp = 'scaleY';
      }
      var transform = [(0, _defineProperty2.default)({}, scaleProp, this.animate.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1]
      })), (0, _defineProperty2.default)({}, scaleProp, this.animate.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1]
      }))];
      return {
        transform: transform,
        opacity: this.animate
      };
    }
  }, {
    key: "toValue",
    value: function toValue(_toValue, onFinished) {
      _reactNativeWeb.Animated.timing(this.animate, {
        toValue: _toValue,
        duration: this.duration,
        useNativeDriver: _reactNativeWeb.Platform.OS !== 'web'
      }).start(function () {
        if (onFinished != null) {
          onFinished();
        }
      });
    }
  }]);
}(_Animation2.default);