var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Card = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../ThemeManager");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var Card = exports.Card = function (_PureComponent) {
  function Card(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Card);
    _this = _callSuper(this, Card, [props]);
    _this.onLayout = function (e) {
      if (e && e.nativeEvent && e.nativeEvent.layout && e.nativeEvent.layout.width > 0) {
        _this.setState({
          imageWidth: e.nativeEvent.layout.width
        });
      }
      if (_this.props.onLayout != null) {
        _this.props.onLayout(e);
      }
    };
    _this.state = {
      imageWidth: 1
    };
    return _this;
  }
  (0, _inherits2.default)(Card, _PureComponent);
  return (0, _createClass2.default)(Card, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        children = _this$props.children,
        style = _this$props.style,
        backgroundColor = _this$props.backgroundColor,
        outerPadding = _this$props.outerPadding,
        contentHeight = _this$props.contentHeight;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          height: contentHeight || 'auto',
          padding: outerPadding,
          overflow: 'visible'
        },
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          onLayout: this.onLayout,
          style: [_ThemeManager.ThemeManager.style.elevation2, {
            backgroundColor: backgroundColor,
            justifyContent: 'flex-start',
            borderRadius: 6,
            overflow: _reactNativeWeb.Platform.OS !== 'ios' ? 'hidden' : 'visible'
          }, style],
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              width: '100%',
              height: contentHeight || 'auto'
            },
            children: children
          })
        })
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        backgroundColor: _ThemeManager.ThemeManager.style.cardDefaultBg,
        outerPadding: 8
      };
    }
  }]);
}(_react.PureComponent);