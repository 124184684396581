var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ThemeManager = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _reactNativeWeb = require("../../../../upmesh-support/node_modules/react-native-web");
var _Styles = require("./Styles");
var ThemeManager = exports.ThemeManager = function () {
  function ThemeManager() {
    (0, _classCallCheck2.default)(this, ThemeManager);
  }
  return (0, _createClass2.default)(ThemeManager, null, [{
    key: "noSelectionWebStyle",
    value: function noSelectionWebStyle() {
      return _reactNativeWeb.Platform.OS === 'web' ? {
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none',
        'outline-style': 'none'
      } : {};
    }
  }, {
    key: "style",
    get: function get() {
      if (ThemeManager._style == null) {
        ThemeManager._style = new _Styles.Styles();
      }
      return ThemeManager._style;
    },
    set: function set(style) {
      ThemeManager._style = style;
    }
  }]);
}();