var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var Animation = exports.default = function () {
  function Animation() {
    var toValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var _optional = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    (0, _classCallCheck2.default)(this, Animation);
    this._value = toValue;
    this.animate = new _reactNativeWeb.Animated.Value(toValue);
    this.animations = this.createAnimations();
  }
  return (0, _createClass2.default)(Animation, [{
    key: "value",
    get: function get() {
      return this._value;
    }
  }, {
    key: "createAnimations",
    value: function createAnimations(_data) {
      return {};
    }
  }, {
    key: "toValue",
    value: function toValue(_toValue, _onFinished) {}
  }]);
}();