"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _assertThisInitialized2 = _interopRequireDefault(require("@babel/runtime/helpers/assertThisInitialized"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubscriptionChanged = void 0;
var EventRootOdata_1 = require("cqrs-core/build/src/core/events/EventRootOdata");
var SubscriptionChanged = function (_EventRootOdata_1$Eve) {
  function SubscriptionChanged(entityId, data) {
    var _this;
    (0, _classCallCheck2.default)(this, SubscriptionChanged);
    var eventName = 'SubscriptionChanged';
    if (data.userId != null && data.subscription === 'pro') {
      _this = _callSuper(this, SubscriptionChanged, [{
        entityId: entityId,
        data: data,
        eventName: eventName,
        aggregate: 'user',
        aggregateId: data.userId
      }]);
      return (0, _possibleConstructorReturn2.default)(_this, (0, _assertThisInitialized2.default)(_this));
    }
    if (data.companyId != null && data.subscription === 'enterprise') {
      _this = _callSuper(this, SubscriptionChanged, [{
        entityId: entityId,
        data: data,
        eventName: eventName,
        aggregate: 'company',
        aggregateId: data.companyId
      }]);
      return (0, _possibleConstructorReturn2.default)(_this, (0, _assertThisInitialized2.default)(_this));
    }
    throw new Error(`combination not working: ${data.subscription}, company: ${data.companyId}, user: ${data.userId}`);
    return (0, _assertThisInitialized2.default)(_this);
  }
  (0, _inherits2.default)(SubscriptionChanged, _EventRootOdata_1$Eve);
  return (0, _createClass2.default)(SubscriptionChanged);
}(EventRootOdata_1.EventRootOdata);
exports.SubscriptionChanged = SubscriptionChanged;