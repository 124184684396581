var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListItem = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _color = _interopRequireDefault(require("color"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../ThemeManager");
var _Icon = require("./Icon");
var _MaterialText = require("./text/MaterialText");
var _Ripple = require("./utils/Ripple");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ListItem = exports.ListItem = function (_PureComponent) {
  function ListItem(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ListItem);
    _this = _callSuper(this, ListItem, [props]);
    _this.onEnterAll = function () {
      _this.setState({
        hoverAll: true
      });
    };
    _this.onLeaveAll = function () {
      _this.setState({
        hoverAll: false
      });
    };
    _this.onPress = function (e) {
      if (_this.props.onPress != null) {
        if (e.persist != null && typeof e.persist === 'function') e.persist();
        _this.props.onPress(e);
      }
    };
    _this.setMainTooltip = function (mainTooltip) {
      _this.setState({
        mainTooltip: mainTooltip
      });
    };
    _this.state = {
      hoverAll: false,
      hoverText: false
    };
    return _this;
  }
  (0, _inherits2.default)(ListItem, _PureComponent);
  return (0, _createClass2.default)(ListItem, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        width = _this$props.width,
        backgroundColor = _this$props.backgroundColor,
        onPress = _this$props.onPress,
        paddingLeft = _this$props.paddingLeft,
        selected = _this$props.selected,
        hoverColor = _this$props.hoverColor,
        style = _this$props.style,
        title = _this$props.title,
        iconRight = _this$props.iconRight;
      var _this$state = this.state,
        hoverAll = _this$state.hoverAll,
        mainTooltip = _this$state.mainTooltip;
      var bgColor = backgroundColor;
      var pLeft = paddingLeft != null ? paddingLeft : 0;
      if (hoverAll && this.onPress != null) {
        bgColor = hoverColor;
      }
      if (selected) {
        bgColor = _ThemeManager.ThemeManager.style.lisItemSelectedColor;
      }
      var outerStyle = Object.assign({
        width: width,
        position: 'relative',
        paddingLeft: pLeft,
        backgroundColor: bgColor,
        alignItems: 'center',
        flexDirection: 'row'
      }, style);
      if (onPress == null) {
        return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: outerStyle,
          children: [this.renderLeft(), this.renderMain(), this.renderRight()]
        });
      }
      var iconOnPress = false;
      if (iconRight != null && iconRight instanceof Array) {
        var value = iconRight.find(function (element) {
          return element.onPress != null;
        });
        iconOnPress = !(value != null);
      } else if (iconRight != null) {
        iconOnPress = iconRight.onPress == null;
      }
      if (onPress != null && iconOnPress) {
        return (0, _jsxRuntime.jsxs)(_Ripple.Ripple, {
          style: outerStyle,
          onPress: this.onPress,
          rippleColor: _ThemeManager.ThemeManager.style.lisItemRippleColor,
          hoverColor: hoverColor,
          toolTip: mainTooltip,
          accessibilityLabel: title,
          onMouseEnter: this.onEnterAll,
          onMouseLeave: this.onLeaveAll,
          children: [this.renderLeft(), this.renderMain(), this.renderRight()]
        });
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: Object.assign({}, outerStyle, {
          justifyContent: 'space-between',
          paddingLeft: 0
        }),
        children: [(0, _jsxRuntime.jsxs)(_Ripple.Ripple, {
          onPress: this.onPress,
          rippleColor: _ThemeManager.ThemeManager.style.lisItemRippleColor,
          style: Object.assign({}, outerStyle, {
            width: '100%'
          }),
          hoverColor: hoverColor,
          toolTip: mainTooltip,
          accessibilityLabel: title,
          onMouseEnter: this.onEnterAll,
          onMouseLeave: this.onLeaveAll,
          children: [this.renderLeft(), this.renderMain()]
        }), this.renderRight()]
      });
    }
  }, {
    key: "renderLeft",
    value: function renderLeft() {
      var thumbnail = this.props.thumbnail;
      if (thumbnail === undefined) {
        return null;
      }
      var thumbnailHeight = thumbnail.width === 100 ? 56 : thumbnail.width;
      var thumbnailWidth = thumbnail.width != null ? thumbnail.width : 24;
      var thumbnailRounded = thumbnail.rounded != null ? thumbnail.rounded : false;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          height: thumbnailHeight,
          width: thumbnailWidth != null ? thumbnailWidth : 40,
          borderRadius: thumbnailRounded ? thumbnailHeight / 2 : 0,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          marginLeft: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(thumbnailWidth < 100 ? 16 : 0),
          overflow: 'visible'
        },
        children: thumbnail.thumbnail
      });
    }
  }, {
    key: "renderMain",
    value: function renderMain() {
      var _this$props2 = this.props,
        title = _this$props2.title,
        showToolTipOnCutText = _this$props2.showToolTipOnCutText,
        secondTextLine = _this$props2.secondTextLine,
        thirdTextLine = _this$props2.thirdTextLine,
        textColor = _this$props2.textColor,
        iconRight = _this$props2.iconRight,
        titleColor = _this$props2.titleColor;
      var rightIcons = iconRight != null && Array.isArray(iconRight) ? iconRight.length : 1;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          flex: 1,
          height: ListItem.getHeight(this.props),
          maxWidth: '100%',
          paddingLeft: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(this.props.paddingMidToLeft != null ? this.props.paddingMidToLeft : _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16)),
          paddingRight: iconRight != null ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16) + _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(36) * rightIcons : _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16),
          alignItems: 'flex-start',
          alignContent: 'center',
          justifyContent: 'center',
          flexShrink: 1,
          backgroundColor: 'transparent'
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '100%'
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.Body1,
            onToolTipCreated: this.setMainTooltip,
            numberOfLines: 1,
            textPadding: 0,
            color: titleColor != null ? titleColor : textColor,
            fontSize: 16,
            fixedWidth: "100%",
            ellipsizeMode: "tail",
            showToolTipOnCutText: !!showToolTipOnCutText,
            children: title
          })
        }), secondTextLine != null && secondTextLine.length > 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '100%'
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            textPadding: 0,
            numberOfLines: 1,
            ellipsizeMode: "tail",
            type: _MaterialText.MaterialTextTypes.Body1,
            color: titleColor != null ? textColor : (0, _color.default)(textColor).alpha(0.54).toString(),
            fontSize: 12,
            fixedWidth: "100%",
            children: secondTextLine
          })
        }) : null, thirdTextLine != null && thirdTextLine.length > 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '100%'
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            textPadding: 0,
            numberOfLines: 1,
            type: _MaterialText.MaterialTextTypes.Body1,
            color: titleColor != null ? textColor : (0, _color.default)(textColor).alpha(0.54).toString(),
            fontSize: 12,
            fixedWidth: "100%",
            children: thirdTextLine
          })
        }) : null, (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '100%'
          },
          children: this.props.children
        })]
      });
    }
  }, {
    key: "renderRight",
    value: function renderRight() {
      var _this$props3 = this.props,
        textRight = _this$props3.textRight,
        textColor = _this$props3.textColor,
        iconRight = _this$props3.iconRight;
      var height = ListItem.getHeight(this.props);
      if (iconRight == null && (textRight == null || textRight.length === 0)) {
        return null;
      }
      var items = [];
      if (textRight != null && textRight.length > 0) {
        items.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            position: 'absolute',
            top: 8,
            right: 16
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            textPadding: 0,
            type: _MaterialText.MaterialTextTypes.Caption,
            color: (0, _color.default)(textColor).alpha(0.42).toString(),
            children: textRight
          })
        }, "rightText"));
      }
      if (iconRight != null) {
        if (iconRight instanceof Array) {
          var icons = [];
          iconRight.forEach(function (element) {
            icons.push((0, _jsxRuntime.jsx)(_Icon.Icon, Object.assign({}, element, {
              toolTip: ""
            }), `icon_${JSON.stringify(element)}`));
          });
          items.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              height: height,
              position: 'absolute',
              right: 16,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            },
            children: icons
          }, "rightIcon"));
        } else {
          items.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              height: height,
              position: 'absolute',
              right: 16,
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'center'
            },
            children: (0, _jsxRuntime.jsx)(_Icon.Icon, Object.assign({}, iconRight, {
              toolTip: ""
            }))
          }, "rightIcon"));
        }
      }
      return items;
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        width: '100%',
        title: '',
        textColor: _ThemeManager.ThemeManager.style.black87,
        backgroundColor: '#FFFFFF',
        hoverColor: _ThemeManager.ThemeManager.style.lisItemHoverColor,
        selected: false
      };
    }
  }, {
    key: "getHeight",
    value: function getHeight(props) {
      var secondTextLine = props.secondTextLine,
        thirdTextLine = props.thirdTextLine,
        thumbnail = props.thumbnail,
        height = props.height;
      if (height != null) return height;
      var newHeight = 48;
      if (thirdTextLine != null && thirdTextLine.length > 0) {
        return _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(88);
      }
      if (secondTextLine != null && secondTextLine.length > 0) {
        if (thumbnail != null) {
          return _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(72);
        }
        return _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(64);
      }
      if (thumbnail != null) {
        var thumbHeight = thumbnail.width === 100 ? 56 : thumbnail.width;
        newHeight = thumbHeight + (thumbnail.width === 24 ? 32 : 16);
      }
      return _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(newHeight);
    }
  }]);
}(_react.PureComponent);