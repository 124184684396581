var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DialogPickerState = exports.DialogPicker = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _scrollbarWidth = require("@xobotyi/scrollbar-width");
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _I18n = require("../i18n/I18n");
var _ResizeEvent = require("../ResizeEvent");
var _Routing = require("../routing/Routing");
var _ThemeManager = require("../ThemeManager");
var _DialogAnimation = require("./animations/DialogAnimation");
var _DialogActions = require("./dialog/DialogActions");
var _Icon = require("./Icon");
var _Overlay = require("./Overlay");
var _ScrollContainer = require("./scroll/ScrollContainer");
var _ScrollEvents = require("./scroll/ScrollEvents");
var _MaterialText = require("./text/MaterialText");
var _Toast = require("./Toast");
var _KeyboardEvents = require("./utils/KeyboardEvents");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var DialogPickerState;
(function (DialogPickerState) {
  DialogPickerState["opened"] = "opened";
  DialogPickerState["opening"] = "opening";
  DialogPickerState["closed"] = "closed";
  DialogPickerState["closing"] = "closing";
})(DialogPickerState || (exports.DialogPickerState = DialogPickerState = {}));
var DialogPicker = exports.DialogPicker = function (_PureComponent) {
  function DialogPicker(props) {
    var _this;
    (0, _classCallCheck2.default)(this, DialogPicker);
    _this = _callSuper(this, DialogPicker, [props]);
    _this.scrollEvent = new _ScrollEvents.ScrollEvents();
    _this.scrollPosition = 0;
    _this.animationRunning = false;
    _this._onLayoutView = function (_event) {
      _this.forceUpdate();
    };
    _this.close = function (_callBack) {
      if (!_this.animationRunning) {
        if (_this.animationRunningTO != null) {
          clearTimeout(_this.animationRunningTO);
        }
        _this.closeNow(_callBack);
      } else {
        _this.animationRunningTO = setTimeout(function () {
          _this.close(_callBack);
        }, 200);
      }
    };
    _this.closeNow = function (_callBack) {
      _this.stopKeyboardEvents();
      if (_Toast.Toast.isOpened) {
        _Toast.Toast.restartTimeout();
      }
      var onClosed = _this.props.onClosed;
      var onClose = _this.state.onClose;
      var callback = function callback() {
        if (onClosed != null && typeof onClosed === 'function') {
          onClosed();
        }
        if (onClose != null && typeof onClose === 'function') {
          onClose();
        }
        if (_callBack != null && typeof _callBack === 'function') {
          _callBack();
        }
      };
      _this.setdialogState(0, callback);
    };
    _this.closeHandler = function () {
      if (!_this.state.isBlocking) {
        var _DialogPicker$instanc;
        (_DialogPicker$instanc = DialogPicker.instance) == null || _DialogPicker$instanc.close();
      }
      return true;
    };
    _this.onOverlayPress = function () {
      var closeOnTouchOutside = _this.state.closeOnTouchOutside;
      if (closeOnTouchOutside === true) {
        if (_this.state.closeFunction != null) {
          _this.state.closeFunction();
        } else {
          _this.close();
        }
      }
    };
    _this.stopKeyboardEvents = function () {
      _KeyboardEvents.KeyboardEvents.remove('DialogPicker');
      if (_reactNativeWeb.Platform.OS === 'android') {
        _reactNativeWeb.BackHandler.removeEventListener('hardwareBackPress', _this.closeHandler);
      }
    };
    _this.onResize = function (e) {
      _this.setState({
        windowHeight: e.windowHeight,
        windowWidth: e.windowWidth
      });
    };
    _this.handleScroll = function (e) {
      if (e && e.nativeEvent && e.nativeEvent.contentOffset && e.nativeEvent.contentOffset.y) {
        _this.scrollPosition = e.nativeEvent.contentOffset.y;
        _this.scrollEvent.scrollPostion = e.nativeEvent.contentOffset.y;
      }
    };
    _this.state = DialogPicker.defaultState;
    _this.dialogAnimation = new _DialogAnimation.DialogAnimation(0, undefined, _this.props.animationDuration, 24);
    return _this;
  }
  (0, _inherits2.default)(DialogPicker, _PureComponent);
  return (0, _createClass2.default)(DialogPicker, [{
    key: "dialogSize",
    get: function get() {
      return {
        dialogStyle: {
          maxWidth: undefined,
          maxHeight: undefined,
          width: 'auto',
          height: 'auto'
        },
        containerStyle: {
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: _ResizeEvent.ResizeEvent.current.contentTop,
          paddingLeft: _ResizeEvent.ResizeEvent.current.contentLeft,
          paddingRight: _ResizeEvent.ResizeEvent.current.contentRight,
          paddingBottom: _ResizeEvent.ResizeEvent.current.currentKeyboardHeight + _ResizeEvent.ResizeEvent.current.contentBottom
        }
      };
    }
  }, {
    key: "dialogState",
    get: function get() {
      return this.state.dialogState;
    }
  }, {
    key: "pointerEvents",
    get: function get() {
      if (this.props.overlayPointerEvents != null) {
        return this.props.overlayPointerEvents;
      }
      return this.state.dialogState === DialogPickerState.opened ? 'auto' : 'none';
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      _ResizeEvent.ResizeEvent.onResize.attach(this.onResize);
      DialogPicker._instance = this;
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _ResizeEvent.ResizeEvent.onResize.detach(this.onResize);
      if (_reactNativeWeb.Keyboard != null) {
        _reactNativeWeb.Keyboard.dismiss();
      }
    }
  }, {
    key: "open",
    value: function open(state) {
      var _this2 = this;
      var openImmediately = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      this.startKeyboardEvents();
      if (!this.animationRunning) {
        if (this.animationRunningTO != null) {
          clearTimeout(this.animationRunningTO);
        }
        var newState = Object.assign({}, DialogPicker.defaultState, state);
        if (newState.openPosition == null) {
          newState.openPosition = {
            x: _ResizeEvent.ResizeEvent.current.windowWidth / 2,
            y: _ResizeEvent.ResizeEvent.current.windowHeight / 2,
            width: 1,
            height: 1
          };
        } else {
          this.dialogAnimation = new _DialogAnimation.DialogAnimation(this.dialogState === DialogPickerState.opened ? 1 : 0, newState.openPosition, this.props.animationDuration, 24);
        }
        newState.openImmediately = openImmediately;
        this.setState(newState, function () {
          if (openImmediately) {
            _this2.openNow();
          }
        });
      } else {
        this.animationRunningTO = setTimeout(function () {
          _this2.open(state, openImmediately);
        }, 200);
      }
    }
  }, {
    key: "openNow",
    value: function openNow() {
      if (_Toast.Toast.isOpened) {
        _Toast.Toast.resetTimeout();
      }
      var onOpened = this.props.onOpened;
      this.setdialogState(1, onOpened);
    }
  }, {
    key: "getScrollBarWidth",
    value: function getScrollBarWidth() {
      var scrollable = this.state.scrollable;
      if (_reactNativeWeb.Platform.OS !== 'web' || !scrollable) return 0;
      try {
        var s = (0, _scrollbarWidth.scrollbarWidth)();
        return s != null ? s : 0;
      } catch (err) {
        return 0;
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _DialogPicker$instanc2;
      var _this$state = this.state,
        dialogState = _this$state.dialogState,
        showCloseIcon = _this$state.showCloseIcon;
      if (dialogState === DialogPickerState.closed && this.state.openImmediately !== true) {
        return null;
      }
      var overlayPointerEvents = this.pointerEvents;
      var dialogSize = this.dialogSize.dialogStyle;
      var hidden = dialogState === DialogPickerState.closed ? _ThemeManager.ThemeManager.style.dialogHidden : {};
      var isShowOverlay = dialogState === DialogPickerState.opened || dialogState === DialogPickerState.opening;
      var dimensions = {
        width: this.state.windowWidth,
        height: this.state.windowHeight
      };
      var maxContentHeight = '100%';
      var header = [];
      var footer = [];
      var closeIcon = showCloseIcon ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          right: Math.max(8, this.getScrollBarWidth()),
          top: 8,
          width: 36,
          height: 36
        },
        children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
          onPress: (_DialogPicker$instanc2 = DialogPicker.instance) == null ? void 0 : _DialogPicker$instanc2.close,
          toolTip: _I18n.I18n.m.getMessage('close'),
          icon: "close"
        })
      }) : null;
      var animationStyle = this.dialogAnimation != null && this.dialogAnimation.animations != null ? this.dialogAnimation.animations : undefined;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: [_ThemeManager.ThemeManager.style.dialogContainer, hidden, dimensions, this.dialogSize.containerStyle],
        children: [(0, _jsxRuntime.jsx)(_Overlay.Overlay, {
          width: this.state.windowWidth,
          height: this.state.windowHeight,
          pointerEvents: overlayPointerEvents,
          showOverlay: isShowOverlay,
          onPress: this.state.closeOnTouchOutside === true ? this.onOverlayPress : undefined,
          backgroundColor: this.props.overlayBackgroundColor,
          opacity: this.props.overlayOpacity,
          animationDuration: this.props.animationDuration
        }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.Animated.View, {
          onLayout: this._onLayoutView,
          style: [_ThemeManager.ThemeManager.style.dialogBackground, _ThemeManager.ThemeManager.style.elevation24, dialogSize, animationStyle],
          children: [header, header.length === 0 && this.state.fixedTitle === true && this.state.title != null && this.state.title.length > 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              padding: _ThemeManager.ThemeManager.style.contentPaddingValue
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              type: _MaterialText.MaterialTextTypes.H6,
              children: this.state.title
            })
          }) : null, this.renderContent(maxContentHeight), footer, this.state.buttons != null && this.state.buttons.length > 0 ? (0, _jsxRuntime.jsx)(_DialogActions.DialogActions, {
            children: this.state.buttons
          }) : null, closeIcon]
        })]
      });
    }
  }, {
    key: "renderContent",
    value: function renderContent() {
      var maxHeight = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '100%';
      var keyboardShouldPersistTaps = _reactNativeWeb.Platform.OS === 'android' ? {
        keyboardShouldPersistTaps: 'always'
      } : undefined;
      var _this$state2 = this.state,
        scrollable = _this$state2.scrollable,
        title = _this$state2.title,
        fixedTitle = _this$state2.fixedTitle,
        content = _this$state2.content,
        buttons = _this$state2.buttons,
        contentPadding = _this$state2.contentPadding,
        scrollContainerStyle = _this$state2.scrollContainerStyle;
      var style = {
        maxHeight: maxHeight,
        padding: contentPadding === true ? _ThemeManager.ThemeManager.style.contentPaddingValue : 0,
        marginBottom: scrollable === true && buttons != null && buttons.length > 0 ? 48 : undefined,
        borderBottomWidth: 0,
        borderColor: _ThemeManager.ThemeManager.style.borderColor,
        position: 'relative'
      };
      var titleView = fixedTitle !== true && title != null && title.length > 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          marginBottom: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(20)
        },
        children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: title
        })
      }) : null;
      var contentView = content != null ? typeof content === 'string' ? (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
        type: _MaterialText.MaterialTextTypes.Body1,
        color: _ThemeManager.ThemeManager.style.defaultTextColor,
        children: content
      }) : content : null;
      if (!scrollable) {
        return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: style,
          children: [titleView, contentView]
        });
      }
      return (0, _jsxRuntime.jsxs)(_ScrollContainer.ScrollContainer, Object.assign({
        contentContainerStyle: scrollContainerStyle,
        scrollEnabled: scrollable,
        scrollEventThrottle: 16,
        onScroll: this.handleScroll
      }, keyboardShouldPersistTaps, {
        style: style,
        children: [titleView, contentView]
      }));
    }
  }, {
    key: "startKeyboardEvents",
    value: function startKeyboardEvents() {
      var _this3 = this;
      _KeyboardEvents.KeyboardEvents.onKeyDown(function (e) {
        switch (e.keyCode) {
          case 27:
            e.preventDefault();
            if (_this3.state.closeOnTouchOutside === true) {
              var _DialogPicker$instanc3;
              (_DialogPicker$instanc3 = DialogPicker.instance) == null || _DialogPicker$instanc3.close();
            }
            break;
        }
      }, 'DialogPicker');
      if (_reactNativeWeb.Platform.OS === 'android') {
        _reactNativeWeb.BackHandler.addEventListener('hardwareBackPress', this.closeHandler);
      }
    }
  }, {
    key: "setdialogState",
    value: function setdialogState(toValue) {
      var _this4 = this;
      var callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
      if (!this.animationRunning) {
        this.animationRunning = true;
        var dialogState = toValue > 0 ? DialogPickerState.opening : DialogPickerState.closing;
        this.setState({
          dialogState: dialogState
        }, function () {
          _this4.dialogAnimation.toValue(toValue, function () {
            if (dialogState === DialogPickerState.closing) {
              _this4.setState(DialogPicker.defaultState, function () {
                _this4.animationRunning = false;
                _Routing.Routing.instance.routeBlock.post(false);
                setTimeout(callback, 100);
              });
            } else {
              _this4.setState({
                dialogState: DialogPickerState.opened,
                openImmediately: true
              }, function () {
                _this4.animationRunning = false;
                _Routing.Routing.instance.routeBlock.post(_this4.state.isBlocking);
                callback();
              });
            }
          });
        });
      } else {
        clearTimeout(this.animationRunningTO);
        this.animationRunningTO = setTimeout(function () {
          _this4.setdialogState(toValue, callback);
        }, this.props.animationDuration);
      }
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        animationDuration: 195,
        windowWidth: 1,
        windowHeight: 1,
        closeFunction: function closeFunction() {},
        onOpened: function onOpened() {},
        onClosed: function onClosed() {},
        open: false
      };
    }
  }, {
    key: "defaultState",
    get: function get() {
      return {
        title: '',
        isBlocking: true,
        openPosition: undefined,
        closeFunction: function closeFunction() {
          var _DialogPicker$instanc4;
          return (_DialogPicker$instanc4 = DialogPicker.instance) == null ? void 0 : _DialogPicker$instanc4.closeNow();
        },
        onClose: undefined,
        saveFunction: undefined,
        saveTitle: 'save',
        closeTitle: 'close',
        dialogState: DialogPickerState.closed,
        width: 372,
        height: 0,
        maxWidth: 0.9,
        maxHeight: 0.9,
        content: '',
        buttons: undefined,
        closeOnTouchOutside: true,
        scrollable: false,
        fixedTitle: false,
        contentPadding: true,
        openImmediately: false,
        saveButtonDisabled: false,
        closeButtonDisabled: false,
        windowWidth: _ResizeEvent.ResizeEvent.current.windowWidth,
        windowHeight: _ResizeEvent.ResizeEvent.current.windowHeight,
        showCloseButton: false,
        showCloseIcon: false
      };
    }
  }, {
    key: "instance",
    get: function get() {
      if (DialogPicker._instance != null) {
        return DialogPicker._instance;
      }
      return null;
    }
  }]);
}(_react.PureComponent);
DialogPicker.changeScroll = function (disable) {
  if (DialogPicker.instance != null) {
    var _DialogPicker$instanc5;
    (_DialogPicker$instanc5 = DialogPicker.instance) == null || _DialogPicker$instanc5.setState({
      scrollable: !disable
    });
  }
};