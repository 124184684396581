"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeCompanyPayment = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var CompanyPaymentChanged_1 = require("../../events/CompanyPaymentChanged");
var ChangeCompanyPayment = function (_CommandRoot_1$Comman) {
  function ChangeCompanyPayment(privatedata) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 ? arguments[2] : undefined;
    (0, _classCallCheck2.default)(this, ChangeCompanyPayment);
    _this = _callSuper(this, ChangeCompanyPayment, [privatedata, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'ChangeCompanyPayment';
    return _this;
  }
  (0, _inherits2.default)(ChangeCompanyPayment, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ChangeCompanyPayment, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') {
          return true;
        }
        var partner = ReadModels_1.ReadModels.get('UpmeshPartner');
        var partners = yield partner.get({
          filter: `users/userId eq '${userId}'`
        });
        var companies = ReadModels_1.ReadModels.get('Company');
        var company = yield companies.getById(this.entityId);
        if (partners.length > 0 && company.partnerId === partners[0].id) {
          if (partners[0].hasRight('canManagePrices', userId)) {
            return true;
          }
        }
        var admin = ReadModels_1.ReadModels.get('SupportRoles');
        var admins = yield admin.get({
          filter: `userId eq '${userId}' and deleted ne true`
        });
        if (admins.length > 0) {
          for (var adm of admins) {
            if (adm.role === 'Admin') {
              return true;
            }
          }
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "clone",
    value: function clone(newData, entityId, token) {
      return new ChangeCompanyPayment(newData, entityId, token);
    }
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        try {
          var s = ReadModels_1.ReadModels.get('CompanyPayment');
          var oldSettings = yield s.getById(this.entityId);
          var d = {};
          var hasChanged = false;
          for (var k in this.data) {
            if (this.data[k] !== oldSettings[k]) {
              hasChanged = true;
              d[k] = this.data[k];
            }
          }
          if (hasChanged) {
            return new CompanyPaymentChanged_1.CompanyPaymentChanged(this.entityId, d);
          }
          return [];
        } catch (e) {
          return new CompanyPaymentChanged_1.CompanyPaymentChanged(this.entityId, Object.assign({}, this.data));
        }
        return new CompanyPaymentChanged_1.CompanyPaymentChanged(this.entityId, Object.assign({}, this.data));
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var errors = [];
        if (key == null || key === 'price') {
          if (this.data.price == null || this.data.price === 0) {
            errors.push({
              name: 'price',
              key: 'price',
              message: 'Preis wird benötigt',
              messageCode: 'required'
            });
          }
        }
        if (key == null || key === 'priceCollaborator') {
          if (this.data.priceCollaborator == null || this.data.priceCollaborator === 0) {
            errors.push({
              name: 'priceCollaborator',
              key: 'priceCollaborator',
              message: 'Preis wird benötigt',
              messageCode: 'required'
            });
          }
        }
        if (key == null || key === 'priceTimeTracking') {
          if (this.data.priceTimeTracking == null || this.data.priceTimeTracking === 0) {
            errors.push({
              name: 'priceTimeTracking',
              key: 'priceTimeTracking',
              message: 'Preis wird benötigt',
              messageCode: 'required'
            });
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ChangeCompanyPayment = ChangeCompanyPayment;