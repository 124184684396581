var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LightboxWrapper = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _crossFetch = _interopRequireDefault(require("cross-fetch"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _I18n = require("../../i18n/I18n");
var _ResizeEvent = require("../../ResizeEvent");
var _LoadingEvents = require("../../routing/LoadingEvents");
var _Routing = require("../../routing/Routing");
var _ThemeManager = require("../../ThemeManager");
var _AnimatedHandler = _interopRequireDefault(require("../animations/AnimatedHandler"));
var _MoveAnimated = require("../animations/MoveAnimated");
var _OpacityAnimated = require("../animations/OpacityAnimated");
var _ScaleAnimated = require("../animations/ScaleAnimated");
var _BottomSheet = require("../BottomSheet");
var _Icon = require("../Icon");
var _CacheableImage = require("../images/CacheableImage");
var _KeyboardEvents = require("../utils/KeyboardEvents");
var _Zoom = require("../zoom/Zoom");
var _ImageEditor = require("./ImageEditor");
var _LightboxExifContentView = require("./LightboxExifContentView");
var _LightBoxHandler = require("./LightBoxHandler");
var _PanoramaImage = require("./PanoramaImage");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var LightboxWrapper = exports.LightboxWrapper = function (_PureComponent) {
  function LightboxWrapper(props) {
    var _this;
    (0, _classCallCheck2.default)(this, LightboxWrapper);
    _this = _callSuper(this, LightboxWrapper, [props]);
    _this.animating = false;
    _this.currentZoom = 1;
    _this.isMoving = false;
    _this.slideAnimationState = new _reactNativeWeb.Animated.Value(0.5);
    _this.startMove = new Date();
    _this.bottomSheetIsOpen = false;
    _this.showPanorama = function () {
      var currentImage = _this.state.currentImage;
      if ((currentImage == null ? void 0 : currentImage.lb.panoramaImageSource) != null) {
        _LoadingEvents.LoadingEvents.instance.startLoading();
        _this.loadPanoramaImageWithHeader(currentImage.lb.panoramaImageSource, currentImage.lb.auth).then(function (r) {
          _this.setState(function (prevState) {
            _LoadingEvents.LoadingEvents.instance.stopLoading();
            return {
              panoramaImage: !prevState.panoramaImage,
              panoramaImageSource: r
            };
          });
        }).catch(function (e) {
          _LoadingEvents.LoadingEvents.instance.stopLoading();
        });
      }
    };
    _this.loadPanoramaImageWithHeader = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (source, auth) {
        var headers = {};
        if ((auth == null || auth) && _CurrentUser.CurrentUser.token != null && _CurrentUser.CurrentUser.token.length > 0) headers['authorization'] = `Bearer ${_CurrentUser.CurrentUser.token}`;
        var res = yield (0, _crossFetch.default)(source, {
          method: 'GET',
          headers: headers
        });
        var content = yield res.blob();
        var reader = new FileReader();
        return new Promise(function (resolve, reject) {
          reader.onload = function (r) {
            var t = r.currentTarget;
            if (t != null && t.result != null && (t.result.toString().substr(0, 10) === 'data:image' || t.result.toString().substr(0, 29) === 'data:application/octet-stream')) {
              _CacheableImage.CacheableImage.webcache[source['uri']] = t.result.toString();
              resolve(t.result.toString());
            } else {
              reject(new Error('no result'));
            }
          };
          reader.onerror = function (e) {
            reject(e);
          };
          reader.readAsDataURL(content);
        });
      });
      return function (_x, _x2) {
        return _ref.apply(this, arguments);
      };
    }();
    _this.onZoom = function (scale) {
      _this.currentZoom = scale;
      var swipeEnabled = _this.state.swipeEnabled;
      if (scale <= 1 && !swipeEnabled) {
        _this.setState({
          swipeEnabled: true
        });
      } else if (scale > 1 && swipeEnabled) {
        _this.setState({
          swipeEnabled: false
        });
      }
    };
    _this.toggleBottomSheet = function (_e) {
      var currentImage = _this.state.currentImage;
      if (!_this.bottomSheetIsOpen && currentImage != null) {
        _this.openBottomSheet();
      } else {
        _this.closeBottomSheet();
      }
    };
    _this.onEditorSave = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(function* (fileOrPath) {
        try {
          var currentImage = _this.state.currentImage;
          if (fileOrPath != null && currentImage != null && currentImage.lb.editSettings != null && currentImage.lb.editSettings.saveEditedFunction != null) {
            currentImage.lb.editSettings.saveEditedFunction(fileOrPath);
          }
        } catch (err) {}
        _this.setState({
          editorActive: false
        }, function () {
          _this.closeLB();
        });
      });
      return function (_x3) {
        return _ref2.apply(this, arguments);
      };
    }();
    _this.openPhotoEditor = function (_e) {
      try {
        var editorActive = _this.state.editorActive;
        var currentImage = _this.state.currentImage;
        var imageData = currentImage == null ? void 0 : currentImage.lb.panoramaImageSource;
        if (!imageData) {
          var _currentImage$lb$edit;
          imageData = currentImage == null ? void 0 : currentImage.lb.imageURL;
          if (!imageData) imageData = currentImage == null || (_currentImage$lb$edit = currentImage.lb.editSettings) == null ? void 0 : _currentImage$lb$edit.targetUrl;
          if (imageData && imageData.startsWith('http') && imageData.indexOf('?')) {
            var ind = imageData.indexOf('?');
            imageData = imageData.substr(0, ind);
          }
        }
        if (imageData && (currentImage == null ? void 0 : currentImage.lb.editSettings) != null) {
          _ImageEditor.ImageEditor.openImageEditor(imageData, currentImage.lb.editSettings.originalname, _this.onEditorSave, currentImage == null ? void 0 : currentImage.lb.auth).then(function () {
            _this.setState({
              editorActive: !editorActive,
              swipeEnabled: editorActive
            });
          }).catch(function (err) {
            return void 0;
          });
        }
      } catch (e) {
        _this.setState({
          editorActive: false,
          swipeEnabled: true
        });
      }
    };
    _this.openBottomSheet = function () {
      var _BottomSheet$instance;
      var currentImage = _this.state.currentImage;
      if (currentImage == null) return _this.closeBottomSheet();
      (_BottomSheet$instance = _BottomSheet.BottomSheet.instance) == null || _BottomSheet$instance.open({
        content: (0, _jsxRuntime.jsx)(_LightboxExifContentView.LightboxExifContentView, {
          metadata: currentImage.lb.metaData
        }),
        closeOnTouchOutside: true,
        closeFunction: _this.closeBottomSheet,
        elevation: 1005
      });
      _this.bottomSheetIsOpen = true;
      return _this.bottomSheetIsOpen;
    };
    _this.closeBottomSheet = function () {
      var _BottomSheet$instance2;
      (_BottomSheet$instance2 = _BottomSheet.BottomSheet.instance) == null || _BottomSheet$instance2.close();
      _this.bottomSheetIsOpen = false;
      return _this.bottomSheetIsOpen;
    };
    _this.startKeyboardEvents = function () {
      _this.stopKeyboardEvents();
      if (_reactNativeWeb.Platform.OS === 'android') {
        _this.backHandler = _reactNativeWeb.BackHandler.addEventListener('hardwareBackPress', _this.closeLB);
      }
      _KeyboardEvents.KeyboardEvents.onKeyDown(function (e) {
        switch (e.keyCode) {
          case 27:
            e.preventDefault();
            _this.closeLB();
            break;
          case 37:
            e.preventDefault();
            if (_this.state.prevImage != null) {
              _this.onPressPrev();
            }
            break;
          case 39:
            e.preventDefault();
            if (_this.state.nextImage != null) {
              _this.onPressNext();
            }
            break;
        }
      }, 'LightBox');
    };
    _this.stopKeyboardEvents = function () {
      _KeyboardEvents.KeyboardEvents.remove('LightBox');
      if (_this.backHandler) _this.backHandler.remove();
    };
    _this.animationEnd = function (animationState) {
      if (animationState === 0.004) {
        var _this$state = _this.state,
          currentImage = _this$state.currentImage,
          prevImage = _this$state.prevImage,
          nextImage = _this$state.nextImage;
        if (currentImage != null) {
          currentImage.lb.onClosed();
        }
        if (prevImage != null) {
          prevImage.lb.onClosed();
        }
        if (nextImage != null) {
          nextImage.lb.onClosed();
        }
        _Routing.Routing.instance.routeBlock.post(false);
        _this.setState({
          animationState: 0,
          currentImage: null,
          prevImage: null,
          nextImage: null
        });
      } else if (animationState === 1) {
        _this.onOpened();
      }
    };
    _this.closeLB = function () {
      _this.closeBottomSheet();
      if (_this.currentZoom !== 1) {
        _this.setState({
          zoomTo: _this.currentZoom,
          panoramaImage: false
        }, function () {
          _this.setState({
            zoomTo: 1
          }, function () {
            setTimeout(function () {
              _this.closeLBNow();
            }, 200);
          });
        });
        return true;
      }
      _this.closeLBNow();
      return true;
    };
    _this.onPressNext = function (_e) {
      if (!_this.animating) {
        _this.animating = true;
        var _this$state2 = _this.state,
          nextImage = _this$state2.nextImage,
          currentImage = _this$state2.currentImage,
          prevImage = _this$state2.prevImage;
        if (prevImage != null) {
          prevImage.lb.onClosed();
        }
        prevImage = currentImage;
        if (nextImage != null) {
          currentImage = nextImage;
          _reactNativeWeb.Animated.timing(_this.slideAnimationState, {
            toValue: 1,
            duration: 195,
            useNativeDriver: _reactNativeWeb.Platform.OS !== 'web'
          }).start(function () {
            if (currentImage != null) {
              var getNextImage = _LightBoxHandler.LightBoxHandler.getNext(currentImage.lb.group, currentImage.lb.laId);
              nextImage = null;
              var asyncNow = function () {
                var _ref3 = (0, _asyncToGenerator2.default)(function* () {
                  if (getNextImage != null) {
                    var infoNext = yield _this.getConentInfo(getNextImage.lb);
                    nextImage = Object.assign({}, getNextImage, infoNext);
                  }
                  _this.slideAnimationState.setValue(0.5);
                  _this.setState({
                    currentImage: currentImage,
                    prevImage: prevImage,
                    nextImage: nextImage,
                    panoramaImage: false
                  }, function () {
                    _this.onOpened();
                    _this.animating = false;
                    if (_this.bottomSheetIsOpen) _this.openBottomSheet();
                  });
                });
                return function asyncNow() {
                  return _ref3.apply(this, arguments);
                };
              }();
              asyncNow().catch(function (err) {
                return void 0;
              });
            }
          });
        }
      }
    };
    _this.onPressPrev = function (_e) {
      if (!_this.animating) {
        _this.animating = true;
        var _this$state3 = _this.state,
          prevImage = _this$state3.prevImage,
          currentImage = _this$state3.currentImage,
          nextImage = _this$state3.nextImage;
        if (nextImage != null) {
          nextImage.lb.onClosed();
        }
        nextImage = currentImage;
        currentImage = prevImage;
        _reactNativeWeb.Animated.timing(_this.slideAnimationState, {
          toValue: 0,
          duration: 195,
          useNativeDriver: _reactNativeWeb.Platform.OS !== 'web'
        }).start(function () {
          if (currentImage != null) {
            var getPrev = _LightBoxHandler.LightBoxHandler.getPrev(currentImage.lb.group, currentImage.lb.laId);
            prevImage = null;
            var asyncNow = function () {
              var _ref4 = (0, _asyncToGenerator2.default)(function* () {
                if (getPrev != null) {
                  var infoNext = yield _this.getConentInfo(getPrev.lb);
                  prevImage = Object.assign({}, getPrev, infoNext);
                }
                _this.slideAnimationState.setValue(0.5);
                _this.setState({
                  currentImage: currentImage,
                  prevImage: prevImage,
                  nextImage: nextImage,
                  panoramaImage: false
                }, function () {
                  _this.onOpened();
                  _this.animating = false;
                  if (_this.bottomSheetIsOpen) _this.openBottomSheet();
                });
              });
              return function asyncNow() {
                return _ref4.apply(this, arguments);
              };
            }();
            asyncNow().catch(function (err) {
              return void 0;
            });
          }
        });
      }
    };
    _this.onResize = function () {
      var _ref5 = (0, _asyncToGenerator2.default)(function* (resized) {
        var _this$state4 = _this.state,
          currentImage = _this$state4.currentImage,
          prevImage = _this$state4.prevImage,
          nextImage = _this$state4.nextImage;
        if (currentImage != null) {
          var info = yield _this.getConentInfo(currentImage.lb);
          currentImage.position = info.position;
          currentImage.scaleFactor = info.scaleFactor;
        }
        if (prevImage != null) {
          var _info = yield _this.getConentInfo(prevImage.lb);
          prevImage.position = _info.position;
          prevImage.scaleFactor = _info.scaleFactor;
        }
        if (nextImage != null) {
          var _info2 = yield _this.getConentInfo(nextImage.lb);
          nextImage.position = _info2.position;
          nextImage.scaleFactor = _info2.scaleFactor;
        }
        _this.setState({
          currentImage: currentImage,
          nextImage: nextImage,
          prevImage: prevImage,
          windowHeight: resized.windowHeight,
          windowWidth: resized.windowWidth
        });
      });
      return function (_x4) {
        return _ref5.apply(this, arguments);
      };
    }();
    _this.openLB = function (lb) {
      _this.startKeyboardEvents();
      var getPprevImage = _LightBoxHandler.LightBoxHandler.getPrev(lb.group, lb.laId);
      var getNextImage = _LightBoxHandler.LightBoxHandler.getNext(lb.group, lb.laId);
      var nextImage = null;
      var prevImage = null;
      var asyncNow = function () {
        var _ref6 = (0, _asyncToGenerator2.default)(function* () {
          if (getNextImage != null) {
            var infoNext = yield _this.getConentInfo(getNextImage.lb);
            nextImage = Object.assign({}, getNextImage, infoNext);
          }
          if (getPprevImage != null) {
            var infoPrev = yield _this.getConentInfo(getPprevImage.lb);
            prevImage = Object.assign({}, getPprevImage, infoPrev);
          }
          var info = yield _this.getConentInfo(lb);
          _this.slideAnimationState = new _reactNativeWeb.Animated.Value(0.5);
          var currentImage = Object.assign({
            lb: lb
          }, info, {
            lbId: lb.laId
          });
          _this.setState({
            currentImage: currentImage,
            prevImage: prevImage,
            nextImage: nextImage,
            animationState: 0.005,
            zoomTo: 1,
            editorActive: false,
            swipeEnabled: true
          }, function () {
            lb.onOpen();
            _this.scale();
          });
        });
        return function asyncNow() {
          return _ref6.apply(this, arguments);
        };
      }();
      asyncNow().catch(function (err) {
        return void 0;
      });
    };
    _this.state = {
      windowWidth: _ResizeEvent.ResizeEvent.current.windowWidth,
      windowHeight: _ResizeEvent.ResizeEvent.current.windowHeight,
      prevImage: null,
      nextImage: null,
      currentImage: null,
      animationState: 0,
      swipeEnabled: true,
      zoomTo: 1,
      panoramaImage: false,
      editorActive: false
    };
    _this.createPanResponder();
    return _this;
  }
  (0, _inherits2.default)(LightboxWrapper, _PureComponent);
  return (0, _createClass2.default)(LightboxWrapper, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _LightBoxHandler.LightBoxHandler.openLightboxEvent.attach(this.openLB);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.stopKeyboardEvents();
      _LightBoxHandler.LightBoxHandler.openLightboxEvent.detach(this.openLB);
    }
  }, {
    key: "render",
    value: function render() {
      var _this$state5 = this.state,
        animationState = _this$state5.animationState,
        currentImage = _this$state5.currentImage,
        windowHeight = _this$state5.windowHeight,
        windowWidth = _this$state5.windowWidth,
        panoramaImage = _this$state5.panoramaImage,
        editorActive = _this$state5.editorActive;
      if (animationState === 0 || animationState === 0.004 || currentImage == null || editorActive) {
        return null;
      }
      var panHandlers = panoramaImage ? {} : this._panResponder.panHandlers;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, Object.assign({
        style: {
          position: 'absolute',
          top: 0,
          width: windowWidth,
          height: windowHeight
        }
      }, panHandlers, {
        children: [(0, _jsxRuntime.jsx)(_OpacityAnimated.OpacityAnimated, {
          id: "LIGHBOX_WRAPPER",
          from: 0,
          to: _ThemeManager.ThemeManager.style.lightBoxOverlayOpacity,
          animationState: animationState,
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: windowWidth,
              height: windowHeight,
              backgroundColor: _ThemeManager.ThemeManager.style.lightBoxOverlaycolor
            }
          })
        }, "overlay"), this.renderImage('current'), this.renderImage('prev'), this.renderImage('next'), this.renderLeftRightButtons(), this.renderIconButtons()]
      }));
    }
  }, {
    key: "renderNonEditorIcons",
    value: function renderNonEditorIcons() {
      var currentImage = this.state.currentImage;
      var nonEditorIcons = [];
      if (currentImage && currentImage.lb) {
        var _currentImage$lb$edit2;
        if (currentImage.lb.panoramaImageSource != null) {
          nonEditorIcons.push((0, _jsxRuntime.jsx)(_Icon.Icon, {
            toolTip: _I18n.I18n.m.getMessage('lightBoxShowPanorama'),
            icon: "panorama-horizontal",
            iconSize: 24,
            outerSize: 32,
            backgroundColor: _ThemeManager.ThemeManager.style.black38,
            color: "#FFFFFF",
            onPress: this.showPanorama
          }, "lb-wrapper-icon-panorama"));
        }
        if (currentImage.lb.downloadFunction != null || currentImage.lb.metaData != null) {
          nonEditorIcons.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: 16
            }
          }, "lb-wrapper-view"));
        }
        if (((_currentImage$lb$edit2 = currentImage.lb.editSettings) == null ? void 0 : _currentImage$lb$edit2.saveEditedFunction) != null) {
          nonEditorIcons.push((0, _jsxRuntime.jsx)(_Icon.Icon, {
            toolTip: _I18n.I18n.m.getMessage('lightBoxEditImage'),
            icon: "pencil-outline",
            iconSize: 24,
            outerSize: 32,
            backgroundColor: _ThemeManager.ThemeManager.style.black38,
            color: "#FFFFFF",
            onPress: this.openPhotoEditor
          }, "lb-wrapper-edit"));
        }
        if (currentImage.lb.downloadFunction != null) {
          nonEditorIcons.push((0, _jsxRuntime.jsx)(_Icon.Icon, {
            toolTip: _I18n.I18n.m.getMessage('lightBoxDownload'),
            icon: "download",
            iconSize: 24,
            outerSize: 32,
            backgroundColor: _ThemeManager.ThemeManager.style.black38,
            color: "#FFFFFF",
            onPress: currentImage.lb.downloadFunction
          }, "lb-wrapper-download"));
        }
        if (currentImage.lb.downloadFunction != null && currentImage.lb.metaData != null) {
          nonEditorIcons.push((0, _jsxRuntime.jsx)(_Icon.Icon, {
            toolTip: _I18n.I18n.m.getMessage('lightBoxInformation'),
            icon: "information-outline",
            iconSize: 24,
            outerSize: 32,
            backgroundColor: _ThemeManager.ThemeManager.style.black38,
            color: "#FFFFFF",
            onPress: this.toggleBottomSheet
          }, "lb-wrapper-metadata"));
        }
      }
      return nonEditorIcons;
    }
  }, {
    key: "renderIconButtons",
    value: function renderIconButtons() {
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          top: _ThemeManager.ThemeManager.style.contentPaddingValue + _ResizeEvent.ResizeEvent.current.contentTop,
          right: _ThemeManager.ThemeManager.style.contentPaddingValue + _ResizeEvent.ResizeEvent.current.contentRight,
          left: _ThemeManager.ThemeManager.style.contentPaddingValue + _ResizeEvent.ResizeEvent.current.contentRight,
          zIndex: 1001,
          elevation: 1001,
          justifyContent: 'space-between',
          flexDirection: 'row'
        },
        children: [(0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: "arrow-left",
          toolTip: _I18n.I18n.m.getMessage('close'),
          iconSize: 24,
          outerSize: 32,
          backgroundColor: _ThemeManager.ThemeManager.style.black38,
          color: "#FFFFFF",
          onPress: this.closeLB
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row'
          },
          children: this.renderNonEditorIcons()
        })]
      });
    }
  }, {
    key: "renderImage",
    value: function renderImage(type) {
      var _this$state6 = this.state,
        animationState = _this$state6.animationState,
        windowHeight = _this$state6.windowHeight,
        windowWidth = _this$state6.windowWidth,
        nextImage = _this$state6.nextImage,
        prevImage = _this$state6.prevImage,
        currentImage = _this$state6.currentImage,
        zoomTo = _this$state6.zoomTo,
        panoramaImage = _this$state6.panoramaImage,
        panoramaImageSource = _this$state6.panoramaImageSource;
      var lb = currentImage;
      var outputRange = [windowWidth * 2, 0];
      var boxPoition = {
        left: -windowWidth,
        width: windowWidth,
        height: windowHeight
      };
      var animationState2 = animationState;
      var animationId = 'LIGHBOX_WRAPPER';
      if (type === 'next') {
        animationState2 = 1;
        outputRange = [windowWidth * 2, 0];
        lb = nextImage;
        boxPoition = {
          left: 0,
          width: windowWidth,
          height: windowHeight
        };
      } else if (type === 'prev') {
        animationState2 = 1;
        lb = prevImage;
        outputRange = [windowWidth * 2, 0];
        boxPoition = {
          left: -windowWidth * 2,
          width: windowWidth,
          height: windowHeight
        };
      }
      if (lb == null) {
        return null;
      }
      var translateX = this.slideAnimationState.interpolate({
        outputRange: outputRange,
        inputRange: [0, 1]
      });
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
        pointerEvents: "box-none",
        collapsable: false,
        style: Object.assign({
          position: 'absolute',
          top: 0
        }, boxPoition, {
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
          transform: [{
            translateX: translateX
          }],
          elevation: 1000,
          backgroundColor: 'transparent'
        }),
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            position: 'relative',
            width: _ResizeEvent.ResizeEvent.current.windowWidth,
            height: _ResizeEvent.ResizeEvent.current.windowHeight,
            overflow: 'visible'
          },
          children: type === 'current' && panoramaImage && panoramaImageSource != null && currentImage != null ? (0, _jsxRuntime.jsx)(_PanoramaImage.PanoramaImage, {
            size: {
              width: _ResizeEvent.ResizeEvent.current.windowWidth,
              height: _ResizeEvent.ResizeEvent.current.windowHeight
            },
            image: panoramaImageSource
          }, currentImage.lb.panoramaImageSource) : (0, _jsxRuntime.jsx)(_Zoom.Zoom, {
            offset: {
              left: 0,
              top: 0
            },
            area: {
              width: _ResizeEvent.ResizeEvent.current.windowWidth,
              height: _ResizeEvent.ResizeEvent.current.windowHeight
            },
            onChanged: this.onZoom,
            positionInParentToChangeLayout: {
              x: boxPoition.left,
              y: 0
            },
            zoomTo: zoomTo,
            children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                position: 'relative',
                width: _ResizeEvent.ResizeEvent.current.windowWidth,
                height: _ResizeEvent.ResizeEvent.current.windowHeight,
                overflow: 'visible'
              },
              children: (0, _jsxRuntime.jsx)(_MoveAnimated.MoveAnimated, {
                id: animationId,
                duration: 195,
                from: lb.position,
                to: {
                  x: (windowWidth - lb.position.scaledWidth) / 2,
                  y: (windowHeight - lb.position.scaledHeight) / 2
                },
                animationState: animationState2,
                children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                  style: {
                    width: lb.position.orgWidth,
                    height: lb.position.orgHeight,
                    overflow: 'visible'
                  },
                  children: (0, _jsxRuntime.jsx)(_ScaleAnimated.ScaleAnimated, {
                    id: animationId,
                    duration: 195,
                    from: 1,
                    to: lb.scaleFactor,
                    animationState: animationState2,
                    size: {
                      width: lb.position.orgWidth,
                      height: lb.position.orgHeight
                    },
                    children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                      style: {
                        width: lb.position.orgWidth,
                        height: lb.position.orgHeight,
                        overflow: 'visible'
                      },
                      children: lb.lb.getChilds()
                    })
                  })
                })
              })
            })
          })
        })
      }, `lb_${lb.lb.group}_${lb.lb.index}_${lb.lb.laId}`);
    }
  }, {
    key: "renderLeftButton",
    value: function renderLeftButton() {
      var _this$state7 = this.state,
        prevImage = _this$state7.prevImage,
        editorActive = _this$state7.editorActive;
      if (prevImage == null || editorActive) {
        return null;
      }
      var leftButton = (0, _jsxRuntime.jsx)(_Icon.Icon, {
        icon: "chevron-left",
        toolTip: _I18n.I18n.m.getMessage('prev'),
        iconSize: 64,
        outerSize: 64,
        backgroundColor: _ThemeManager.ThemeManager.style.black38,
        onPress: this.onPressPrev,
        color: "#FFFFFF"
      });
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          height: 64,
          top: '50%',
          width: 96,
          paddingLeft: _ThemeManager.ThemeManager.style.contentPaddingValue,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'flex-start',
          left: 0,
          zIndex: 1001,
          elevation: 1001,
          backgroundColor: 'transparent'
        },
        children: leftButton
      });
    }
  }, {
    key: "renderLeftRightButtons",
    value: function renderLeftRightButtons() {
      var _this$state8 = this.state,
        currentImage = _this$state8.currentImage,
        swipeEnabled = _this$state8.swipeEnabled;
      if (currentImage == null || !swipeEnabled) {
        return null;
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        pointerEvents: "box-none",
        style: {
          position: 'absolute',
          top: (_ResizeEvent.ResizeEvent.current.contentHeight - currentImage.position.scaledHeight) / 2,
          bottom: (_ResizeEvent.ResizeEvent.current.contentHeight - currentImage.position.scaledHeight) / 2,
          justifyContent: 'center',
          left: 0,
          right: 0,
          zIndex: 1001,
          elevation: 1001,
          backgroundColor: 'transparent'
        },
        children: [this.renderLeftButton(), this.renderRightButton()]
      });
    }
  }, {
    key: "renderRightButton",
    value: function renderRightButton() {
      var _this$state9 = this.state,
        nextImage = _this$state9.nextImage,
        editorActive = _this$state9.editorActive;
      if (nextImage == null || editorActive) {
        return null;
      }
      var rightButton = (0, _jsxRuntime.jsx)(_Icon.Icon, {
        onPress: this.onPressNext,
        icon: "chevron-right",
        toolTip: _I18n.I18n.m.getMessage('next'),
        iconSize: 64,
        outerSize: 64,
        backgroundColor: _ThemeManager.ThemeManager.style.black38,
        color: "#FFFFFF"
      });
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          paddingRight: _ThemeManager.ThemeManager.style.contentPaddingValue,
          position: 'absolute',
          height: 64,
          top: '50%',
          width: 96,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'flex-end',
          right: 0,
          zIndex: 1001,
          elevation: 1001,
          backgroundColor: 'transparent'
        },
        children: rightButton
      });
    }
  }, {
    key: "closeLBNow",
    value: function closeLBNow() {
      var _this2 = this;
      var aniIds = ['LIGHBOX_WRAPPER'];
      this.stopKeyboardEvents();
      if (this.state.currentImage != null) {
        if (this.state.prevImage != null) {
          this.state.prevImage.lb.onClosed();
        }
        if (this.state.nextImage != null) {
          this.state.nextImage.lb.onClosed();
        }
        this.state.currentImage.lb.onClose();
        if (this.state.currentImage.lb.parrallelAnimationId != null) {
          aniIds.push(this.state.currentImage.lb.parrallelAnimationId);
        }
        this.forceUpdate();
      }
      _AnimatedHandler.default.startAnimationById(aniIds, 0.004, function () {
        _this2.animationEnd(0.004);
      });
      this.setState({
        animationState: 0.004,
        swipeEnabled: true,
        zoomTo: 1,
        panoramaImage: false,
        editorActive: false
      });
    }
  }, {
    key: "createPanResponder",
    value: function createPanResponder() {
      var _this3 = this;
      this._panResponder = _reactNativeWeb.PanResponder.create({
        onStartShouldSetPanResponder: function onStartShouldSetPanResponder(_evt, _gestureState) {
          return false;
        },
        onStartShouldSetPanResponderCapture: function onStartShouldSetPanResponderCapture(_evt, _gestureState) {
          _this3.startMoveX = _gestureState.dx;
          return false;
        },
        onMoveShouldSetPanResponder: function onMoveShouldSetPanResponder(_evt, _gestureState) {
          var swipeEnabled = _this3.state.swipeEnabled;
          if (!swipeEnabled) {
            return false;
          }
          var diff = Math.abs(_gestureState.dx - _this3.startMoveX);
          return diff > _ThemeManager.ThemeManager.style.minSwipeValue;
        },
        onMoveShouldSetPanResponderCapture: function onMoveShouldSetPanResponderCapture(_evt, _gestureState) {
          return false;
        },
        onPanResponderGrant: function onPanResponderGrant(_evt, _gestureState) {
          return false;
        },
        onPanResponderMove: function onPanResponderMove(_evt, _gestureState) {
          var swipeEnabled = _this3.state.swipeEnabled;
          if (!swipeEnabled) {
            return false;
          }
          if (_this3.isMoving) {
            var _this3$state = _this3.state,
              prevImage = _this3$state.prevImage,
              nextImage = _this3$state.nextImage,
              windowWidth = _this3$state.windowWidth;
            var dx = _gestureState.dx;
            var distance = -dx / windowWidth * 0.5 + 0.5;
            if (nextImage == null && distance > 0.6) {
              distance = 0.6;
            }
            if (prevImage == null && distance < 0.4) {
              distance = 0.4;
            }
            _this3.slideAnimationState.setValue(distance);
          } else {
            _this3.isMoving = true;
            _this3.startMove = new Date();
          }
          return false;
        },
        onPanResponderTerminationRequest: function onPanResponderTerminationRequest(_evt, _gestureState) {
          return true;
        },
        onPanResponderRelease: function onPanResponderRelease(_evt, _gestureState) {
          _this3.isMoving = false;
          var swipeDuration = Date.now() - _this3.startMove.getTime();
          var nearest = Math.round(_this3.slideAnimationState._value * 2) / 2;
          var shortSwipe = swipeDuration < 100 && Math.abs(_gestureState.dx) > 20;
          if (shortSwipe) {
            nearest = _gestureState.dx > 0 ? 0 : 1;
          }
          var _this3$state2 = _this3.state,
            prevImage = _this3$state2.prevImage,
            nextImage = _this3$state2.nextImage;
          if (nearest === 0.5 || nearest < 0.5 && prevImage == null || nearest > 0.5 && nextImage == null) {
            _this3.animating = true;
            _reactNativeWeb.Animated.timing(_this3.slideAnimationState, {
              toValue: 0.5,
              duration: 195,
              useNativeDriver: _reactNativeWeb.Platform.OS !== 'web'
            }).start(function () {
              _this3.animating = false;
            });
          } else if (nearest >= 1) {
            _this3.onPressNext(null);
          } else {
            _this3.onPressPrev(null);
          }
          return true;
        },
        onPanResponderTerminate: function onPanResponderTerminate(_evt, _gestureState) {},
        onShouldBlockNativeResponder: function onShouldBlockNativeResponder(_evt, _gestureState) {
          return true;
        }
      });
    }
  }, {
    key: "getConentInfo",
    value: function () {
      var _getConentInfo = (0, _asyncToGenerator2.default)(function* (lb) {
        var _this$state10 = this.state,
          windowWidth = _this$state10.windowWidth,
          windowHeight = _this$state10.windowHeight;
        var size = yield lb.getSize();
        var scaleFactor = Math.min(windowHeight / size.height, windowWidth / size.width);
        var position = {
          x: size.x,
          y: size.y,
          scaledWidth: size.width * scaleFactor,
          scaledHeight: size.height * scaleFactor,
          orgWidth: size.width,
          orgHeight: size.height
        };
        return {
          position: position,
          scaleFactor: scaleFactor
        };
      });
      function getConentInfo(_x5) {
        return _getConentInfo.apply(this, arguments);
      }
      return getConentInfo;
    }()
  }, {
    key: "onOpened",
    value: function onOpened() {
      var _this4 = this;
      _Routing.Routing.instance.routeBlock.post(true);
      if (this.state.currentImage != null) {
        this.state.currentImage.lb.onOpened(this.state.currentImage.scaleFactor);
      }
      if (this.state.prevImage != null) {
        this.state.prevImage.lb.onOpened(this.state.prevImage.scaleFactor);
      }
      if (this.state.nextImage != null) {
        this.state.nextImage.lb.onOpened(this.state.nextImage.scaleFactor);
      }
      this.forceUpdate(function () {
        var _this4$state$currentI;
        if (((_this4$state$currentI = _this4.state.currentImage) == null ? void 0 : _this4$state$currentI.lb.panoramaImageSource) != null) {
          _this4.showPanorama();
        }
      });
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      if (JSON.stringify(prevProps.size) !== JSON.stringify(this.props.size)) {
        this.onResize(this.props.size).catch(function (err) {
          return void 0;
        });
      }
    }
  }, {
    key: "scale",
    value: function scale() {
      var _this5 = this;
      var aniIds = ['LIGHBOX_WRAPPER'];
      if (this.state.currentImage != null && this.state.currentImage.lb.parrallelAnimationId != null) {
        aniIds.push(this.state.currentImage.lb.parrallelAnimationId);
      }
      _AnimatedHandler.default.startAnimationById(aniIds, 1, function () {
        _this5.animationEnd(1);
      });
      this.setState({
        animationState: 1
      });
    }
  }]);
}(_react.PureComponent);