"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeleteAccount = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var ClientStore_1 = require("upmesh-core/build/src/client/ClientStore");
var DeleteAccount_1 = require("upmesh-core/build/src/client/commands/user/DeleteAccount");
var UpmeshClient_1 = require("upmesh-core/build/src/client/UpmeshClient");
var DeleteAccount = function (_CommandRoot_1$Comman) {
  function DeleteAccount(data, userId, token) {
    var _this;
    (0, _classCallCheck2.default)(this, DeleteAccount);
    _this = _callSuper(this, DeleteAccount, [data, userId, token]);
    _this.waitForResult = true;
    _this.commandName = 'DeleteAccount';
    _this.aggregate = 'supportuser';
    return _this;
  }
  (0, _inherits2.default)(DeleteAccount, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(DeleteAccount, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var admin = ReadModels_1.ReadModels.get('SupportRoles');
        var admins = yield admin.get({
          filter: `userId eq '${userId}' and deleted ne true`
        });
        if (admins.length > 0) {
          for (var adm of admins) {
            if (adm.role === 'Admin' || adm.role === 'Support') {
              return true;
            }
          }
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var token = UpmeshClient_1.UpmeshClient.instance.serverConnection.token;
        if (token != null) {
          var c = new DeleteAccount_1.DeleteAccount({
            confirmed: true,
            systemDelete: this.data.bySystem
          }, this.entityId, token);
          yield c.execute(ClientStore_1.ClientStore.commandStore);
        } else throw new Error('cant get server connection');
        return [];
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.DeleteAccount = DeleteAccount;