"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeCompanyUserRole = void 0;
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var ChangeCompanyUserRole_1 = require("upmesh-core/build/src/client/commands/companies/ChangeCompanyUserRole");
var ChangeCompanyUserRole = function (_ChangeCompanyUserRol) {
  function ChangeCompanyUserRole(privatedata, entityId, token) {
    var _this;
    (0, _classCallCheck2.default)(this, ChangeCompanyUserRole);
    _this = _callSuper(this, ChangeCompanyUserRole, [privatedata, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'ChangeCompanyUserRole';
    return _this;
  }
  (0, _inherits2.default)(ChangeCompanyUserRole, _ChangeCompanyUserRol);
  return (0, _createClass2.default)(ChangeCompanyUserRole, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') {
          return true;
        }
        var companies = ReadModels_1.ReadModels.get('Company');
        var company = yield companies.getById(this.data.companyId);
        var partner = ReadModels_1.ReadModels.get('UpmeshPartner');
        var partners = yield partner.get({
          filter: `users/userId eq '${userId}'`
        });
        if (partners.length > 0) {
          company.partnerId = partners[0].id;
          if (partners[0].hasRight('canManageCompanies', userId)) {
            return true;
          }
        }
        var admin = ReadModels_1.ReadModels.get('SupportRoles');
        var admins = yield admin.get({
          filter: `userId eq '${userId}' and deleted ne true`
        });
        if (admins.length > 0) {
          for (var adm of admins) {
            if (adm.role === 'Admin') {
              return true;
            }
          }
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "clone",
    value: function clone(newData, entityId, token) {
      return new ChangeCompanyUserRole(newData, entityId, token);
    }
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return _superPropGet(ChangeCompanyUserRole, "createEvents", this, 3)([]);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        yield _superPropGet(ChangeCompanyUserRole, "validate", this, 3)([key]);
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ChangeCompanyUserRole_1.ChangeCompanyUserRole);
exports.ChangeCompanyUserRole = ChangeCompanyUserRole;