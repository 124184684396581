var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Header = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ResizeEvent = require("../../ResizeEvent");
var _ThemeManager = require("../../ThemeManager");
var _SearchBar = require("../SearchBar");
var _MaterialText = require("../text/MaterialText");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var Header = exports.Header = function (_PureComponent) {
  function Header(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Header);
    _this = _callSuper(this, Header, [props]);
    _this.onFocusSearchBar = function () {
      var searchBarProps = _this.props.searchBarProps;
      _this.setState({
        searchBarFocus: true
      }, function () {
        if (searchBarProps && searchBarProps.onFocus) searchBarProps.onFocus();
      });
    };
    _this.onBlurSearchBar = function () {
      var searchBarProps = _this.props.searchBarProps;
      _this.setState({
        searchBarFocus: false
      }, function () {
        if (searchBarProps && searchBarProps.onBlur) searchBarProps.onBlur();
      });
    };
    _this.onLayout = function (_e) {};
    _this.state = {
      searchBarFocus: false
    };
    return _this;
  }
  (0, _inherits2.default)(Header, _PureComponent);
  return (0, _createClass2.default)(Header, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        leftButtons = _this$props.leftButtons,
        rightButtons = _this$props.rightButtons,
        title = _this$props.title,
        backgroundColor = _this$props.backgroundColor,
        textColor = _this$props.textColor,
        additionalContent = _this$props.additionalContent,
        additionalContentPaddingLeft = _this$props.additionalContentPaddingLeft,
        searchBarProps = _this$props.searchBarProps,
        withElevation = _this$props.withElevation,
        withBorder = _this$props.withBorder,
        subTitle = _this$props.subTitle;
      var searchBarFocus = this.state.searchBarFocus;
      var searchBarOnly = searchBarFocus && _ResizeEvent.ResizeEvent.current.contentWidth <= _ThemeManager.ThemeManager.style.breakpointM;
      var headerHeight = additionalContent != null ? _ThemeManager.ThemeManager.style.headerHeightBig : _ThemeManager.ThemeManager.style.headerHeight;
      var paddingValue = _ThemeManager.ThemeManager.style.appBarContentPaddingValue - _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(6);
      var elevation = withElevation === true ? _ThemeManager.ThemeManager.style.elevation4 : {};
      var border = withBorder === false ? {} : {
        borderStyle: 'solid',
        borderColor: _ThemeManager.ThemeManager.style.borderColor,
        borderWidth: 0,
        borderBottomWidth: 1
      };
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        onLayout: this.onLayout,
        style: Object.assign({}, elevation, border, {
          padding: 0,
          alignSelf: 'stretch',
          alignItems: 'flex-start',
          height: headerHeight,
          overflow: _reactNativeWeb.Platform.OS !== 'ios' ? 'hidden' : 'visible',
          backgroundColor: backgroundColor != null && backgroundColor.length > 0 ? backgroundColor : _ThemeManager.ThemeManager.style.headerBG,
          justifyContent: 'flex-start'
        }),
        children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row',
            alignSelf: 'stretch',
            overflow: 'hidden',
            alignItems: 'center',
            height: _ThemeManager.ThemeManager.style.headerHeight,
            padding: 0
          },
          children: [leftButtons != null && leftButtons.length > 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              paddingLeft: paddingValue
            },
            children: leftButtons
          }) : null, (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              paddingLeft: 16,
              paddingRight: rightButtons != null && rightButtons.length > 0 ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(4) : _ThemeManager.ThemeManager.style.contentPaddingValue,
              alignItems: 'center',
              justifyContent: this.props.titleCentered === true ? 'center' : 'flex-start',
              flexDirection: 'row',
              flexGrow: 1,
              flexShrink: 1,
              height: _ThemeManager.ThemeManager.style.headerHeight,
              overflow: 'hidden'
            },
            children: [title != null && typeof title === 'string' && title.length > 0 && !searchBarOnly && (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
              style: {
                width: '100%',
                marginRight: searchBarProps != null ? 8 : 0
              },
              children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
                type: _MaterialText.MaterialTextTypes.H6,
                centeredBox: false,
                centeredText: false,
                color: textColor != null && textColor.length > 0 ? textColor : _ThemeManager.ThemeManager.style.headerTitleColor,
                numberOfLines: 1,
                children: title
              }), subTitle && subTitle.length > 0 && (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
                type: _MaterialText.MaterialTextTypes.Body2,
                numberOfLines: 1,
                children: subTitle
              })]
            }), title != null && typeof title !== 'string' && !searchBarOnly && (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                width: '100%',
                marginRight: searchBarProps != null ? 8 : 0
              },
              children: title
            }), searchBarProps != null ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                marginVertical: 3,
                paddingRight: searchBarOnly ? 16 : 4,
                width: '100%'
              },
              children: (0, _jsxRuntime.jsx)(_SearchBar.SearchBar, Object.assign({
                onFocus: this.onFocusSearchBar,
                onBlur: this.onBlurSearchBar,
                small: true
              }, searchBarProps))
            }) : null]
          }), rightButtons != null && rightButtons.length > 0 && !searchBarOnly ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              paddingRight: paddingValue,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            },
            children: rightButtons.map(function (c, i) {
              return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                children: c
              }, `rightButton_${i}`);
            })
          }) : null]
        }), additionalContent != null && !searchBarOnly ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            maxHeight: 72,
            paddingLeft: additionalContentPaddingLeft !== true ? 0 : leftButtons != null && leftButtons.length > 0 ? _ThemeManager.ThemeManager.style.contentPaddingValue + leftButtons.length * 48 : _ThemeManager.ThemeManager.style.contentPaddingValue,
            alignSelf: 'stretch',
            overflow: 'hidden'
          },
          children: additionalContent
        }) : null]
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        additionalContentPaddingLeft: false,
        backgroundColor: _ThemeManager.ThemeManager.style.headerBG,
        leftButtons: [],
        rightButtons: [],
        titleCentered: false,
        withElevation: false,
        withBorder: true
      };
    }
  }]);
}(_react.PureComponent);