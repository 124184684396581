var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BackgroundRenderer = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var BackgroundRenderer = exports.BackgroundRenderer = function (_Component) {
  function BackgroundRenderer() {
    var _this;
    (0, _classCallCheck2.default)(this, BackgroundRenderer);
    _this = _callSuper(this, BackgroundRenderer, arguments);
    _this.scaleFactor = new _reactNativeWeb.Animated.Value(1);
    _this.translate = new _reactNativeWeb.Animated.ValueXY({
      x: 0,
      y: 0
    });
    return _this;
  }
  (0, _inherits2.default)(BackgroundRenderer, _Component);
  return (0, _createClass2.default)(BackgroundRenderer, [{
    key: "animation",
    get: function get() {
      return [{
        scale: this.scaleFactor
      }];
    }
  }, {
    key: "translateAnimation",
    get: function get() {
      return [{
        translateX: this.translate.x
      }, {
        translateY: this.translate.y
      }];
    }
  }, {
    key: "render",
    value: function render() {
      var area = this.props.area;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: Object.assign({}, area, {
          position: 'relative',
          overflow: 'hidden'
        }),
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
          style: Object.assign({
            position: 'absolute'
          }, area, {
            transform: this.translateAnimation,
            overflow: 'visible'
          }),
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
            style: Object.assign({
              position: 'absolute'
            }, area, {
              transform: this.animation
            }),
            children: this.props.children
          })
        })
      });
    }
  }, {
    key: "shouldComponentUpdate",
    value: function shouldComponentUpdate(nextProps, _nextState, _nextContext) {
      var onChanged = nextProps.onChanged;
      if (nextProps.animated && nextProps.scaleFactor !== this.props.scaleFactor) {
        var duration = 195;
        _reactNativeWeb.Animated.parallel([_reactNativeWeb.Animated.timing(this.scaleFactor, {
          duration: duration,
          toValue: nextProps.scaleFactor,
          useNativeDriver: _reactNativeWeb.Platform.OS !== 'web'
        }), _reactNativeWeb.Animated.timing(this.translate, {
          duration: duration,
          toValue: {
            x: nextProps.translateX * nextProps.scaleFactor,
            y: nextProps.translateY * nextProps.scaleFactor
          },
          useNativeDriver: _reactNativeWeb.Platform.OS !== 'web'
        })]).start(function () {
          if (onChanged != null) {
            onChanged(nextProps.scaleFactor, {
              x: nextProps.translateX,
              y: nextProps.translateY
            });
          }
        });
      } else {
        this.scaleFactor.setValue(nextProps.scaleFactor);
        this.translate.setValue({
          x: nextProps.translateX * nextProps.scaleFactor,
          y: nextProps.translateY * nextProps.scaleFactor
        });
        if (onChanged != null && (nextProps.scaleFactor !== this.props.scaleFactor || nextProps.translateY !== this.props.translateY || nextProps.translateX !== this.props.translateX)) {
          onChanged(nextProps.scaleFactor, {
            x: nextProps.translateX,
            y: nextProps.translateY
          });
        }
      }
      return nextProps.area.width !== this.props.area.width || nextProps.area.height !== this.props.area.height || nextProps.children !== this.props.children;
    }
  }]);
}(_react.Component);