var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Chip = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _color = _interopRequireDefault(require("color"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _Icon = require("../Icon");
var _MaterialText = require("../text/MaterialText");
var _Ripple = require("../utils/Ripple");
var _ChipContainer = require("./ChipContainer");
var _I18n = require("../../i18n/I18n");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var Chip = exports.Chip = function (_PureComponent) {
  function Chip(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Chip);
    _this = _callSuper(this, Chip, [props]);
    _this.onEnter = function () {
      _this.setState({
        hover: true
      });
    };
    _this.onLeave = function () {
      _this.setState({
        hover: false
      });
    };
    _this.state = {
      hover: false
    };
    return _this;
  }
  (0, _inherits2.default)(Chip, _PureComponent);
  return (0, _createClass2.default)(Chip, [{
    key: "getBackgroundColor",
    value: function getBackgroundColor() {
      var _this$props = this.props,
        onPressChip = _this$props.onPressChip,
        hoverColor = _this$props.hoverColor,
        backgroundColor = _this$props.backgroundColor,
        chipDisabled = _this$props.chipDisabled;
      if (this.state.hover === true && onPressChip !== undefined && chipDisabled !== true) {
        if (hoverColor == null || hoverColor.length === 0) {
          return (0, _color.default)(backgroundColor).darken(0.1).toString();
        }
        return hoverColor;
      }
      return backgroundColor;
    }
  }, {
    key: "getButtonColor",
    value: function getButtonColor() {
      var _this$props2 = this.props,
        onPressChip = _this$props2.onPressChip,
        removeIconColor = _this$props2.removeIconColor,
        hoverTextColor = _this$props2.hoverTextColor;
      if (this.state.hover === true && onPressChip != null) {
        return hoverTextColor;
      }
      return removeIconColor;
    }
  }, {
    key: "getHoverTextColor",
    value: function getHoverTextColor() {
      var _this$props3 = this.props,
        hoverTextColor = _this$props3.hoverTextColor,
        textColor = _this$props3.textColor;
      if (this.props.hoverTextColor != null && this.props.hoverTextColor.length > 0) {
        return hoverTextColor;
      }
      return textColor;
    }
  }, {
    key: "getRemoveIconColor",
    value: function getRemoveIconColor() {
      var _this$props4 = this.props,
        removeIconColor = _this$props4.removeIconColor,
        textColor = _this$props4.textColor;
      if (removeIconColor != null) {
        return removeIconColor;
      }
      if (textColor !== Chip.defaultProps.textColor) {
        return textColor;
      }
      return _ThemeManager.ThemeManager.style.black54;
    }
  }, {
    key: "getTextColor",
    value: function getTextColor() {
      var _this$props5 = this.props,
        onPressChip = _this$props5.onPressChip,
        hoverTextColor = _this$props5.hoverTextColor,
        textColor = _this$props5.textColor;
      if (this.state.hover === true && onPressChip != null) {
        return hoverTextColor;
      }
      return textColor;
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props6 = this.props,
        onPressChip = _this$props6.onPressChip,
        chipDisabled = _this$props6.chipDisabled,
        outlined = _this$props6.outlined,
        borderProps = _this$props6.borderProps,
        onPressButton = _this$props6.onPressButton;
      return (0, _jsxRuntime.jsxs)(_ChipContainer.ChipContainer, {
        onMouseEnter: this.onEnter,
        onMouseLeave: this.onLeave,
        outlined: outlined,
        borderProps: borderProps,
        onPress: onPressChip,
        disabled: chipDisabled,
        backgroundColor: this.getBackgroundColor(),
        button: onPressButton != null ? this.renderButton() : null,
        children: [this.renderThumbnail(), this.renderText(), onPressButton != null ? null : this.renderButton()]
      });
    }
  }, {
    key: "renderButton",
    value: function renderButton() {
      var _this$props7 = this.props,
        button = _this$props7.button,
        onPressButton = _this$props7.onPressButton,
        buttonDisabled = _this$props7.buttonDisabled,
        removeIcon = _this$props7.removeIcon,
        removeIconTooltip = _this$props7.removeIconTooltip,
        accessibilityLabel = _this$props7.accessibilityLabel,
        removeIconSize = _this$props7.removeIconSize;
      if (!button) {
        return null;
      }
      var iconColor = this.getRemoveIconColor();
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          marginRight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(4)
        },
        children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
          accessibilityLabel: accessibilityLabel,
          icon: removeIcon != null ? removeIcon : 'close-circle',
          toolTip: removeIconTooltip != null ? removeIconTooltip : _I18n.I18n.m.getMessage('remove'),
          iconSize: removeIconSize || 16,
          outerSize: 24,
          backgroundColor: "transparent",
          color: iconColor,
          onPress: onPressButton,
          disabled: onPressButton == null || buttonDisabled
        })
      });
    }
  }, {
    key: "renderText",
    value: function renderText() {
      var _this$props8 = this.props,
        title = _this$props8.title,
        button = _this$props8.button,
        onPressText = _this$props8.onPressText;
      var hover = this.state.hover;
      var calculatedPadding = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(8);
      return (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
        accessibilityLabel: title,
        onPress: onPressText,
        disabled: onPressText == null,
        onMouseEnter: this.onEnter,
        onMouseLeave: this.onLeave,
        style: {
          paddingVertical: _reactNativeWeb.Platform.OS === 'web' ? calculatedPadding : 0,
          paddingLeft: calculatedPadding,
          paddingRight: !button ? calculatedPadding : 0
        },
        children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          color: hover ? this.getHoverTextColor() : this.getTextColor(),
          type: _MaterialText.MaterialTextTypes.Body2,
          children: title
        })
      });
    }
  }, {
    key: "renderThumbnail",
    value: function renderThumbnail() {
      var thumbnail = this.props.thumbnail;
      if (thumbnail == null) {
        return null;
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(24),
          width: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(24),
          borderRadius: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(12),
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          overflow: 'hidden'
        },
        children: thumbnail
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        title: '',
        button: false,
        buttonDisabled: false,
        chipDisabled: false,
        textColor: _ThemeManager.ThemeManager.style.black87,
        backgroundColor: _ThemeManager.ThemeManager.style.chipDefaultBg,
        outlined: false,
        removeIcon: 'close-circle'
      };
    }
  }]);
}(_react.PureComponent);