var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Dragable = void 0;
exports.Table = Table;
exports.createWorkBook = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _clipboard = _interopRequireDefault(require("@react-native-clipboard/clipboard"));
var _naturalOrderby = require("natural-orderby");
var _SimpleStorage = require("odatarepos/build/src/db/SimpleStorage");
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var XLSX = _interopRequireWildcard(require("xlsx"));
var _UseComponentSize = _interopRequireDefault(require("../hooks/UseComponentSize"));
var _UseScroll = _interopRequireDefault(require("../hooks/UseScroll"));
var _UseScrollbarWidth = _interopRequireDefault(require("../hooks/UseScrollbarWidth"));
var _I18n = require("../i18n/I18n");
var _ThemeManager = require("../ThemeManager");
var _DialogUp = require("./DialogUp");
var _ExcelDownloadHelper = require("./file/ExcelDownloadHelper");
var _Icon = require("./Icon");
var _Menu = require("./Menu");
var _Spinner = require("./Spinner");
var _TableRow = require("./TableRow");
var _TableSettings = _interopRequireDefault(require("./TableSettings"));
var _TableTypes = require("./TableTypes");
var _MaterialText = require("./text/MaterialText");
var _Measurement = require("./utils/Measurement");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var lineHeight = function lineHeight(maxHeight) {
  if (maxHeight === 0) return 'auto';
  return _TableRow.RAW_HEIGHT;
};
var panResponderX0 = 0;
var panResponderTimeout;
var Dragable = exports.Dragable = function (_PureComponent) {
  function Dragable() {
    var _this;
    (0, _classCallCheck2.default)(this, Dragable);
    _this = _callSuper(this, Dragable, arguments);
    _this.pan = _reactNativeWeb.PanResponder.create({
      onStartShouldSetPanResponder: function onStartShouldSetPanResponder() {
        return true;
      },
      onStartShouldSetPanResponderCapture: function onStartShouldSetPanResponderCapture() {
        return true;
      },
      onMoveShouldSetPanResponder: function onMoveShouldSetPanResponder() {
        return true;
      },
      onMoveShouldSetPanResponderCapture: function onMoveShouldSetPanResponderCapture() {
        return true;
      },
      onPanResponderGrant: function onPanResponderGrant(_evt, gestureState) {
        _this.props.onStart(gestureState.x0);
      },
      onPanResponderMove: function onPanResponderMove(_evt, gestureState) {
        _this.props.onMove(gestureState.moveX);
      },
      onPanResponderEnd: function onPanResponderEnd(_evt, _gestureState) {
        _this.props.onEnd(_gestureState.moveX);
      }
    });
    return _this;
  }
  (0, _inherits2.default)(Dragable, _PureComponent);
  return (0, _createClass2.default)(Dragable, [{
    key: "render",
    value: function render() {
      if (!this.pan) return null;
      var webStyle = _reactNativeWeb.Platform.OS === 'web' ? {
        cursor: 'col-resize',
        userSelect: 'none'
      } : {};
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, Object.assign({
        collapsable: false,
        style: Object.assign({}, webStyle, {
          position: 'absolute',
          backgroundColor: 'transparent',
          right: 0,
          width: 32,
          height: 64,
          zIndex: 1501
        })
      }, this.pan.panHandlers));
    }
  }]);
}(_react.PureComponent);
var createWorkBook = exports.createWorkBook = function createWorkBook(tableData, selected, columns, selectedColumns, sheetName) {
  if (tableData != null && tableData.length > 0) {
    var selectedData = tableData.filter(function (element) {
      if (selected.size === 0) return true;
      return selected.has(`${element.id}`);
    });
    var sortedSelectedColumnsAsStrings = [];
    var mySelectedColumns = columns.filter(function (column) {
      var selected = null;
      if (column.title != null && typeof column.title === 'string') {
        if (selectedColumns == null || selectedColumns.includes(column.title)) {
          selected = column.title;
        }
      }
      if (column.title != null && typeof column.title === 'object') {
        var tmpTitle = column.title;
        if (selectedColumns == null || selectedColumns.includes(tmpTitle.toolTip)) {
          selected = tmpTitle.toolTip;
        }
      }
      if (selected != null) {
        sortedSelectedColumnsAsStrings.push(selected);
        return true;
      }
      return false;
    });
    var workBook = XLSX.utils.book_new();
    workBook.Props = {
      Title: sheetName,
      CreatedDate: new Date()
    };
    workBook.SheetNames.push(sheetName);
    var sheetData = [];
    sheetData.push(sortedSelectedColumnsAsStrings);
    selectedData.forEach(function (dataElement) {
      var row = [];
      for (var columnElement of mySelectedColumns) {
        row.push(dataElement[columnElement.keyInData]);
      }
      sheetData.push(row);
    });
    workBook.Sheets[sheetName] = XLSX.utils.aoa_to_sheet(sheetData, {
      dateNF: 'dd/mm/yyyy'
    });
    return workBook;
  }
  return null;
};
function Table(_ref) {
  var tableName = _ref.tableName,
    visibleColumns = _ref.visibleColumns,
    minimumWidth = _ref.minimumWidth,
    data = _ref.data,
    keyExtractor = _ref.keyExtractor,
    emptyTableImage = _ref.emptyTableImage,
    emptyTableText = _ref.emptyTableText,
    emptyTableHint = _ref.emptyTableHint,
    _ref$columns = _ref.columns,
    columns = _ref$columns === void 0 ? [] : _ref$columns,
    sortBy = _ref.sortBy,
    _ref$sortDirection = _ref.sortDirection,
    sortDirection = _ref$sortDirection === void 0 ? 'asc' : _ref$sortDirection,
    onRowPress = _ref.onRowPress,
    maxHeight = _ref.maxHeight,
    actions = _ref.actions,
    actionItemsLength = _ref.actionItemsLength,
    multiselectEnabled = _ref.multiselectEnabled,
    onMultiSelect = _ref.onMultiSelect,
    selectedIDs = _ref.selectedIDs,
    onChangeSort = _ref.onChangeSort,
    isSelectable = _ref.isSelectable,
    _ref$minColumnWidth = _ref.minColumnWidth,
    minColumnWidth = _ref$minColumnWidth === void 0 ? 128 : _ref$minColumnWidth,
    _ref$sortable = _ref.sortable,
    sortable = _ref$sortable === void 0 ? true : _ref$sortable,
    headerAlignment = _ref.headerAlignment,
    overWriteSort = _ref.overWriteSort,
    innerPaddingBottom = _ref.innerPaddingBottom;
  var getColumnWidth = function getColumnWidth() {
    var newColumnWidth = [];
    columns.forEach(function (c, i) {
      var _StyleSheet$flatten = _reactNativeWeb.StyleSheet.flatten(c.style),
        width = _StyleSheet$flatten.width,
        minWidth = _StyleSheet$flatten.minWidth;
      if (typeof width === 'number') newColumnWidth[i] = width;else if (typeof minWidth === 'number') newColumnWidth[i] = minWidth;else newColumnWidth[i] = 200;
      newColumnWidth[i] = Math.max(newColumnWidth[i], minColumnWidth);
    });
    return newColumnWidth;
  };
  var _useState = (0, _react.useState)(sortDirection),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    direction = _useState2[0],
    setDirection = _useState2[1];
  var _useState3 = (0, _react.useState)(visibleColumns),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    selectedColumns = _useState4[0],
    setSelectedColumns = _useState4[1];
  var _useState5 = (0, _react.useState)(sortBy || (columns[0] ? columns[0].keyInData : undefined)),
    _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
    selectedColumn = _useState6[0],
    setSelectedColumn = _useState6[1];
  var _useState7 = (0, _react.useState)(data),
    _useState8 = (0, _slicedToArray2.default)(_useState7, 2),
    tableData = _useState8[0],
    setTableData = _useState8[1];
  var _useState9 = (0, _react.useState)(minimumWidth),
    _useState10 = (0, _slicedToArray2.default)(_useState9, 2),
    minWidth = _useState10[0],
    setMinWidth = _useState10[1];
  var _useState11 = (0, _react.useState)(getColumnWidth()),
    _useState12 = (0, _slicedToArray2.default)(_useState11, 2),
    columnWidth = _useState12[0],
    setColumnWidth = _useState12[1];
  var _useState13 = (0, _react.useState)((0, _toConsumableArray2.default)(Array(columns.length).keys())),
    _useState14 = (0, _slicedToArray2.default)(_useState13, 2),
    order = _useState14[0],
    setOrder = _useState14[1];
  var _useComponentSize = (0, _UseComponentSize.default)(),
    _useComponentSize2 = (0, _slicedToArray2.default)(_useComponentSize, 2),
    width = _useComponentSize2[0].width,
    onLayout = _useComponentSize2[1];
  var _useScroll = (0, _UseScroll.default)(),
    _useScroll2 = (0, _slicedToArray2.default)(_useScroll, 2),
    scroll = _useScroll2[0],
    _onScroll = _useScroll2[1];
  var _useComponentSize3 = (0, _UseComponentSize.default)(),
    _useComponentSize4 = (0, _slicedToArray2.default)(_useComponentSize3, 2),
    scrollViewSize = _useComponentSize4[0],
    onLayoutScrollView = _useComponentSize4[1];
  var _useState15 = (0, _react.useState)({
      index: -1,
      width: 0,
      x: 0
    }),
    _useState16 = (0, _slicedToArray2.default)(_useState15, 2),
    currentColumnMove = _useState16[0],
    setCurrentColumnMove = _useState16[1];
  var _useState17 = (0, _react.useState)(true),
    _useState18 = (0, _slicedToArray2.default)(_useState17, 2),
    isScrollable = _useState18[0],
    setIsScrollable = _useState18[1];
  var _useState19 = (0, _react.useState)(data != null ? data.length : 0),
    _useState20 = (0, _slicedToArray2.default)(_useState19, 2),
    maxSelectedLength = _useState20[0],
    setMaxSelectedLength = _useState20[1];
  var _useState21 = (0, _react.useState)(selectedIDs != null ? selectedIDs : new Set()),
    _useState22 = (0, _slicedToArray2.default)(_useState21, 2),
    selected = _useState22[0],
    setSelected = _useState22[1];
  var sbw = (0, _UseScrollbarWidth.default)();
  var sheetName = tableName.length > 30 ? tableName.slice(0, 30) : tableName;
  (0, _react.useEffect)(function () {
    var m = 0;
    if (data != null && data.length > 0) {
      if (isSelectable != null) {
        data.forEach(function (item) {
          if (isSelectable(item)) m += 1;
        });
      } else {
        m = data.length;
      }
    }
    setMaxSelectedLength(m);
  }, [data]);
  var copyHandler = function copyHandler() {
    var workBook = createWorkBook(tableData, selected, columns, selectedColumns, sheetName);
    if (workBook != null) {
      var csvString = XLSX.utils.sheet_to_csv(workBook.Sheets[sheetName], {
        dateNF: 'dd/mm/yyyy',
        FS: ';',
        RS: '\n',
        forceQuotes: true
      });
      _clipboard.default.setString(csvString);
    }
  };
  var printHandler = function printHandler() {
    var workBook = createWorkBook(tableData, selected, columns, selectedColumns, sheetName);
    if (workBook != null) {
      _ExcelDownloadHelper.ExcelDownloadHelper.saveAndOpen(sheetName, workBook).catch(function (err) {});
    }
  };
  (0, _react.useEffect)(function () {
    if (_reactNativeWeb.Platform.OS === 'web') {
      var keyboardListener = function keyboardListener(event) {
        if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
          event.preventDefault();
          printHandler();
        }
      };
      document.addEventListener('keydown', keyboardListener);
      return function () {
        document.removeEventListener('keydown', keyboardListener);
      };
    }
    return function () {};
  }, [multiselectEnabled, selected, data, visibleColumns, selectedColumns]);
  (0, _react.useEffect)(function () {
    setSelected(selectedIDs != null ? selectedIDs : new Set());
  }, [selectedIDs]);
  var fetchSettings = function fetchSettings() {
    if (selectedColumns == null && visibleColumns == null) {
      try {
        var settings = _SimpleStorage.SimpleStorage.get(`tableSettings_${tableName}`);
        if (settings != null) {
          try {
            var tableSettings = JSON.parse(settings);
            if (tableSettings.columnWidth && tableSettings.columnWidth.length >= columns.length) setColumnWidth(tableSettings.columnWidth);
            if (tableSettings.currentSelected != null) setSelectedColumns((0, _toConsumableArray2.default)(tableSettings.currentSelected));
            if (tableSettings.direction != null) setDirection(tableSettings.direction);
            if (tableSettings.selectedColumn != null && columns.find(function (a) {
              return typeof a.title === 'string' && a.title === tableSettings.selectedColumn || typeof a.title === 'object' && a.title.toolTip === tableSettings.selectedColumn;
            })) setSelectedColumn(tableSettings.selectedColumn);
            if (tableSettings.order != null && tableSettings.order.length === columns.length) setOrder(tableSettings.order);else setOrder((0, _toConsumableArray2.default)(Array(columns.length).keys()));
          } catch (e) {}
        }
      } catch (e) {}
    }
  };
  (0, _react.useEffect)(function () {
    fetchSettings();
  }, [visibleColumns]);
  (0, _react.useEffect)(function () {
    if (minimumWidth != null) {
      if (minimumWidth !== minWidth) setMinWidth(minimumWidth);
    } else {
      var newWidth = 100;
      columns.forEach(function (c, i) {
        var t = c.hiddenTitleForSettings != null ? c.hiddenTitleForSettings : typeof c.title === 'string' ? c.title : c.title.toolTip;
        if (selectedColumns == null || selectedColumns.includes(t)) {
          newWidth += columnWidth[i];
        }
      });
      var newW = tableData != null ? newWidth - (sbw || 0) : undefined;
      if (newW !== minWidth) setMinWidth(newW);
    }
  }, [minimumWidth, columns, width, selectedColumns, columnWidth, tableData]);
  var sortTable = function sortTable(column, initialSort) {
    if (overWriteSort != null) {
      if (column != null) {
        var _newDirection = direction;
        var _columnName = column.keyInData;
        if (!initialSort && _columnName === selectedColumn) {
          var reversedDirection = direction === 'desc' ? 'asc' : 'desc';
          _newDirection = reversedDirection;
        }
        overWriteSort({
          sortBy: column.keyInData.toString(),
          direction: _newDirection
        });
        if (_columnName !== selectedColumn) setSelectedColumn(_columnName);
        if (_newDirection !== direction) setDirection(_newDirection);
      }
      return;
    }
    var dataForSorting = data;
    if (!dataForSorting || !column) return;
    if (!sortable) {
      if (dataForSorting !== tableData) setTableData(dataForSorting);
      return;
    }
    var newDirection = direction;
    var columnName = column.keyInData;
    var columnNameSorter = column.dataType === 'string' ? function (item) {
      if (item[column.keyInData] == null) {
        return '';
      }
      return typeof item[column.keyInData] === 'string' ? item[column.keyInData].toLowerCase() : item[column.keyInData];
    } : column.dataType === 'number' ? function (item) {
      return typeof item[column.keyInData] === 'string' ? Number.parseFloat(item[column.keyInData]) : item[column.keyInData];
    } : column.keyInData;
    if (!initialSort && columnName === selectedColumn) {
      var _reversedDirection = direction === 'desc' ? 'asc' : 'desc';
      newDirection = _reversedDirection;
    }
    var sortFunction = column.sortFunction;
    var sortedData = sortFunction ? dataForSorting.sort(sortFunction(newDirection)) : (0, _naturalOrderby.orderBy)(dataForSorting, [columnNameSorter], [newDirection]);
    if (columnName !== selectedColumn) setSelectedColumn(columnName);
    if (newDirection !== direction) setDirection(newDirection);
    if (sortedData !== tableData) setTableData(sortedData);
    if (onChangeSort != null) {
      onChangeSort(sortedData);
    }
  };
  var moveEndColumnWidth = function moveEndColumnWidth(dx) {
    var newColumnWidth = (0, _toConsumableArray2.default)(columnWidth);
    newColumnWidth[currentColumnMove.index] = Math.max(Math.round(currentColumnMove.width + dx), columns[currentColumnMove.index].minWidth || minColumnWidth);
    if (typeof newColumnWidth[currentColumnMove.index] === 'number') setColumnWidth(newColumnWidth);
    setCurrentColumnMove({
      index: -1,
      width: 0,
      x: 0
    });
  };
  (0, _react.useEffect)(function () {
    var to = setTimeout(function () {
      var tableSettings = {
        currentSelected: selectedColumns,
        selectedColumn: selectedColumn,
        columnWidth: columnWidth,
        columns: columns,
        order: order,
        direction: direction
      };
      _SimpleStorage.SimpleStorage.set(`tableSettings_${tableName}`, JSON.stringify(tableSettings));
    }, 250);
    return function () {
      return clearTimeout(to);
    };
  }, [selectedColumns, columnWidth, columns, order, direction, selectedColumn]);
  var endPanResponder = function endPanResponder() {
    clearTimeout(panResponderTimeout);
    setIsScrollable(true);
  };
  (0, _react.useEffect)(function () {
    if (overWriteSort == null && data && data[0]) {
      sortTable(columns.find(function (col) {
        return col.keyInData === selectedColumn;
      }), true);
    } else {
      setTableData(data);
    }
  }, [data]);
  var getDefaultCellTextAlignment = function getDefaultCellTextAlignment(dataType) {
    if (dataType === 'object') {
      return 'center';
    }
    return dataType === 'string' ? 'flex-start' : 'flex-end';
  };
  var renderHeadlineElement = function renderHeadlineElement(column, index, smallLayout) {
    var icon;
    if (smallLayout) {
      return null;
    }
    var content = typeof column.title === 'string' ? (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
      centeredBox: !smallLayout,
      ellipsizeMode: smallLayout ? undefined : 'tail',
      type: _MaterialText.MaterialTextTypes.Subtitle2,
      color: _ThemeManager.ThemeManager.style.black42,
      strong: true,
      children: column.title
    }) : (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        alignSelf: smallLayout ? 'flex-start' : undefined
      },
      children: (0, _jsxRuntime.jsx)(_Icon.Icon, Object.assign({}, column.title))
    });
    if (smallLayout) return content;
    if (sortable && selectedColumn === column.keyInData && column.sortable !== false) {
      icon = (0, _jsxRuntime.jsx)(_Icon.Icon, {
        toolTip: "",
        icon: direction === 'asc' ? 'arrow-up' : 'arrow-down'
      });
    }
    var w = currentColumnMove.index === index ? currentColumnMove.width + currentColumnMove.x : columnWidth[index];
    var columnStyle = _reactNativeWeb.StyleSheet.flatten([_TableTypes.styles.headerItem, {
      height: maxHeight === 0 ? '100%' : lineHeight(maxHeight)
    }, column.style, {
      maxWidth: w,
      width: w
    }]);
    var cellContentStyle = {
      justifyContent: headerAlignment != null ? headerAlignment : getDefaultCellTextAlignment(column.dataType)
    };
    var leftIconPosition = column.dataType !== 'string';
    return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: {
        position: 'relative'
      },
      children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.Pressable, {
        disabled: column.sortable === false,
        onPress: function onPress() {
          return sortTable(column);
        },
        style: Object.assign({}, columnStyle, {
          paddingRight: leftIconPosition ? 15 : 16,
          paddingLeft: 16
        }),
        accessibilityLabel: "headlineElement",
        children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: [{
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center'
          }, cellContentStyle],
          children: [leftIconPosition ? icon : null, content, leftIconPosition ? null : icon]
        })
      }, `headline-element-inner-${column.title}-${index}`), (0, _jsxRuntime.jsx)(Dragable, {
        onStart: function onStart(x) {
          setIsScrollable(false);
          setCurrentColumnMove({
            index: index,
            width: !columnWidth[index] || Number.isNaN(columnWidth[index]) ? columns[index].minWidth || minColumnWidth : columnWidth[index],
            x: 0
          });
          panResponderX0 = x;
          panResponderTimeout = setTimeout(endPanResponder, 3000);
        },
        onMove: function onMove(x) {
          clearTimeout(panResponderTimeout);
          panResponderTimeout = setTimeout(endPanResponder, 3000);
          var min = Math.max(Math.round(currentColumnMove.width + (x - panResponderX0)), columns[currentColumnMove.index].minWidth || minColumnWidth);
          setCurrentColumnMove(Object.assign({}, currentColumnMove, {
            x: min - currentColumnMove.width
          }));
        },
        onEnd: function onEnd(x) {
          moveEndColumnWidth(x - panResponderX0);
          setIsScrollable(true);
          endPanResponder();
        }
      })]
    }, `headline-element-${column.title}-${index}`);
  };
  var getActionWidth = function getActionWidth() {
    if (actionItemsLength === 0) {
      return 0;
    }
    var max = minWidth != null && minWidth > scrollViewSize.width ? 1 : 3;
    var n = Math.min(actionItemsLength, max);
    return n * 40 + 16;
  };
  var onSelectAll = function onSelectAll() {
    var s = new Set();
    if (tableData == null) setSelected(s);
    if (selected.size !== maxSelectedLength) {
      tableData == null || tableData.forEach(function (f) {
        if (isSelectable == null || isSelectable(f)) s.add(f.id.toString());
      });
    }
    setSelected(s);
    if (onMultiSelect) onMultiSelect(s);
  };
  var openSettingsMenu = function openSettingsMenu(e) {
    var _DialogUp$instance;
    var currentSelected = selectedColumns == null ? [] : (0, _toConsumableArray2.default)(selectedColumns);
    if (selectedColumns == null) {
      columns.forEach(function (c) {
        var t = c.hiddenTitleForSettings != null ? c.hiddenTitleForSettings : typeof c.title === 'string' ? c.title : c.title.toolTip;
        currentSelected.push(t);
      });
    }
    var openPosition = {
      x: e.nativeEvent.pageX,
      y: e.nativeEvent.pageY
    };
    (_DialogUp$instance = _DialogUp.DialogUp.instance) == null || _DialogUp$instance.open({
      content: (0, _jsxRuntime.jsx)(_TableSettings.default, {
        currentSelected: currentSelected,
        columnWidth: columnWidth,
        selectedColumns: selectedColumns,
        order: order,
        onSave: function onSave(tableSettings) {
          var _DialogUp$instance2;
          setSelectedColumns((0, _toConsumableArray2.default)(tableSettings.currentSelected));
          setColumnWidth(tableSettings.columnWidth.map(function (width, index) {
            return width != null && !Number.isNaN(width) ? width : columns[index].minWidth || minColumnWidth;
          }));
          setOrder(tableSettings.order);
          (_DialogUp$instance2 = _DialogUp.DialogUp.instance) == null || _DialogUp$instance2.close();
        },
        columns: columns
      }),
      scrollable: false,
      fullscreenResponsive: true,
      openPosition: openPosition,
      contentPadding: false
    });
  };
  var rightPosition = function rightPosition() {
    var listScrollBarExists = tableData != null && tableData.length * _TableRow.RAW_HEIGHT > maxHeight - 146;
    var actionsRightPosition = Math.max(0, width - scrollViewSize.width - scroll.x);
    var scrolledToEnd = scroll.x + scrollViewSize.width >= width;
    if (listScrollBarExists && sbw != null && !scrolledToEnd) {
      actionsRightPosition -= sbw;
    }
    return actionsRightPosition;
  };
  var openTableMenu = function openTableMenu(e) {
    _Measurement.Measurement.measure(e.currentTarget).then(function (p) {
      var _Menu$instance4;
      var client = {
        height: p.height,
        width: p.width,
        x: p.pageX,
        y: p.pageY
      };
      var items = [{
        onPress: function onPress() {
          var _Menu$instance;
          (_Menu$instance = _Menu.Menu.instance) == null || _Menu$instance.close();
          copyHandler();
        },
        thumbnail: {
          thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            icon: "clipboard-text-outline",
            toolTip: _I18n.I18n.m.getMessage('tableCopyHelper')
          }),
          width: 40
        },
        text: _I18n.I18n.m.getMessage('tableCopyHelper')
      }, {
        onPress: function onPress() {
          var _Menu$instance2;
          (_Menu$instance2 = _Menu.Menu.instance) == null || _Menu$instance2.close();
          printHandler();
        },
        thumbnail: {
          thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            icon: "download-outline",
            toolTip: _I18n.I18n.m.getMessage('tableExportHelper')
          }),
          width: 40
        },
        text: _I18n.I18n.m.getMessage('tableExportHelper')
      }, {
        onPress: function onPress(e) {
          var _Menu$instance3;
          (_Menu$instance3 = _Menu.Menu.instance) == null || _Menu$instance3.close();
          openSettingsMenu(e);
        },
        thumbnail: {
          thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            icon: "table-cog",
            toolTip: _I18n.I18n.m.getMessage('tableSettings')
          }),
          width: 40
        },
        text: _I18n.I18n.m.getMessage('tableSettings')
      }];
      (_Menu$instance4 = _Menu.Menu.instance) == null || _Menu$instance4.open({
        client: client,
        items: items,
        formInput: false
      });
    }).catch(function (err) {});
  };
  var renderActionsButton = function renderActionsButton() {
    return (columns == null ? void 0 : columns.length) > 1 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        position: 'absolute',
        right: 0,
        height: lineHeight(maxHeight),
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingHorizontal: 0,
        width: 56
      },
      children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          backgroundColor: 'transparent',
          width: 44,
          height: '100%',
          paddingBottom: _ThemeManager.ThemeManager.style.borderWidth,
          borderWidth: 0,
          borderLeftWidth: 0,
          borderStyle: 'solid',
          borderColor: _ThemeManager.ThemeManager.style.borderColor
        },
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: 44,
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            icon: "dots-vertical",
            toolTip: _I18n.I18n.m.getMessage('tableSettings'),
            onPress: openTableMenu,
            backgroundColor: "#FFFFFF"
          })
        }, "action_dots_settings")
      }, "action_dots_settings")
    }) : null;
  };
  var renderHeader = function renderHeader() {
    if (!Array.isArray(columns) || columns.length === 0) {
      return null;
    }
    return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: [_TableTypes.styles.header, _TableTypes.styles.bottomBorderStyle, {
        paddingRight: 5
      }],
      children: [multiselectEnabled ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          height: '100%',
          justifyContent: 'center',
          width: 44,
          paddingHorizontal: 4
        },
        children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
          toolTip: "",
          icon: selected && data && selected.size === maxSelectedLength ? 'checkbox-marked-outline' : 'checkbox-blank-outline',
          color: selected && data && selected.size === maxSelectedLength ? _ThemeManager.ThemeManager.style.brandPrimary : _ThemeManager.ThemeManager.style.defaultIconColor,
          onPress: onSelectAll
        })
      }) : undefined, (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          flexDirection: 'row'
        },
        children: order.map(function (columnIndex) {
          var column = columns[columnIndex];
          if (!column) return null;
          var t = column.hiddenTitleForSettings != null ? column.hiddenTitleForSettings : typeof column.title === 'string' ? column.title : column.title.toolTip;
          if (selectedColumns == null || selectedColumns.includes(t)) {
            return renderHeadlineElement(column, columnIndex, false);
          }
          return null;
        })
      }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          paddingHorizontal: getActionWidth() > 0 ? 8 : 0,
          width: getActionWidth()
        }
      })]
    });
  };
  var renderEmptyTable = function renderEmptyTable() {
    return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: {
        width: '100%',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        padding: 16
      },
      children: [emptyTableImage ? (0, _jsxRuntime.jsx)(_reactNativeWeb.Image, {
        style: {
          width: '100%',
          height: 200,
          resizeMode: 'contain'
        },
        resizeMode: "contain",
        source: emptyTableImage
      }) : undefined, (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          marginTop: 16,
          alignItems: 'center'
        },
        children: [emptyTableText == null ? null : (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          centeredBox: true,
          centeredText: true,
          color: _ThemeManager.ThemeManager.style.black54,
          type: _MaterialText.MaterialTextTypes.Subtitle2,
          strong: true,
          children: emptyTableText
        }), emptyTableHint == null ? null : (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          centeredBox: true,
          centeredText: true,
          color: _ThemeManager.ThemeManager.style.black54,
          type: _MaterialText.MaterialTextTypes.Subtitle2,
          children: emptyTableHint
        })]
      })]
    });
  };
  var onPressMultiSelectCheckbox = (0, _react.useCallback)(function (item) {
    var s = new Set(selected);
    if (s.has(item.id.toString())) {
      s.delete(item.id.toString());
    } else {
      s.add(item.id.toString());
    }
    setSelected(s);
    if (onMultiSelect) {
      onMultiSelect(s);
    }
  }, [selected]);
  var renderRow = function renderRow(_ref2) {
    var item = _ref2.item,
      index = _ref2.index;
    var backgroundColor = item.bgColor ? item.bgColor : index % 2 === 0 ? '#FFFFFF' : '#fafafa';
    var rowKey = keyExtractor != null ? keyExtractor(item) : `tableRow_${item.id}_${item['lastModifiedAt']}_${backgroundColor}`;
    return (0, _jsxRuntime.jsx)(_TableRow.TableRow, {
      selectedColumns: selectedColumns,
      columns: columns,
      order: order,
      rightPosition: rightPosition(),
      rowKey: rowKey,
      backgroundColor: backgroundColor,
      item: item,
      onPressMultiSelectCheckbox: onPressMultiSelectCheckbox,
      lineHeight: lineHeight(maxHeight),
      selectedRow: selected != null && selected.has(item.id.toString()),
      isSelectable: isSelectable,
      actionWidth: getActionWidth(),
      onRowPress: onRowPress,
      actions: actions,
      multiselectEnabled: !!multiselectEnabled,
      columnWidth: columnWidth,
      scrollViewSize: scrollViewSize,
      minWidth: minWidth != null ? minWidth : 200
    }, rowKey);
  };
  var renderContent = function renderContent() {
    if (tableData == null || width === 0) {
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: _TableTypes.styles.loadingSpinner,
        children: (0, _jsxRuntime.jsx)(_Spinner.Spinner, {})
      });
    }
    if (tableData.length === 0) {
      return renderEmptyTable();
    }
    var renderWebHeader = (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        width: '100%',
        height: 54
      },
      children: renderHeader()
    });
    return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: {
        width: '100%'
      },
      children: [renderWebHeader, maxHeight === 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          height: 'auto'
        },
        children: tableData.map(function (m, index) {
          return renderRow({
            item: m,
            index: index
          });
        })
      }) : (0, _jsxRuntime.jsx)(_reactNativeWeb.FlatList, {
        initialNumToRender: maxHeight === 0 ? 10 : Math.ceil(maxHeight / _TableRow.RAW_HEIGHT) + 5,
        scrollEnabled: isScrollable,
        style: {
          width: '100%',
          height: _reactNativeWeb.Platform.OS === 'web' ? maxHeight - 54 : 'auto',
          maxHeight: maxHeight === 0 ? undefined : maxHeight - 54
        },
        renderItem: renderRow,
        data: tableData,
        getItemLayout: maxHeight === 0 ? undefined : function (_data, index) {
          return {
            length: _TableRow.RAW_HEIGHT,
            offset: _TableRow.RAW_HEIGHT * index,
            index: index
          };
        },
        keyExtractor: function keyExtractor(item) {
          return `${item.id.toString()}-${item['lastModifiedAt'] ? item['lastModifiedAt'] : '?'}`;
        },
        contentContainerStyle: {
          paddingBottom: innerPaddingBottom != null ? innerPaddingBottom : 92
        }
      })]
    });
  };
  var to;
  return minWidth != null && tableData != null && tableData.length > 0 ? (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
    style: {
      width: '100%'
    },
    children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.ScrollView, {
      onLayout: onLayoutScrollView,
      onScroll: function onScroll(e) {
        if (typeof e.persist === 'function') e.persist();
        if (to) clearTimeout(to);
        to = setTimeout(function () {
          _onScroll(e);
        }, 500);
      },
      disableScrollViewPanResponder: true,
      scrollEventThrottle: 100,
      nestedScrollEnabled: true,
      horizontal: true,
      style: {
        width: '100%',
        borderRadius: _ThemeManager.ThemeManager.style.borderRadius
      },
      contentContainerStyle: {
        width: '100%',
        minWidth: minWidth
      },
      scrollEnabled: isScrollable,
      children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        collapsable: false,
        onLayout: onLayout,
        style: {
          width: '100%',
          minWidth: minWidth
        },
        children: renderContent()
      })
    }), renderActionsButton()]
  }) : (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
    onLayout: onLayout,
    style: {
      width: '100%',
      borderRadius: _ThemeManager.ThemeManager.style.borderRadius
    },
    children: [renderContent(), renderActionsButton()]
  });
}