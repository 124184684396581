"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CompanyPaymentEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var CompanyPaymentEntity = function (_ReadModelEntity_1$Re) {
  function CompanyPaymentEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, CompanyPaymentEntity);
    _this = _callSuper(this, CompanyPaymentEntity, [obj]);
    _this.entityName = 'CompanyPayment';
    _this.price = 69;
    _this.priceCollaborator = 19.9;
    _this.priceTimeTracking = 4.5;
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(CompanyPaymentEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(CompanyPaymentEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new CompanyPaymentEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('price', new OdataEntity_1.OdataIndex());
      baseMap.set('priceCollaborator', new OdataEntity_1.OdataIndex());
      baseMap.set('priceTimeTracking', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.CompanyPaymentEntity = CompanyPaymentEntity;