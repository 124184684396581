"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReactivateAccount = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("../../CurrentUser");
var AccountStatusChanged_1 = require("../../events/user/AccountStatusChanged");
var ReactivateAccount = function (_CommandRoot_1$Comman) {
  function ReactivateAccount(data, userId, token) {
    var _this;
    (0, _classCallCheck2.default)(this, ReactivateAccount);
    _this = _callSuper(this, ReactivateAccount, [data, userId, token]);
    _this.waitForResult = true;
    _this.commandName = 'ReactivateAccount';
    _this.aggregate = 'user';
    return _this;
  }
  (0, _inherits2.default)(ReactivateAccount, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ReactivateAccount, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        try {
          var tokenData = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token);
          var userId = tokenData._id;
          if (userId === 'all') return true;
        } catch (e) {}
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new AccountStatusChanged_1.AccountStatusChanged(this.entityId, {
          status: 'active',
          userId: this.entityId
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ReactivateAccount = ReactivateAccount;