var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WrapperNetworkContext = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _netinfo = _interopRequireDefault(require("@react-native-community/netinfo"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _NetworkStatus = require("upmesh-auth-core/build/src/client/NetworkStatus");
var _Connection = require("../../../Connection");
var _connectionContext = _interopRequireDefault(require("../../../connectionContext"));
var _Uploads = require("../../../file/upload/Uploads");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var WrapperNetworkContext = exports.WrapperNetworkContext = function (_PureComponent) {
  function WrapperNetworkContext(props) {
    var _AuthClient$instance4;
    var _this;
    (0, _classCallCheck2.default)(this, WrapperNetworkContext);
    _this = _callSuper(this, WrapperNetworkContext, [props]);
    _this.unsubscribeFromNetworkConnectivityChanges = null;
    _this.addListener = function () {
      if (_AuthClient.AuthClient.instance != null) {
        _AuthClient.AuthClient.instance.serverConnectionsChanged.attach(_this.onAuthServerConnectionChange);
      } else {
        _this.addListenerTimer = setTimeout(_this.addListener, 500);
      }
    };
    _this.handleNetworkConnectivityChange = function (networkState) {
      var immediatelyConnectToServer = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var isInternetReachable = networkState.isInternetReachable;
      _NetworkStatus.NetworkStatus.online = isInternetReachable === true;
      if (isInternetReachable === true && immediatelyConnectToServer && _AuthClient.AuthClient.instance != null && _AuthClient.AuthClient.instance.serverConnection != null && !_AuthClient.AuthClient.instance.serverConnection.isConnected) {
        _AuthClient.AuthClient.instance.serverConnection.connect().then(function (_c) {
          var _AuthClient$instance;
          _Connection.Connection.offline = isInternetReachable !== true;
          _Connection.Connection.type = networkState.type;
          var connectedToServer = (_AuthClient$instance = _AuthClient.AuthClient.instance) == null ? void 0 : _AuthClient$instance.serverConnected();
          _Connection.Connection.connectedToServer = connectedToServer;
          _this.setState({
            initNetwork: true,
            offline: isInternetReachable !== true,
            type: networkState.type,
            connectedToServer: connectedToServer
          });
        }).catch(function (err) {
          return void 0;
        });
      } else {
        var _AuthClient$instance2;
        _Connection.Connection.offline = isInternetReachable !== true;
        _Connection.Connection.type = networkState.type;
        var connectedToServer = (_AuthClient$instance2 = _AuthClient.AuthClient.instance) == null ? void 0 : _AuthClient$instance2.serverConnected();
        _Connection.Connection.connectedToServer = connectedToServer;
        _this.setState({
          initNetwork: true,
          offline: isInternetReachable !== true,
          type: networkState.type,
          connectedToServer: connectedToServer
        });
      }
    };
    _this.onAuthServerConnectionChange = function () {
      var _AuthClient$instance3;
      var connectedToServer = (_AuthClient$instance3 = _AuthClient.AuthClient.instance) == null ? void 0 : _AuthClient$instance3.serverConnected();
      if (connectedToServer) _Uploads.Uploads.instance.restartUploads();
      _Connection.Connection.connectedToServer = connectedToServer;
      _this.setState({
        connectedToServer: connectedToServer
      });
    };
    _this.state = {
      initNetwork: false,
      offline: true,
      connectedToServer: (_AuthClient$instance4 = _AuthClient.AuthClient.instance) == null ? void 0 : _AuthClient$instance4.serverConnected()
    };
    return _this;
  }
  (0, _inherits2.default)(WrapperNetworkContext, _PureComponent);
  return (0, _createClass2.default)(WrapperNetworkContext, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;
      this.addListener();
      _netinfo.default.fetch().then(function (networkStatus) {
        _this2.handleNetworkConnectivityChange(networkStatus, false);
      }).catch(function (err) {
        return void 0;
      });
      this.unsubscribeFromNetworkConnectivityChanges = _netinfo.default.addEventListener(this.handleNetworkConnectivityChange);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (_AuthClient.AuthClient.instance != null) {
        _AuthClient.AuthClient.instance.serverConnectionsChanged.detach(this.onAuthServerConnectionChange);
      }
      if (this.addListenerTimer != null) {
        clearTimeout(this.addListenerTimer);
      }
      if (this.unsubscribeFromNetworkConnectivityChanges != null) {
        this.unsubscribeFromNetworkConnectivityChanges();
      }
    }
  }, {
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsx)(_connectionContext.default.Provider, {
        value: {
          type: this.state.type != null ? this.state.type : 'unknown',
          offline: this.state.offline,
          connectedToServer: this.state.connectedToServer
        },
        children: !this.state.initNetwork ? null : this.props.children
      });
    }
  }]);
}(_react.PureComponent);