"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddPayment = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var AddPayment = function (_CommandRoot_1$Comman) {
  function AddPayment(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, AddPayment);
    _this = _callSuper(this, AddPayment, [data, entityId, token]);
    _this.commandName = 'AddPayment';
    _this.aggregate = 'company';
    _this.data = data;
    return _this;
  }
  (0, _inherits2.default)(AddPayment, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(AddPayment, [{
    key: "getCompany",
    value: function () {
      var _getCompany = (0, _asyncToGenerator2.default)(function* () {
        var _this$data$to;
        if (this.data.to.type !== 'company') return null;
        if (this._company) return this._company;
        var companies = ReadModels_1.ReadModels.get('Company');
        this._company = yield companies.getById((_this$data$to = this.data.to) == null ? void 0 : _this$data$to.id);
        return this._company;
      });
      function getCompany() {
        return _getCompany.apply(this, arguments);
      }
      return getCompany;
    }()
  }, {
    key: "getPartner",
    value: function () {
      var _getPartner = (0, _asyncToGenerator2.default)(function* () {
        var _this$data$to2;
        if (this.data.to.type !== 'partner') return null;
        if (this._partner) return this._partner;
        var partners = ReadModels_1.ReadModels.get('UpmeshPartner');
        this._partner = yield partners.getById((_this$data$to2 = this.data.to) == null ? void 0 : _this$data$to2.id);
        return this._partner;
      });
      function getPartner() {
        return _getPartner.apply(this, arguments);
      }
      return getPartner;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') {
          return true;
        }
        var admin = ReadModels_1.ReadModels.get('SupportRoles');
        var admins = yield admin.get({
          filter: `userId eq '${userId}' and deleted ne true`
        });
        if (admins.length > 0) {
          for (var adm of admins) {
            if (adm.role === 'Admin') {
              return true;
            }
          }
        }
        if (this.data.to.type !== 'company') throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
        var company = yield this.getCompany();
        if (company == null) throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
        var partner = ReadModels_1.ReadModels.get('UpmeshPartner');
        var partners = yield partner.get({
          filter: `users/userId eq '${userId}'`
        });
        if (partners.length > 0 && company.partnerId === partners[0].id && this.data.from === company.partnerId) {
          if (partners[0].hasRight('canManagePayment', userId)) {
            return true;
          }
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        var errors = [];
        if (_key == null || _key === 'unitPrice') {
          if (this.data.unitPrice == null || this.data.unitPrice === 0) {
            errors.push({
              key: 'unitPrice',
              message: 'Einzelpreis wird benötigt',
              messageCode: 'badRequest'
            });
          }
          if (this.data.amount == null || this.data.amount <= 0) {
            errors.push({
              key: 'amount',
              message: 'Anzahl wird benötigt',
              messageCode: 'badRequest'
            });
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return null;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.AddPayment = AddPayment;