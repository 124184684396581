var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireDefault(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _I18n = require("../../i18n/I18n");
var _FormInputFilled = require("../forminput/FormInputFilled");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var DateInput = exports.default = function (_React$Component) {
  function DateInput(props) {
    var _this;
    (0, _classCallCheck2.default)(this, DateInput);
    _this = _callSuper(this, DateInput, [props]);
    _this.getError = function () {
      var _this$props = _this.props,
        minDate = _this$props.minDate,
        maxDate = _this$props.maxDate;
      if (minDate != null && maxDate != null) return _I18n.I18n.m.getMessage('dateNotInRangeBoth', {
        maxDate: _I18n.I18n.m.dateCurrent.localeDateString(maxDate),
        minDate: _I18n.I18n.m.dateCurrent.localeDateString(minDate)
      });
      if (minDate != null) return _I18n.I18n.m.getMessage('dateNotInRangeMin', {
        minDate: _I18n.I18n.m.dateCurrent.localeDateString(minDate)
      });
      if (maxDate != null) return _I18n.I18n.m.getMessage('dateNotInRangeMax', {
        maxDate: _I18n.I18n.m.dateCurrent.localeDateString(maxDate)
      });
      return '';
    };
    _this.setDate = function (val) {
      _this.setState({
        input: val
      });
      try {
        var newDate = _I18n.I18n.m.dateCurrent.localeDateFromStringWithTime(val);
        var inRange = _this.props.getDateInRange({
          newDate: newDate
        });
        _this.setState({
          error: inRange ? undefined : _this.getError()
        });
        if (!inRange) return;
        if (!Number.isNaN(newDate.getTime())) {
          _this.props.updateDate(newDate);
          var preview = _I18n.I18n.m.dateCurrent.localeDateString(newDate);
          _this.setState({
            preview: preview
          });
        }
      } catch (err) {}
    };
    _this.throwError = function (e) {
      _this.setState({
        error: e
      });
    };
    _this.state = {
      input: '',
      error: undefined,
      preview: ''
    };
    return _this;
  }
  (0, _inherits2.default)(DateInput, _React$Component);
  return (0, _createClass2.default)(DateInput, [{
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          marginRight: 24,
          marginLeft: 24
        },
        children: (0, _jsxRuntime.jsx)(_FormInputFilled.FormInputFilled, {
          value: this.state.input,
          error: !!this.state.error,
          helperText: this.state.error ? this.state.error : this.state.preview,
          onChange: this.setDate
        })
      });
    }
  }]);
}(_react.default.Component);