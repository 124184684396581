var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContentFooter = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _ContentFooterEventHandler = require("./ContentFooterEventHandler");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ContentFooter = exports.ContentFooter = function (_PureComponent) {
  function ContentFooter(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ContentFooter);
    _this = _callSuper(this, ContentFooter, [props]);
    _this.animatedBottom = new _reactNativeWeb.Animated.Value(_ThemeManager.ThemeManager.style.footerHeight + _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(10));
    _this.open = false;
    _this.statusChange = function (status) {
      if (status.open) {
        _this.open = true;
        if (status.footerContent != null) {
          var footerHeight = status.footerHeight != null ? status.footerHeight : _ThemeManager.ThemeManager.style.footerHeight;
          if (_this.state.footerHeight !== footerHeight) {
            ContentFooter._currentHeight = footerHeight;
            _this.animatedBottom = new _reactNativeWeb.Animated.Value(footerHeight);
            _this.setState({
              footerHeight: footerHeight,
              footerContent: status.footerContent
            }, function () {
              _this.show();
            });
          }
        }
      } else {
        _this.open = false;
        ContentFooter._currentHeight = 0;
        _ContentFooterEventHandler.ContentFooterEventHandler.heightChangeEvent.post(0);
        _this.hide();
      }
    };
    _this.state = {
      footerContent: null,
      footerHeight: 0
    };
    return _this;
  }
  (0, _inherits2.default)(ContentFooter, _PureComponent);
  return (0, _createClass2.default)(ContentFooter, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _ContentFooterEventHandler.ContentFooterEventHandler.statusEvent.attach(this.statusChange);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _ContentFooterEventHandler.ContentFooterEventHandler.statusEvent.detach(this.statusChange);
    }
  }, {
    key: "render",
    value: function render() {
      var size = this.props.size;
      var _this$state = this.state,
        footerHeight = _this$state.footerHeight,
        footerContent = _this$state.footerContent;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
        style: Object.assign({}, {
          position: 'absolute',
          left: size.contentLeft,
          right: size.contentRight,
          bottom: size.currentKeyboardHeight + size.windowBottom,
          padding: 0,
          alignItems: 'flex-start',
          height: footerHeight,
          overflow: 'hidden',
          backgroundColor: _ThemeManager.ThemeManager.style.headerBG,
          justifyContent: 'flex-start',
          flexDirection: 'row',
          transform: [{
            translateY: this.animatedBottom
          }]
        }),
        children: footerContent
      });
    }
  }, {
    key: "hide",
    value: function hide() {
      var _this2 = this;
      _reactNativeWeb.Animated.timing(this.animatedBottom, {
        duration: 195,
        useNativeDriver: _reactNativeWeb.Platform.OS !== 'web',
        toValue: this.state.footerHeight
      }).start(function () {
        _this2.setState({
          footerContent: null,
          footerHeight: 0
        });
      });
    }
  }, {
    key: "show",
    value: function show() {
      var _this3 = this;
      _ContentFooterEventHandler.ContentFooterEventHandler.heightChangeEvent.post(this.state.footerHeight);
      _reactNativeWeb.Animated.timing(this.animatedBottom, {
        duration: 195,
        useNativeDriver: _reactNativeWeb.Platform.OS !== 'web',
        toValue: 0
      }).start(function () {
        _ContentFooterEventHandler.ContentFooterEventHandler.heightChangeEvent.post(_this3.state.footerHeight);
      });
    }
  }], [{
    key: "getCurrentHeight",
    value: function getCurrentHeight() {
      return ContentFooter._currentHeight;
    }
  }]);
}(_react.PureComponent);
ContentFooter._currentHeight = 0;