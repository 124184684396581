var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SvgOverlayWithMask = SvgOverlayWithMask;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _lodash = _interopRequireDefault(require("lodash"));
var _react = _interopRequireWildcard(require("../../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../../upmesh-support/node_modules/react-native-web");
var _reactNativeSvg = _interopRequireDefault(require("react-native-svg"));
var _ThemeManager = require("../../../ThemeManager");
var _AnimatedPath = require("./AnimatedPath");
var _utilities = require("./utilities");
var _jsxRuntime = require("../../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function SvgOverlayWithMask(props) {
  var _useState = (0, _react.useState)(),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    svgElement = _useState2[0],
    setSvgElement = _useState2[1];
  var getPath = function getPath(previousPath, animation) {
    var size = props.size,
      position = props.position,
      currentStep = props.currentStep;
    return (0, _utilities.svgMaskPathMorph)({
      animation: animation,
      previousPath: previousPath,
      to: {
        position: position,
        size: size,
        shape: currentStep == null ? void 0 : currentStep.shape,
        maskOffset: (currentStep == null ? void 0 : currentStep.maskOffset) || 0,
        borderRadius: (currentStep == null ? void 0 : currentStep.borderRadius) || 0,
        borderRadiusObject: currentStep == null ? void 0 : currentStep.borderRadiusObject
      }
    });
  };
  var animation = (0, _react.useRef)(new _reactNativeWeb.Animated.Value(1)).current;
  var opacity = (0, _react.useRef)(new _reactNativeWeb.Animated.Value(0)).current;
  var rafID = (0, _react.useRef)();
  var animationListenerID = (0, _react.useRef)();
  var _useState3 = (0, _react.useState)(),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    canvasSize = _useState4[0],
    setCanvasSize = _useState4[1];
  var getSvgPath = function getSvgPath(size) {
    return `M0,0H${size.width}V${size.height}H0V0ZM${size.width / 2},${size.height / 2} h 1 v 1 h -1 Z`;
  };
  var _useState5 = (0, _react.useState)(getPath(getSvgPath(_reactNativeWeb.Dimensions.get('window')), animation)),
    _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
    prevComposedSvgPath = _useState6[0],
    setPrevComposedSvgPath = _useState6[1];
  var _useState7 = (0, _react.useState)(getPath(getSvgPath(_reactNativeWeb.Dimensions.get('window')), animation)),
    _useState8 = (0, _slicedToArray2.default)(_useState7, 2),
    composedSvgPath = _useState8[0],
    setComposedSvgPath = _useState8[1];
  (0, _react.useEffect)(function () {
    return function () {
      if (animationListenerID.current) animation.removeListener(animationListenerID.current);
      if (rafID.current) cancelAnimationFrame(rafID.current);
    };
  }, []);
  var animate = function () {
    var _ref = (0, _asyncToGenerator2.default)(function* () {
      animation.setValue(0);
      var animationListener = function animationListener() {
        var path = getPath(prevComposedSvgPath, animation);
        rafID.current = requestAnimationFrame(function () {
          if (svgElement != null) {
            try {
              if (_reactNativeWeb.Platform.OS !== 'web') {
                svgElement.setNativeProps({
                  d: path
                });
              } else {
                setComposedSvgPath(path);
              }
            } catch (err) {}
          }
        });
      };
      animationListenerID.current = animation.addListener(animationListener);
      var animations = [_reactNativeWeb.Animated.timing(animation, {
        toValue: 1,
        duration: 195,
        easing: _reactNativeWeb.Easing.linear,
        useNativeDriver: false
      })];
      if (opacity['_value'] !== 1) {
        animations.push(_reactNativeWeb.Animated.timing(opacity, {
          toValue: 1,
          duration: 195,
          easing: _reactNativeWeb.Easing.linear,
          useNativeDriver: true
        }));
      }
      return new Promise(function (r) {
        _reactNativeWeb.Animated.parallel(animations, {
          stopTogether: false
        }).start(function (result) {
          if (result.finished) {
            animation.removeListener(animationListenerID.current);
            r();
          }
        });
      });
    });
    return function animate() {
      return _ref.apply(this, arguments);
    };
  }();
  var onResize = _lodash.default.debounce(function () {
    var size = canvasSize != null ? canvasSize : _reactNativeWeb.Dimensions.get('window');
    setPrevComposedSvgPath(getSvgPath(size));
    var path = getPath(getSvgPath(size), animation);
    setPrevComposedSvgPath(path);
    setComposedSvgPath(path);
  }, 16);
  (0, _react.useEffect)(function () {
    onResize();
  }, [canvasSize]);
  (0, _react.useEffect)(function () {
    if (svgElement) {
      animate().then(function () {
        animation.setValue(1);
        var path = getPath(prevComposedSvgPath, animation);
        setPrevComposedSvgPath(path);
        setComposedSvgPath(path);
      }).catch(function (err) {
        return void 0;
      });
    } else {
      var path = getPath(composedSvgPath, animation);
      setComposedSvgPath(path);
      setPrevComposedSvgPath(path);
      animation.setValue(1);
    }
  }, [props.size, props.position, svgElement]);
  var onLayout = function onLayout(e) {
    setCanvasSize({
      height: e.nativeEvent.layout.height,
      width: e.nativeEvent.layout.width
    });
  };
  if (!canvasSize) return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
    style: Object.assign({}, _ThemeManager.ThemeManager.style.absoluteStyle),
    onLayout: onLayout
  });
  return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
    style: Object.assign({}, _ThemeManager.ThemeManager.style.absoluteStyle),
    onLayout: onLayout,
    pointerEvents: "none",
    children: (0, _jsxRuntime.jsx)(_reactNativeSvg.default, {
      pointerEvents: "none",
      width: canvasSize.width,
      height: canvasSize.height,
      viewBox: `0 0 ${canvasSize.width} ${canvasSize.height}`,
      children: (0, _jsxRuntime.jsx)(_AnimatedPath.AnimatedSvgPath, {
        ref: setSvgElement,
        fill: _ThemeManager.ThemeManager.style.overlayColor,
        strokeWidth: 0,
        fillRule: "evenodd",
        d: composedSvgPath,
        opacity: opacity
      })
    })
  }, "123");
}