var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Fab = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../ThemeManager");
var _ScaleAnimation = require("./animations/ScaleAnimation");
var _FabActions = require("./FabActions");
var _Icon = require("./Icon");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var FAB_OPENING = 'opening';
var FAB_OPENED = 'opened';
var FAB_CLOSING = 'closing';
var FAB_CLOSED = 'closed';
var Fab = exports.Fab = function (_PureComponent) {
  function Fab(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Fab);
    _this = _callSuper(this, Fab, [props]);
    _this.animationRunning = false;
    _this.fabAnimation = new _ScaleAnimation.ScaleAnimation(0);
    _this._onLayoutView = function (_event) {
      _this.forceUpdate();
    };
    _this.close = function (callback) {
      if (_this.state.open) {
        if (Fab.instance != null) {
          Fab.instance.closeButtons();
        }
      }
      if (!_this.animationRunning) {
        if (_this.animationRunningTO != null) {
          clearTimeout(_this.animationRunningTO);
        }
        _this.closeNow(callback);
      } else {
        _this.animationRunningTO = setTimeout(function () {
          _this.close();
        }, 200);
      }
    };
    _this.closeButtons = function () {
      if (_this.state.open) {
        _this.setState({
          open: false
        });
      }
    };
    _this.closeNow = function (callback) {
      var onClosed = _this.state.onClosed;
      _this.setfabState(0, function () {
        if (callback != null && typeof callback === 'function') {
          callback();
        }
        if (onClosed != null) {
          onClosed();
        }
      });
    };
    _this.onPressFabActions = function (action) {
      return function (e) {
        _this.closeButtons();
        action(e);
      };
    };
    _this.openButtons = function () {
      if (!_this.state.open && _this.state.fabActions.length > 0) {
        _this.setState({
          open: true
        });
      }
    };
    _this.openCloseFab = function () {
      var open = _this.state.open;
      if (open) {
        if (Fab.instance != null) {
          Fab.instance.closeButtons();
        }
      } else if (Fab.instance != null) {
        Fab.instance.openButtons();
      }
    };
    _this.state = Fab.defaultState;
    return _this;
  }
  (0, _inherits2.default)(Fab, _PureComponent);
  return (0, _createClass2.default)(Fab, [{
    key: "fabState",
    get: function get() {
      return this.state.fabState;
    }
  }, {
    key: "pointerEvents",
    get: function get() {
      return this.state.fabState === FAB_OPENED ? 'auto' : 'none';
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      Fab._instance = this;
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (_reactNativeWeb.Keyboard != null) {
        _reactNativeWeb.Keyboard.dismiss();
      }
    }
  }, {
    key: "open",
    value: function open(state) {
      var _this2 = this;
      if (!this.animationRunning) {
        if (this.animationRunningTO != null) {
          clearTimeout(this.animationRunningTO);
        }
        if (this.fabState === FAB_OPENED) {
          this.close(function () {
            return _this2.open(state);
          });
        } else {
          var newState = Object.assign(Fab.defaultState, state);
          this.fabAnimation = new _ScaleAnimation.ScaleAnimation(0);
          this.setState(newState, function () {
            _this2.openNow();
          });
        }
      } else {
        if (this.animationRunningTO != null) {
          clearTimeout(this.animationRunningTO);
        }
        this.animationRunningTO = setTimeout(function () {
          _this2.open(state);
        }, 200);
      }
    }
  }, {
    key: "openNow",
    value: function openNow() {
      var onOpened = this.state.onOpened;
      this.setfabState(1, onOpened);
    }
  }, {
    key: "render",
    value: function render() {
      var size = this.props.size;
      var _this$state = this.state,
        fabState = _this$state.fabState,
        fabIcon = _this$state.fabIcon,
        fabIconMoon = _this$state.fabIconMoon,
        fabIconOpen = _this$state.fabIconOpen,
        fabIconOpenMoon = _this$state.fabIconOpenMoon,
        fabColor = _this$state.fabColor,
        fabColorOpen = _this$state.fabColorOpen,
        fabIconColor = _this$state.fabIconColor,
        fabIconColorOpen = _this$state.fabIconColorOpen,
        fabIconRotation = _this$state.fabIconRotation,
        onPressFab = _this$state.onPressFab,
        onPressFabOpen = _this$state.onPressFabOpen,
        small = _this$state.small,
        open = _this$state.open,
        fabActions = _this$state.fabActions,
        title = _this$state.title,
        extraPaddingBottom = _this$state.extraPaddingBottom;
      if (fabState === FAB_CLOSED) {
        return null;
      }
      var onPressThisFab = open && onPressFabOpen != null ? onPressFabOpen : onPressFab != null ? onPressFab : this.openCloseFab;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          bottom: size.contentBottom + extraPaddingBottom,
          right: size.contentRight,
          zIndex: 6,
          overflow: 'visible',
          alignContent: 'flex-end',
          alignItems: 'flex-end'
        },
        children: [open && fabActions.length > 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
          style: _reactNativeWeb.StyleSheet.flatten([{
            justifyContent: 'space-between',
            overflow: 'hidden',
            alignContent: 'flex-end',
            alignItems: 'flex-end',
            paddingRight: _ThemeManager.ThemeManager.style.contentPaddingValue,
            paddingLeft: _ThemeManager.ThemeManager.style.contentPaddingValue,
            paddingBottom: _ThemeManager.ThemeManager.style.contentPaddingValue / 2,
            height: 56 * fabActions.length + _ThemeManager.ThemeManager.style.contentPaddingValue / 2
          }]),
          children: this.renderFabActions()
        }) : null, (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
          pointerEvents: _reactNativeWeb.Platform.OS.toString() === 'web' ? 'box-none' : 'auto',
          style: _reactNativeWeb.StyleSheet.flatten([{
            width: 56 + 2 * _ThemeManager.ThemeManager.style.contentPaddingValue,
            height: 72,
            justifyContent: 'flex-end',
            alignContent: 'flex-end',
            alignItems: 'flex-end',
            overflow: 'visible',
            paddingRight: _ThemeManager.ThemeManager.style.contentPaddingValue,
            paddingBottom: _ThemeManager.ThemeManager.style.contentPaddingValue,
            paddingLeft: _ThemeManager.ThemeManager.style.contentPaddingValue
          }, this.fabAnimation != null ? this.fabAnimation.animations : null]),
          children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            icon: open && fabIconOpen != null ? fabIconOpen : fabIcon,
            iconIconMoon: open && fabIconOpen != null ? fabIconOpenMoon : fabIconMoon,
            iconSize: 24,
            toolTip: title,
            rotation: fabIconRotation,
            outerSize: small === true ? 40 : 56,
            color: open && fabIconColorOpen != null ? fabIconColorOpen : fabIconColor,
            backgroundColor: open && fabColorOpen != null ? fabColorOpen : fabColor,
            onPress: fabState === FAB_OPENED ? onPressThisFab : undefined,
            elevation: 2
          })
        })]
      });
    }
  }, {
    key: "renderFabActions",
    value: function renderFabActions() {
      var _this$state2 = this.state,
        fabActions = _this$state2.fabActions,
        small = _this$state2.small;
      if (fabActions != null && (fabActions.length < 2 || fabActions.length > 6)) {}
      var fabActionButtons = [];
      var maxDelay = 195;
      var stepDelay = maxDelay / fabActions.length;
      for (var i = 0; i < fabActions.length; i += 1) {
        fabActionButtons.push((0, _react.createElement)(_FabActions.FabActions, Object.assign({
          delay: maxDelay - stepDelay * i,
          small: small === true
        }, fabActions[i], {
          key: `fa_${i}_${fabActions[i].text}`,
          onPress: fabActions[i].onPress != null ? this.onPressFabActions(fabActions[i].onPress) : undefined
        })));
      }
      return fabActionButtons;
    }
  }, {
    key: "setfabState",
    value: function setfabState(toValue, callback) {
      var _this3 = this;
      if (!this.animationRunning) {
        this.animationRunning = true;
        var fabState = toValue > 0 ? FAB_OPENING : FAB_CLOSING;
        if (this.fabAnimation != null) {
          this.setState({
            fabState: fabState
          }, function () {
            _this3.fabAnimation.toValue(toValue, function () {
              if (fabState === FAB_CLOSING) {
                _this3.setState(Fab.defaultState, function () {
                  _this3.animationRunning = false;
                  if (callback != null) {
                    callback();
                  }
                });
              } else {
                _this3.setState({
                  fabState: FAB_OPENED
                }, function () {
                  _this3.animationRunning = false;
                  if (callback != null) {
                    callback();
                  }
                });
              }
            });
          });
        } else if (fabState === FAB_CLOSING) {
          this.setState(Fab.defaultState, function () {
            _this3.animationRunning = false;
            if (callback != null) {
              callback();
            }
          });
        } else {
          this.setState({
            fabState: FAB_OPENED
          }, function () {
            _this3.animationRunning = false;
            if (callback != null) {
              callback();
            }
          });
        }
      } else {
        clearTimeout(this.animationRunningTO);
        this.animationRunningTO = setTimeout(function () {
          _this3.setfabState(toValue, callback);
        });
      }
    }
  }], [{
    key: "defaultState",
    get: function get() {
      return {
        fabColor: _ThemeManager.ThemeManager.style.brandPrimary,
        fabIconColor: '#FFFFFF',
        fabIcon: 'plus',
        fabIconMoon: false,
        fabIconOpenMoon: false,
        fabState: FAB_CLOSED,
        fabIconRotation: 0,
        fabActions: [],
        onPressFabOpen: undefined,
        onPressFab: undefined,
        onOpened: undefined,
        onClosed: undefined,
        small: false,
        open: false,
        title: '',
        fabColorOpen: undefined,
        fabIconColorOpen: undefined,
        fabIconOpen: undefined,
        fabStyle: undefined,
        extraPaddingBottom: 0
      };
    }
  }, {
    key: "instance",
    get: function get() {
      if (Fab._instance != null) {
        return Fab._instance;
      }
      return undefined;
    }
  }, {
    key: "isOpened",
    get: function get() {
      return Fab.instance != null && Fab.instance.state != null && Fab.instance.state.fabState != null && (Fab.instance.state.fabState === FAB_OPENED || Fab.instance.state.fabState === FAB_OPENING);
    }
  }]);
}(_react.PureComponent);