var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var AnimatedHandler = function () {
  function AnimatedHandler() {
    (0, _classCallCheck2.default)(this, AnimatedHandler);
  }
  return (0, _createClass2.default)(AnimatedHandler, null, [{
    key: "register",
    value: function register(id, baId, ba) {
      if (AnimatedHandler._instances[id] == null) {
        AnimatedHandler._instances[id] = [];
      }
      for (var i = 0; i < AnimatedHandler._instances[id].length; i += 1) {
        if (AnimatedHandler._instances[id][i].baId === baId) {
          return;
        }
      }
      AnimatedHandler._instances[id].push({
        baId: baId,
        ba: ba
      });
    }
  }, {
    key: "remove",
    value: function remove(id, baId) {
      for (var i = 0; i < AnimatedHandler._instances[id].length; i += 1) {
        if (AnimatedHandler._instances[id][i].baId === baId) {
          AnimatedHandler._instances[id].splice(i, 1);
          return;
        }
      }
    }
  }, {
    key: "startAnimationById",
    value: function startAnimationById(ids, toValue, callback) {
      var bas = [];
      var aniID = typeof ids === 'string' ? ids : ids.toString();
      var aniIDs = typeof ids === 'string' ? [ids] : ids;
      if (AnimatedHandler.runningAnimations[aniID] != null) {
        AnimatedHandler.runningAnimations[aniID].stop();
      }
      for (var id of aniIDs) {
        if (AnimatedHandler._instances[id] != null) {
          for (var i = 0; i < AnimatedHandler._instances[id].length; i += 1) {
            bas.push(AnimatedHandler._instances[id][i].ba.createAnimation(toValue));
          }
        }
      }
      if (bas.length > 0) {
        AnimatedHandler.runningAnimations[aniID] = _reactNativeWeb.Animated.parallel(bas, {
          stopTogether: true
        });
        AnimatedHandler.runningAnimations[aniID].start(function () {
          AnimatedHandler.runningAnimations[aniID] = undefined;
          if (callback != null) {
            callback();
          }
        });
      }
    }
  }]);
}();
AnimatedHandler.runningAnimations = new Map();
AnimatedHandler._instances = new Map();
var _default = exports.default = AnimatedHandler;