var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KeyboardEvents = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var KeyboardEvents = exports.KeyboardEvents = function () {
  function KeyboardEvents() {
    (0, _classCallCheck2.default)(this, KeyboardEvents);
  }
  return (0, _createClass2.default)(KeyboardEvents, null, [{
    key: "onKeyDown",
    value: function onKeyDown(f, identifier) {
      if (_reactNativeWeb.Platform.OS === 'web') {
        if (document.onkeydown == null) {
          document.onkeydown = KeyboardEvents.onKeyDownCall;
        }
        KeyboardEvents.remove(identifier);
        KeyboardEvents._functions.push({
          id: identifier,
          _function: f
        });
      }
    }
  }, {
    key: "remove",
    value: function remove(identifier) {
      if (_reactNativeWeb.Platform.OS === 'web') {
        for (var i = 0; i < KeyboardEvents._functions.length; i += 1) {
          if (KeyboardEvents._functions[i].id === identifier) {
            KeyboardEvents._functions.splice(i, 1);
            break;
          }
        }
      }
    }
  }]);
}();
KeyboardEvents._functions = [];
KeyboardEvents.onKeyDownCall = function (e) {
  for (var i = 0; i < KeyboardEvents._functions.length; i += 1) {
    KeyboardEvents._functions[i]._function(e);
  }
};