var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChipGroup = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _StringUtils = require("cqrs-core/build/src/core/StringUtils");
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _DialogUp = require("../DialogUp");
var _Icon = require("../Icon");
var _MaterialText = require("../text/MaterialText");
var _Chip = require("./Chip");
var _ChipDialogForm = require("./ChipDialogForm");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ChipGroup = exports.ChipGroup = function (_Component) {
  function ChipGroup(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ChipGroup);
    _this = _callSuper(this, ChipGroup, [props]);
    _this.isUpdating = false;
    _this.updateAutoCompleteList = function () {
      if (_this.isUpdatingTO) clearTimeout(_this.isUpdatingTO);
      if (_this.isUpdating === false) {
        _this.isUpdating = true;
        _this.setState({
          autocompleteList: _this.createAutoCompleteList(_this.props.availableChips)
        }, function () {
          _this.isUpdating = false;
        });
      } else {
        _this.isUpdatingTO = setTimeout(_this.updateAutoCompleteList, 100);
      }
    };
    _this.getListItem = function (chip, selected) {
      var chipGroups = _this.props.chipGroups;
      var secondTextLine = chip.secondTextLine;
      var thirdTextLine = chip.thirdTextLine;
      var thumbnail = chip.thumbnail ? {
        thumbnail: chip.thumbnail,
        width: 24,
        rounded: true
      } : undefined;
      if (chipGroups != null && chip.groupId != null) {
        var group = chipGroups.find(function (group) {
          return group.id === chip.groupId;
        });
        if (group != null) {
          if (secondTextLine == null) {
            secondTextLine = group.groupName;
          } else if (thirdTextLine == null) {
            thirdTextLine = group.groupName;
          }
          if (thumbnail == null && group.backgroundColor && group.backgroundColor.length > 0) {
            thumbnail = {
              width: 24,
              rounded: true,
              thumbnail: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                style: {
                  height: 24,
                  width: 24,
                  borderRadius: 12,
                  backgroundColor: group.backgroundColor
                }
              })
            };
          }
        }
      }
      return {
        selected: selected,
        secondTextLine: secondTextLine,
        thirdTextLine: thirdTextLine,
        thumbnail: thumbnail,
        title: chip.title,
        groupId: chip.groupId,
        id: chip.id ? chip.id : chip.title
      };
    };
    _this.onChange = function () {
      if (_this.props.onChanged != null) {
        _this.props.onChanged(_this.state.chips);
      }
    };
    _this.onPressButton = function (data) {
      return function (_e) {
        var _this$props = _this.props,
          onPressButton = _this$props.onPressButton,
          availableChips = _this$props.availableChips;
        var _this$state = _this.state,
          chips = _this$state.chips,
          autocompleteList = _this$state.autocompleteList;
        var newAutoComplete;
        if (autocompleteList) {
          var autocompleteIndex = autocompleteList.findIndex(function (chip) {
            return chip.title === chips[data.number].title;
          });
          if (autocompleteIndex > -1) {
            newAutoComplete = (0, _toConsumableArray2.default)(autocompleteList);
            newAutoComplete[autocompleteIndex].selected = false;
          } else {
            newAutoComplete = (0, _toConsumableArray2.default)(autocompleteList);
            var chip = chips[data.number];
            newAutoComplete.push(_this.getListItem(chip, false));
          }
        } else {
          newAutoComplete = _this.createAutoCompleteList(availableChips, chips);
        }
        var prevChip = chips[data.number];
        chips.splice(data.number, 1);
        _this.setState({
          chips: chips,
          autocompleteList: newAutoComplete
        }, function () {
          if (prevChip.onPressButton != null) {
            prevChip.onPressButton(_e);
          }
          if (onPressButton != null) {
            onPressButton(_e);
          }
          _this.onChange();
        });
      };
    };
    _this.onSave = function (items) {
      var _DialogUp$instance;
      var _this$state2 = _this.state,
        chips = _this$state2.chips,
        autocompleteList = _this$state2.autocompleteList;
      var availableChips = _this.props.availableChips ? _this.props.availableChips.slice() : chips;
      var newChips = [];
      if (autocompleteList != null) {
        var _loop = function _loop(i) {
          if (items[i] != null && items[i].selected === true) {
            var invisibleIndex = availableChips.findIndex(function (chip) {
              return chip.id === items[i].id;
            });
            if (invisibleIndex === -1) {
              var createdChip = {
                title: items[i].title,
                groupId: items[i].groupId
              };
              newChips.push(createdChip);
            } else {
              newChips.push(availableChips[invisibleIndex]);
            }
          }
        };
        for (var i = 0; i < items.length; i += 1) {
          _loop(i);
        }
      }
      (_DialogUp$instance = _DialogUp.DialogUp.instance) == null || _DialogUp$instance.close();
      var onChanged = _this.props.onChanged;
      if (onChanged != null) onChanged([].concat(newChips));
      _this.setState({
        chips: [].concat(newChips),
        autocompleteList: (0, _toConsumableArray2.default)(items)
      });
    };
    _this.onAddedNewItem = function (chip, groupId) {
      var onAddedNewItem = _this.props.onAddedNewItem;
      var chips = _this.state.chips;
      chips.push(Object.assign({}, chip, {
        groupId: groupId || chip.groupId
      }));
      _this.setState({
        chips: chips
      });
      if (onAddedNewItem != null) onAddedNewItem(chip, groupId);
    };
    _this.closeDialog = function () {
      var _DialogUp$instance2;
      (_DialogUp$instance2 = _DialogUp.DialogUp.instance) == null || _DialogUp$instance2.close();
    };
    _this.openDialog = function () {
      var _DialogUp$instance3;
      var _this$props2 = _this.props,
        dialogTitle = _this$props2.dialogTitle,
        dialogIcon = _this$props2.dialogIcon,
        canAddNewChips = _this$props2.canAddNewChips,
        addNewText = _this$props2.addNewText,
        checkBeforeEnterNewText = _this$props2.checkBeforeEnterNewText,
        searchBarPlaceholder = _this$props2.searchBarPlaceholder,
        label = _this$props2.label,
        sortFormByGroup = _this$props2.sortFormByGroup,
        chipGroups = _this$props2.chipGroups,
        addNewButtons = _this$props2.addNewButtons,
        canAddNewChipsViaSearchbar = _this$props2.canAddNewChipsViaSearchbar,
        disableAutoSort = _this$props2.disableAutoSort;
      var autocompleteList = _this.state.autocompleteList;
      (_DialogUp$instance3 = _DialogUp.DialogUp.instance) == null || _DialogUp$instance3.open({
        content: (0, _jsxRuntime.jsx)(_ChipDialogForm.ChipDialogForm, {
          title: dialogTitle != null ? dialogTitle : label,
          icon: dialogIcon,
          items: autocompleteList,
          onCancel: _this.closeDialog,
          multiselect: true,
          onSave: _this.onSave,
          canAddNewChips: canAddNewChips,
          addNewText: addNewText,
          onAddedNewItem: _this.onAddedNewItem,
          checkBeforeEnterNewText: checkBeforeEnterNewText,
          searchBarPlaceholder: searchBarPlaceholder,
          sortByGroup: sortFormByGroup,
          chipGroups: chipGroups,
          addNewButtons: addNewButtons,
          canAddNewChipsViaSearchbar: canAddNewChipsViaSearchbar,
          disableAutoSort: disableAutoSort
        }, "ChipDialogForm"),
        contentPadding: false,
        fullscreenResponsive: true,
        showCloseButton: false,
        showCloseIcon: true
      });
    };
    var chips = props.chips != null ? props.chips : props.initChips ? props.initChips : [];
    _this.state = {
      chips: chips,
      autocompleteList: _this.createAutoCompleteList(props.availableChips, props.chips)
    };
    return _this;
  }
  (0, _inherits2.default)(ChipGroup, _Component);
  return (0, _createClass2.default)(ChipGroup, [{
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps, _prevState, _snapshot) {
      try {
        if (prevProps.availableChips == null || this.props.availableChips == null || prevProps.chips == null || this.props.chips == null) {
          this.updateAutoCompleteList();
        } else if (_StringUtils.StringUtils.deepStringify(prevProps.availableChips) !== _StringUtils.StringUtils.deepStringify(this.props.availableChips) || _StringUtils.StringUtils.deepStringify(prevProps.chips) !== _StringUtils.StringUtils.deepStringify(this.props.chips)) {
          this.updateAutoCompleteList();
        }
      } catch (e) {
        this.isUpdatingTO = setTimeout(this.updateAutoCompleteList, 500);
      }
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.state.autocompleteList == null) {
        this.setState({
          autocompleteList: this.createAutoCompleteList()
        });
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.isUpdatingTO) clearTimeout(this.isUpdatingTO);
    }
  }, {
    key: "createAutoCompleteList",
    value: function createAutoCompleteList(availableChips) {
      var _this$state3,
        _this2 = this;
      var chips = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (_this$state3 = this.state) == null ? void 0 : _this$state3.chips;
      if (availableChips != null && availableChips.length > 0) {
        var listItems = [];
        if (chips != null && chips.length > 0) {
          for (var i = 0; i < availableChips.length; i += 1) {
            var aChip = availableChips[i];
            var includes = false;
            var j = 0;
            do {
              if (aChip.id === chips[j].id) {
                includes = true;
              }
              j += 1;
            } while (!includes && j < chips.length);
            if (aChip.title != null) {
              listItems.push(this.getListItem(aChip, includes));
            }
          }
          var _loop2 = function _loop2(_i) {
            if (listItems.findIndex(function (li) {
              return li['id'] === chips[_i].id;
            }) === -1) {
              listItems.push(_this2.getListItem(chips[_i], true));
            }
          };
          for (var _i = 0; _i < chips.length; _i += 1) {
            _loop2(_i);
          }
        } else {
          for (var _i2 = 0; _i2 < availableChips.length; _i2 += 1) {
            var _aChip = availableChips[_i2];
            if (_aChip.title != null) {
              var chipListItem = this.getListItem(_aChip, false);
              listItems.push(chipListItem);
            }
          }
        }
        return listItems;
      }
      return [];
    }
  }, {
    key: "getCurrentChips",
    value: function getCurrentChips() {
      return this.state.chips;
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props3 = this.props,
        availableChips = _this$props3.availableChips,
        bordered = _this$props3.bordered,
        editable = _this$props3.editable,
        label = _this$props3.label;
      var borderStyle = {};
      if (bordered) {
        borderStyle = {
          borderWidth: 1,
          borderRadius: 2,
          borderColor: _ThemeManager.ThemeManager.style.borderColor
        };
      }
      var addButton = null;
      if (availableChips != null && editable !== false) {
        addButton = this.renderPlusButton();
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: _reactNativeWeb.StyleSheet.flatten([borderStyle, {
          overflow: 'visible'
        }]),
        children: [label != null && label.length > 0 ? (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.Caption,
          children: label
        }) : null, (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            overflow: 'visible'
          },
          children: [this.renderChips(), addButton]
        })]
      });
    }
  }, {
    key: "renderChips",
    value: function renderChips() {
      var _this$props4 = this.props,
        textColor = _this$props4.textColor,
        removeIconColor = _this$props4.removeIconColor,
        backgroundColor = _this$props4.backgroundColor,
        leftContent = _this$props4.leftContent,
        onPressChip = _this$props4.onPressChip,
        chipDisabled = _this$props4.chipDisabled,
        buttonDisabled = _this$props4.buttonDisabled,
        availableChips = _this$props4.availableChips,
        editable = _this$props4.editable,
        displayDisabledButtons = _this$props4.displayDisabledButtons,
        outlined = _this$props4.outlined,
        borderProps = _this$props4.borderProps;
      var chips = this.state.chips;
      var renderedChips = [];
      if (chips.length > 0) {
        for (var i = 0; i < chips.length; i += 1) {
          var disableButton = chips[i].buttonDisabled != null ? chips[i].buttonDisabled : buttonDisabled;
          var hasButton = editable !== false && chips[i].button !== false;
          hasButton = hasButton && availableChips != null && availableChips.length > 0;
          hasButton = hasButton && !(disableButton === true && displayDisabledButtons === false);
          renderedChips.push((0, _jsxRuntime.jsx)(_Chip.Chip, {
            title: chips[i].title != null ? chips[i].title : undefined,
            textColor: chips[i].textColor != null ? chips[i].textColor : textColor,
            removeIconColor: chips[i].removeIconColor != null ? chips[i].removeIconColor : removeIconColor,
            backgroundColor: chips[i].backgroundColor != null ? chips[i].backgroundColor : backgroundColor,
            thumbnail: chips[i].thumbnail != null ? chips[i].thumbnail : leftContent,
            onPressButton: this.onPressButton({
              number: i
            }),
            onPressChip: chips[i].onPressChip != null ? chips[i].onPressChip : onPressChip != null ? onPressChip : undefined,
            button: hasButton,
            chipDisabled: chips[i].chipDisabled != null ? chips[i].chipDisabled : chipDisabled,
            outlined: chips[i].outlined != null ? chips[i].outlined : outlined,
            borderProps: chips[i].borderProps != null ? chips[i].borderProps : borderProps,
            buttonDisabled: disableButton
          }, `chip${i}${chips[i].title}`));
        }
      }
      return renderedChips;
    }
  }, {
    key: "renderPlusButton",
    value: function renderPlusButton() {
      if (this.props.chipDisabled === false) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {});
      }
      var tooltipAddChip = this.props.tooltipAddChip;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          margin: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(4),
          height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(32),
          width: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(32),
          borderRadius: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16),
          borderWidth: 1,
          borderStyle: 'dashed',
          borderColor: _ThemeManager.ThemeManager.style.black42,
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center'
        },
        children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: "plus",
          toolTip: tooltipAddChip != null ? tooltipAddChip : _I18n.I18n.m.getMessage('add'),
          onPress: this.openDialog,
          outerSize: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(32),
          color: _ThemeManager.ThemeManager.style.brandPrimary
        })
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        bordered: false,
        selectGroupTitle: _I18n.I18n.m.getMessage('chipSelectGroupTitle')
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.chips != null && nextProps.chips !== prevState.chips) {
        var newState = Object.assign({}, prevState, {
          chips: nextProps.chips != null ? nextProps.chips : []
        });
        return newState;
      }
      return null;
    }
  }]);
}(_react.Component);