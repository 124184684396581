var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseAnimated = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var uuid = _interopRequireWildcard(require("uuid"));
var _AnimatedHandler = _interopRequireDefault(require("./AnimatedHandler"));
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var BaseAnimated = exports.BaseAnimated = function (_PureComponent) {
  function BaseAnimated(props) {
    var _this;
    (0, _classCallCheck2.default)(this, BaseAnimated);
    _this = _callSuper(this, BaseAnimated, [props]);
    _this.animationState = new _reactNativeWeb.Animated.Value(1);
    _this.useNativeDriver = _reactNativeWeb.Platform.OS !== 'web';
    _this.animationState = new _reactNativeWeb.Animated.Value(props.animationState);
    _this.state = {
      animationState: props.animationState,
      duration: props.duration == null ? 195 : props.duration
    };
    return _this;
  }
  (0, _inherits2.default)(BaseAnimated, _PureComponent);
  return (0, _createClass2.default)(BaseAnimated, [{
    key: "animateValue",
    value: function animateValue(animationState) {
      var _this2 = this;
      this.createAnimation(animationState).start(function () {
        if (_this2.props.onAnimationEnd !== undefined) {
          _this2.props.onAnimationEnd(animationState);
        }
      });
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.props.id != null && this.baId == null) {
        this.baId = uuid.v1();
        _AnimatedHandler.default.register(this.props.id, this.baId, this);
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.props.id != null && this.baId != null) {
        _AnimatedHandler.default.remove(this.props.id, this.baId);
      }
    }
  }, {
    key: "createAnimation",
    value: function createAnimation(toValue) {
      return _reactNativeWeb.Animated.timing(this.animationState, {
        toValue: toValue,
        duration: this.state.duration,
        useNativeDriver: this.useNativeDriver
      });
    }
  }, {
    key: "render",
    value: function render() {
      var children = this.props.children;
      if (this.props.id == null) {
        this.animateValue(this.state.animationState);
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
        style: this.getAnimated(),
        children: children
      });
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.animationState !== prevState.animationState) {
        return {
          animationState: nextProps.animationState
        };
      }
      return null;
    }
  }]);
}(_react.PureComponent);