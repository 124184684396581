var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MenuRaw = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _I18n = require("../i18n/I18n");
var _ResizeEvent = require("../ResizeEvent");
var _ThemeManager = require("../ThemeManager");
var _MenuAnimation = require("./animations/MenuAnimation");
var _FormInputOutlined = require("./forminput/FormInputOutlined");
var _ListItem = require("./ListItem");
var _Overlay = require("./Overlay");
var _KeyboardEvents = require("./utils/KeyboardEvents");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var MENURAW_OPENING = 'opening';
var MENURAW_OPENED = 'opened';
var MENURAW_CLOSING = 'closing';
var MENURAW_CLOSED = 'closed';
var MenuRaw = exports.MenuRaw = function (_PureComponent) {
  function MenuRaw(props) {
    var _this;
    (0, _classCallCheck2.default)(this, MenuRaw);
    _this = _callSuper(this, MenuRaw, [props]);
    _this.menuRawAnimation = new _MenuAnimation.MenuAnimation();
    _this.selectedHeight = 0;
    _this.close = function () {
      if (MenuRaw.instance != null) {
        _this.closeNow();
      }
    };
    _this.closeNow = function () {
      _this.stopKeyboardEvents();
      var onClosed = _this.props.onClosed;
      if (_this.menuRawState !== MENURAW_CLOSED && _this.menuRawState !== MENURAW_CLOSING) {
        _this.setmenuRawState(0, onClosed);
      }
    };
    _this.keyboardDidShow = function (e) {
      _this.setState(function (prevState) {
        if (!prevState.client || !prevState.filterFormInput) {
          return null;
        }
        var topMargin = prevState.client.y - e.endCoordinates.height;
        return {
          client: Object.assign({}, prevState.client, {
            y: topMargin > _ResizeEvent.ResizeEvent.current.contentTop ? topMargin : _ResizeEvent.ResizeEvent.current.contentTop
          })
        };
      });
    };
    _this.onSubmit = function (_selectedElement) {
      var selectedElement = _this.state.selectedElement;
      if (selectedElement != null && selectedElement >= 0 && selectedElement < _this.state.items.length) {
        var selected = _this.state.items[selectedElement];
        if (selected.onPress != null) {
          selected.onPress(selected);
        }
        _this.closeNow();
      }
    };
    _this.setScrollView = function (r) {
      _this.scrollView = r;
    };
    _this.search = function () {
      var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var items = _this.state.items;
      if (text != null && text.length > 0) {
        _this.setState({
          searchList: _this.createSearchList(items, text)
        });
      } else {
        _this.setState({
          searchList: items
        });
      }
    };
    _this.startKeyboardEvents = function () {
      _this.stopKeyboardEvents();
      _KeyboardEvents.KeyboardEvents.onKeyDown(function (e) {
        var _MenuRaw$instance;
        var items = _this.state.items;
        var selectedElement = _this.state.selectedElement;
        if (selectedElement == null) {
          selectedElement = -1;
        }
        switch (e.keyCode) {
          case 13:
            e.preventDefault();
            _this.onSubmit(selectedElement);
            break;
          case 27:
            e.preventDefault();
            (_MenuRaw$instance = MenuRaw.instance) == null || _MenuRaw$instance.close();
            break;
          case 38:
            e.preventDefault();
            if (selectedElement != null && selectedElement > 0) {
              _this.setState({
                selectedElement: selectedElement - 1
              });
            } else {
              _this.setState({
                selectedElement: items.length - 1
              });
            }
            break;
          case 40:
            e.preventDefault();
            if (selectedElement != null && selectedElement < items.length - 1) {
              _this.setState({
                selectedElement: selectedElement + 1
              });
            } else {
              _this.setState({
                selectedElement: 0
              });
            }
            break;
        }
      }, 'MenuRaw');
    };
    _this.stopKeyboardEvents = function () {
      _KeyboardEvents.KeyboardEvents.remove('MenuRaw');
    };
    _this.onOverlayPress = function () {
      _this.closeNow();
    };
    _this.state = MenuRaw.defaultState;
    return _this;
  }
  (0, _inherits2.default)(MenuRaw, _PureComponent);
  return (0, _createClass2.default)(MenuRaw, [{
    key: "menuRawState",
    get: function get() {
      return this.state.menuRawState;
    }
  }, {
    key: "pointerEvents",
    get: function get() {
      if (this.state.overlayPointerEvents != null) {
        return this.state.overlayPointerEvents;
      }
      if (this.props.overlayPointerEvents != null) {
        return this.props.overlayPointerEvents;
      }
      return this.state.menuRawState === MENURAW_OPENED ? 'auto' : 'none';
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      MenuRaw._instance = this;
      var open = this.props.open;
      if (open === true) {
        this.openNow();
      }
      if (_reactNativeWeb.Platform.OS !== 'web') {
        this.keyboardDidShowListener = _reactNativeWeb.Keyboard.addListener('keyboardDidShow', this.keyboardDidShow);
      }
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps, _prevState, _snapshot) {
      var _this$props = this.props,
        haveOverlay = _this$props.haveOverlay,
        size = _this$props.size,
        open = _this$props.open;
      if (haveOverlay !== prevProps.haveOverlay || prevProps.size.windowWidth !== size.windowWidth || prevProps.size.windowHeight !== size.windowHeight) {
        this.forceUpdate();
      }
      if (open !== prevProps.open) {
        if (prevProps.open === true) {
          this.openNow();
        } else {
          this.closeNow();
        }
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.keyboardDidShowListener) {
        this.keyboardDidShowListener.remove();
      }
    }
  }, {
    key: "createSearchList",
    value: function createSearchList(items, text) {
      var searchList = [];
      if (items != null) {
        if (text != null && text.length > 0) {
          for (var i = 0; i < items.length; i += 1) {
            var regexp = new RegExp(this.escapeRegExp(text), 'i');
            var _items$i = items[i],
              title = _items$i.title,
              secondTextLine = _items$i.secondTextLine,
              thirdTextLine = _items$i.thirdTextLine;
            var added = false;
            if (title != null) {
              var match = title.match(regexp);
              if (match != null && match.length > 0) {
                searchList.push(items[i]);
                added = true;
              }
            }
            if (!added && secondTextLine != null) {
              var _match = secondTextLine.match(regexp);
              if (_match != null && _match.length > 0) {
                searchList.push(items[i]);
              }
            }
            if (!added && thirdTextLine != null) {
              var _match2 = thirdTextLine.match(regexp);
              if (_match2 != null && _match2.length > 0) {
                searchList.push(items[i]);
              }
            }
          }
        } else {
          searchList = items;
        }
      }
      return searchList;
    }
  }, {
    key: "open",
    value: function open(state) {
      var _this2 = this;
      var newState = Object.assign({}, MenuRaw.defaultState, state);
      this.setState(Object.assign({}, newState, {
        searchList: state.items
      }), function () {
        _this2.openNow();
      });
    }
  }, {
    key: "openNow",
    value: function openNow() {
      this.startKeyboardEvents();
      if (this.scrollView) {
        this.scrollView.scrollTo({
          y: this.state.client != null && this.state.client.y != null ? this.selectedHeight - this.state.client.y + 8 : 0,
          animated: false
        });
      }
      var onOpened = this.props.onOpened;
      this.setmenuRawState(this.getNewHeight().height, onOpened);
    }
  }, {
    key: "render",
    value: function render() {
      var _this$state = this.state,
        menuRawState = _this$state.menuRawState,
        client = _this$state.client,
        showOverlay = _this$state.showOverlay,
        closeOnTouchOutside = _this$state.closeOnTouchOutside;
      var _this$props2 = this.props,
        size = _this$props2.size,
        animationDuration = _this$props2.animationDuration;
      var window = _ResizeEvent.ResizeEvent.current;
      this.menuRawHeight = 16;
      var renderedMenuRawElements = this.renderMenuRawElements();
      var hidden = menuRawState === MENURAW_CLOSED ? _ThemeManager.ThemeManager.style.dialogHidden : {};
      var overlayPointerEvents = this.pointerEvents;
      var dimensions = {
        width: size.windowWidth,
        height: size.windowHeight
      };
      var newHeight = this.getNewHeight();
      if (menuRawState === MENURAW_CLOSED) {
        return null;
      }
      var newWidth = 280;
      var left = client != null ? client.x : 0;
      if (client != null && client.width != null) {
        newWidth = Math.max(112, client.width);
        left = client.x + newWidth > window.windowWidth - 32 ? window.windowWidth - newWidth - 16 : client.x;
        if (left < 16) {
          left = 16;
          if (newWidth > window.windowWidth - 32) {
            newWidth = window.windowWidth - 32;
          }
        }
      }
      var elevation = {};
      if (_reactNativeWeb.Platform.OS.toString() === 'android') {
        elevation = {
          elevation: this.state.elevation,
          zIndex: this.state.elevation
        };
      } else {
        elevation = {
          zIndex: this.state.elevation
        };
      }
      var scrollContainerPadding = _reactNativeWeb.Platform.OS !== 'ios' ? 0 : 10;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: [_ThemeManager.ThemeManager.style.dialogContainer, hidden, dimensions, elevation],
        pointerEvents: overlayPointerEvents,
        children: [(0, _jsxRuntime.jsx)(_Overlay.Overlay, {
          width: size.windowWidth != null ? size.windowWidth : 1,
          height: size.windowHeight,
          pointerEvents: overlayPointerEvents,
          showOverlay: showOverlay,
          onPress: closeOnTouchOutside != null ? this.onOverlayPress : undefined,
          backgroundColor: "transparent",
          animationDuration: animationDuration,
          elevation: this.state.elevation != null ? this.state.elevation - 1 : 0
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
          style: _reactNativeWeb.StyleSheet.flatten([_ThemeManager.ThemeManager.style.elevation8, {
            left: left,
            top: newHeight.top,
            width: newWidth,
            height: newHeight.height,
            position: 'absolute',
            backgroundColor: '#FFFFFF',
            paddingTop: 8,
            paddingBottom: 8,
            borderRadius: 4
          }, elevation, this.menuRawAnimation != null ? this.menuRawAnimation.animations : undefined]),
          children: this.menuRawHeight > newHeight.height || _reactNativeWeb.Platform.OS.toString() === 'android' ? (0, _jsxRuntime.jsx)(_reactNativeWeb.ScrollView, {
            ref: this.setScrollView,
            scrollEnabled: this.menuRawHeight > newHeight.height,
            style: {
              width: newWidth,
              paddingVertical: scrollContainerPadding
            },
            keyboardDismissMode: "none",
            keyboardShouldPersistTaps: "always",
            children: renderedMenuRawElements
          }) : (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: newWidth
            },
            children: renderedMenuRawElements
          })
        })]
      });
    }
  }, {
    key: "renderFormInput",
    value: function renderFormInput() {
      var _this$state2 = this.state,
        client = _this$state2.client,
        filterFormInput = _this$state2.filterFormInput;
      if (filterFormInput !== true) {
        return null;
      }
      var fiHeight = _reactNativeWeb.Platform.OS.toString() === 'android' ? 65 : 77;
      this.menuRawHeight += _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(65 + 14);
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          marginLeft: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(8),
          marginRight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(8),
          height: fiHeight,
          overflow: 'visible'
        },
        children: (0, _jsxRuntime.jsx)(_FormInputOutlined.FormInputOutlined, {
          disableScrollTo: true,
          autoCompleteList: undefined,
          fieldWidth: client != null && client.width != null ? client.width - 16 : 200,
          onChange: this.search,
          onSubmitEditing: this.search,
          labelText: _I18n.I18n.m.getMessage('search'),
          iconLeft: "magnify"
        })
      }, "FI");
    }
  }, {
    key: "renderMenuRawElements",
    value: function renderMenuRawElements() {
      var _this$state3 = this.state,
        selectedElement = _this$state3.selectedElement,
        searchList = _this$state3.searchList;
      this.selectedHeight = 0;
      var renderedMenuRawElements = [];
      renderedMenuRawElements.push(this.renderFormInput());
      if (searchList != null) {
        for (var i = 0; i < searchList.length; i += 1) {
          var element = searchList[i];
          if (element.selected != null) {
            this.selectedHeight = this.menuRawHeight - 8;
          }
          this.menuRawHeight += _ListItem.ListItem.getHeight(element);
          renderedMenuRawElements.push((0, _jsxRuntime.jsx)(_ListItem.ListItem, Object.assign({}, element, {
            selected: selectedElement === i
          }), i));
          if (element.cutLine === true) {
            this.menuRawHeight += 16;
            renderedMenuRawElements.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                marginTop: 8,
                marginBottom: 8,
                marginRight: _reactNativeWeb.Platform.OS.toString() === 'web' ? 8 : 0,
                borderColor: _ThemeManager.ThemeManager.style.borderColor,
                borderBottomWidth: 1
              }
            }, i + 0.5));
          }
        }
      }
      return renderedMenuRawElements;
    }
  }, {
    key: "setmenuRawState",
    value: function setmenuRawState(toValue) {
      var _this3 = this;
      var callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
      var menuRawState = toValue > 0 ? MENURAW_OPENING : MENURAW_CLOSING;
      if (this.menuRawAnimation != null) {
        this.setState({
          menuRawState: menuRawState
        }, function () {
          _this3.menuRawAnimation.toValue(toValue, function () {
            menuRawState = menuRawState === MENURAW_CLOSING ? MENURAW_CLOSED : MENURAW_OPENED;
            _this3.setState({
              menuRawState: menuRawState
            });
            callback();
          });
        });
      } else {
        menuRawState = menuRawState === MENURAW_CLOSING ? MENURAW_CLOSED : MENURAW_OPENED;
        this.setState({
          menuRawState: menuRawState
        });
        callback();
      }
    }
  }, {
    key: "escapeRegExp",
    value: function escapeRegExp(s) {
      return s.replace(/([.*+?^${}()|\[\]\/\\])/g, '\\$1');
    }
  }, {
    key: "getNewHeight",
    value: function getNewHeight() {
      var client = this.state.client;
      var windowHeight = _ResizeEvent.ResizeEvent.current.windowHeight;
      var newHeight = this.menuRawHeight;
      var top = 0;
      if (client != null && client.y != null && client.height != null) {
        top = client.y + client.height;
      }
      if (top + newHeight > windowHeight - 16) {
        top = windowHeight - newHeight - 16;
      }
      if (top < 16 + _ThemeManager.ThemeManager.style.statusBarTopPadding) {
        top = 16 + _ThemeManager.ThemeManager.style.statusBarTopPadding;
        if (newHeight > windowHeight - 32 - _ThemeManager.ThemeManager.style.statusBarTopPadding) {
          newHeight = windowHeight - 32 - _ThemeManager.ThemeManager.style.statusBarTopPadding;
        }
      }
      return {
        top: top,
        height: newHeight
      };
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        animationDuration: 150,
        haveOverlay: true,
        onOpened: function onOpened() {},
        onClosed: function onClosed() {},
        open: false,
        size: _ResizeEvent.ResizeEvent.current
      };
    }
  }, {
    key: "defaultState",
    get: function get() {
      return {
        client: {
          x: _ResizeEvent.ResizeEvent.current.windowWidth / 2,
          y: _ResizeEvent.ResizeEvent.current.windowHeight / 2,
          width: 0,
          height: 56,
          selected: 0
        },
        closeOnTouchOutside: true,
        overlayPointerEvents: undefined,
        searchList: [],
        selectedElement: undefined,
        showOverlay: true,
        filterFormInput: false,
        formInput: undefined,
        items: [{
          title: 'MISSING LIST ITEMS'
        }],
        closeFunction: function closeFunction() {
          var _MenuRaw$instance2;
          return (_MenuRaw$instance2 = MenuRaw.instance) == null ? void 0 : _MenuRaw$instance2.close;
        },
        menuRawState: MENURAW_CLOSED,
        elevation: 8
      };
    }
  }, {
    key: "instance",
    get: function get() {
      if (MenuRaw._instance != null) {
        return MenuRaw._instance;
      }
      return null;
    }
  }]);
}(_react.PureComponent);