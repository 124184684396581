var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireDefault(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _MaterialText = require("../text/MaterialText");
var _Ripple = require("../utils/Ripple");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var YearButton = exports.default = function (_React$Component) {
  function YearButton(props) {
    (0, _classCallCheck2.default)(this, YearButton);
    return _callSuper(this, YearButton, [props]);
  }
  (0, _inherits2.default)(YearButton, _React$Component);
  return (0, _createClass2.default)(YearButton, [{
    key: "render",
    value: function render() {
      var _this = this;
      return (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
        style: {
          width: 102,
          height: 52,
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: this.props.marginLeft
        },
        onPress: function onPress() {
          return _this.props.selectYear(_this.props.year);
        },
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 36,
            width: 72,
            borderRadius: 18,
            backgroundColor: this.props.selected ? _ThemeManager.ThemeManager.style.brandPrimary : undefined,
            justifyContent: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            fixedWidth: 72,
            textAlign: "center",
            color: this.props.selected ? 'white' : undefined,
            children: this.props.year
          })
        })
      });
    }
  }]);
}(_react.default.Component);