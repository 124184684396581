"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UpmeshPartnerSettingsChanged = void 0;
var EventRootOdata_1 = require("cqrs-core/build/src/core/events/EventRootOdata");
var UpmeshPartnerSettingsChanged = function (_EventRootOdata_1$Eve) {
  function UpmeshPartnerSettingsChanged(partnerId, data) {
    var _this;
    (0, _classCallCheck2.default)(this, UpmeshPartnerSettingsChanged);
    _this = _callSuper(this, UpmeshPartnerSettingsChanged, [{
      data: data,
      entityId: partnerId,
      eventName: 'UpmeshPartnerSettingsChanged',
      aggregate: 'UpmeshPartner',
      aggregateId: partnerId
    }]);
    _this.data = data;
    return _this;
  }
  (0, _inherits2.default)(UpmeshPartnerSettingsChanged, _EventRootOdata_1$Eve);
  return (0, _createClass2.default)(UpmeshPartnerSettingsChanged);
}(EventRootOdata_1.EventRootOdata);
exports.UpmeshPartnerSettingsChanged = UpmeshPartnerSettingsChanged;