var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MessagesDe = void 0;
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _MessagesDe = require("materialTheme/build/src/theme/i18n/MessagesDe");
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var MessagesDe = exports.MessagesDe = function (_MessagesDeTheme) {
  function MessagesDe() {
    var _this;
    (0, _classCallCheck2.default)(this, MessagesDe);
    _this = _callSuper(this, MessagesDe, arguments);
    _this['?'] = '?';
    _this.ProjectnotFound = 'Das Projekt wurde nicht gefunden oder Sie haben keinen Zugriff mehr auf das Projekt!';
    _this.abort = 'Abbrechen';
    _this.absence = 'Abwesenheit';
    _this.absenceCostCenter = 'Kostenstelle';
    _this.absenceDeleteQuestion = 'Möchten Sie die Abwesenheit wirklich löschen?';
    _this.absenceDialogTitleAdd = 'Neue Abwesenheit erfassen';
    _this.absenceDialogTitleChange = 'Abwesenheit ändern';
    _this.absenceEnds = 'Bis';
    _this.absenceErrorEndsBeforeStart = 'Endzeit vor Startzeit';
    _this.absenceMember = 'Für';
    _this.absenceNoDataHint = 'Erstellen Sie neue Abwesenheiten, indem Sie auf den + Button drücken';
    _this.absenceNoDataHintNoRights = 'Sie haben keine Berechtigung Abwesenheiten zu erfassen';
    _this.absenceNoDataText = 'Keine Abwesenheiten erfasst';
    _this.absenceNote = 'Bemerkung';
    _this.absenceOpenRequests = 'Offene Anträge';
    _this.absenceRange = 'Zeitraum';
    _this.absenceStarts = 'Von';
    _this.absenceState = 'Status';
    _this.absenceStateApproved = 'genehmigt';
    _this.absenceStateOpen = 'offen';
    _this.absenceStateRejected = 'abgelehnt';
    _this.absenceTask = 'Grund';
    _this.absences = 'Abwesenheiten';
    _this.account = 'Mein Konto';
    _this.accountAddress = 'Adresse';
    _this.accountChangeAddressCity = 'Ort';
    _this.accountChangeAddressCountry = 'Land';
    _this.accountChangeAddressStreet = 'Straße';
    _this.accountChangeAddressStreetN = 'Hausnummer';
    _this.accountChangeAddressTitle = 'Adresse ändern';
    _this.accountChangeAddressZip = 'PLZ';
    _this.accountChangeCompanyTitle = 'Firma ändern';
    _this.accountChangeNames = 'Name ändern';
    _this.accountChangeNew = 'Neues Passwort';
    _this.accountChangeOld = 'Altes Passwort';
    _this.accountChangePw = 'Passwort ändern';
    _this.accountCompany = 'Firma';
    _this.accountCompanySettingsPageTitle = 'Firmenverwaltung';
    _this.accountCompanySettingsUserListHeaderTitle = 'Mitarbeiter';
    _this.accountDeleteEmailQuestion = 'Wollen Sie diese E-Mail-Adresse wirklich löschen?';
    _this.accountDisconnectGoogleAlert = 'Wollen Sie die Verbindung mit Ihrem Google Konto wirklich trennen?';
    _this.accountEmailNotValidated = 'E-Mail-Adresse noch nicht verifiziert.';
    _this.accountEmailRequestValidateMail = 'Verifizierungs-E-Mail erneut senden?';
    _this.accountEmails = 'E-Mail-Adressen';
    _this.accountEmailsAdd = 'E-Mail-Adresse hinzufügen';
    _this.accountEmailsNotification = 'an diese E-Mail Benachrichtigungen schicken';
    _this.accountFunctionInCompany = 'Position';
    _this.accountImageDeleteHint = 'Nutzerbild löschen';
    _this.accountImageDropHint = 'Nutzerbild ändern';
    _this.accountLogout = 'Abmelden';
    _this.accountLogoutQuestion = 'Wenn Sie sich abmelden, können Sie sich nur mit vorhandener Internetverbindung' + ' wieder anmelden und Ihre Offline gespeicherten Daten gehen vorloren';
    _this.accountLogoutQuestionTitle = 'Abmelden bei upmesh?\n';
    _this.accountManagerPhone = 'Anrufen';
    _this.accountManagerServicesContractTopics = 'Vertragsthemen';
    _this.accountManagerServicesLicenses = 'Lizenzen';
    _this.accountManagerServicesOnboarding = 'Onboarding';
    _this.accountManagerServicesTrainings = 'Schulungen';
    _this.accountManagerSubtitle = 'Ihr Ansprechpartner bei upmesh';
    _this.accountManagerWriteMail = 'Mail schreiben';
    _this.accountNotWithGoogleConnectedButton = 'Mit Google verbinden';
    _this.accountSettings = 'Einstellungen';
    _this.accountWithGoogleConnectedButton = 'Googleverbindung trennen';
    _this.accountWithGoogleConnectedButtonShort = 'trennen';
    _this.active = 'aktiv';
    _this.activities = 'Aktivitäten';
    _this.add = 'Hinzufügen';
    _this.addCompanyUserAsTeamMember = 'Eigene Firmen-Mitarbeiter hinzufügen';
    _this.addImageDropHint = 'Bild hinzufügen';
    _this.addImageOnMultiSelect = 'Bild hinzufügen';
    _this.addMapMapTitle = 'Kartentitel';
    _this.addMapTitle = 'Karte hinzufügen';
    _this.addNewCustomFieldRule = 'Neue Regel';
    _this.addPlanSnapshot = 'Planausschnitt hinzufügen';
    _this.addSubtickets = 'Sub-Tickets hinzufügen';
    _this.addTags = 'hinzufügen';
    _this.addTeamMember = 'Teammitglied hinzufügen';
    _this.addTicket = 'Ticket hinzufügen';
    _this.added = 'Hinzugefügt';
    _this.addedPlanVersion = 'Planversion hinzugefügt';
    _this.addisonExportCompanyInvalid = 'Die Firmennummer "{error}" ist ungültig für einen Addison-Export. (maximal 7 Ziffern)';
    _this.addisonExportCostCenterInvalid = 'Die Kostenstelle "{error}" ist ungültig für einen Addison-Export. (maximal 9 Ziffern)';
    _this.addisonExportCostUnitInvalid = 'Der Kostenträger "{{error}}" ist ungültig für einen Addison-Export. (maximal 9 Ziffern)';
    _this.addisonExportWageTypeInvalid = 'Die Lohnart "{{error}}" ist ungültig für einen Addison-Export. (maximal 4 Ziffern)';
    _this.addressErrorRequireCity = 'Stadt wird benötigt';
    _this.addressErrorRequireCountry = 'Land wird benötigt';
    _this.addressErrorRequireStreet = 'Straße wird benötigt';
    _this.addressErrorRequireStreetNumber = 'Hausnummer wird benötigt';
    _this.addressErrorRequireZip = 'PLZ wird benötigt';
    _this.addressPhoneCompany = 'Telefonnummer';
    _this.adsSettingsCheckboxLabel = 'relevante Werbung';
    _this.adsSettingsSectionLabel = 'Ad Einstellungen';
    _this.adsSettingsUpgradeSubscriptionLink = 'Sie möchten keine Werbung mehr sehen?\nJetzt upgraden';
    _this.adsUserConsentAgreeWithRelevantAdsButtonLabel = 'Ja. Mehr relevante Werbung';
    _this.adsUserConsentHeaderNote = 'Wir sind uns unserer Verantwortung gegenüber Ihrer Privatsphäre und Datensicherheit bewusst. Wir können Ihnen diese App kostenfrei anbieten, indem wir Ihnen Werbung zeigen.';
    _this.adsUserConsentHeaderQuestion = 'Dürfen wir weiterhin Ihre Daten verwenden, um Ihnen maßgeschneiderte Werbung zeigen zu können?';
    _this.adsUserConsentLessRelevantAdsButtonLabel = 'Nein. Weniger relevante Werbung';
    _this.adsUserConsentLinkToMoreInfoAboutCollectingData = 'Sie können hier weitere Informationen sehen, wie wir und unsere Partner Daten sammeln und nutzen.';
    _this.adsUserConsentSecondPageHeaderNote = 'Sie können uns die Erlaubnis zur Nutzung Ihrer Daten jederzeit in den App- Einstellungen entziehen. Sehen Sie hier, wie wir und unsere Partner Daten sammeln und nutzen.';
    _this.adsUserConsentUpdateChoiceLaterText = 'Sie können uns die Erlaubnis zur Nutzung Ihrer Daten jederzeit in den App- Einstellungen entziehen. Unsere Partner sammeln Daten und nutzen dafür eine eindeutige ID, um auf Ihrem Gerät Werbung anzeigen zu können.';
    _this.adsUserConsentUpmeshPrivacyLinkText = 'Wie upmesh Ihre Daten nutzt.';
    _this.allProjects = 'Alle Projekte';
    _this.allTickets = 'Alle Tickets';
    _this.apiToken = 'Token';
    _this.apiTokenAddCompanyText = 'Firmentoken';
    _this.apiTokenAddQuestionCompany = 'Möchten Sie einen neuen firmenbezogenen Zugangstoken für die Api erstellen?';
    _this.apiTokenAddQuestionNote = 'Notiz';
    _this.apiTokenAddQuestionUser = 'Möchten Sie einen neuen nutzerbezogenen Zugangstoken für die Api erstellen?';
    _this.apiTokenAddText = 'Token erzeugen';
    _this.apiTokenAddUserText = 'Benutzertoken';
    _this.apiTokenAlert = 'Ein API-Token ist ein Authentifizierungsschlüssel, der für den Zugriff auf bestimmte Funktionen und Daten in der App verwendet wird. Damit können autorisierte Anwendungen Aktionen in Ihrem Konto ausführen.';
    _this.apiTokenCopyToClipoard = 'Zwischenablage';
    _this.apiTokenNotYet = 'Es sind noch keine Api Zugriffs Token vorhanden';
    _this.apiTokenRevoke = 'Widerrufen';
    _this.apiTokenRevokeQuestion = 'Möchten Sie den Zugang wirklich widerrufen?';
    _this.apiTokenRevoked = 'Widerrufen';
    _this.apiTokenTitle = 'API Zugriff';
    _this.appData = 'App Einstellungen';
    _this.apply = 'Bestätigen';
    _this.archive = 'Archivieren';
    _this.archiveActivePlanMap = 'Karte';
    _this.archiveAddressContact = 'Kontatkname';
    _this.archiveAddressLatitude = 'Längengrad';
    _this.archiveAddressLongitude = 'Breitengrad';
    _this.archiveDocumentMimeType = 'MIME-Typ';
    _this.archiveDocuments = 'Dokumente';
    _this.archiveDocumentsFiles = 'Dateien';
    _this.archiveDocumentsSize = 'Dateigröße';
    _this.archiveDocumentsTitle = 'Dateiname';
    _this.archiveDocumentsUploadedAt = 'Hochgeladen am';
    _this.archiveDocumentsUploadedBy = 'Hochgeladen von';
    _this.archiveJournals = 'Tagesberichte';
    _this.archiveMap = 'Karten';
    _this.archivePlan = 'Pläne';
    _this.archivePlanActivatedBy = 'Aktiviert von';
    _this.archivePlanActivatedOn = 'Aktiviert am';
    _this.archivePlanActiveId = 'Aktive PlanId';
    _this.archivePlanNotes = 'Anmerkungen';
    _this.archivePlanTitle = 'Plantitel';
    _this.archivePlanVersions = 'Version';
    _this.archiveProjectDetails = 'Projektdetails';
    _this.archiveProjectDetailsBuildEnd = 'Bauende';
    _this.archiveProjectDetailsBuildStart = 'Baubeginn';
    _this.archiveProjectDetailsEnd = 'Projektende';
    _this.archiveProjectDetailsProjectAbo = 'Projet Abbonement';
    _this.archiveProjectDetailsProjectDescription = 'Projektbeschreibung';
    _this.archiveProjectDetailsProjectSize = 'Projektgröße';
    _this.archiveProjectDetailsStart = 'Projektstart';
    _this.archiveProjectDetailsTitle = 'Projekttitel';
    _this.archiveProjectDetailsType = 'Projektart';
    _this.archiveTeamCity = 'Stadt';
    _this.archiveTeamCompany = 'Firma';
    _this.archiveTeamCountry = 'Land';
    _this.archiveTeamDescription = 'Beshcreibung';
    _this.archiveTeamEmail = 'E-Mail';
    _this.archiveTeamFirstName = 'Vorname';
    _this.archiveTeamLastName = 'Nachname';
    _this.archiveTeamPostalCode = 'Postleitzahl';
    _this.archiveTeamRole = 'Rolle';
    _this.archiveTeamStatus = 'Status';
    _this.archiveTeamStatusAccepted = 'Akzeptiert';
    _this.archiveTeamStreet = 'Straße';
    _this.archiveTeamStreetNumber = 'Hausnummer';
    _this.archiveTicket = 'Ticket archivieren';
    _this.archiveTicketArchived = 'Archiviert';
    _this.archiveTicketAssignedTo = 'Zugewiesen an';
    _this.archiveTicketComment = 'Kommentar';
    _this.archiveTicketCreator = 'Ersteller';
    _this.archiveTicketDescription = 'Beschreibung';
    _this.archiveTicketFileId = 'Datei ID';
    _this.archiveTicketFilesFromActivities = 'Dateien aus den Aktivitäten';
    _this.archiveTicketImages = 'Bilder';
    _this.archiveTicketMiscellaneous = 'Sonstige';
    _this.archiveTicketPlanAffiliation = 'Planzugehörigkeit';
    _this.archiveTicketPlanPosition = 'Plan Position';
    _this.archiveTicketShort = 'Archivieren';
    _this.archiveTicketState = 'Ticket Status';
    _this.archiveTicketTags = 'Tags';
    _this.archiveTicketTitle = 'Ticket Titel';
    _this.archiveTicketType = 'Tickettyp';
    _this.archiveTickets = 'Tickets';
    _this.archiveTicketsDialogHeadline = 'Tickets archivieren';
    _this.archiveTicketsQuestion = 'Sollen diese Tickets archiviert / wiederhergestellt werden?\nArchivierte Tickets können' + ' jederzeit über die Filterfunktion eingesehen und wieder hergestellt werden';
    _this.archived = 'archiviert';
    _this.askOwnerForUpgradeProject = 'Diese Funktion ist nur in einem Pro- oder Firmen-Projekt (Enterprise) nutzbar. Bitte' + ' fragen Sie den Projekt Eigentümer nach einem Upgrade!';
    _this.audio = 'Audio';
    _this.back = 'zurück';
    _this.badRequest = 'Fehlerhafte Eingabe';
    _this.basicProject = 'Basic-Projekt';
    _this.bau2cloud = 'upmesh';
    _this.bookmark = 'Bookmarks';
    _this.brzExportCompanyInvalid = 'Die Betriebsnummer "{{error}}" ist ungültig für einen BRZ-Export. (maximal 6 Ziffern).';
    _this.brzExportCompanyNumber = 'Betriebsnummer';
    _this.brzExportCompanyNumberNeeded = 'Für den BRZ-Export wird eine Betriebsnummer benötigt.';
    _this.brzExportCostCenterInvalid = 'Die Kostenstelle "{{error}}" ist nicht gültig für einen BRZ-Export. (maximal 8 Ziffern)';
    _this.brzExportProjectNameInvalid = 'Der Projektname "{{error}}" ist zu lang für einen BRZ-Export. (maximal 15 Stellen)';
    _this.brzExportWageTypeInvalid = 'Die Lohnart "{{error}}" ist ungültig für einen BRZ-Export. (maximal 3 Ziffern)';
    _this.buildTime = 'Bauzeit';
    _this.builder = 'Bauherr';
    _this.bulkChangesAddWatcher = 'Beobachter hinzufügen';
    _this.bulkChangesArchive = 'Archivieren';
    _this.bulkChangesChangeApprover = 'Prüfer ändern';
    _this.bulkChangesChangeAssignee = 'Zugewiesenen ändern';
    _this.bulkChangesChangeDueDate = 'Fälligkeitsdatum ändern';
    _this.bulkChangesChangingEntries = 'Ändere Eintrag {{count}} von {{sum}}.';
    _this.bulkChangesChangingTickets = 'Ändere Ticket {{count}} von {{sum}}.';
    _this.bulkChangesConfirmationDialog = 'Wollen Sie diese {{count}} Einträge wirklich bearbeiten?';
    _this.bulkChangesConfirmationDialogHeader = 'Tickets bearbeiten';
    _this.bulkChangesConfirmationDialogText = 'Wollen Sie diese {{count}} Tickets wirklich bearbeiten?';
    _this.bulkChangesDelete = 'Löschen';
    _this.bulkChangesIconTooltip = 'Massenänderung durchführen';
    _this.bulkChangesMoreOptions = 'Mehr Optionen';
    _this.bulkChangesNewValue = 'Neuer Wert';
    _this.bulkChangesNoRights = '{{count}} Tickets konnten wegen fehlender Berechtigung nicht geändert werden';
    _this.bulkChangesNotAssignedToUser = 'Nicht zugewiesen';
    _this.bulkChangesSelectAll = 'Alle auswählen';
    _this.bulkChangesSelectNone = 'Keins auswählen';
    _this.calendarWeekShort = 'KW';
    _this.camCreateNewPlan = 'Neuen Plan erstellen';
    _this.camCreateNewTicket = 'Neues Ticket erstellen';
    _this.camSaveInMyMedia = 'Im privaten Ordner ablegen';
    _this.camTargetOptionTitle = 'Foto verwenden für';
    _this.camera = 'Kamera';
    _this.canChangeAllTickets = 'Alle Tickets bearbeiten';
    _this.canEditJournals = 'Kann Tagesberichte bearbeiten';
    _this.canTrackExtraPay = 'Kann Zulagen erfassen';
    _this.cancel = 'Abbrechen';
    _this.cantChangeUserRoleHeIsLastAdmin = 'Die Rolle kann nicht geändert werden. Er ist der letzte Firmenadmin!';
    _this.cantDeleteActivePlanversion = 'Die aktive Planversion kann nicht gelöscht werden';
    _this.cantDeleteFileHasAssigendTickets = 'Die Datei kann nicht gelöscht werden, da sie einem Ticket zugeordnet ist.' + ' Dateien in Tickets können nur vom Ersteller innerhalb von 24 Stunden gelöscht werden.';
    _this.cantDeleteHasAssigendTickets = 'Das Teammitglied konnte nicht entfernt werden, da es noch zugewiesene Tickets hat';
    _this.cantDeleteHasAssigendTicketsMe = 'Sie konnten das Projekt nicht verlassen, da Sie noch zugewiesene Tickets haben';
    _this.cantDeleteRoleUsersHasRole = 'Die Rolle kann nicht gelöscht werden, solange einem Teammitglied die Rolle zugewiesen ist';
    _this.cantDeleteTicketLayoutTicketHasType = 'Das Layout kann nicht gelöscht werden, dem zugeordnetem Typ "{{type}}" ist mindestens ein Ticket zugewiesen';
    _this.cantRemoveUserHeIsInvolvedInEnterpriseProjects = 'Dem Benutzer kann die Volllizenz nicht entzogen werden. Er hat eine Rolle in einem oder mehreren' + ' Firmen-Projekten, in denen die Lizenz benötigt wird.';
    _this.cantRemoveUserHeIsInvolvedInEnterpriseProjectsForceRemoveOrDowngrade = 'Soll der Benutzer aus den ensprechenden Projekten entfernt oder seine Rolle zum "Nachunternehmer" geändert werden?';
    _this.cantRemoveUserHeIsLastAdmin = 'Dem Benutzer kann die Volllizenz nicht entzogen werden. Er ist der letzte Firmenadmin!';
    _this.change = 'Ändern';
    _this.changeCompanyBackGroundImage = 'Firmen-Hintergrundlogo ändern';
    _this.changeCompanyImage = 'Neu hochladen';
    _this.changeFolder = 'Ordner bearbeiten';
    _this.changeOwner = 'Projekt übergeben';
    _this.changeRoleProError = 'Die Rolle konnte nicht geändert werden: Der Benutzer benötigt einen Pro- oder Firmen-Account für diese Rolle';
    _this.changeRoleProProjectError = 'Die Rolle konnte nicht geändert werden: Pro Projekt erforderlich';
    _this.changeRoleToManager = 'Projektleiter werden';
    _this.changeTicketPlanPosition = 'Ticket Position ändern';
    _this.changeView = 'Ansicht ändern';
    _this.chipGroupNoGroup = 'Keine Gruppe';
    _this.clear = 'Zurücksetzen';
    _this.close = 'Schließen';
    _this.closeAppText = 'Wollen Sie upmesh wirklich beenden?';
    _this.closeAppTitle = 'upmesh beenden?';
    _this.collaborator = 'Mitarbeiter';
    _this.color = 'Farbe';
    _this.commandAcceptInvitation = 'Einladung akzeptieren';
    _this.commandActivatePlanVersion = 'Planversion aktiveren';
    _this.commandAddEmail = 'E-Mail-Adresse hinzufügen';
    _this.commandAddStoredFile = 'Datei hinzufügen';
    _this.commandAddTag = 'Tag hinzufügen';
    _this.commandAddTicketTag = 'Ticket Tag hinzufügen';
    _this.commandAddTicketWatcher = 'Beobachter hinzufügen';
    _this.commandArchiveTicket = 'Ticket archivieren';
    _this.commandAssignTicketTo = 'Ticket zuweisen';
    _this.commandChangeAppData = 'Einstellungen ändern';
    _this.commandChangeExtraPayTracking = 'Erfasste Zulage bearbeiten';
    _this.commandChangePassword = 'Passwort ändern';
    _this.commandChangePlanDetails = 'Plan Details ändern';
    _this.commandChangeProject = 'Projektdaten ändern';
    _this.commandChangeProjectAddress = 'Ansprechpartner verwalten';
    _this.commandChangeProjectCrafts = 'Projekt Gewerke bearbeiten';
    _this.commandChangeProjectTags = 'Projekt Tags bearbeiten';
    _this.commandChangeProjectTicketLayouts = 'Ticketlayouts bearbeiten';
    _this.commandChangeProjectTicketTypes = 'Projekt Tickettypen ändern';
    _this.commandChangeTicket = 'Ticketdaten ändern';
    _this.commandChangeTicketApprover = 'Ticket Prüfer ändern';
    _this.commandChangeTicketCraft = 'Ticket Gewerk ändern';
    _this.commandChangeTicketCustomField = 'Benutzerdefiniertes Feld in "{{value}}" ändern';
    _this.commandChangeTicketDescription = 'Ticket Beschreibung ändern';
    _this.commandChangeTicketPlanAffiliation = 'Planzugehörigkeit ändern';
    _this.commandChangeTicketPlanPosition = 'Planposition ändern';
    _this.commandChangeTicketStatus = 'Ticket Status ändern';
    _this.commandChangeTicketSubTicketFrom = 'Übergeordnetes Ticket ändern';
    _this.commandChangeTicketTags = 'Ticket Tags ändern';
    _this.commandChangeTicketTitle = 'Ticket Titel ändern';
    _this.commandChangeTicketType = 'Tickettyp ändern';
    _this.commandChangeTimeTracking = 'Erfasste Zeit bearbeiten';
    _this.commandCreateExtraPayTracking = 'Zulage erfassen';
    _this.commandCreatePlan = 'Plan erstellen';
    _this.commandCreatePlanFile = 'Plandatei hochladen';
    _this.commandCreatePlanVersion = 'Plan Version erstellen';
    _this.commandCreateProject = 'Projekt erstellen';
    _this.commandCreateProjectRole = 'Projekt Rolle erstellen';
    _this.commandCreateTicket = 'Ticket erstellen';
    _this.commandCreateTicketComment = 'Ticket kommentieren';
    _this.commandCreateTimeTracking = 'Zeit erfassen';
    _this.commandDeclineInvitation = 'Einladung ablehnen';
    _this.commandDeleteExtraPayTracking = 'Erfasste Zulage löschen';
    _this.commandDeleteTicket = 'Ticket löschen';
    _this.commandDeleteTimeTracking = 'Erfasste Zeit löschen';
    _this.commandDiscardPlanFile = 'Plandatei löschen';
    _this.commandEditFolders = 'Ordner verwalten';
    _this.commandEditTicketComment = 'Ticket Kommentar bearbeiten';
    _this.commandFailPlanUpload = 'Plan Upload fehlgeschlagen';
    _this.commandInviteUser = 'Benutzer einladen';
    _this.commandLinkStoredFile = 'Datei einem Ticket zu weisen';
    _this.commandOfflineNotPossible = 'Diese Aktion kann Offline nicht ausgeführt werden';
    _this.commandRemoveStoredFile = 'Dateien löschen';
    _this.commandRemoveTicketTag = 'Ticket Tag entfernen';
    _this.commandRemoveTicketWatcher = 'Ticket Beobachter entfernen';
    _this.commandRemoveUser = 'Teammitglied entfernen';
    _this.commandRestoreTicket = 'Ticket wiederherstellen';
    _this.commandSaveJournal = 'Tagesbericht speichern';
    _this.commandSetTicketShouldCompleteDate = 'Ticket Fälligkeitsdatum ändern';
    _this.commandStartSoredFileUpload = 'Datei upload starten';
    _this.commandUnArchiveTicket = 'Ticket reaktivieren';
    _this.company = 'Firma';
    _this.companyAddCostCenterDialogTitle = 'Kostenstelle hinzufügen';
    _this.companyAddCraftDialogTitle = 'Gewerk hinzufügen';
    _this.companyAddEditUserDialogAddMemberInfo = 'Die Person kann andere Mitarbeitende zu Projekten hinzufügen\n\nErforderliche Lizenz: Projekleiterlizenz';
    _this.companyAddEditUserDialogAdminInfo = 'Die Person kann Einstellungen für die Firma und anderen' + ' Firmenmitglieder vornehmen\n\nErforderliche Lizenz: Projekleiterlizenz';
    _this.companyAddEditUserDialogExtraPayInfo = 'Die Person kann Zulagen sich und andere Mitarbeitende erfassen' + ' \n\nErforderliche Lizenz: ab Zeiterfassungslizenz';
    _this.companyAddEditUserDialogJournalsInfo = 'Die Person kann Tagesberichte für diese Firma erstellen und bearbeiten.\n\nErforderliche Lizenz:' + ' Mitarbeiter- oder Projekleiterlizenz';
    _this.companyAddEditUserDialogPayrollInfo = 'Die Person kann alle Zeiterfassungen für diese Firma' + ' sehen, sowie diese sperren\n\nErforderliche Lizenz: Projekleiterlizenz';
    _this.companyAddExtraPayDialogTitle = 'Zulage erstellen';
    _this.companyAddFileTypeDialogTitle = 'Kategorie hinzufügen';
    _this.companyAddGroupDialogTitle = 'Gruppe hinzufügen';
    _this.companyAddHolidayDialogTitle = 'Feiertag hinzufügen';
    _this.companyAddNewUserDilaogButton = 'Account erstellen';
    _this.companyAddNewUserDilaogText = 'Ein Account mit der E-Mail-Adresse {{email}} wurde nicht gefunden. Wollen Sie einen neuen Account erstellen?';
    _this.companyAddRegionDialogTitle = 'Region hinzufügen';
    _this.companyAddTaskDialogTitle = 'Tätigkeit hinzufügen';
    _this.companyAddTicketTypeDialogTitle = 'Tickettyp hinzufügen';
    _this.companyAddUserDilaogButton = 'Hinzufügen';
    _this.companyAddUserDilaogCompanyAdmin = 'Firmenadmin';
    _this.companyAddUserDilaogFooter = 'Ein Firmenadmin kann:\n- Mitarbeiter in die Firma hinzufügen und entfernen\n- das Firmenlogo ändern';
    _this.companyAddUserDilaogText = 'Fügen Sie einen neuen Mitarbeiter zu Ihrem Firmenkonto hinzu';
    _this.companyAddUserDilaogTitle = 'Mitarbeiter hinzufügen';
    _this.companyAddress = 'Adresse';
    _this.companyBackGroundLogoDropHint = 'Als Hintergrundlogo hinzufügen';
    _this.companyBackGroundLogoInfoMessage = 'Das Bild wird als Hintergrundbild auf der Startseite angezeigt.';
    _this.companyBackgroundLogo = 'Hintergrundlogo';
    _this.companyChangeCostCenterDialogTitle = 'Kostenstelle ändern';
    _this.companyChangeCraftDialogTitle = 'Gewerk bearbeiten';
    _this.companyChangeExtraPayDialogTitle = 'Zulage bearbeiten';
    _this.companyChangeFileTypeDialogTitle = 'Kategorie bearbeiten';
    _this.companyChangeGroupDialogTitle = 'Gruppe bearbeiten';
    _this.companyChangeHolidayDialogTitle = 'Feiertag ändern';
    _this.companyChangeRegionDialogTitle = 'Region bearbeiten';
    _this.companyChangeTaskDialogTitle = 'Tätigkeit bearbeiten';
    _this.companyChangeTicketTypeDialogTitle = 'Tickettyp bearbeiten';
    _this.companyChangeUserDilaogTitle = 'Mitarbeiter ändern';
    _this.companyCostCenterAlreadyExists = 'Eine Kostenstelle mit dem Kennzeichen existiert bereits';
    _this.companyCostCenterCantAddProjectToCostCenterOnlyCostUnit = 'Nur Kostenträgern können Projekte zugeordnet werden';
    _this.companyCostCenterCantChangeFromCostUnitIfProjectsAdded = 'Der Kostenträger kann nicht in eine Kostenstelle geändert solange noch Projekte zugeordnet sind';
    _this.companyCostCenterChangeProjects = 'Projekte zuweisen';
    _this.companyCostCenterMarkOnlyAlphaNumeric = 'Das Kennzeichen darf nur Buchstaben und Zahlen beeinhalten';
    _this.companyCostCenterMarkRequired = 'Das Kennzeichen wird benötigt';
    _this.companyCostCenterMarkToLong = 'Das Kennzeichen ist zu lang, maximal 20 Zeichen';
    _this.companyCostCenterNoDataHint = 'Drücken Sie auf den + Button, um eine neue Kostenstelle zu erstellen';
    _this.companyCostCenterNoDataText = 'Keine Kostenstellen vorhanden';
    _this.companyCraftAlreadyExists = 'Das Gewerk existiert bereits';
    _this.companyCraftNoDataHint = 'Drücken Sie auf den + Button, um ein neues Gewerk zu erstellen';
    _this.companyCraftNoDataText = 'Keine Gewerke vorhanden';
    _this.companyCraftNotExists = 'Das Gewerk existiert nicht';
    _this.companyEditUserDilaogTitle = 'Mitarbeiter bearbeiten';
    _this.companyExcelCategoryNameHeader = 'Kategoriename';
    _this.companyExcelDialogTitle = 'Excel Import';
    _this.companyExcelGroupNameHeader = 'Gruppenname';
    _this.companyExcelRegionCountryHeader = 'Land';
    _this.companyExcelRegionNameHeader = 'Region';
    _this.companyExcelTagGroupHeader = 'Taggruppe';
    _this.companyExcelTagNameHeader = 'Tagname';
    _this.companyExcelWorkingTimeEnd = 'Ende';
    _this.companyExcelWorkingTimePause = 'Pause';
    _this.companyExcelWorkingTimePostRecordingHeader = 'Tage nacherfassbar';
    _this.companyExcelWorkingTimeStart = 'Start';
    _this.companyExtraPayAlreadyExists = 'Eine Zulade mit dem Kennzeichen existiert bereits';
    _this.companyExtraPayMarkOnlyAlphaNumeric = 'Das Kennzeichen darf nur Buchstaben und Zahlen beeinhalten';
    _this.companyExtraPayMarkRequired = 'Das Kennzeichen wird benötigt';
    _this.companyExtraPayMarkToLong = 'Das Kennzeichen ist zu lang, maximal 20 Zeichen';
    _this.companyExtraPayNoDataHint = 'Drücken Sie auf den + Button, um eine neue Zulage zu erstellen';
    _this.companyExtraPayNoDataHintTracking = 'Gehen Sie zur Zulagenverwatlung in der Firmenverwatlung und erstellen Sie Zulagen';
    _this.companyExtraPayNoDataText = 'Keine Zulagen vorhanden';
    _this.companyFileTypeAlreadyExists = 'Die Kategorie existiert bereits';
    _this.companyFileTypeNoDataHint = 'Drücken Sie auf den + Button, um eine neue Kategorie zu erstellen';
    _this.companyFileTypeNoDataText = 'Keine Kategorien vorhanden';
    _this.companyFileTypeNotExists = 'Die Kategorie existiert nicht';
    _this.companyGroupAlreadyExists = 'Eine Gruppe mit diesem Namen existiert bereits';
    _this.companyGroupDefaultName = 'Mitarbeiter';
    _this.companyGroupDoesNotExist = 'Gruppe existiert nicht';
    _this.companyGroupNeedGroup = 'Nutzer können nicht aus der Standardgruppe entfernt werden';
    _this.companyGroupNoChangeDefault = 'Standardgruppenname kann nicht geändert werden';
    _this.companyGroupNoDataHint = 'Keine Gruppen vorhanden';
    _this.companyGroupNoDataText = 'Drücken Sie auf den + Button, um eine neue Gruppe zu erstellen';
    _this.companyGroupNoDeleteDefault = 'Standard Gruppe kann nicht gelöscht werden';
    _this.companyGroupRequired = 'Gruppenname wird benötigt';
    _this.companyGroupUserNotInCompany = 'Nutzer ist nicht vorhanden';
    _this.companyGroupsEditMembers = 'Gruppenmitglieder bearbeiten';
    _this.companyHasNoCompanyText = 'Sie sind aktuell keinem Firmenprofil zugeordnet. Bitte kontaktieren Sie Ihren Firmenadministrator oder nehmen Sie mit unserem Support Team Kontakt auf, um Ihr eigenes Firmenprofil zu erstellen.';
    _this.companyHolidayAffiliateWithRegion = 'Feiertag Regionen zuweisen';
    _this.companyHolidayAlreadyExists = 'Feiertag existiert bereits';
    _this.companyHolidayDayOrHours = 'Tag oder Stunden';
    _this.companyHolidayName = 'Feiertag';
    _this.companyHolidayNoDataHint = 'Keine Feiertage vorhanden';
    _this.companyHolidayNoDataText = 'Drücken Sie auf den + Button, um einen neuen Feiertag zu erstellen';
    _this.companyHolidayWageType = 'Lohnart';
    _this.companyHolidayYearly = 'Jährlich';
    _this.companyId = 'Firma';
    _this.companyImportHolidayHeadline = 'Feiertage importieren';
    _this.companyImportHolidayRegions = 'Importierten Feiertagen Regionen zuweisen';
    _this.companyImportHolidayResult = 'Es wurden {{count}} neue Feiertage gefunden';
    _this.companyImportHolidayState = 'Bundesländer:';
    _this.companyImportHolidayTitle = 'Importiert nicht vorhandene Feiertage für das Jahr {{first}}';
    _this.companyImportHolidayWageType = 'Lohnart für importierte Feiertage';
    _this.companyLicence = 'Lizenz';
    _this.companyListAlreadyExists = 'Eine Liste mit dem Namen "{{name}}" existiert bereits';
    _this.companyListNoDataHint = 'Drücken Sie auf den + Button, um eine neue Liste zu erstellen';
    _this.companyListNoDataText = 'Keine Listen vorhanden';
    _this.companyListNotExists = 'Die Liste existiert nicht';
    _this.companyListTableList = 'Optionen';
    _this.companyLogo = 'Firmenlogo';
    _this.companyLogoDropHint = 'Als Firmenlogo hinzufügen';
    _this.companyLogoInfoMessage = 'Das Logo wird in den Tagesberichten, Ticketberichten und als Profilbild angezeigt.';
    _this.companyMember = 'Mitarbeiter';
    _this.companyMemberCanInviteCompanyUserToProjects = 'Kann Mitarbeiter zu Projekten hinzufügen';
    _this.companyProfile = 'Firmenprofil';
    _this.companyProject = 'Firmenprojekte';
    _this.companyProjectInfo = 'Firmen Projekteinstellungen';
    _this.companyProjectStateAlreadyExists = 'Ein Status mit diesem Namen existiert bereits';
    _this.companyProjectStateColorRequired = 'Es wird eine Statusfarbe benötigt';
    _this.companyProjectTeamAddMeAsManagerButton = 'Mich hinzufügen';
    _this.companyProjectTeamAddMeAsManagerQuestion = 'Möchten Sie sich selbst als Projektleiter hinzufügen?';
    _this.companyRegionAlreadyExists = 'Region existiert bereits';
    _this.companyRegionDoesNotExist = 'Region existiert nicht';
    _this.companyRegionsEditMembers = 'Mitglieder zuweisen';
    _this.companyRegionsNoDataHint = 'Keine Regionen vorhanden';
    _this.companyRegionsNoDataText = 'Drücken Sie auf den + Button, um eine neue Region zu erstellen';
    _this.companyRemoveCostCenterQuestion = 'Möchten Sie die Kostenstelle wirklich löschen?';
    _this.companyRemoveCraftQuestion = 'Möchten Sie das Gewerk "{{craft}}" wirklich entfernen?';
    _this.companyRemoveEnterpriseUserQuestion = 'Möchten Sie den Mitarbeiter "{{username}}" wirklich entfernen?';
    _this.companyRemoveExtraPayQuestion = 'Möchten Sie die Zulage wirklich löschen?';
    _this.companyRemoveFileTypeQuestion = 'Möchten Sie die Kategorie "{{fileType}}" wirklich löschen?';
    _this.companyRemoveGroupQuestion = 'Möchten Sie die Gruppe "{{group}}" wirklich löschen?';
    _this.companyRemoveHolidayQuestion = 'Möchten Sie den Feiertag "{{holiday.name}}" wirklich löschen?';
    _this.companyRemoveListQuestion = 'Möchten Sie die Liste "{{name}}" wirklich löschen?';
    _this.companyRemoveRegionQuestion = 'Möchten Sie die Region "{{region.region}} {{region.country}}" wirklich löschen?';
    _this.companyRemoveReportTemplateQuestion = 'Möchten Sie die Berichtsvorlage "{{title}}" wirklich löschen?';
    _this.companyRemoveTagsQuestion = 'Möchten Sie das Tag "{{tag.tagName}}" wirklich löschen?';
    _this.companyRemoveTaskQuestion = 'Möchten Sie die Tätigkeit "{{task}}" wirklich löschen?';
    _this.companyRemoveTicketTypeQuestion = 'Möchten Sie den Tickettypen "{{type}}" wirklich löschen?';
    _this.companyRemoveUser = 'Mitarbeiter entfernen?';
    _this.companyRemoveWorkingTimeModelQuestion = 'Möchten Sie das Arbeitszeitmodell "{{name}}" wirklich löschen?';
    _this.companyReportTemplateAlreadyExists = 'Eine Reportvorlage mit dem Namen existiert bereits in der Firma';
    _this.companyReportTemplateType = 'Berichtsart';
    _this.companyRole = 'Rolle';
    _this.companyRoleAdmin = 'Firmenadmin';
    _this.companyRoleInactive = 'Inaktiv';
    _this.companyRoleManager = 'Projektleiter';
    _this.companyRoleTimetracker = 'Zeiterfasser';
    _this.companyRoleUser = 'Mitarbeiter';
    _this.companySettings = 'Firmeneinstellungen';
    _this.companySettingsCraftsTagsTypesInformation = 'Diese Listeneinträge dienen als Vorlage für neue Firmen-Projekte. Im Projekt sind Anpassungen möglich.';
    _this.companySettingsTicketstatusInformation = 'Alle veränderten Ticket-Status überschreiben die ursprünglichen Status in allen Firmenprojekten, außer in jenen Projekten, wo individuelle Anpassungen vorgenommen wurden.';
    _this.companySettingsUserInformation = 'Laden Sie neue interne Nutzer zu Ihrer Firma ein, um mit Ihnen in upmesh zu arbeiten. Eingeladene Nutzer benötigen eine Lizenz Ihres Kontingents.';
    _this.companyTagAlreadyExists = 'Das Tag existiert bereits';
    _this.companyTagColorAlreadyUsed = 'Die Farbe wird schon von einer anderen Gruppe verwendet';
    _this.companyTagNotExists = 'Das Tag existiert nicht';
    _this.companyTagsNoDataHint = 'Drücken Sie auf den + Button, um ein neues Tag zu erstellen';
    _this.companyTagsNoDataText = 'Keine Tags vorhanden';
    _this.companyTaskAlreadyExists = 'Eine Tätigkeit mit dem Kennzeichen existiert bereits';
    _this.companyTaskCantDeleteUsed = 'Die Tätigkeit kann nicht gelöscht werden, wenn es in der Zeiterfassung verwendet wurde';
    _this.companyTaskNoDataHint = 'Drücken Sie auf den + Button, um eine neue Tätigkeit zu erstellen';
    _this.companyTaskNoDataText = 'Keine Tätigkeiten vorhanden';
    _this.companyTemplatesForProjects = 'Vorlagen für Projekte';
    _this.companyTicketLayoutAlreadyExists = 'Das Ticketlayout existiert bereits in der Firma';
    _this.companyTicketTypeAlreadyExists = 'Der Tickettyp existiert bereits';
    _this.companyTicketTypeNotExists = 'Der Tickettyp existiert nicht';
    _this.companyTypeNoDataHint = 'Drücken Sie auf den + Button, um einen neuen Tickttypen zu erstellen';
    _this.companyTypeNoDataText = 'Keine Tickettypen vorhanden';
    _this.companyUserCanAbsenceFor = 'Abwesenheiten erfassen für';
    _this.companyUserCanAbsenceForAll = 'Alle';
    _this.companyUserCanAbsenceForGroup = 'Gruppenmitglieder';
    _this.companyUserCanAbsenceForNobody = 'Keinen';
    _this.companyUserCanAbsenceForSelf = 'Nur sich selbst';
    _this.companyUserCanAbsenceFrom = 'Abwesenheiten einsehen für';
    _this.companyUserCanAbsenceFromAll = 'Alle';
    _this.companyUserCanAbsenceFromGroup = 'Gruppenmitglieder';
    _this.companyUserCanAbsenceFromSelf = 'Nur sich selbst';
    _this.companyUserCanLockTimeTrackingFrom = 'Zeiten sperren von';
    _this.companyUserCanLockTimeTrackingFromAll = 'Allen';
    _this.companyUserCanLockTimeTrackingFromGroup = 'Gruppe';
    _this.companyUserCanLockTimeTrackingFromNobody = 'Keinem';
    _this.companyUserCanPermitAbsenceFor = 'Abwesenheiten genehmigen für';
    _this.companyUserCanTimeTrackFor = 'Zeiten erfassen für';
    _this.companyUserCanTimeTrackForAll = 'Alle';
    _this.companyUserCanTimeTrackForGroup = 'Gruppenmitglieder';
    _this.companyUserCanTimeTrackForSelf = 'Nur sich selbst';
    _this.companyUserCanTimeTrackFrom = 'Zeiten sehen von';
    _this.companyUserCanTimeTrackFromAll = 'Allen';
    _this.companyUserCanTimeTrackFromGroup = 'Gruppenmitgliedern';
    _this.companyUserCanTimeTrackFromSelf = 'Nur sich selbst';
    _this.companyUserForceDowngrade = 'Rolle ändern';
    _this.companyUserForceRemove = 'Entfernen';
    _this.companyUserLicenseCollaborator = 'Mitarbeiterlizenz';
    _this.companyUserLicenseFull = 'Projektleiterlizenz';
    _this.companyUserLicenseTimeTracking = 'Zeiterfassungslizenz';
    _this.companyUserLicensesCollaborator = 'Mitarbeiterlizenzen';
    _this.companyUserLicensesFull = 'Projektleiterlizenzen';
    _this.companyUserLicensesTimeTracking = 'Zeiterfassungslizenzen';
    _this.companyWorkingTimeModelAlreadyExists = 'Ein Arbeitszeitmodel mit dem Kennzeichen existiert bereits';
    _this.companyWorkingTimeModelSetAsDefaultQuestion = 'Möchten Sie das Arbeitszeitmodell "{{name}}" als Standard setzen?';
    _this.companylistsTitleCreateNew = 'Neue Liste erstellen';
    _this.companylistsTitleEdit = 'Liste "{{name}}" bearbeiten';
    _this.companys = 'Firmen';
    _this.conflict = 'conflict';
    _this.constructionSite = 'Baustelle';
    _this.contact = 'Kontakt';
    _this.contactPersons = 'Ansprechpartner';
    _this.copmanyReportTemplateNoDataHint = 'Drücken Sie auf den + Button, um eine neue Berichtsvorlage zu erstellen';
    _this.copmanyReportTemplateNoDataText = 'Keine Berichtsvorlagen vorhanden';
    _this.copy = 'Kopieren';
    _this.costCenter = 'Kostenstelle';
    _this.costCenterCostType = 'Kostenträger?';
    _this.costCenterDescription = 'Bezeichnung';
    _this.costCenterHasAbsencesItems = 'Die Kostenstelle hat erfasste Abwesenheiten und kann deswegen nicht gelöscht werden';
    _this.costCenterHasExtraPayTrackedItems = 'Die Kostenstelle hat erfasste Zulagen und kann deswegen nicht gelöscht werden';
    _this.costCenterHasTimeTrackedItems = 'Die Kostenstelle hat erfasste Zeiten und kann deswegen nicht gelöscht werden';
    _this.costCenterId = 'Kostenstelle';
    _this.costCenterIdNotFound = 'Die Kostenstelle wurde nicht gefunden';
    _this.costCenterIsCostUnit = 'Ist Kostenträger';
    _this.costCenterMark = 'Kennzeichen';
    _this.costCenterMultipleProjectsError = 'Der Kostenstelle sind mehrere Projekte zugewiesen';
    _this.costCenterNoProjectError = 'Der Kostenstelle ist kein Projekt zugewiesen';
    _this.costCenterProjectWithoutCenterError = 'Dem Projekt ist keine Kostenstelle zugewiesen';
    _this.costCenterType = 'Kostenträger?';
    _this.costCenters = 'Kostenstellen';
    _this.costTypeTypeError = 'costType muss entweder costCenter oder costUnit sein';
    _this.costUnit = 'Kostenträger';
    _this.costUnitNeedsProject = 'Wenn ein Kostenträger gewählt ist, wird die Projekt id benötigt';
    _this.costUnits = 'Kostenträger';
    _this.craft = 'Gewerk';
    _this.craftCablework = 'Kabelleitungstiefbau';
    _this.craftCarpenter = 'Zimmerer';
    _this.craftCommunication = 'Kommunikation';
    _this.craftConcrete = 'Beton';
    _this.craftDemolitionWork = 'Abbruch';
    _this.craftDoors = 'Türen';
    _this.craftDrywall = 'Trockenbau';
    _this.craftEarthWork = 'Erdbau';
    _this.craftElectrical = 'Elektro';
    _this.craftFacade = 'Fassade';
    _this.craftFinishCarpenter = 'Tischler';
    _this.craftFlooring = 'Boden';
    _this.craftHVAC = 'HLS';
    _this.craftLandscaping = 'Landschaftsbau';
    _this.craftMason = 'Maurer';
    _this.craftMetalWork = 'Metallbau';
    _this.craftPaint = 'Maler';
    _this.craftPlumbing = 'Klempner';
    _this.craftRoofing = 'Dachdecker';
    _this.craftSpecialHeavyConstruction = 'Spezialtiefbau';
    _this.craftStaging = 'Gerüstbau';
    _this.craftSteelwork = 'Stahlbau';
    _this.craftTiles = 'Fliesen';
    _this.craftWindows = 'Fenster';
    _this.crafts = 'Gewerke';
    _this.create = 'Erstellen';
    _this.createFolder = 'Ordner erstellen';
    _this.createPlanVersion = 'Plan "{{planTitle}}" wird erstellt';
    _this.createProjectAddCostUnit = 'Bestehender';
    _this.createProjectAddCostUnitLabel = 'Bestehenden wählen';
    _this.createProjectFromTemplateFinish = 'Ihr Projekt wurde erstellt';
    _this.createProjectFromTemplateHint = 'Ihr Projekt wird erstellt';
    _this.createProjectNewCostUnit = 'Neu';
    _this.createProjectNoCostUnit = 'Keiner';
    _this.createSubfolder = 'Unterordner erstellen';
    _this.created = 'Erstellt';
    _this.createdAt = 'Erstellt';
    _this.creator = 'Ersteller';
    _this.customFieldCheckboxList = 'Checkliste';
    _this.customFieldDate = 'Datum';
    _this.customFieldDateRange = 'Datumsbereich';
    _this.customFieldDivider = 'Trennlinie';
    _this.customFieldList = 'Liste';
    _this.customFieldMultiPerson = 'Person (Mehrfachauswahl)';
    _this.customFieldMultiselectList = 'Liste (Mehrfachauswahl)';
    _this.customFieldNumber = 'Zahl';
    _this.customFieldPerson = 'Person';
    _this.customFieldProgress = 'Fortschritt';
    _this.customFieldText = 'Text';
    _this.customFields = 'Benutzerdefinierte Felder';
    _this.dashboard = 'Dashboard';
    _this.dashboardChartItemApprovers = 'Prüfer';
    _this.dashboardChartItemCraft = 'Gewerk';
    _this.dashboardChartItemOther = 'Sonstige';
    _this.dashboardChartItemPercentage = 'Prozentsatz';
    _this.dashboardChartItemQuantity = 'Anzahl';
    _this.dashboardChartItemShowPercentage = 'Prozentsatz zeigen';
    _this.dashboardChartItemTicketOverview = 'Ticketübersicht';
    _this.dashboardChartItemType = 'Typ';
    _this.dashboardFocusItemAllAssignees = 'Alle Zugewiesenen';
    _this.dashboardFocusItemNoTickets = 'Es sind keine Tickets in diesem Zeitraum fällig';
    _this.dashboardFocusTicketsItem = 'Fokustickets';
    _this.dashboardFocusTicketsItemAssignedTo = 'Zugewiesen an';
    _this.dashboardFocusTicketsItemDueDate = 'Fälligkeitsdatum';
    _this.dashboardFocusTicketsItemDueInAMonth = 'In einem Monat fällig';
    _this.dashboardFocusTicketsItemDueInAWeek = 'In einer Woche fällig';
    _this.dashboardFocusTicketsItemDueInTwoWeeks = 'In zwei Wochen fällig';
    _this.dashboardFocusTicketsItemDueToday = 'Heute fällig';
    _this.dashboardFocusTicketsItemDueTomorrow = 'Morgen fällig';
    _this.dashboardFocusTicketsItemLastChanged = 'Zuletzt geändert';
    _this.dashboardFocusTicketsItemOverdue = 'Überfällig';
    _this.dashboardFocusTicketsItemSortBy = 'Sortieren nach';
    _this.dashboardFocusTicketsItemTitle = 'Titel';
    _this.dashboardGanttChart = 'Gantt-Chart';
    _this.dashboardGanttChartDateRange = 'Datumsbereich';
    _this.dashboardGanttChartTimeScale = 'Zeitskala';
    _this.dashboardGanttChartTimeSortBy = 'Sortieren nach';
    _this.dashboardItemsAssignee = 'Zugewiesener';
    _this.dashboardItemsStatus = 'Status';
    _this.dashboardNotes = 'Persönliche Notizen';
    _this.dashboardTimeType = 'genereller Zeitraum';
    _this.date = 'Datum';
    _this.datevExportPersNrInvalid = 'Die PersNr {{error}} ist ungültig für einen LODAS-Export. (maximal 5 Ziffern)';
    _this.datevExportWageTypeInvalid = 'Die Lohnart "{{error}}" ist ungültig für einen LODAS-Export. (maximal 4 Ziffern)';
    _this.datevKostenstellebwdTooLong = 'Die kostenstelle#bwd oder kostentraeger#bwd "{{error}}", waren länger als die von LODAS vorgegebenen 13 Zeichen.';
    _this.datevLohnUndGehaltAusfallInvalid = 'Der Ausfallschlüssel "{{error}}" ist zu lang. (maximal 2 Stellen)';
    _this.datevLohnUndGehaltKostenStelleInvalid = 'Der Kostenträger/Kostenstelle "{{error}}" ist zu lang. (maximal 8 Stellen)';
    _this.datevLohnUndGehaltPersNumberInvalid = 'Die Personalnummer "{{error}}" ist ungültig für einen Datev-Export. (maximal 5 Ziffern)';
    _this.datevLohnUndGehaltWageTypeInvalid = 'Die Lohnart "{{error}}" ist ungültig. Nummer einer Lohnart, 4-stellig numerisch, Wertebereich 1 bis 5999 und' + ' 8000 bis 9999.';
    _this.day = 'Tag';
    _this.dayBeforeYesterday = 'Vorgestern';
    _this.days = 'Tage';
    _this.dbError = 'Datenbankfehler';
    _this.default = 'Standard';
    _this.defaultErrorTitleOnSave = 'Die Änderung konnte nicht gespeichert werden';
    _this.defaultFields = 'Standardfelder';
    _this.delete = 'Löschen';
    _this.deleteAccount = 'Konto löschen';
    _this.deleteAccountDialogConfirmaionText = 'Löschen bestätigen';
    _this.deleteAccountDialogSuccessText = 'Schade, dass Sie gehen. Wir werden die Löschung Ihres Kontos in 5 Tagen vornehmen. Solange haben Sie noch Zeit' + ' die Löschung zu verhindern und Ihr Konto zu reaktivieren.';
    _this.deleteAccountDialogSuccessTitle = 'Löschung erfolgreich beantragt';
    _this.deleteAccountDialogText = 'Sobald Sie Ihr Konto löschen, werden alle Ihre Daten dauerhaft gelöscht. Sie verlieren den' + ' Zugang zu allen Projekten';
    _this.deleteAccountDialogTitle = 'upmesh Konto wirklich löschen?';
    _this.deleteAccountErrorHasCompanySub = 'Sie haben durch Ihre Firma ein laufendes Abonnement und können ihr Konto deshalb' + ' nicht löschen. Für mehr Informationen kontaktieren Sie bitte unseren Support.';
    _this.deleteAccountErrorHasSubscription = 'Sie können Ihr Konto nicht während eines laufenden Abonnements löschen. Für mehr Informationen kontaktieren ' + 'Sie bitte unseren Support.';
    _this.deleteAccountErrorStillInProjects = 'Damit Sie Ihr Konto löschen können, müssen Sie zuerst alle Projekte, in denen' + ' Sie der Projekteigentümer sind, löschen oder die Inhaberschaft an eine weitere Person übertragen und Projekte,' + ' in denen Sie Mitglied sind, verlassen.';
    _this.deleteAll = 'Alle löschen';
    _this.deleteFiles = 'Datei(en) löschen';
    _this.deletePdf = 'PDF-Dokument löschen';
    _this.deletePlan = 'Plan löschen';
    _this.deleteRuleMessage = 'Soll die Regel wirklich gelöscht werden?';
    _this.deleteTicket = 'Ticket löschen';
    _this.deleteTicketDeleted = 'Gelöscht';
    _this.deleteTicketsDialogHeadline = 'Tickets löschen';
    _this.deleteTicketsQuestion = 'Sollen gelöschte Tickets wiederhergestellt und offene gelöscht werden?\nGelöschte Tickets können' + ' jederzeit über die Filterfunktion eingesehen und wieder hergestellt werden';
    _this.deleteVersion = 'Version löschen';
    _this.deleted = 'gelöscht';
    _this.deletedProject = 'Gelöschtes Projekt';
    _this.discard = 'Verwerfen';
    _this.discardChanges = 'Änderungen verwerfen';
    _this.distribution = 'Vertrieb';
    _this.documents = 'Dokumente';
    _this.downgrade = 'downgrade';
    _this.download = 'herunterladen';
    _this.downloadLinkError = 'Fehler beim Downloaden der Datei';
    _this.downloadPlan = 'Plan herunterladen';
    _this.downloadPlanWithTickets = 'Plan mit Tickets herunterladen';
    _this.dropNewFieldHere = 'Neues Feld hier ablegen';
    _this.eMailNotFound = 'E-Mail-Adresse nicht gefunden';
    _this.edit = 'Bearbeiten';
    _this.editPlan = 'Plan bearbeiten';
    _this.editProject = 'Projekt bearbeiten';
    _this.editTags = 'Tags bearbeiten';
    _this.email = 'E-Mail-Adresse';
    _this.emailNotification = 'E-Mail Benachrichtigungen';
    _this.emailValidateError = 'Ihre E-Mail-Adresse konnte nicht verifiziert werden';
    _this.emailValidateErrorNoEmail = 'Ihre E-Mail-Adresse konnte nicht verifiziert werden, E-Mail nicht gefunden';
    _this.emailValidateErrorNoSuchCode = 'Ihre E-Mail-Adresse konnte nicht verifiziert werden, Code ungültig';
    _this.emailValidateErrorTitle = 'E-Mail-Adresse verifizieren';
    _this.emailValidateErrorTokenInvalid = 'Ihre E-Mail-Adresse konnte nicht verifiziert werden, Code ungültig';
    _this.emailValidateSend = 'Es wurde eine neue Verifizierungsmail versendet.';
    _this.emailValidateSuccess = 'Ihre E-Mail-Adresse wurde erfolgreich verifiziert';
    _this.emailValidateTitle = 'E-Mail Verifizierung';
    _this.emailValidateTokenExpired = 'Ihre E-Mail-Adresse konnte nicht verifiziert' + ' werden, der Code ist abgelaufen. Es wurde eine neue Verifizierungsmail versendet.';
    _this.emailValidationError = 'Keine gültige E-Mail-Adresse';
    _this.employee = 'Mitarbeiter';
    _this.ended = 'beendet';
    _this.ends = 'Ende';
    _this.enterProject = 'Projekt öffnen';
    _this.enterpriseProject = 'Firmen-Projekt';
    _this.error = 'Fehler';
    _this.exportAsCSV = 'Export als CSV';
    _this.exportExcelToolTip = 'Excel-Export';
    _this.exportPlan = 'Plan-Export';
    _this.exportPlanWithTickets = 'Plan-Export mit Tickets';
    _this.exportTimeTrackingEntries = 'Einträge exportieren';
    _this.extraField = 'Wert';
    _this.extraOnlyOnceADay = 'Die Zulage kann nur ein mal pro Tag angelegt werden';
    _this.extraPay = 'Zulage';
    _this.extraPayAddTrack = 'Zulage erfassen';
    _this.extraPayChangeTrack = 'Zulage ändern';
    _this.extraPayDescription = 'Bezeichnung';
    _this.extraPayFieldType = 'Zusatzangabe';
    _this.extraPayFieldTypeDuration = 'Zeitspanne';
    _this.extraPayFieldTypeNone = 'Keine';
    _this.extraPayFieldTypeNumber = 'Menge';
    _this.extraPayFieldTypeNumberIn = 'Menge in {{unit}}';
    _this.extraPayFieldTypeNumberMax = 'Maximum';
    _this.extraPayFieldTypeNumberMin = 'Minimum';
    _this.extraPayFieldTypeNumberUnit = 'Einheit';
    _this.extraPayFieldValue = 'Wert';
    _this.extraPayHasTrackedItems = 'Die Zulage hat erfasste Zulagen und kann deswegen nicht gelöscht werden';
    _this.extraPayId = 'Zulage';
    _this.extraPayMark = 'Kennzeichen';
    _this.extraPayOnceAday = 'Einmalig pro Tag';
    _this.extraPayTracking = 'Zulagen';
    _this.extraPayWageType = 'Lohnart';
    _this.extraPays = 'Zulagen';
    _this.failed = 'fehlgeschlagen';
    _this.fileDeleteQuestion = 'Möchten Sie die Datei wirklich löschen?';
    _this.fileIsCopying = 'Datei {{ file }} wird dupliziert';
    _this.filePreparingDownload = 'Download wird vorbereitet';
    _this.fileReadyToDownload = 'Datei bereit zum herunterladen';
    _this.fileTypes = 'Kategorien';
    _this.files = 'Dateien';
    _this.filesAddTitle = 'Ziel auswählen';
    _this.filesAddTitleFolders = 'Ordner auswählen';
    _this.filesAddTitlePlans = 'Plan auswählen';
    _this.filesAddTitleProjects = 'Projekt auswählen';
    _this.filesAddTitleTickets = 'Ticket auswählen';
    _this.filesAll = 'Alle';
    _this.filesChangeCommentTypeTooltip = 'Kategorie und Kommentar bearbeiten';
    _this.filesChangeFileTypeDialog = 'Kategorie und Kommentar ändern';
    _this.filesChangeToNoType = 'Keine Kategorie';
    _this.filesCommentHeadline = 'Kommentar';
    _this.filesCreateChange = 'Datei bearbeitet/geändert';
    _this.filesDeleteQuestion = 'Wollen Sie die ausgewählten Dateien wirklich löschen?';
    _this.filesDeleteQuestionAction = 'löschen';
    _this.filesDeleteQuestionAllVersions = 'Die Datei hat mehrere Versionen. Wollen Sie nur die aktuelle Version löschen oder alle Versionen?';
    _this.filesDeleted = 'gelöscht';
    _this.filesDownload = 'Herunterladen';
    _this.filesEditPdfEnterpriseFunctionDescription = 'Bearbeiten Sie PDF Dateien, schreiben Sie Anmerkungen, setzen Lesezeichen und mehr. Jetzt upgraden und auf' + ' noch weitere Funktionen sowie einen erweiterten Dateispeicher freuen.';
    _this.filesEditPdfEnterpriseFunctionHeadline = 'PDF Bearbeitung ist eine Enterprise Funktion';
    _this.filesFileSize = 'Dateigröße';
    _this.filesFileTypeHeadline = 'Kategorie';
    _this.filesFolderPrivate = 'Privat';
    _this.filesFolderPublic = 'Projektablage';
    _this.filesPathTitle = 'Pfad';
    _this.filesRename = 'Umbenennen';
    _this.filesRenameHeader = 'Datei Umbenennen';
    _this.filesRenameNewFileName = 'Neuer Dateiname';
    _this.filesSaveNotPossible = 'Speichern nicht möglich';
    _this.filesSaveNotPossibleCreator = 'Sie sind nicht der Autor der Datei.';
    _this.filesSaveNotPossibleFolder = 'Sie haben keine Bearbeitungsrechte in diesem Ordner.';
    _this.filesSaveNotPossibleOffline = 'Sie sind offline. Stellen Sie die Internetverbindung wieder her oder speichern Sie eine Kopie der Datei.';
    _this.filesSaveNotPossibleTime = 'Der erlaubte Bearbeitungszeitraum ist abgelaufen. Ändern Sie diesen in den Projekteinstellungen oder speichern Sie die Datei als Kopie.';
    _this.filesTicketTitle = 'Ticket Titel';
    _this.filesTitle = 'Name';
    _this.filesUploadDate = 'Hochgeladen am';
    _this.filesUploadDescription = 'Dateien für die Projektdokumentation';
    _this.filesUploadDescriptionUploadButton = 'Keine Dateien vorhanden';
    _this.filesUploadHint = 'Fügen Sie neue Dateien mit dem + Button hinzu, um sie später an Tickets anzuhängen.';
    _this.filesUploadHintReports = 'Hier finden Sie alle bisher erstellten Tagesberichte.';
    _this.filesUploadHintTickets = 'Hier finden Sie alle Dateien, die zu einem Ticket hinzugefügt worden sind.';
    _this.filesUploadHintUploadButton = 'Fügen Sie neue Dateien in der Dateiverwaltung hinzu, um sie hier zu verwenden';
    _this.filesUploadTextButton = 'Dateien auswählen';
    _this.filesUploadTitle = 'Dateien hochladen';
    _this.filesUploader = 'Hochgeladen von';
    _this.filesVersions = 'Versionen';
    _this.filesVersionsManage = 'Versionen verwalten';
    _this.filterChange = 'Filter anpassen';
    _this.filterRemove = 'Filter entfernen';
    _this.firstName = 'Vorname';
    _this.folder = 'Ordner';
    _this.folderAccess = 'Zugriffsrechte';
    _this.folderAccessAddToSubFolder = '+ neue Rechte zu Unterordnern hinzufügen';
    _this.folderAccessAll = 'Zugriff einschränken';
    _this.folderAccessAllHelperText = 'Ohne Einschränkung haben alle Vollzugriff.';
    _this.folderAccessOnlyFolder = 'Rechte nur für diesen Ordner';
    _this.folderAccessOverwriteSubFolder = '+ Rechte für Unterordner überschreiben';
    _this.folderCreateChange = 'Ordner bearbeitet/geändert';
    _this.folderDeleteQuestion = 'Möchten Sie den Ordner wirklich löschen?';
    _this.folderDescription = 'Beschreibung';
    _this.folderName = 'Ordnername';
    _this.folderNameRequired = 'Ordnername wird benötigt';
    _this.folderRoleOrUser = 'Rolle oder Benutzer';
    _this.folderRoleOrUserChangeTitle = 'Benutzer oder Rolle hinzufügen';
    _this.folderTakeOverRights = 'Rechte für Unterordner übernehmen?';
    _this.folderWithNameAlreadyExists = 'Ein Ordner mit dem Namen "{{title}}" existiert bereits im Projekt';
    _this.foldersAndFilesUploadHint = 'Ordner inklusive Unterordner und Dateien hier ablegen';
    _this.forbidden = 'Keine Berechtigung';
    _this.forbiddenCommand = 'Sie haben keine Berechtigung, um die Aktion auszuführen';
    _this.forgotPwCodeCodeTitle = 'Neues Passwort setzen';
    _this.forgotPwCodeSubmit = 'Passwort setzen';
    _this.forgotPwErrorNoSuchAccount = 'Account mit E-Mail-Adresse nicht gefunden';
    _this.forgotPwRegisterNow = 'Jetzt registrieren!';
    _this.forgotPwRequestCode = 'Code anfordern';
    _this.forgotPwSubmit = 'zurücksetzen';
    _this.forgotPwSuccess = 'Sie sollten innerhalb der nächsten Minuten eine E-Mail mit einem Link zur Passwort Wiederherstellung erhalten';
    _this.forgotPwText = 'Geben Sie Ihre E-Mail-Adresse ein. Sie erhalten einen Link per E-Mail, um ein neues Passwort zu setzen.';
    _this.forgotPwTitle = 'Passwort zurücksetzen';
    _this.fromCamera = 'Kamera';
    _this.gdiExportCostCenterInvalid = 'Die Kostenstelle "{{error}}" ist ungültig für einen GDI-Export. (maximal 12 Ziffern)';
    _this.gdiExportCostUnitInvalid = 'Der Kostenträger "{{error}}" ist ungültig für einen GDI-Export. (maximal 12 Stellen)';
    _this.gdiExportWageTypeInvalid = 'Die Lohnartennummer "{{error}}" ist ungültig für einen GDI-Export. (muss im Bereich 1-9899 liegen)';
    _this.gdiExportWorkTypeInvalid = 'Das Tätigkeitskennzeichen "{{error}}" ist ungültig für einen GDI-Export. (maximal 2 Stellen)';
    _this.globalSearch = 'Suche (Strg/⌘ + k)';
    _this.globalSearchLastRequests = 'Letze Suchanfragen';
    _this.globalSearchMobile = 'Suche';
    _this.goToExtraPayAdmin = 'zur Zulagenverwaltung';
    _this.goToHome = 'zur Startseite';
    _this.group = 'Gruppe';
    _this.groupSetDefault = 'Standardgruppe setzen';
    _this.groups = 'Gruppen';
    _this.hideMap = 'Karte verbergen';
    _this.higherClientVersionNeeded = 'Es wird eine höhere Client Version für die Verbindung benötigt';
    _this.holiday = 'Feiertage';
    _this.holidays = 'Feiertage';
    _this.home = 'Startseite';
    _this.homeFirstStepsCreatProjectButton = 'Projekt erstellen';
    _this.hours = 'Stunden';
    _this.id = 'ID';
    _this.imageNotFound = 'Bild nicht verfügbar';
    _this.images = 'Bilder';
    _this.importExcelDialogHeader = 'Tickets importieren';
    _this.importExcelDuplicatesIgnore = 'Tickets überspringen';
    _this.importExcelDuplicatesIgnoreDescription = 'Kein Ticket erstellen, falls die Daten aus der Excel mit dieser Spalte übereinstimmen:';
    _this.importExcelDuplicatesNew = 'Neue Tickets erstellen';
    _this.importExcelDuplicatesNewDescription = 'Alle Excel Elemente als neue Tickets erstellen.';
    _this.importExcelDuplicatesUpdate = 'Tickets aktualisieren';
    _this.importExcelDuplicatesUpdateDescription = 'Ticket Inhalte überschreiben, falls die Daten aus Excel mit dieser Spalte übereinstimmen:';
    _this.importExcelError = 'Es sind Fehler beim Importieren der Tickets aufgetreten';
    _this.importExcelErrorAtLine = 'Fehler in Zeile:';
    _this.importExcelHandleDuplicatesHeader = 'Wie soll mit Duplikaten umgegangen werden?';
    _this.importExcelImport = 'importieren';
    _this.importExcelNoAssignment = 'Keine Zuweisung';
    _this.importExcelNoValue = 'Kein Wert';
    _this.importExcelSelectFile = 'Datei auswählen';
    _this.importExcelSelectFileChange = 'Datei ändern';
    _this.importExcelSuccess = 'Alle Tickets wurden erfolgreich erstellt';
    _this.importExcelTableHeaderExcel = 'Excel-Spalten';
    _this.importExcelTableHeaderForm = 'Formular Attribute';
    _this.importExcelTableHeaderImport = 'Importierte Attribute';
    _this.importExcelTableHeaderPreview = 'Vorschau';
    _this.importExcelTableHeaderTicket = 'Ticketfelder';
    _this.importExcelToolTip = 'Excel-Import';
    _this.importExcelTypeLabel = 'Ticket typ auswählen';
    _this.importList = 'Liste importieren';
    _this.initDatabase = 'Initialisiere Datenbank';
    _this.internalServer = 'Interner Serverfehler';
    _this.invalidDate = 'Ungültiges Datum';
    _this.invitationAccept = 'Annehmen';
    _this.invitationAcceptedLoaded = 'Das Projekt ist jetzt verfügbar!';
    _this.invitationAcceptedLoadedButton = 'Zum Projekt';
    _this.invitationAcceptedLoading = 'Das Projekt wird im Hintergrund vorbereitet...';
    _this.invitationAlreadyAccepted = 'Einladung bereits akzeptiert';
    _this.invitationAlreadyIn = 'Dieser Benutzer ist bereits im Team';
    _this.invitationMessage = '{{addedByUserName}} hat Sie zum Projekt {{currentProjectTitle}} eingeladen';
    _this.invitationReject = 'Ablehnen';
    _this.inviteTeamMember = 'Teammitglied einladen';
    _this.invitedBy = 'Eingeladen von';
    _this.joined = 'Beitritt';
    _this.journal = 'Tagesbericht';
    _this.journalAddAttachment = 'Anhänge hinzufügen';
    _this.journalAddDateInFutureError = ' Das Datum sollte nicht in der Zukunft liegen';
    _this.journalAddNewReceiverMail = '{{email}} hinzufügen';
    _this.journalAdditionalFields = 'Zusätzliche Felder';
    _this.journalAttachments = 'Anhänge';
    _this.journalAttendanceAdd = 'Nachunternehmer hinzufügen';
    _this.journalAttendanceArrival = 'Ankunft';
    _this.journalAttendanceCompany = 'Firma';
    _this.journalAttendanceCompanynameMissing = 'Der Firmenname fehlt bei dem Nachunternehmer-Eintrag {{index}}';
    _this.journalAttendanceDeparture = 'Abfahrt';
    _this.journalAttendanceEmployeeCount = 'Anzahl Mitarbeiter';
    _this.journalAttendanceEmployeeCountToLow = 'Bei dem Nachunternehmer-Eintrag {{index}} muss die Anzahl der Mitarbeiter mindestens 1 betragen';
    _this.journalAttendanceFreeText = 'Freitext';
    _this.journalAttendances = 'Arbeitszeiten Nachunternehmer';
    _this.journalAttendancesNoData = 'Keine Anwesenheitsdaten';
    _this.journalCardsThisWeek = 'Diese Woche';
    _this.journalCreate = 'Bericht erstellen';
    _this.journalCreateVersion = 'Versionieren';
    _this.journalCreateVersionQuestion = 'Möchten Sie eine neue Version des Tagesberichtes erstellen? Das generierte pdf kann danach nicht mehr geändert werden.';
    _this.journalDamage = 'Schadensmeldungen';
    _this.journalDateHasNoJournal = 'Es gibt keinen Tagesbericht für dieses Datum';
    _this.journalDatepickerLabel = 'Berichtszeitpunkt';
    _this.journalDeleteEntry = 'löschen';
    _this.journalDialogHeader = 'Tagesbericht {{date}}';
    _this.journalEmployee = 'Arbeitszeiten eigene Mitarbeiter';
    _this.journalEmployeeAdd = 'Mitarbeiter/Gruppe hinzufügen';
    _this.journalEmployeeGroupCountToLow = 'Bei der Mitarbeitergruppe {{index}} muss die Anzahl der Mitarbeiter mindestens 1 betragen';
    _this.journalEmployeeGroupName = 'Der Mitarbeiter-Gruppenname fehlt bei dem Eintrag {{index}}';
    _this.journalEmployeeName = 'Name';
    _this.journalEmployeeNameMissing = 'Der Mitarbeitername fehlt bei dem Eintrag {{index}}';
    _this.journalEmployeeNoData = 'Keine Anwesenheitsdaten';
    _this.journalEmployees = 'Arbeitszeiten eigene Mitarbeiter';
    _this.journalEmployeesEmployees = 'Mitarbeiter';
    _this.journalEmployeesGroup = 'Gruppe';
    _this.journalEmployeesHours = 'Stunden';
    _this.journalEmployeesHoursAll = 'Stunden [Gesamt]';
    _this.journalEmployeesHoursPerEmployee = 'Stunden pro Mitarbeiter';
    _this.journalEmployeesJob = 'Tätigkeit';
    _this.journalFreeText = 'Freitext';
    _this.journalFrom = 'Tagesbericht vom';
    _this.journalGetAsPDF = 'PDF Vorschau';
    _this.journalHeadline = 'Tagesbericht';
    _this.journalImageAddText = 'Bilder hinzufügen';
    _this.journalImagePerLine = 'Bilder pro Zeile';
    _this.journalImageQuality = 'Fotoauflösung';
    _this.journalImageText = 'Bilder';
    _this.journalImport = 'Importieren';
    _this.journalImportFromOthers = 'Import aus vorherigem Bericht';
    _this.journalImportFromTimetracking = 'Aus Zeiterfassung importieren';
    _this.journalImportFromTimetrackingButton = 'Importieren';
    _this.journalImportFromTimetrackingQuestion = 'Möchten Sie die erfassten Einträge Ihrer Mitarbeiter importieren?';
    _this.journalImportFromTimetrackingResult = 'Es wurden {{inserts}} Einträge importiert und {{updates}} Einträge aktualisiert';
    _this.journalImportFromWeather = 'Import Wetter';
    _this.journalImportFromWeatherAddAddress = 'Möchten Sie jetzt eine Adresse eintragen?';
    _this.journalImportFromWeatherAddressNeeded = 'Es wird eine Projektadresse benötigt';
    _this.journalImportFromWeatherEndTime = 'Endzeit';
    _this.journalImportFromWeatherResult = 'Es wurden {{count}} Wetterdaten importiert';
    _this.journalImportFromWeatherStartTime = 'Startzeit';
    _this.journalMultiDownload = 'Tagesberichte speichern';
    _this.journalMultiDownloadDefaultFilename = 'Tagesberichte_{{from}}_bis_{{to}}';
    _this.journalMultiDownloadDownlaod = 'Speichern';
    _this.journalMultiDownloadFileName = 'Dateiname';
    _this.journalMultiDownloadFinished = 'Gespeichert als {{name}}';
    _this.journalMultiDownloadLastMonth = 'Letzter Monat';
    _this.journalMultiDownloadLastWeek = 'Letzte Woche';
    _this.journalMultiDownloadNoJournalsFound = 'Keine Tagesberichte gefunden';
    _this.journalMultiDownloadSelectRange = 'Zeitraum';
    _this.journalMultiDownloadShowInFolder = 'In Ordner anzeigen';
    _this.journalMultiDownloadThisMonth = 'Dieser Monat';
    _this.journalMultiDownloadThisWeek = 'Diese Woche';
    _this.journalNoReport = 'Es wurde bisher kein Tagesbericht für den {{date}} erstellt.';
    _this.journalNoReportCreateNew = 'Erstellen Sie hier einen neuen Bericht.';
    _this.journalNoReportFuture = 'Sie können nur an Berichte vom aktuellen und vergangenen Tagen erstellen.';
    _this.journalNoTicketsChangedToday = 'Keine geänderten Tickets';
    _this.journalProtocol = 'Tagesberichte';
    _this.journalReceiverSearchOrAddNew = 'Suchen oder E-Mail hinzufügen';
    _this.journalRecipient = 'Empfänger';
    _this.journalSave = 'Entwurf speichern';
    _this.journalSearchJournals = 'Tagesberichte suchen';
    _this.journalSend = 'senden';
    _this.journalSendCancel = 'Nein, danke';
    _this.journalSendNow = 'versenden';
    _this.journalSendQuestion = 'Möchten Sie den Tagesbericht an folgende Empfänger versenden?';
    _this.journalSendTitle = 'Tagesbericht versenden';
    _this.journalSender = 'Versender';
    _this.journalSettings = 'Berichtseinstellungen';
    _this.journalSettingsAdditionalField = 'Feld hinzufügen';
    _this.journalSettingsAdditionalFieldName = 'Name';
    _this.journalSettingsBuilderContent = 'Angezeigter Bauherr';
    _this.journalSettingsBuilderContentHelp = 'Template Variable: {builder}: Bauherr';
    _this.journalSettingsBuilderHeader = 'Bauherr';
    _this.journalSettingsCompanyName = 'Angezeigter Firmenname';
    _this.journalSettingsFieldActivate = 'Einblenden';
    _this.journalSettingsFieldDeactivate = 'Ausblenden';
    _this.journalSettingsFilename = 'Dateiname';
    _this.journalSettingsFilenameHelp = 'Template Variablen: {forDay}: Datum, {project}: Projekttitel';
    _this.journalSettingsHeadlineText = 'Abschnittüberschriften überschreiben';
    _this.journalSettingsHeadlineTextHelper = 'Neu definierte Überschriften werden nur im Ausdruck angezeigt.';
    _this.journalSettingsLogo = 'Berichtslogo';
    _this.journalSettingsLogoButton = 'Berichtslogo auswählen';
    _this.journalSettingsTemplateDefault = 'Standardvorlage';
    _this.journalSettingsTemplateHeader = 'Vorlage';
    _this.journalSettingsTemplateLabel = 'Genutzte Vorlage';
    _this.journalSign = 'Signieren';
    _this.journalSignDesignation = 'Bezeichnung';
    _this.journalSignJournal = 'Tagesbericht signieren';
    _this.journalSignPlace = 'Ort';
    _this.journalSpecialOccurrences = 'Besondere Vorkommnisse';
    _this.journalSpecialOccurrencesNone = 'Keine';
    _this.journalSpecialOccurrencesPlaceholder = 'Behinderungen / Schadensmeldung / Begehungen / Abnahmen';
    _this.journalSpecifics = 'Besonderheiten';
    _this.journalTableNoDataHint = 'Drücken Sie auf den + Button, um einen neuen Tagesbericht zu erstellen';
    _this.journalTableNoDataText = 'Kein Tagesbericht vorhanden';
    _this.journalTableVersions = 'Versionen';
    _this.journalTicketReport = 'Ticketbericht';
    _this.journalTicketReportFilter = 'Ticketfilter setzen';
    _this.journalTicketReportInclude = 'Ticketbericht einbinden';
    _this.journalTicketReportPreview = 'Ticketbericht Vorschau';
    _this.journalTicketReportSettings = 'Ticketbericht Einstellungen';
    _this.journalVersions = 'Bisherige Versionen';
    _this.journalWeather = 'Wetter';
    _this.journalWeatherAdd = 'Wettereintrag hinzufügen';
    _this.journalWeatherAllDay = 'ganztägig';
    _this.journalWeatherClear = 'Sonne';
    _this.journalWeatherCloudy = 'Wolken';
    _this.journalWeatherCloudyWindy = 'Wolken & Wind';
    _this.journalWeatherConditions = 'Wetterlage';
    _this.journalWeatherFog = 'Nebel';
    _this.journalWeatherHail = 'Hagel';
    _this.journalWeatherHighLowerThanLow = 'Bei dem Wettereintrag {{index}} ist die Höchsttemperatur kleiner als die Tiefsttemperatur (tief)';
    _this.journalWeatherLightning = 'Blitze';
    _this.journalWeatherNoData = 'Keine Wetterdaten';
    _this.journalWeatherPartlyCloudy = 'Teils Wolken';
    _this.journalWeatherPouring = 'Starkregen';
    _this.journalWeatherRain = 'Regen';
    _this.journalWeatherSleet = 'Schneeregen';
    _this.journalWeatherSnow = 'Schnee';
    _this.journalWeatherTemperatureHigh = 'Temp (hoch)';
    _this.journalWeatherTemperatureLow = 'Temp (tief)';
    _this.journalWeatherThunderstorm = 'Gewitter';
    _this.journalWeatherTime = 'Uhrzeit';
    _this.journalWeatherWind = 'Wind';
    _this.journalWorkDone = 'Durchgeführte Arbeiten';
    _this.keepBasicSubscription = 'Basic bleiben';
    _this.keepEnterpriseSubscription = 'Enterprise bleiben';
    _this.keepProSubscription = 'Pro bleiben';
    _this.kiActionCreateTicket = 'Ticket erstellen';
    _this.kiActionCreateTicketSuccess = 'Ihr ticket wurde erstellt öffnen';
    _this.kiActionHelper = 'Beschreibe das Ticket, das du erstellen möchtest';
    _this.kiActionLoading = 'Antwort wird geladen...';
    _this.kiActionNotFound = 'Es wurde keine passende Aktion für ihre Anfrage gefunden.';
    _this.kiDialogTitle = '🤖 meshy - AI Assistent';
    _this.kiFabTitle = 'meshy - AI Assistent öffnen';
    _this.language = 'Sprache';
    _this.lastMonth = 'letzten Monat';
    _this.lastName = 'Nachname';
    _this.lastTimeActive = 'Zuletzt aktiv';
    _this.lastWeek = 'letzte Woche';
    _this.latitude = 'Breitengrad';
    _this.linkTokenExpired = 'Der Link ist nicht mehr gültig';
    _this.listCopyFromEmptyState = 'Keine Liste in den Firmeneinstellungen hinterlegt.';
    _this.listCopyFromTitle = 'Wählen Sie eine Liste zum Kopieren aus.';
    _this.listsTitle = 'Listen';
    _this.loading = 'wird geladen...';
    _this.loadingTicketReport = 'Lade Ticketbericht';
    _this.logBook = 'Aktivitäten';
    _this.logBookAbsenceApproved = '{{creatorName}} hat die Abwesenheit genehmigt';
    _this.logBookAbsenceApproved_me = 'Sie haben die Abwesenheit genehmigt';
    _this.logBookAbsenceChanged = '{{creatorName}} hat eine Abwesenheit geändert';
    _this.logBookAbsenceChanged_me = 'Sie haben eine Abwesenheit geändert';
    _this.logBookAbsenceCreated = '{{creatorName}} hat eine Abwesenheit beantragt';
    _this.logBookAbsenceCreated_me = 'Sie haben eine Abwesenheit beantragt';
    _this.logBookAbsenceRejected = '{{creatorName}} hat die Abwesenheit abgelehnt';
    _this.logBookAbsenceRejected_me = 'Sie haben die Abwesenheit abgelehnt';
    _this.logBookCopyProject = 'Das Projekt wurde durch {{creatorName}} kopiert';
    _this.logBookEntityAbsence = 'Abwesenheit';
    _this.logBookEntityPlan = 'Plan';
    _this.logBookEntityPlanVersion = 'Planversion';
    _this.logBookEntityTicket = 'Ticket';
    _this.logBookEntityTicketComment = 'Ticket';
    _this.logBookFolderChanged = '{{creatorName}} hat den Ordner geändert';
    _this.logBookFolderChanged_me = 'Sie haben den Ordner geändert';
    _this.logBookFolderCreated = '{{creatorName}} hat den Ordner "{{title}}" erstellt';
    _this.logBookFolderCreated_me = 'Sie haben den Ordner "{{title}}" erstellt';
    _this.logBookFolderDeleted = '{{creatorName}} hat den Ordner "{{entityTitle}}" gelöscht';
    _this.logBookFolderDeleted_me = 'Sie haben den Ordner "{{entityTitle}}" gelöscht';
    _this.logBookFolderMoved = '{{creatorName}} hat den Ordner verschoben';
    _this.logBookFolderMoved_me = 'Sie haben den Ordner verschoben';
    _this.logBookInDashboardOuterTicket = '{{inner}}, im Ticket "{{entityTitle}}"';
    _this.logBookInMultiOuter = '{{inner}} im Projekt "{{projectTitle}}"';
    _this.logBookPlanChanged = '{{creatorName}} hat die Plandaten geändert';
    _this.logBookPlanChanged_me = 'Sie haben die Plandaten geändert';
    _this.logBookPlanCreated = '{{creatorName}} hat einen neuen Plan erstellt';
    _this.logBookPlanCreated_me = 'Sie haben einen neuen Plan erstellt';
    _this.logBookPlanDeleted = '{{creatorName}} hat den Plan {{plantitle}} gelöscht';
    _this.logBookPlanDeleted_me = 'Sie haben den Plan {{plantitle}} gelöscht';
    _this.logBookPlanVersionActivated = '{{creatorName}} hat eine neue Planversion aktiviert';
    _this.logBookPlanVersionActivated_me = 'Sie haben eine neue Planversion aktiviert';
    _this.logBookPlanVersionCreated = '{{creatorName}} hat eine neue Planversion erstellt';
    _this.logBookPlanVersionCreated_me = 'Sie haben eine neue Planversion erstellt';
    _this.logBookPlanVersionDeleted = '{{creatorName}} hat die Planversion {{version}} gelöscht';
    _this.logBookPlanVersionDeleted_me = 'Sie haben die Planversion {{version}} gelöscht';
    _this.logBookProjectAddressChanged = '{{creatorName}} hat den Projektkontakt geändert: {{changes}}';
    _this.logBookProjectAddressChanged_me = 'Sie haben den Projektkontakt geändert: {{changes}}';
    _this.logBookProjectAddressCreated = '{{creatorName}} hat den Projektkontakt hinzugefügt: {{changes}}';
    _this.logBookProjectAddressCreated_me = 'Sie haben den Projektkontakt hinzugefügt: {{changes}}';
    _this.logBookProjectArchivingChanged = 'Das Projekt wurde archiviert/reaktiviert';
    _this.logBookProjectArchivingChanged_false = 'Das Projekt wurde reaktiviert';
    _this.logBookProjectArchivingChanged_true = 'Das Projekt wurde archiviert';
    _this.logBookProjectChanged = '{{creatorName}} hat die Projektdaten geändert';
    _this.logBookProjectChanged_me = 'Sie haben die Projektdaten geändert';
    _this.logBookProjectCopied = 'Das Projekt "{{projectTitle}}" wurde erfolgreich von "{{originTitle}}" kopiert';
    _this.logBookProjectCreated = 'Das Projekt wurde von {{creatorName}} erstellt';
    _this.logBookProjectCreated_me = 'Sie haben das Projekt erstellt';
    _this.logBookProjectDeletionChanged = 'Das Projekt wurde gelöscht';
    _this.logBookProjectDeletionChanged_false = 'Das Projekt wurde wiederhergestellt';
    _this.logBookProjectDeletionChanged_true = 'Das Projekt wurde gelöscht';
    _this.logBookProjectOwnerChanged = 'Das Projekt wurde von {{oldOwnerName}} an {{newOwnerName}} übergeben';
    _this.logBookProjectOwnerChanged_me = 'Das Projekt wurde von Ihnen an {{newOwnerName}} übergeben';
    _this.logBookProjectRoleCreated = '{{creatorName}} hat die Rolle "{{roleName}}" erstellt';
    _this.logBookProjectRoleCreated_me = 'Sie haben die Rolle "{{roleName}}" erstellt';
    _this.logBookProjectSubscriptionChanged = '{{creatorName}} hat das Projekt Abonnement auf {{projectSubscription}} geändert';
    _this.logBookProjectSubscriptionChanged_me = 'Sie haben das Projekt Abonnement auf {{projectSubscription}} geändert';
    _this.logBookProjectUserAccepted = '{{creatorName}} hat die Einladung angenommen';
    _this.logBookProjectUserAccepted_me = 'Sie haben die Einladung angenommen';
    _this.logBookProjectUserAdded = '{{email}} wurde von {{creatorName}} eingeladen';
    _this.logBookProjectUserAddedHimself = '{{creatorName}} hat sich zum Projekt hinzugefügt';
    _this.logBookProjectUserAddedHimself_me = 'Sie haben sich zum Projekt hinzugefügt';
    _this.logBookProjectUserAdded_me = 'Sie haben {{email}} eingeladen';
    _this.logBookProjectUserRemoved = '{{creatorName}} hat {{userName}} aus dem Projekt entfernt';
    _this.logBookProjectUserRemovedHimself = '{{creatorName}} hat das Projekt verlassen';
    _this.logBookProjectUserRemovedHimself_me = 'Sie haben das Projekt verlassen';
    _this.logBookProjectUserRemoved_me = 'Sie haben {{userName}} aus dem Projekt entfernt';
    _this.logBookProjectUserRoleChanged = '{{creatorName}} hat die Rolle von {{userName}} in {{roleName}} geändert';
    _this.logBookProjectUserRoleChanged_me = 'Sie haben die Rolle von {{userName}} in {{roleName}} geändert';
    _this.logBookShowMoreActivities = 'Mehr anzeigen';
    _this.logBookStoredFileAdded = '{{creatorName}} hat die Datei "{{filename}}" in den Ordner "Projektablage" hochgeladen';
    _this.logBookStoredFileAdded_folder = '{{creatorName}} hat die Datei "{{filename}}" in den Ordner "{{folderName}}" hochgeladen';
    _this.logBookStoredFileAdded_me = 'Sie haben die Datei "{{filename}}" in den Ordner "Projektablage" hochgeladen';
    _this.logBookStoredFileAdded_mefolder = 'Sie haben die Datei "{{filename}}" in den Ordner "{{folderName}}" hochgeladen';
    _this.logBookStoredFileAdded_meticket = 'Sie haben die Datei "{{filename}}" ins Ticket hochgeladen';
    _this.logBookStoredFileAdded_ticket = '{{creatorName}} hat die Datei "{{filename}}" ins Ticket hochgeladen';
    _this.logBookStoredFileChanged = '{{creatorName}} hat die Datei "{{filename}}" im Ordner "Projektablage" geändert';
    _this.logBookStoredFileChanged_folder = '{{creatorName}} hat die Datei "{{filename}}" im Ordner "{{folderName}}" geändert';
    _this.logBookStoredFileChanged_me = 'Sie haben die Datei "{{filename}}" im Ordner "Projektablage" geändert';
    _this.logBookStoredFileChanged_mefolder = 'Sie haben die Datei "{{filename}}" im Ordner "{{folderName}}" geändert';
    _this.logBookStoredFileChanged_meticket = 'Sie haben die Datei "{{filename}}" im Ticket geändert';
    _this.logBookStoredFileChanged_ticket = '{{creatorName}} hat die Datei "{{filename}}" im Ticket geändert';
    _this.logBookStoredFileDeleted = '{{creatorName}} hat die Datei "{{filename}}" aus dem Ordner "Projektablage" entfernt';
    _this.logBookStoredFileDeleted_folder = '{{creatorName}} hat die Datei "{{filename}}" aus dem Ordner "{{folderName}}" entfernt';
    _this.logBookStoredFileDeleted_me = 'Sie haben die Datei "{{filename}}" aus dem Ordner "Projektablage" entfernt';
    _this.logBookStoredFileDeleted_mefolder = 'Sie haben die Datei "{{filename}}" aus dem Ordner "{{folderName}}" entfernt';
    _this.logBookStoredFileDeleted_meticket = 'Sie haben die Datei "{{filename}}" aus dem Ticket entfernt';
    _this.logBookStoredFileDeleted_ticket = '{{creatorName}} hat die Datei "{{filename}}" aus dem Ticket entfernt';
    _this.logBookStoredFilenameChanged = '{{userName}} hat die Datei "{{oldFilename}}" in der "Projektablage" in "{{filename}}" umbenannt.';
    _this.logBookStoredFilenameChanged_folder = '{{userName}} hat die Datei "{{oldFilename}}" im Ordner "{{folderName}}" in "{{filename}}" umbenannt.';
    _this.logBookStoredFilenameChanged_me = 'Sie haben die Datei "{{oldFilename}}" in der "Projektablage" in "{{filename}}" umbenannt';
    _this.logBookStoredFilenameChanged_mefolder = 'Sie haben die Datei "{{oldFilename}}" im Ordner "{{folderName}}" in "{{filename}}" umbenannt';
    _this.logBookStoredFilenameChanged_meticket = 'Sie haben die Datei "{{oldFilename}}" im Ticket in "{{filename}}" umbenannt';
    _this.logBookStoredFilenameChanged_ticket = '{{userName}} hat die Datei "{{oldFilename}}" im Ticket in "{{filename}}" umbenannt.';
    _this.logBookTicketApproverChanged = '{{creatorName}} hat als Prüfer {{approver}} gesetzt';
    _this.logBookTicketApproverChanged_himself = '{{creatorName}} hat sich als Prüfer gesetzt';
    _this.logBookTicketApproverChanged_me = 'Sie haben als Prüfer {{approver}} gesetzt';
    _this.logBookTicketApproverChanged_meyou = 'Sie haben sich als Prüfer gesetzt';
    _this.logBookTicketApproverChanged_you = '{{creatorName}} hat Sie als Prüfer gesetzt';
    _this.logBookTicketApproverRemoved = '{{creatorName}} hat den Prüfer {{removedUser}} entfernt';
    _this.logBookTicketApproverRemoved_himself = '{{creatorName}} hat sich als Prüfer entfernt';
    _this.logBookTicketApproverRemoved_me = 'Sie haben den Prüfer {{removedUser}} entfernt';
    _this.logBookTicketApproverRemoved_meyou = 'Sie haben sich als Prüfer entfernt';
    _this.logBookTicketApproverRemoved_you = '{{creatorName}} hat Sie als Prüfer entfernt';
    _this.logBookTicketArchiveStatusChanged = '{{creatorName}} hat das Ticket archiviert/reaktiviert';
    _this.logBookTicketArchiveStatusChanged_false = '{{creatorName}} hat das Ticket reaktiviert';
    _this.logBookTicketArchiveStatusChanged_falseme = 'Sie haben das Ticket reaktiviert';
    _this.logBookTicketArchiveStatusChanged_true = '{{creatorName}} hat das Ticket archiviert';
    _this.logBookTicketArchiveStatusChanged_trueme = 'Sie haben das Ticket archiviert';
    _this.logBookTicketAssignedChanged = '{{creatorName}} hat das Ticket {{assigner}} zugewiesen';
    _this.logBookTicketAssignedChanged_himself = '{{creatorName}} hat sich das Ticket zugewiesen';
    _this.logBookTicketAssignedChanged_me = 'Sie haben das Ticket {{assigner}} zugewiesen';
    _this.logBookTicketAssignedChanged_meyou = 'Sie haben sich das Ticket zugewiesen';
    _this.logBookTicketAssignedChanged_you = '{{creatorName}} hat Ihnen das Ticket zugewiesen';
    _this.logBookTicketAssignerRemoved = '{{creatorName}} hat den Zugewiesenen {{removedUser}} entfernt';
    _this.logBookTicketAssignerRemoved_himself = '{{creatorName}} hat sich als Zugewiesenen entfernt';
    _this.logBookTicketAssignerRemoved_me = 'Sie haben den Zugewiesenen {{removedUser}} entfernt';
    _this.logBookTicketAssignerRemoved_meyou = 'Sie haben sich als Zugewiesenen entfernt';
    _this.logBookTicketAssignerRemoved_you = '{{creatorName}} hat Sie als Zugewiesenen entfernt';
    _this.logBookTicketCommentAdded = '{{creatorName}} hat das Ticket kommentiert: "{{comment}}"';
    _this.logBookTicketCommentAddedFile = '{{creatorName}} hat dem Ticket eine Datei hinzugefügt';
    _this.logBookTicketCommentAddedFile_me = 'Sie haben dem Ticket eine Datei hinzugefügt';
    _this.logBookTicketCommentAddedPicture = '{{creatorName}} hat dem Ticket ein Bild hinzugefügt';
    _this.logBookTicketCommentAddedPicture_me = 'Sie haben dem Ticket ein Bild hinzugefügt';
    _this.logBookTicketCommentAdded_me = 'Sie haben das Ticket kommentiert: "{{comment}}"';
    _this.logBookTicketCommentChanged = '{{creatorName}} hat seinen Ticketkommentar geändert: "{{comment}}"';
    _this.logBookTicketCommentChanged_me = 'Sie haben Ihren Ticketkommentar geändert: "{{comment}}"';
    _this.logBookTicketCommentFileAdded = 'Datei wurde dem Ticket hinzugefügt';
    _this.logBookTicketCompletionOnChanged = '{{creatorName}} hat das Fälligkeitsdatum auf {{date}} geändert';
    _this.logBookTicketCompletionOnChanged_me = 'Sie haben das Fälligkeitsdatum auf {{date}} geändert';
    _this.logBookTicketCompletionOnRemoved = '{{creatorName}} hat das Fälligkeitsdatum entfernt';
    _this.logBookTicketCompletionOnRemoved_me = 'Sie haben das Fälligkeitsdatum entfernt';
    _this.logBookTicketCraftChanged = '{{creatorName}} hat das Gewerk in "{{craft}}" geändert';
    _this.logBookTicketCraftChanged_me = 'Sie haben das Gewerk in "{{craft}}" geändert';
    _this.logBookTicketCraftRemoved = '{{creatorName}} hat das Gewerk entfernt';
    _this.logBookTicketCraftRemoved_me = 'Sie haben das Gewerk entfernt';
    _this.logBookTicketCreated = '{{creatorName}} hat das Ticket erstellt';
    _this.logBookTicketCreated_me = 'Sie haben das Ticket erstellt';
    _this.logBookTicketCustomFieldChanged = '{{creatorName}} hat das Feld "{{name}}" in "{{formattedValue}}" geändert';
    _this.logBookTicketCustomFieldChanged_me = 'Sie haben das Feld "{{name}}" in "{{formattedValue}}" geändert';
    _this.logBookTicketCustomFieldRemoved = '{{creatorName}} hat das Feld "{{name}}" zurückgesetzt';
    _this.logBookTicketCustomFieldRemoved_me = 'Sie haben das Feld "{{name}}" zurückgesetzt';
    _this.logBookTicketDefault = '{{creatorName}} hat etwas geändert (fehlender Text für: {{eventName}})';
    _this.logBookTicketDefault_me = 'Sie haben etwas geändert (fehlender Text für: {{eventName}})';
    _this.logBookTicketDeleteStatusChanged = '{{creatorName}} hat das Ticket gelöscht';
    _this.logBookTicketDeleteStatusChanged_false = '{{creatorName}} hat das Ticket wiederhergestellt';
    _this.logBookTicketDeleteStatusChanged_falseme = 'Sie haben das Ticket wiederhergestellt';
    _this.logBookTicketDeleteStatusChanged_trueme = 'Sie haben das Ticket gelöscht';
    _this.logBookTicketDescriptionChanged = '{{creatorName}} hat die Beschreibung in "{{description}}" geändert';
    _this.logBookTicketDescriptionChanged_me = 'Sie haben die Beschreibung in "{{description}}" geändert';
    _this.logBookTicketExpired = 'Das Ticket "{{entityTitle}}" ist heute fällig';
    _this.logBookTicketNumberChanged = 'Die Nummer "{{ticket.ticketNumber}}" wurde zugewiesen';
    _this.logBookTicketPlanAffiliationChanged = '{{creatorName}} hat das Ticket auf den Plan {{planTitle}} verschoben';
    _this.logBookTicketPlanAffiliationChanged_me = 'Sie haben das Ticket auf den Plan {{planTitle}} verschoben';
    _this.logBookTicketPlanAffiliationRemoved = '{{creatorName}} hat die Planzugehörigkeit entfernt';
    _this.logBookTicketPlanAffiliationRemoved_me = 'Sie haben die Planzugehörigkeit entfernt';
    _this.logBookTicketPlanPositionChanged = '{{creatorName}} hat die Position auf dem Plan geändert';
    _this.logBookTicketPlanPositionChanged_me = 'Sie haben die Position auf dem Plan geändert';
    _this.logBookTicketStatusChanged = '{{creatorName}} hat den Status in "{{status}}" geändert';
    _this.logBookTicketStatusChanged_me = 'Sie haben den Status in "{{status}}" geändert';
    _this.logBookTicketSubTicketAdded = '{{creatorName}} hat das Sub-Ticket "{{subTicketFromTitle}}" hinzugefügt';
    _this.logBookTicketSubTicketAdded_me = 'Sie haben das Sub-Ticket "{{subTicketFromTitle}}" hinzugefügt';
    _this.logBookTicketSubTicketFromChanged = '{{creatorName}} hat das übergeordnete Ticket in "{{subTicketFromTitle}}" geändert';
    _this.logBookTicketSubTicketFromChanged_me = 'Sie haben das übergeordnete Ticket in "{{subTicketFromTitle}}" geändert';
    _this.logBookTicketSubTicketFromRemoved = '{{creatorName}} hat das übergeordnete Ticket "{{subTicketFromTitle}}" entfernt';
    _this.logBookTicketSubTicketFromRemoved_me = 'Sie haben das übergeordnete Ticket "{{subTicketFromTitle}}" entfernt';
    _this.logBookTicketSubTicketRemoved = '{{creatorName}} hat das Sub-Ticket "{{subTicketFromTitle}}" entfernt';
    _this.logBookTicketSubTicketRemoved_me = 'Sie haben das Sub-Ticket "{{subTicketFromTitle}}" entfernt';
    _this.logBookTicketTagAdded = '{{creatorName}} hat das Tag "{{tag.tagName}}" hinzugefügt';
    _this.logBookTicketTagAdded_me = 'Sie haben das Tag "{{tag.tagName}}" hinzugefügt';
    _this.logBookTicketTagRemoved = '{{creatorName}} hat das Tag "{{tag.tagName}}" entfernt';
    _this.logBookTicketTagRemoved_me = 'Sie haben das Tag "{{tag.tagName}}" entfernt';
    _this.logBookTicketTagsChanged = '{{creatorName}} hat die Tags geändert';
    _this.logBookTicketTagsChanged_me = 'Sie haben die Tags geändert';
    _this.logBookTicketTitleChanged = '{{creatorName}} hat den Titel in "{{title}}" geändert';
    _this.logBookTicketTitleChanged_me = 'Sie haben den Titel in "{{title}}" geändert';
    _this.logBookTicketTypeChanged = '{{creatorName}} hat den Typen in "{{type}}" geändert';
    _this.logBookTicketTypeChanged_me = 'Sie haben den Typen in "{{type}}" geändert';
    _this.logBookTicketTypeRemoved = '{{creatorName}} hat den Typen entfernt';
    _this.logBookTicketTypeRemoved_me = 'Sie haben den Typen entfernt';
    _this.logBookTicketWatcherAdded = '{{creatorName}} hat den Beobachter "{{watcher}}" hinzugefügt';
    _this.logBookTicketWatcherAdded_himself = '{{creatorName}} hat sich als Beobachter hinzugefügt';
    _this.logBookTicketWatcherAdded_me = 'Sie haben den Beobachter "{{watcher}}" hinzugefügt';
    _this.logBookTicketWatcherAdded_meyou = 'Sie haben sich als Beobachter hinzugefügt';
    _this.logBookTicketWatcherAdded_you = '{{creatorName}} hat Sie als Beobachter hinzugefügt';
    _this.logBookTicketWatcherRemoved = '{{creatorName}} hat den Beobachter "{{watcher}}" entfernt';
    _this.logBookTicketWatcherRemoved_himself = '{{creatorName}} hat sich als Beobachter entfernt';
    _this.logBookTicketWatcherRemoved_me = 'Sie haben den Beobachter "{{watcher}}" entfernt';
    _this.logBookTicketWatcherRemoved_meyou = 'Sie haben sich als Beobachter entfernt';
    _this.logBookTicketWatcherRemoved_you = '{{creatorName}} hat Sie als Beobachter entfernt';
    _this.logBookUserInvitationEmailRequested = 'An {{toEmail}} wurde erneut eine Einladungsemail gesendet';
    _this.login = 'anmelden';
    _this.loginError = 'Fehlerhafte Zugangsdaten';
    _this.loginErrorEmailNotValidated = 'Ihre E-Mail-Adresse ist noch nicht verifiziert. Sie sollten in Kürze eine E-Mail erhalten. Bitte bestätigen Sie' + ' zunächst den Link in der E-Mail, um sich wieder einloggen zu können.';
    _this.loginErrorNoAccount = 'Account nicht vorhanden, jetzt Registrieren?';
    _this.loginErrorNoAccountGoogle = 'Bereits registriert? Dann melden Sie sich jetzt an und verbinden Sie unter Mein Konto Ihren Google Account mit Ihrem upmesh Konto.';
    _this.loginErrorNoAccountGoogleSub = 'Sie sind neu hier, dann registrieren Sie sich jetzt mit Ihrem Google Account!';
    _this.loginErrorNoAccountGoogleTitle = 'Google Account noch nicht verbunden';
    _this.loginErrorNoAccountRegister = 'Registrieren';
    _this.loginErrorNoGoogle = 'Google Login nicht möglich';
    _this.loginErrorWrongCredentials = 'Fehlerhafte Zugangsdaten';
    _this.loginForgotPw = 'Passwort vergessen?';
    _this.loginInfo = 'Indem Sie auf "anmelden" oder "registrieren" tippen, stimmen Sie den Nutzungsbedingungen und Zahlungsbedingungen von upmesh zu.';
    _this.loginOffline = 'Sie benötigen eine Internetverbindung, um sich anzumelden';
    _this.loginRegister = 'Registrieren';
    _this.loginTitle = 'Anmelden';
    _this.loginWithGoogle = 'Mit Google anmelden';
    _this.longitude = 'Längengrad';
    _this.maxLicensesReached = 'Alle Lizenzen im Einsatz';
    _this.maxLicensesReachedText = 'Sie benötigen weitere Lizenzen, um weitere Benutzer hinzuzufügen. Bitte kontaktieren Sie' + ' unseren Support unter 05451-59333';
    _this.maxProjectsForFree = 'Maximale Anzahl kostenloser Projekte erreicht, jetzt zur Pro Version wechseln!';
    _this.maxSizeReached = 'Maximale Projektgröße erreicht';
    _this.maxSizeReachedText = 'Das Projekt hat den maximalen Speicherplatz erreicht. Ab sofort können Sie nur noch' + ' Inhalte lesen. Jetzt upgraden und auf noch mehr Funktionen und einen erweiterten Datenspeicher freuen.';
    _this.maxSizeReachedUpgradeButton = 'Jetzt upgraden';
    _this.maxSmallerThanMin = 'Maximum darf nicht kleiner sein als Minimum';
    _this.memberId = 'Mitarbeiter';
    _this.members = 'Mitglieder';
    _this.menuProject = 'Dashboard';
    _this.menuProjectDailyReports = 'Tagesberichte';
    _this.menuProjectFiles = 'Dateien';
    _this.menuProjectInvolved = 'Team';
    _this.menuProjectPlans = 'Pläne';
    _this.menuTickets = 'Tickets';
    _this.methodNotAllowed = 'Methode nicht erlaubt';
    _this.minutes = 'Minuten';
    _this.minutesShort = 'min';
    _this.moduleTimeTrackingJournalEdit = 'Zeiterfasser (alt, mit Tagesbericht)';
    _this.month = 'Monat';
    _this.more = 'mehr';
    _this.moveFile = 'Datei verschieben';
    _this.moveFileToTicket = 'Zu Ticket hinzufügen';
    _this.moveFileToPlan = 'Zu Pläne hinzufügen';
    _this.moveFolder = 'Ordner verschieben';
    _this.moveFolderTo = `Ordner "{{folderName}}" verschieben nach:`;
    _this.moveFolderToQuestion = `Möchten Sie den Ordner "{{folderName}}" nach "{{targetFolderName}}" verschieben?`;
    _this.multiTabError = 'upmesh ist bereits in einem anderen Tab oder Fenster geöffnet';
    _this.name = 'Name';
    _this.networkbluetooth = 'Bluetooth';
    _this.networkbvpn = 'VPN';
    _this.networkcellular = 'Mobile Daten';
    _this.networkethernet = 'Ethernet';
    _this.networknone = 'Keine';
    _this.networkother = 'Andere';
    _this.networkunknown = 'Unbekannt';
    _this.networkwifi = 'WLAN';
    _this.networkwimax = 'Wimax';
    _this.newPlanVersion = 'als neue Version hinzuügen';
    _this.next = 'weiter';
    _this.nextWeek = 'Nächste Woche';
    _this.no = 'Nein';
    _this.noActivities = 'Noch keine Aktivitäten';
    _this.noActivitiesOffline = 'Aktivitäten stehen offline nicht zur Verfügung';
    _this.noCompany = 'Keine Firma';
    _this.noEntriesFound = 'Keine Einträge gefunden';
    _this.noExtraPayTrackingFound = 'Keine Zulagen gefunden';
    _this.noHigherRightThanBaseRole = 'Die Rolle darf nicht mehr Rechte als die Basisrolle haben';
    _this.noImages = 'Keine Bilder vorhanden';
    _this.noPlan = 'Kein Plan';
    _this.noPlanAffiliation = 'Keinem Plan zugeordnet';
    _this.noProjectsFound = 'Es wurde kein Projekt gefunden.';
    _this.noRightToAddFile = 'Keine Berechtigung, die Datei hinzuzufügen!';
    _this.noRightToEditFile = 'Keine Berechtigung, die Datei zu editieren!';
    _this.noRightToEditFile24h = 'Keine Berechtigung mehr, die Datei zu editieren! (24 Stunden Limit)';
    _this.noRightToRemoveFile = ' Keine Berechtigung, die Datei zu entfernen!';
    _this.noTabConnection = 'Es konnte keine Verbindung zum Haupttab hergestellt werden, Serververbindung verloren';
    _this.noTargetFolderFound = 'Keine möglichen Zielordner gefunden';
    _this.noTicketsAvailable = 'Keine Tickets verfügbar';
    _this.noTimeTrackingRecordsForDay = 'Fehlende Erfassung';
    _this.notEnoughLicenses = 'Es sind nicht ausreichend Lizenzen verfügbar';
    _this.notForFree = 'Diese Funktion ist in Basic-Projekten nicht verfügbar';
    _this.notFound = 'Die angeforderte Seite wurde nicht gefunden';
    _this.notFoundSite = 'Die angeforderte Seite "{site}" wurde nicht gefunden';
    _this.notInBasicProject = 'Diese Funktion ist in Basic-Projekten nicht verfügbar';
    _this.note = 'Notiz';
    _this.notes = 'Notizen';
    _this.notesAddCheckbox = 'Checkbox hinzufügen';
    _this.notesAddNote = 'Klicken Sie, um eine Notiz hinzuzufügen';
    _this.notesAddNoteToProject = 'Klicken Sie, um eine Notiz zum Projekt {{projectName}} hinzuzufügen';
    _this.notesNoExistingNotes = 'Klicken Sie, um ihre erste Notiz zu erstellen';
    _this.notesNoNotesInProject = 'In dem Projekt {{projectName}} gibt es noch keine Notiz. Klicken Sie, um dort eine zu erstellen';
    _this.notesTypeHere = 'Hier tippen...';
    _this.notificationsErrorMessage = 'Die Benachrichtigungseinstellungen konnten nicht gespeichert werden';
    _this.notificationsEverythingReadHeader = 'Alles erledigt!';
    _this.notificationsEverythingReadSubline = 'Sie sind auf dem neuesten Stand.';
    _this.notificationsMarkAllAsRead = 'alle als gelesen markieren';
    _this.notificationsMarkAsNotRead = 'als ungelesen markieren';
    _this.notificationsMarkAsRead = 'als gelesen markieren';
    _this.notificationsOfflineMessage = 'Sie sind mit keinem Server verbunden. Offline können keine Änderungen vorgenommen werden.';
    _this.notificationsRead = 'gelesen';
    _this.notificationsSettings = 'Benachrichtigungseinstellungen';
    _this.notificationsSettingsAutoAddNew = 'Neue automatisch aktivieren';
    _this.notificationsShowOnlyUnread = 'nur ungelesene';
    _this.novalineExportCostCenterInvalid = 'Die Kostenstelle "{{error}}" ist nicht gültig für einen Novaline-Export. (maximal 15 Stellen)';
    _this.novalineExportCostUnitAsCostCenter = 'Kostenträger als Kostenstellen exportieren.';
    _this.novalineExportCostUnitInvalid = 'Der Kostenträger "{{error}}" ist ungültig für einen Novaline-Export. (maximal 15 Stellen)';
    _this.novalineExportKundenNummerInvalid = 'Die Kunden-Nr. "{{error}}" ist ungültig für einen Novaline-Export. (maximal 8 Ziffern).';
    _this.novalineExportKundenNummerNeeded = 'Für den Novaline-Export wird eine Kunden-Nr. benötigt.';
    _this.novalineExportMandantNumberInvalid = 'Die Mandant-Nr. "{{error}}" ist ungültig für einen Novaline-Export. (maximal 8 Ziffern).';
    _this.novalineExportMandantNumberNeeded = 'Für den Novaline-Export wird eine Mandant-Nr. benötigt.';
    _this.novalineExportWageTypeInvalid = 'Die Lohnart "{{error}}" ist ungültig für einen Novaline-Export. (maximal 8 Ziffern)';
    _this.novalineExportWorkTypeInvalid = 'Die Arbeitsart {{error}} ist ungültig für einen Novaline-Export. (maximal 2 Stellen)';
    _this.numberToBig = 'Der Wert ist zu groß, maximum {{value}}';
    _this.numberToSmall = 'Der Wert ist zu klein, minimum {{value}}';
    _this.oClock = 'Uhr';
    _this.offline = 'Offline';
    _this.offlineNotAvailable = 'Offline nicht verfügbar';
    _this.offlineSettingsZoomLevel = 'Bis Zoomstufe';
    _this.ok = 'ok';
    _this.openFile = 'Datei wurde unter "{{target}}" gespeichert.\nMöchten Sie die Datei jetzt öffnen?';
    _this.openFolder = 'Ordner öffnen';
    _this.openJournal = 'Tagesbericht öffnen';
    _this.openLinkInThisTab = 'Diesen Tab zum Haupttab machen';
    _this.openMenu = 'Menu öffnen';
    _this.openTicket = 'Ticket öffnen';
    _this.or = 'oder';
    _this.overrideApprovalRequired = 'Bestätigung zum überschreiben benötigt';
    _this.ownerChangeConfirmation = 'Die Übertragung des Projektes muss bestätigt werden.';
    _this.ownerChangeMaxFreeProjects = 'Das Basis Mitglied ist bereits Eigentümer von 5 Projekten. Mehr als 5 Projekte sind ab der Pro Version möglich';
    _this.ownerChangeProProjectOnlyToProUser = 'Ein Pro Projekt kann nur einem Mitglied mit einem Pro Account übergeben werden.';
    _this.password = 'Passwort';
    _this.pause = 'Pause';
    _this.paymentMethod = 'Zahlungsweise';
    _this.payroll = 'Lohnabrechner';
    _this.pdfHasTooManyPages = 'Der Plan konnte nicht hochgeladen werden. Es sind maximal 50 Seiten pro pdf erlaubt';
    _this.pdfSaveChangesQuestionText = 'Sie können wählen, ob die vorhandene Datei ersetzt wird oder eine Kopie mit dem neuen' + ' Inhalt erstellt werden soll.';
    _this.pdfSaveChangesQuestionTitle = 'Möchten Sie die Änderungen speichern?';
    _this.phone = 'Telefon';
    _this.plan = 'Plan';
    _this.planActivate = 'aktivieren';
    _this.planActivateQuestion = 'Planversion zur aktiven Version machen?';
    _this.planCurrentVersionNotActiveOne = 'Die neueste Planversion ist nicht aktiviert';
    _this.planDeleteQuestionTextWithTickets = 'Wenn Sie diesen Plan löschen, verlieren die zugeordneten Tickets ihre Planzugehörigkeit. Alle Planversionen werden ebenfalls gelöscht.';
    _this.planDeleteQuestionTextWithoutTickets = 'Möchten Sie diesen Plan löschen? Alle Versionen werden ebenfalls gelöscht.';
    _this.planDeleteQuestionTitle = 'Löschen bestätigen {{plantitle}}';
    _this.planDetailChange = 'Plandetails geändert';
    _this.planDetails = 'Details';
    _this.planDetailsPlanActivePlan = 'Aktive Planversion';
    _this.planDetailsPlanNotes = 'Anmerkungen';
    _this.planDetailsPlanTags = 'Tags';
    _this.planDetailsPlanTitle = 'Plantitel';
    _this.planDetailsViewChangeToTiles = 'Zur Kachelansicht wechseln';
    _this.planEditTitleExists = 'Der Plantitel existiert bereits';
    _this.planFiles = 'Plandateien';
    _this.planFilterAddTeammember = 'Hinzufügen';
    _this.planFilterHeader = 'Pläne filtern';
    _this.planFilterPersons = 'Ersteller';
    _this.planFilterTags = 'Tags';
    _this.planFilterTimePeriod = 'Erstellungszeitraum';
    _this.planNotFound = 'Dieser Plan existiert nicht (mehr)';
    _this.planSiteReleasePlans = 'Pläne freigeben';
    _this.planSiteSearchPlans = 'Pläne suchen';
    _this.planTilesNotYetCreated = 'Der Plan ist noch nicht in voller Qualität verfügbar. Dies kann einige Minuten dauern.';
    _this.planTitle = 'Plantitel';
    _this.planToTheVersions = 'Zu den Planversionen';
    _this.planUploadDescription = 'Pläne hochladen und starten';
    _this.planUploadDropHint = 'Plan hinzufügen';
    _this.planUploadDropOnMultiSelect = 'Plan hinzufügen';
    _this.planUploadExisting = 'Datei bereits vorhanden';
    _this.planUploadNew = 'Neu';
    _this.planUploadNothingToSave = 'Keinen Plan ausgewählt';
    _this.planUploadPlanNotes = 'Anmerkung';
    _this.planUploadPlanTitle = 'Plantitel*';
    _this.planUploadPlanTitleAlready = 'Plantitel bereits vorhanden. Anderer Titel oder neue Version';
    _this.planUploadPlanTitleAlreadyInOtherPage = 'Plantitel wird bereits auf Seite {{page}} verwendet';
    _this.planUploadPlanTitleRequired = 'Der Plantitel wird benötigt';
    _this.planUploadProgress = 'Planverarbeitung wird durchgeführt';
    _this.planUploadTakeSite = 'Seite {{pageNumber}} verwenden';
    _this.planUploadTextButton = 'Pläne als PDF oder Bild auswählen';
    _this.planUploadTitle = 'Pläne hochladen';
    _this.planUploadedDeleteQuestion = 'PDF Datei verwerfen?';
    _this.planUploadedErrorOnSubmit = 'Nicht alle Formulare wurden korrekt ausgefüllt';
    _this.planUploadedQuestionAfterSubmit = 'Wenn Sie nur eine Auswahl von Seiten eines Plans freigeben möchten, werden die nicht ausgewählten Seiten gelöscht.\n' + '\n' + 'Pläne, von denen Sie jetzt keine Seiten freigeben möchten, bleiben bestehen. Sie können diese auch später noch freigeben.\n' + '\n' + 'Möchten Sie die nicht ausgewählten Seiten der pdf Datei löschen?';
    _this.planVersion = 'Planversionen';
    _this.planVersionActive = 'Aktiv';
    _this.planVersionCreationFailed = 'Die Erstellung des Plans ist fehlgeschlagen \n{{errorMessage}}';
    _this.planVersionCreationRecreate = 'Neu erstellen';
    _this.planVersionDeleteQuestionText = 'Möchten Sie diese Planversion löschen?';
    _this.planVersionDeleteQuestionTitle = 'Löschen bestätigen {{plantitle}}';
    _this.planVersionIsCreating = 'Der Plan wird erstellt';
    _this.planVersionNotFound = 'Diese Planversion existiert nicht (mehr)';
    _this.planVersionSinglePageOnly = 'Das PDF für eine neue Planversion darf nur eine Seite beinhalten';
    _this.planVersions = 'Versionen';
    _this.planVersionsUploadDirect = 'Neue Planversion als PDF oder Bild auswählen';
    _this.planViewChangeToListView = 'Zur Tabellenansicht wechseln';
    _this.plans = 'Pläne';
    _this.plansActiveVersionDate = 'Aktive Version';
    _this.plansAddFile = 'Datei zum Plan {{planTitle}} als neue Version hinzufügen?';
    _this.plansAddFiles = '{{counted}} Dateien zum Plan {{planTitle}} hinzufügen?';
    _this.plansCreateNewPlanQuestion = 'Ein neuen Plan erstellen?';
    _this.plansCreatedDate = 'Erstellt';
    _this.plansErrorAtDiscardAction = 'Fehler beim Verwerfen des Plans';
    _this.plansErrorNotFound = 'Plan nicht gefunden';
    _this.plansErrorTitleRequired = 'Der Plantitel wird benötigt';
    _this.plansFormTitle = 'Plandaten bearbeiten';
    _this.plansNotFound = 'Keine Pläne gefunden...';
    _this.plansNotFoundDescription = 'Führen Sie eine neue Suche durch.';
    _this.plansNotes = 'Anmerkungen';
    _this.plansNumberOfVersions = 'Versionen';
    _this.plansUploadHint = 'Laden Sie neue Pläne hoch, indem Sie auf den + Button drücken';
    _this.plansUploadedNoPDFsFound = 'Keine Pläne zur Freigabe gefunden';
    _this.plansUploadedTitle = 'Plan freigeben';
    _this.plansUploadedWaitingForRelease = 'Es stehen hochgeladene Pläne zur Freigabe aus.';
    _this.pleaseSelect = 'Bitte wählen';
    _this.prev = 'vorheriges';
    _this.preview = 'Vorschau';
    _this.proProject = 'Pro-Projekt';
    _this.proReportComments = 'Kommentare';
    _this.proReportCreatedBy = 'Erstellt von {{user}}';
    _this.proReportCreatedWith = 'Erstellt mit {{creator}}';
    _this.proReportDescription = 'Beschreibung';
    _this.proReportProvidedBy = 'Bereitgestellt von {{user}}';
    _this.proReportTableOfContents = 'Inhaltsverzeichnis';
    _this.profile = 'Profil';
    _this.profilePersonalDataAccountDeleteButten = 'KONTO LÖSCHEN';
    _this.profilePersonalDataAccountDeleteInformation = 'Das Löschen des Kontos kann' + ' nicht rückgängig gemacht werden! Sie verlieren Zugang zu all Ihren Projekten. Ihre' + ' persönlichen Daten werden gelöscht.';
    _this.profilePersonalDataAccountTitle = 'Konto';
    _this.profilePersonalDataAddEmailAddressButton = 'HINZUFÜGEN';
    _this.profilePersonalDataAddEmailAddressComfirmMessage = 'E-Mail-Adresse wirklich löschen?';
    _this.profilePersonalDataAddEmailAddressIsNotValidated = 'E-Mail noch nicht verifiziert';
    _this.profilePersonalDataAddEmailAddressNormalCase = 'E-Mail-Adresse hinzufügen';
    _this.profilePersonalDataAddEmailAddressSendNotification = 'an diese E-Mail Benachrichtigung senden';
    _this.profilePersonalDataAddEmailAdressText = 'E-Mail-Adresse';
    _this.profilePersonalDataAddEmailAdressUpperCase = 'E-MAIL-ADRESSE HINZUFÜGEN';
    _this.profilePersonalDataAddress = 'Adresse';
    _this.profilePersonalDataAddressCity = 'Stadt';
    _this.profilePersonalDataAddressCountry = 'Land';
    _this.profilePersonalDataAddressStreet = 'Straße';
    _this.profilePersonalDataAddressStreetAndNumber = 'Straße und Hausnr.';
    _this.profilePersonalDataAddressStreetNumber = 'Hausnr.';
    _this.profilePersonalDataAddressZipCode = 'PLZ';
    _this.profilePersonalDataChangePassword = 'PASSWORT ÄNDERN';
    _this.profilePersonalDataChangePasswordComfirm = 'Passwort bestätigen';
    _this.profilePersonalDataChangePasswordNew = 'Neues Passwort';
    _this.profilePersonalDataCompany = 'Firma';
    _this.profilePersonalDataCompleteName = 'Name';
    _this.profilePersonalDataEmailTitle = 'E-Mail-Adressen';
    _this.profilePersonalDataGivenName = 'Vorname';
    _this.profilePersonalDataImageChoose = 'Profilfoto auswählen';
    _this.profilePersonalDataImageDelete = 'Löschen';
    _this.profilePersonalDataImageDrag = 'Bild hierein ziehen oder';
    _this.profilePersonalDataImageTake = 'Foto aufnahmen';
    _this.profilePersonalDataImageUpload = 'Bild hochladen';
    _this.profilePersonalDataPosition = 'Position';
    _this.profilePersonalDataSurName = 'Nachname';
    _this.profilePersonalDataTitle = 'Persönliche Daten';
    _this.project = 'Projekt';
    _this.projectAddCompanyUserToTeamTitle = 'Firmen-Mitarbeiter hinzufügen';
    _this.projectAddCraftDialogTitle = 'Gewerk hinzufügen';
    _this.projectAddFileTypeDialogTitle = 'Kategorie hinzufügen';
    _this.projectAddTagDialogTitle = 'Tag hinzufügen';
    _this.projectAddTeamTitle = 'Personen einladen';
    _this.projectAddTicketTypeDialogTitle = 'Tickettyp hinzufügen';
    _this.projectAddUserGroupDialogTitle = 'Firma hinzufügen';
    _this.projectAddress = 'Projektadressen';
    _this.projectAddressForWeather = 'Fügen Sie einen Projektkontakt hinzu, um auf Wetterdaten zugreifen zu können';
    _this.projectArchiveProFunction = 'Projekt archivieren ist eine PRO Funktion';
    _this.projectArchiveProFunctionButton = 'Okay';
    _this.projectArchiveProFunctionDescription = 'Archivieren Sie Ihr Projekt, um es später wieder reaktivieren zu können.';
    _this.projectArchiveProject = 'Projekt archivieren';
    _this.projectArchiveProjectDialogButton = 'Archivieren';
    _this.projectArchiveProjectDialogText = 'Wenn Sie dieses Projekt archivieren, ist es für alle Projektbeteiligten' + ' archiviert. Sie können archivierte Projekte jederzeit wieder reaktivieren. Nutzen Sie dafür in der' + ' Projektübersicht den Filter zur Anzeige aller archivierten Projekte und wählen Sie anschließend das jeweilige' + ' Projekt aus. \n\nBite bestätigen Sie, dass Sie das Projekt archivieren möchten.';
    _this.projectArchiveProjectDialogTextCompany = 'Wenn Sie dieses Projekt archivieren, ist es für alle Projektbeteiligten' + ' archiviert. Sie können archivierte Projekte jederzeit wieder reaktivieren. Gehen Sie dafür zur' + ' Firmen-Projektübersicht und wählen Sie das jeweilige Projekt aus.' + ' \n\nBite bestätigen Sie, dass Sie das Projekt archivieren möchten.';
    _this.projectArchived = 'Das Projekt ist archiviert';
    _this.projectBuildingEnd = 'Bauende';
    _this.projectBuildingPeriod = 'Bauzeitraum';
    _this.projectBuildingStart = 'Baubeginn';
    _this.projectChangeCraftDialogTitle = 'Gewerk bearbeiten';
    _this.projectChangeFileTypeDialogTitle = 'Kategorie bearbeiten';
    _this.projectChangePhoneHelperText = 'Diese Telefonnummer ist nur in diesem Projekt sichtbar';
    _this.projectChangeRoleTitle = 'Mitglied ändern';
    _this.projectChangeRoleTitleFrom = 'Mitglied "{{userName}}" ändern';
    _this.projectChangeRoleTitleMe = 'Meine Rolle oder Firma ändern';
    _this.projectChangeTicketTypeDialogTitle = 'Tickettyp bearbeiten';
    _this.projectChangeUserGroupDialogTitle = 'Firma bearbeiten';
    _this.projectColor = 'Farbe Projekt-Icon';
    _this.projectContactLabel = 'Projektkontakt';
    _this.projectContactLabelWidget = 'Ansprechpartner';
    _this.projectContactWidgetAddTitle = 'Ansprechpartner hinzufügen';
    _this.projectContactWidgetChangeTitle = 'Ansprechpartner ändern';
    _this.projectContactWidgetSubTitle = 'Für alle Projektbeteiligten sichtbar';
    _this.projectCopyProject = 'Projekt kopieren';
    _this.projectCopyProjectDialogButton = 'Kopieren';
    _this.projectCopyProjectDialogProjectCopyMember = 'Projektmitglieder';
    _this.projectCopyProjectDialogProjectCopyPlans = 'Pläne';
    _this.projectCopyProjectDialogProjectCopyText = 'Folgende Inhalte werden kopiert:';
    _this.projectCopyProjectDialogProjectCopyTicketLayouts = 'Ticketlayouts';
    _this.projectCopyProjectDialogProjectCopyTickets = 'Tickets';
    _this.projectCopyProjectDialogProjectFolderAndFiles = 'Ordner & Dateien';
    _this.projectCopyProjectDialogProjectJournalSettings = 'Berichtseinstellungen';
    _this.projectCopyProjectDialogProjectTitle = 'Projektname';
    _this.projectCopyProjectSuccessToast = 'Das Projekt wird kopiert. Sobald der Kopiervorgang vollständig abgeschlossen ist, erhalten Sie eine eMail.';
    _this.projectCostUnit = 'Kostenträger';
    _this.projectCraftAlreadyExists = 'Das Gewerk existiert bereits im Projekt';
    _this.projectCraftChangeErrorAssignedToMinOneTicket = 'Das Gewerk ist mindestens einem Ticket zugeordnet und kann deswegen nicht geändert werden';
    _this.projectCraftNotExists = 'Das Gewerk existiert nicht im Projekt';
    _this.projectCraftRemoveErrorAssignedToMinOneTicket = 'Das Gewerk ist mindestens einem Ticket zugeordnet und kann deswegen nicht gelöscht werden';
    _this.projectCreateTitle = 'Neues Projekt';
    _this.projectCreationOwnerCompany = 'Firma (Enterprise Projekt)';
    _this.projectCreationOwnerMe = 'Ich';
    _this.projectCreationOwnerMe_basic = 'Ich (Basic Projekt)';
    _this.projectCreationOwnerMe_pro = 'Ich (Pro Projekt)';
    _this.projectCreator = 'Eigentümer';
    _this.projectCreatorNotOwner = 'Projektersteller';
    _this.projectDeleteProject = 'Projekt löschen';
    _this.projectDeleteProjectDialogButton = 'Löschen';
    _this.projectDeleteProjectDialogConfirmaionText = 'Löschen bestätigen';
    _this.projectDeleteProjectDialogDownloadButton = 'Projekt als ZIP-Datei herunterladen';
    _this.projectDeleteProjectDialogText = 'Achtung: Diese Aktion kann nicht rückgängig gemacht werden.\n\nWenn Sie dieses' + ' Projekt löschen, werden alle Daten dauerhaft gelöscht und die Projektbeteiligten haben keinen Zugriff mehr auf' + ' das Projekt. Laden Sie sich das Projekt vorab als ZIP-Datei herunter, um es zu sichern.\n\nTipp: Archivieren' + ' Sie abgeschlossene Projekte.';
    _this.projectDeleted = 'Das Projekt existiert nicht mehr';
    _this.projectDeletionMustBeConfirmed = 'Löschen muss bestätigt werden';
    _this.projectDescription = 'Projektbeschreibung';
    _this.projectDetailsSave = 'Projektdetails speichern';
    _this.projectDone = 'Projekt fertig';
    _this.projectDownload = 'Offline Verfügbarkeit';
    _this.projectDownloadAllPlans = 'alle aktiven Planversionen';
    _this.projectDownloadCurrentConnection = 'aktuelle Verbindung';
    _this.projectDownloadDialogText = 'Laden Sie das Projekt "{{projectTitle}}" herunter, um auch ohne aktive' + ' Internetverbindung Zugriff auf alle Projektdaten zu haben. Während des Downloads darf die App nicht geschlossen' + ' werden.';
    _this.projectDownloadDownloaded = 'heruntergeladen';
    _this.projectDownloadError = 'Fehler beim erstellen der ZIP-Datei';
    _this.projectDownloadFiles = 'Dateien';
    _this.projectDownloadHint = 'Projekt als ZIP-Datei herunterladen';
    _this.projectDownloadHowToButton = 'Okay';
    _this.projectDownloadHowToDescription = 'Klicken Sie in der App im Bereich der Projektliste auf das Wolkensymbol, um die Daten zu synchronisieren.';
    _this.projectDownloadHowToHeadline = 'Projekt offline nutzen';
    _this.projectDownloadLoading = 'Erstelle Archiv';
    _this.projectDownloadMaybeLater = 'Vielleicht später';
    _this.projectDownloadNow = 'Okay';
    _this.projectDownloadOfflineAvailable = 'Offline verfügbar';
    _this.projectDownloadOfflineAvailableSyncing = 'syncronisiert';
    _this.projectDownloadOfflineUnavailable = 'Offline nicht verfübar';
    _this.projectDownloadProFunction = 'Projekt exportieren ist eine PRO Funktion';
    _this.projectDownloadProFunctionButton = 'Okay';
    _this.projectDownloadProFunctionDescription = 'Exportieren Sie alle Pläne, Tickets, Fotos und weitere' + ' Projektdaten in nur einer ZIP-Datei. Jetzt upgraden und auf noch weitere Funktionen sowie einen erweiterten' + ' Datenspeicher freuen.';
    _this.projectDownloadUseMobileData = 'auch mobile Daten nutzen?';
    _this.projectDownloadWaitForDownload = 'warte auf Download...';
    _this.projectEditTitle = 'Projektdetails bearbeiten';
    _this.projectEnd = 'Projektende';
    _this.projectErrorConstructionStartAfterEnd = 'Der Baubeginn sollte vor dem Ende sein';
    _this.projectErrorInvalidColor = 'Die Projektfarbe ist nicht valide.';
    _this.projectErrorIsMarkedAsTemplate = 'Das Projekt ist als Vorlage markiert und kann nur von Projektleitern bearbeitet werden.';
    _this.projectErrorProOrCollaboratorNeededForRole = 'Für Ihre Rolle in dem Projekt benötigen Sie eine Mitarbeiterlizenz oder einen Pro-Account. Bitte wenden Sie' + ' sich an den Projekteigentümer {{ownerName}}, damit er Ihre Rolle ändert oder upgraden Sie Ihren Account!';
    _this.projectErrorProOrEnterpriseNeededForRole = 'Für Ihre Rolle in dem Projekt benötigen Sie eine Projektleiterlizenz oder einen Pro-Account. Bitte wenden Sie' + ' sich an den Projekteigentümer {{ownerName}}, damit er Ihre Rolle ändert oder upgraden Sie Ihren Account!';
    _this.projectErrorProjectStartAfterEnd = 'Der Projektstart muss vor dem Projektende sein';
    _this.projectErrorTemplateNotFound = 'Das Vorlagenprojekt wurde nicht gefunden';
    _this.projectErrorTitleRequired = 'Der Projektname wird benötigt';
    _this.projectErrorTitleToLong = 'Der Projektname ist zu lang';
    _this.projectErrorTooManyTickets = 'maximal 500 Tickets können kopiert werden';
    _this.projectErrorTypeToLong = 'Die Projektart ist zu lang';
    _this.projectFileInvalidFilename = 'Der neue Dateiname ist ungültig';
    _this.projectFileTypeAlreadyExists = 'Die Kategorie existiert bereits im Projekt';
    _this.projectFileTypeChangeErrorAssignedToMinOneFile = 'Die Kategorie ist mindestens einer Datei zugeordnet und kann deswegen nicht geändert werden';
    _this.projectFileTypeNoDataHint = 'Drücken Sie auf den + Button, um eine neue Kategorie zu erstellen';
    _this.projectFileTypeNoDataText = 'Keine Kategorie vorhanden';
    _this.projectFileTypeNotExists = 'Die Kategorie existiert nicht im Projekt';
    _this.projectFileTypeRemoveErrorAssignedToMinOneFile = 'Die Kategorie ist mindestens einer Datei zugeordnet und kann deswegen nicht gelöscht werden';
    _this.projectFilterArchived = 'Eigene archivierte Projekte anzeigen';
    _this.projectFilterTemplateAll = 'Vorlagen und Projekte';
    _this.projectFilterTemplateJustProjects = 'Nur Projekte';
    _this.projectFilterTemplateJustTemplate = 'Nur Vorlagen';
    _this.projectFilterTemplatePicker = 'Projekt Vorlagen';
    _this.projectId = 'Projekt';
    _this.projectImageDeleteQuestion = 'Möchten Sie das Projektbild wirklich löschen?';
    _this.projectImageDropHint = 'Projektbild ändern';
    _this.projectImageRemoveHint = 'Projektbild löschen';
    _this.projectInCreation = 'Das Projekt ist noch nicht vollständig kopiert';
    _this.projectInfo = 'Projektinfo';
    _this.projectInfos = 'Projektinfos';
    _this.projectLocked = 'Das Projekt ist gesperrt. Bitte kontaktiere den Projekteigentümer {{ownerName}} oder verlasse das Projekt.';
    _this.projectLockedOwner = 'Dieses Projekt ist für alle Beteiligten gesperrt. Bitte aktualisieren Sie Ihr Abonnement, um' + ' das Projekt für alle Beteiligten wieder freizuschalten.';
    _this.projectManager = 'Projektleiter';
    _this.projectMaximumSizeReached = 'Das Projekt hat die maximale Größe erreicht und ist schreibgeschützt. ';
    _this.projectMember = 'Projektmitglied';
    _this.projectMembers = 'Projektmitglieder';
    _this.projectName = 'Projektname';
    _this.projectNotInCostUnit = 'Das Projekt ist dem Kostenträger nicht zugeordnet';
    _this.projectOption24HourPhotoEditable = 'Fotos und Dateien im Ticket sind nach dem Hochladen 24 Stunden lang bearbeitbar';
    _this.projectOptionFilesEditableTickets = 'Fotos und Dateien im Ticket sind nach dem Hochladen bearbeitbar';
    _this.projectOptionFilesEditableTickets24h = '24 Stunden lang';
    _this.projectOptionFilesEditableTicketsEver = 'jederzeit';
    _this.projectOptionFilesEditableTicketsNever = 'niemals';
    _this.projectOptions = 'Optionen';
    _this.projectOverview = 'Allgemein';
    _this.projectOwner = 'Projekteigentümer';
    _this.projectOwnerChangeAction = 'Projekt übergeben';
    _this.projectOwnerChangeDialogText = 'Sie werden dann als Projektleiter zu dem Projekt hinzugefügt und können das Projekt danach verlassen';
    _this.projectOwnerChangeDialogTitle = 'Projekt "{{projectName}}" an "{{username}}" übergeben';
    _this.projectOwnerTransferConfirmation = 'Übergabe bestätigen';
    _this.projectPeriod = 'Projektzeitraum';
    _this.projectRemoveCraftQuestion = 'Möchten Sie das Gewerk "{{craft}}" wirklich löschen?';
    _this.projectRemoveFileTypeQuestion = 'Möchten Sie die Kategorie "{{fileType}}" wirklich löschen?';
    _this.projectRemoveTagQuestion = 'Möchten Sie das Tag "{{tag.tagName}}" wirklich löschen?';
    _this.projectRemoveTicketLayoutQuestion = 'Möchten Sie das Layout "{{title}}" wirklich löschen?';
    _this.projectRemoveTicketTypeQuestion = 'Möchten Sie den Typ "{{type}}" wirklich löschen?';
    _this.projectRemoveUserGroupQuestion = 'Wollen Sie die Firma "{{groupname}}" wirklich entfernen?';
    _this.projectRoles = 'Projektrollen';
    _this.projectSetting = 'Projekteinstellungen';
    _this.projectSettings = 'Projekteinstellungen';
    _this.projectSettingsAddType = 'Typ hinzufügen';
    _this.projectSettingsChangeRole = 'Projekteinstellungen bearbeiten';
    _this.projectSettingsContainedIn = 'Enthalten in';
    _this.projectSettingsCrafts = 'Gewerke';
    _this.projectSettingsFileTypes = 'Kategorien';
    _this.projectSettingsName = 'Name';
    _this.projectSettingsTags = 'Tags';
    _this.projectSettingsTicketLayoutFields = 'Felder';
    _this.projectSettingsTicketLayoutFieldsRemovedText = 'Ticketfelder die in diesen Feldern Inhalte haben, werden zurückgesetzt';
    _this.projectSettingsTicketLayoutFieldsRemovedTitle = 'Felder "{{fields}}" wurden entfernt';
    _this.projectSettingsTicketLayoutOnTypes = 'Tickettypen';
    _this.projectSettingsTicketLayoutRuleAnd = 'und hinterlegen Sie für folgende Felder einen Wert in der Bedingung: ';
    _this.projectSettingsTicketLayoutRuleDeleteAndUpdateError = 'Diese Option wird derzeit als Regel verwendet. Bitte entfernen Sie sie zuerst aus der Regel, bevor Sie sie hier aktualisieren oder löschen.';
    _this.projectSettingsTicketLayoutRuleMissingField = 'Fehlendes Feld';
    _this.projectSettingsTicketLayoutRuleMissingFieldAndValue = 'Fehlendes Feld und Wert';
    _this.projectSettingsTicketLayoutRuleMissingFieldText = 'Bitte hinterlegen Sie für folgende Felder ein Feld in der Bedingung: ';
    _this.projectSettingsTicketLayoutRuleMissingValue = 'Fehlender Wert';
    _this.projectSettingsTicketLayoutRuleMissingValueText = 'Bitte hinterlegen Sie für folgende Felder einen Wert in der Bedingung: ';
    _this.projectSettingsTypes = 'Tickettypen';
    _this.projectSize = 'Projektgröße';
    _this.projectStart = 'Projektstart';
    _this.projectState = 'Projektstatus';
    _this.projectStateAddTitle = 'Projektstatus hinzufügen';
    _this.projectStateChangeTitle = 'Projektstatus ändern';
    _this.projectStateDeleteQuestion = 'Den Projektstatus {{state}} wirklich löschen?';
    _this.projectStateInformation = 'Diese Liste dient als eine Auswahl für den Projektstatus. Dieser überschreibt die Projektfarbe, ist allerdings' + ' nur innerhalb der Firma sichtbar';
    _this.projectStateName = 'Statusname';
    _this.projectStateNoDataHint = 'Drücken Sie auf den + Button, um einen neuen Projektstatus zu erstellen';
    _this.projectStateNoDataText = 'Kein Projektstatus vorhanden';
    _this.projectStateNoState = 'Kein Projektstatus';
    _this.projectStateOrder = 'Reihenfolge';
    _this.projectSubscription = 'Projekt Abonnement';
    _this.projectTagAlreadyExists = 'Das Tag existiert bereits im Projekt';
    _this.projectTagChangeErrorAssignedToMinOnePlan = 'Das Tag ist mindestens einem Plan zugeordnet und kann deswegen nicht geändert werden';
    _this.projectTagChangeErrorAssignedToMinOneTicket = 'Das Tag ist mindestens einem Ticket zugeordnet und kann deswegen nicht geändert werden';
    _this.projectTagNotExists = 'Das Tag existiert nicht im Projekt';
    _this.projectTagRemoveErrorAssignedToMinOnePlan = 'Das Tag ist mindestens einem Plan zugeordnet und kann deswegen nicht gelöscht werden';
    _this.projectTagRemoveErrorAssignedToMinOneTicket = 'Das Tag ist mindestens einem Ticket zugeordnet und kann deswegen nicht gelöscht werden';
    _this.projectTeamCompanyMember = 'Mitglieder';
    _this.projectTeamCompanyName = 'Name';
    _this.projectTemplateStateHeader = 'Vorlagenstatus:';
    _this.projectTemplateStateText = 'Projekt ist eine Vorlage';
    _this.projectTemplateTitle = 'Projekt als Vorlage markieren';
    _this.projectTemplateUnmarkTitle = 'Projekt nicht mehr als Vorlage markieren';
    _this.projectTemplateWarning = 'Wenn Sie das Projekt als Vorlage markieren, wird es für Nicht-Projektleiter' + ' schreibgeschützt und taucht nicht mehr unter den normalen Projekten auf.';
    _this.projectTicketLayoutAlreadyExists = 'Das Ticketlayout existiert bereits im Projekt';
    _this.projectTicketLayoutNoDataHint = 'Drücken Sie auf den + Button, um eine neues Layout zu erstellen';
    _this.projectTicketLayoutNoDataText = 'Keine Ticketlayouts vorhanden';
    _this.projectTicketTypeAlreadyExists = 'Der Tickettyp existiert bereits im Projekt';
    _this.projectTicketTypeChangeErrorAssignedToMinOneTicket = 'Der Tickettyp ist mindestens einem Ticket zugeordnet und kann deswegen nicht geändert werden';
    _this.projectTicketTypeNotExists = 'Der Tickettyp existiert nicht im Projekt';
    _this.projectTicketTypeRemoveErrorAssignedToMinOneTicket = 'Der Tickettyp ist mindestens einem Ticket zugeordnet und kann deswegen nicht gelöscht werden';
    _this.projectTime = 'Projektzeit';
    _this.projectTitle = 'Projekttitel';
    _this.projectType = 'Projektart';
    _this.projectTypeNoDataHint = 'Drücken Sie auf den + Button, um einen neuen Tickttypen zu erstellen';
    _this.projectTypeNoDataText = 'Keine Tickettypen vorhanden';
    _this.projectUnarchiveProject = 'Projekt reaktivieren';
    _this.projectUnarchiveProjectDialogButtonDelete = 'Löschen';
    _this.projectUnarchiveProjectDialogButtonDownload = 'Projekt als ZIP-Datei herunterladen';
    _this.projectUnarchiveProjectDialogButtonReactivate = 'Reaktivieren';
    _this.projectUnarchiveProjectDialogText = 'Wählen Sie eine Option aus:';
    _this.projectUnarchiveProjectDialogTitle = 'Archiviertes Projekt';
    _this.projectUpgradeDowngradeUserHint = 'Bis auf "Beobachter" und "Nachunternehmer", müssen alle Team Mitglieder einen Pro- oder Firmen-Account' + ' (Enterprise) besitzen. Da die folgenden Benutzer keinen Pro- oder Firmen-Account besitzen, wird ihre Rolle beim' + ' Upgrade zu "Beobachter" geändert:';
    _this.projectUploadButtonHelperText = 'Dateien aus der Projetkablage auswählen';
    _this.projectUploadButtonProjectFiles = 'aus Projektablage kopieren';
    _this.projectUser = 'Teammitglieder';
    _this.projectUserGroupAlreadyExists = 'Die Firma existiert bereits im Projekt';
    _this.projectUserGroupNotExists = 'Die Firma existiert nicht im Projekt';
    _this.projectUserGroupRemoveErrorAssignedToMinOneUser = 'Die Firma kann nicht entfernt werden, da sie mindestens einem Projekt Mitglied zugeordnet ist.';
    _this.projectUsergroupNoDataHint = 'Drücken Sie auf den + Button, um eine neue Firma zu erstellen';
    _this.projectUsergroupNoDataText = 'Keine Firmen vorhanden';
    _this.projectsFilter = 'Projekte filtern';
    _this.projectsWidgetShowMap = 'Karte anzeigen';
    _this.projectsWidgetViewType = 'Ansicht';
    _this.publishedLinkOverUpmesh = 'Freigabe über upmesh: {{link}}';
    _this.publishedOverUpmesh = 'Freigabe über upmesh';
    _this.pushDeleteQuestion = 'Möchten Sie das Gerät wirklich entfernen?';
    _this.pushDeviceMobile = 'Android/iOS';
    _this.pushDeviceWeb = 'Browser';
    _this.pushNotification = 'Push-Benachrichtigungen';
    _this.pushNotificationOnDevice = 'Auf diesem Gerät';
    _this.pushNotificationOnDeviceDenied = 'Blockiert in Geräteeinstellungen';
    _this.pushNotificationOnDeviceNotSupported = 'Nicht unterstützt auf diesem Gerät';
    _this.pushOtherDevices = 'Weitere Geräte:';
    _this.qualityGood = 'Mittel (3x)';
    _this.qualityLow = 'sehr Klein (0.5x)';
    _this.qualityNormal = 'Klein (1x)';
    _this.qualityOptimal = 'Groß (6x)';
    _this.qualityPlanGood = 'Mittel (600dpi)';
    _this.qualityPlanLow = 'sehr Klein (180dpi)';
    _this.qualityPlanNormal = 'Klein (360dpi)';
    _this.qualityPlanOptimal = 'Groß (1200dpi)';
    _this.quantity = 'Anzahl';
    _this.range = 'Dauer';
    _this.reSync = 'Synceinstellungen';
    _this.recording = 'Aufnahme läuft: {{time}}';
    _this.redo = 'wiederherstellen';
    _this.region = 'Regionen';
    _this.regions = 'Regionen';
    _this.registerChangeEmail = 'E-Mail-Adresse ändern';
    _this.registerErrorRequireEmail = 'E-Mail-Adresse wird benötigt';
    _this.registerErrorRequireFirstName = 'Ihr Vorname wird benötigt';
    _this.registerErrorRequireLastName = 'Ihr Nachname wird benötigt';
    _this.registerErrorUserAlreadyExisting = 'E-Mail-Adresse ist bereits registriert';
    _this.registerWithGoogle = 'Mit Google registrieren';
    _this.release = 'freigeben';
    _this.removeMyself = 'Entferne mich';
    _this.removeTicketWatcherQuestion = 'Möchten Sie sich wirklich von der Beobachtungsliste entfernen?';
    _this.removeTicketWatcherTitle = 'Ticket Beobachter';
    _this.report = 'Bericht drucken';
    _this.reportAttachment = 'Anhang';
    _this.reportAttachmentButton = 'Anhangseiten als PDF auswählen';
    _this.reportCoverPage = 'Titelseite';
    _this.reportCoverPageText = 'Titelseite';
    _this.reportCoverPageTextButton = 'Titelseite(n) als PDF auswählen';
    _this.reportCreateButton = 'Bericht erstellen';
    _this.reportCreated = 'Bericht erstellt';
    _this.reportCreation = 'Bericht erstellen | {{x}} Tickets ausgewählt';
    _this.reportDefaultName = 'upmesh Statusbericht';
    _this.reportDescription = 'Beschreibung';
    _this.reportErrorNotForFree = 'Diese Funktion ist in Basic-Projekten nicht verfügbar';
    _this.reportGroup = 'Gruppe';
    _this.reportGroupByLabel = 'Gruppieren nach';
    _this.reportGroup_approverUserId = 'Prüfer';
    _this.reportGroup_assignedToUserId = 'Zugewiesener';
    _this.reportGroup_companyId = 'Firma';
    _this.reportGroup_craft = 'Gewerk';
    _this.reportGroup_projectId = 'Projekt';
    _this.reportGroup_type = 'Typ';
    _this.reportIncludeLinks = 'Links in Überschriften';
    _this.reportLayout = 'Layout';
    _this.reportLayoutMapLocalization = 'Kartenausschnitt pro Ticket anzeigen';
    _this.reportLayoutPlan = 'Planübersicht pro Ticket anzeigen';
    _this.reportLayoutPlanSnippet = 'Planausschnitt pro Ticket anzeigen';
    _this.reportName = 'Name';
    _this.reportPageBreakForTicket = 'Ticket auf neuer Seite beginnen';
    _this.reportPicturesPerLineLabel = 'Bilder';
    _this.reportPlanWithTicket = 'Plan mit Tickets in Bericht einbinden';
    _this.reportQrCodes = 'QR codes anzeigen';
    _this.reportReportLogo = 'Berichtslogo';
    _this.reportReportLogoButton = 'Berichtslogo auswählen';
    _this.reportSelectDescription = 'Beschreibung';
    _this.reportSelectTitlepage = 'Titelseite';
    _this.reportSettings = 'Berichtseinstellungen';
    _this.reportShowToc = 'Inhaltsverzeichnis anzeigen';
    _this.reportSortByLabel = 'Sortieren nach';
    _this.reportTemplateTitleCreateNew = 'Neue Vorlage erstellen';
    _this.reportTemplates = 'Berichtsvorlagen';
    _this.reportTemplatesDateExamples = 'Verwenden von Feldern die Datumseinträge enhalten. Weitere Informationen zu Datumsfeldern unter:' + ' https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date';
    _this.reportTemplatesDateExamplesAll = 'Datum inklusive Uhrzeit';
    _this.reportTemplatesDateExamplesDay = 'Nur den Tag des Datums';
    _this.reportTemplatesDateExamplesTime = 'Nur die Uhrzeit des Datums';
    _this.reportTemplatesDialogSaveAsPdf = 'Als PDF speichern';
    _this.reportTemplatesDialogTitle = 'Ticketbericht aus Vorlage';
    _this.reportTemplatesDownloadExamples = 'Beispieldateien herunterladen';
    _this.reportTemplatesNotesDialogCopiedToClipboard = `"{{var}}" in Zwischenablage kopiert`;
    _this.reportTemplatesNotesDialogDescription = 'Beschreibung';
    _this.reportTemplatesNotesDialogTabStructure = 'Struktur';
    _this.reportTemplatesNotesDialogTableCategories = 'Kategorie';
    _this.reportTemplatesNotesDialogTableCategoriesGeneral = 'Allgemein';
    _this.reportTemplatesNotesDialogText = 'Hier finden Sie die zur Verfügung stehenden Code-Snippets für Ihre Vorlage';
    _this.reportTemplatesNotesDialogTextExec = 'Führt ein bestimmtes JavaScript-Snippet aus, genau wie INS oder =, fügt aber nichts in das Dokument ein. Sie ' + 'können EXEC beispielsweise verwenden, um Funktionen oder Konstanten zu definieren, bevor Sie sie an anderer ' + 'Stelle in Ihrer Vorlage verwenden.';
    _this.reportTemplatesNotesDialogTextFor = 'Eine Schleife über eine Gruppe von Elementen:';
    _this.reportTemplatesNotesDialogTextIf = 'Inhalte bedingt einschließen (abhängig von der Auswertung eines JavaScript-Ausdrucks):';
    _this.reportTemplatesNotesDialogTextImage = 'Ein Bild in die Vorlage einfügen:';
    _this.reportTemplatesNotesDialogTextIns = 'Eine Variable in die Vorlage einfügen:';
    _this.reportTemplatesNotesDialogTextVars = 'Verfügbare Variablen ( Ticketbericht )';
    _this.reportTemplatesNotesDialogTextVarsDaily = 'Verfügbare Variablen ( Tagesbericht )';
    _this.reportTemplatesNotesDialogTicketVarsAdditionalFieldsContent = 'Inhalt';
    _this.reportTemplatesNotesDialogTicketVarsComment = 'Text';
    _this.reportTemplatesNotesDialogTicketVarsCompanyName = 'Firmenname';
    _this.reportTemplatesNotesDialogTicketVarsContactName = 'Ansprechpartner';
    _this.reportTemplatesNotesDialogTicketVarsCreatorFullname = 'Erstellername';
    _this.reportTemplatesNotesDialogTicketVarsCreatorName = 'Name des Erstellers';
    _this.reportTemplatesNotesDialogTicketVarsDateToday = 'Heutiges Datum';
    _this.reportTemplatesNotesDialogTicketVarsFileComment = 'Datei Kommentar';
    _this.reportTemplatesNotesDialogTicketVarsFilename = 'Dateiname';
    _this.reportTemplatesNotesDialogTicketVarsFileType = 'Datei Kategorie';
    _this.reportTemplatesNotesDialogTicketVarsImageFile = 'Datei';
    _this.reportTemplatesNotesDialogTicketVarsProjectClient = 'Auftraggeber';
    _this.reportTemplatesNotesDialogTicketVarsTicketsApprover = 'Prüfer';
    _this.reportTemplatesNotesDialogTicketVarsTicketsAssignee = 'Zugewiesener';
    _this.reportTemplatesNotesDialogTicketVarsTicketsCompletionOnLocal = 'Fälligkeitsdatum (lokale Zeit)';
    _this.reportTemplatesNotesDialogTicketVarsTicketsCreatedAtLocal = 'Erstellungsdatum (lokale Zeit)';
    _this.reportTemplatesNotesDialogTicketVarsTicketsDescription = 'Beschreibung';
    _this.reportTemplatesNotesDialogTicketVarsTicketsLastModifiedAtLokal = 'Zuletzt bearbeitet am (lokale Zeit)';
    _this.reportTemplatesNotesDialogTicketVarsTicketsPlanTitleLarge = 'Ganzer Plan';
    _this.reportTemplatesNotesDialogTicketVarsTicketsPlanTitleSnippet = 'Titelausschnitt';
    _this.reportTemplatesNotesDialogTicketVarsTicketsWatcher = 'Beobachter';
    _this.reportTemplatesNotesDialogTicketVarsTitle = 'Titel';
    _this.reportTemplatesNotesDialogTicketVarsUploadDate = 'Hochgeladen / Erstellt am';
    _this.reportTemplatesNotesDialogTicketVarsUsername = 'Benutzername';
    _this.reportTemplatesNotesDialogTitle = 'Berichtsvorlagen';
    _this.reportTemplatesNotesDialogType = 'Datentyp';
    _this.reportTemplatesNotesDialogVariable = 'Variable';
    _this.reportTemplatesTitleChange = 'Berichtsvorlage "{{title}}" bearbeiten';
    _this.reportTemplatesTitleCreate = 'Neue Berichtsvorlage erstellen';
    _this.reportTicketActivities = 'Aktivitäten';
    _this.reportTicketActivitiesCategories = 'Kategorien';
    _this.reportTicketActivitiesComments = 'Kommentare';
    _this.reportTicketActivitiesFileComment = 'Die Datei "{{filename}}" wurde zum Ticket hinzugefügt.';
    _this.reportTicketActivitiesFilterCategories = 'Einbezogene Kategorien';
    _this.reportTicketActivitiesFilterForCatHint = 'Kategorien wählen';
    _this.reportTicketActivitiesLogbook = 'Verlauf';
    _this.reportTicketActivitiesPDFsEmbed = 'PDFs einbetten';
    _this.reportTicketActivitiesPhotos = 'Fotos';
    _this.reportTicketActivitiesPhotosQuality = 'Fotoauflösung';
    _this.reportTicketActivitiesPlansQuality = 'Planauflösung';
    _this.reportTicketCreated = 'Erstellt';
    _this.reportTicketDetails = 'Ticketdetails';
    _this.reportTicketDetailsApprover = 'Prüfer';
    _this.reportTicketDetailsAssignee = 'Zugewiesener';
    _this.reportTicketDetailsCompletionOn = 'Fälligkeitsdatum';
    _this.reportTicketDetailsCraft = 'Gewerk';
    _this.reportTicketDetailsCreated = 'Erstellt';
    _this.reportTicketDetailsDescription = 'Beschreibung';
    _this.reportTicketDetailsDue = 'Fällig am';
    _this.reportTicketDetailsLastChanged = 'letzte Änderung';
    _this.reportTicketDetailsNumber = 'Ticketnummer';
    _this.reportTicketDetailsObserver = 'Beobachter';
    _this.reportTicketDetailsPlan = 'Plan';
    _this.reportTicketDetailsStatus = 'Status';
    _this.reportTicketDetailsTags = 'Tags';
    _this.reportTicketDetailsTitle = 'Titel';
    _this.reportTicketDetailsTyp = ' Typ';
    _this.reportTicketDueOn = 'Fällig am';
    _this.reportTicketGroupCompany = 'Unternehmen';
    _this.reportTicketGroupNoGroup = 'Keine Gruppierung';
    _this.reportTicketGroupProject = 'Projekt';
    _this.reportTicketLastChanged = 'Letzte Änderung';
    _this.reportTicketPhotoPerLine = '1 Foto pro Zeile';
    _this.reportTicketPhotosPerLine = 'Fotos pro Zeile';
    _this.reportTypedaily = 'Tagesbericht';
    _this.reportTypeticket = 'Ticketbericht';
    _this.reportWillBeCreated = 'Der Bericht wird erstellt...';
    _this.required = 'Erforderlich';
    _this.reset = 'Zurücksetzen';
    _this.restoreTicket = 'Ticket wiederherstellen';
    _this.restoreTicketShort = 'wiederherstellen';
    _this.role = 'Rolle';
    _this.roleDeleteQuestionText = 'Möchten Sie die Rolle wirklich löschen?';
    _this.roleDeleteQuestionTitle = '"{{roleName}}" löschen?';
    _this.roleDescriptioncollaborator = '• kann Tickets erstellen\n• kann alle Tickets sehen und bearbeiten\n• hat Zugriff auf die Planverwaltung\n';
    _this.roleDescriptionprojectManager = '• hat vollen Zugriff auf das Projekt';
    _this.roleDescriptionsubcontractor = '• kann Tickets erstellen\n• kann nur ihm zugewiesene Tickets sehen\n• hat' + ' keinen Zugriff auf die Planverwaltung\n';
    _this.roleDescriptionwatcher = '• kann nichts erstellen und bearbeiten\n• kann alles sehen';
    _this.roleWithNameExists = 'Es existiert bereits eine Rolle mit diesem Namen';
    _this.roles = 'Rollen';
    _this.rolesActions = 'Aktionen';
    _this.rolesAddDialogActionButton = 'Erstellen';
    _this.rolesAddDialogBaseroleLabel = 'Basiert auf Rolle';
    _this.rolesAddDialogRolenameLabel = 'Rollenname';
    _this.rolesAddDialogTitle = 'Rolle erstellen';
    _this.rolesChangeDialogActionButton = 'ändern';
    _this.rolesChangeDialogRolenameLabel = 'Rollenname';
    _this.rolesChangeDialogTitle = 'Rolle ändern';
    _this.rolesInformationText = 'Die Standardrollen sind nicht editierbar. Benutzerdefinierte Rollen basieren immer auf einer Standardrollen, wobei die Berechtigungen nur weiter eingeschränkt werden können, nicht erweitert.';
    _this.rolesOnlyInEnterprise = 'Rollen können nur in Firmen-Projekten erstellt werden';
    _this.rolesReadRight_allTickets = 'Alle Tickets anzeigen';
    _this.rolesReadRight_allTickets_description = 'Darf alle Tickets des Projektes einsehen';
    _this.rolesReadRight_logs = 'Logbucheinträge anzeigen';
    _this.rolesReadRight_logs_description = 'Darf alle Logbucheinträge des Projektes einsehen';
    _this.rolesReadRight_plans = 'Planverwaltung';
    _this.rolesReadRight_plans_description = 'Darf alle Pläne der Planverwaltung einsehen';
    _this.rolesReadRight_projectSettings = 'Projekteinstellungen';
    _this.rolesReadRight_projectSettings_description = 'Darf die Projekteinstellungen einsehen';
    _this.rolesReadRight_teams = 'Team';
    _this.rolesReadRight_teams_description = 'Darf alle Projektbeteiligten einsehen';
    _this.rolesReadRights = 'Leserechte';
    _this.rolesWriteRights = 'Schreib-/Änderungsrechte';
    _this.rotate = 'Drehen';
    _this.rotateLeft = 'Um 90° nach links drehen';
    _this.rotateRight = 'Um 90° nach rechts drehen';
    _this.save = 'Speichern';
    _this.saveChanges = 'Änderungen speichern';
    _this.saveCopy = 'Kopie speichern';
    _this.search = 'Suchen';
    _this.searchFiles = 'Dateien suchen';
    _this.searchPlans = 'Suchen';
    _this.searchProjects = 'Suchen';
    _this.searchTickets = 'Suchen';
    _this.searching = 'Suche...';
    _this.selectAll = 'Alle auswählen';
    _this.selectFile = 'Datei auswählen';
    _this.selectNone = 'Keine auswählen';
    _this.selectProject = 'Projekt auswählen';
    _this.selected = 'ausgewählt';
    _this.selectorFolderPrivate = 'privater Ordner';
    _this.selectorFolderPublic = 'Projektablage';
    _this.selectorPlans = 'neuer oder vorhandener Plan';
    _this.selectorTickets = 'neues oder vorhandenes Ticket';
    _this.selectorFolders = 'Dateiablage';
    _this.send = 'senden';
    _this.sendNotificationsInCases = 'Senden Sie mir in folgenden Fallen Benachrichtgungen:';
    _this.settingsClusterMarker = 'Clustern von Ticketmarkierungen';
    _this.settingsImageEditor = 'Editor nach Kameraaufnahme';
    _this.settingsRegional = 'Regionale Einstellungen';
    _this.share = 'Freigeben';
    _this.shareAccessCount = 'Zugriffe';
    _this.shareCopy = 'Zwischenablage';
    _this.shareCopyHelper = 'Freigabelink wurde in die Zwischenablage kopiert:';
    _this.shareCreateButton = 'Link erzeugen';
    _this.shareDelete = 'löschen';
    _this.shareEmail = 'Per E-Mail teilen';
    _this.shareExpireText = 'Ablaufdatum';
    _this.shareHeader = '{{fileOrFolder}} teilen';
    _this.shareHelper = 'Z.B. Freigabegrund, Empfänger, Person oder Firma';
    _this.shareMobile = 'Link teilen';
    _this.shareName = 'Titel für Freigabe';
    _this.shares = 'Freigaben';
    _this.show = 'ansehen';
    _this.showLess = 'Weniger anzeigen';
    _this.showMap = 'Karte anzeigen';
    _this.showMore = 'Mehr anzeigen';
    _this.showMoreSubscriptionInfo = 'Weitere Abonnementinformationen anzeigen';
    _this.showRuleFields = 'Felder anzeigen bei einem dieser Werte: ';
    _this.signUpErrorUserAlreadyExisting = 'Benutzer existiert bereits';
    _this.sort = 'sortieren';
    _this.staffNumber = 'Personalnummer';
    _this.startHeader = 'Startseiten';
    _this.startHeaderGeneral = 'Startseite';
    _this.startHeaderProjects = 'Projektstartseite';
    _this.startallTickets = 'Alle Tickets';
    _this.startdashboard = 'Dashboard';
    _this.startfiles = 'Dateien';
    _this.startproject = 'Dashboard';
    _this.startprojects = 'Projekte';
    _this.startprojectsettings = 'Einstellungen';
    _this.starts = 'Start';
    _this.startsDate = 'Datum';
    _this.starttickets = 'Tickets';
    _this.state = 'Status';
    _this.statistics = 'Statistik';
    _this.status = 'Status';
    _this.stopTimeTrackingTitle = 'Erfassungen beenden';
    _this.storedFile = 'Dateien';
    _this.storedFileCopyAttachment = 'Kopie';
    _this.storedFileCopyText = 'Datei duplizieren';
    _this.storedFileFilterAddTeammember = 'Hinzufügen';
    _this.storedFileFilterCreatedAt = 'Hochgeladen am';
    _this.storedFileFilterExtensions = 'Dateiendung';
    _this.storedFileFilterHeader = 'Dateifilter';
    _this.storedFileFilterPersons = 'Hochgeladen von';
    _this.stringToLong = 'zu lang';
    _this.subTickets = 'Untergeordnete Tickets';
    _this.subcontractor = 'Nachunternehmer';
    _this.subcontractorInOwnCompany = 'Basic-Mitarbeiter';
    _this.subscription = 'Abos';
    _this.subscriptionButtonTextEnt = 'Kontaktieren';
    _this.subscriptionButtonTextPro = 'Pro kaufen';
    _this.subscriptionDescriptionBasic = 'Zum Testen unserer Funktionen.';
    _this.subscriptionDescriptionEnt = 'Für Firmen, die auf Standards setzen.';
    _this.subscriptionDescriptionPro = 'Für Profis, die unsere leistungsstarken Features nutzen.';
    _this.subscriptionIndividual = 'Individuell';
    _this.subscriptionManagement = 'Abonnementverwaltung';
    _this.subscriptionManagementBasic = 'Basic';
    _this.subscriptionManagementCurrent = 'Aktuell:';
    _this.subscriptionManagementEnterprise = 'Enterprise (Firmen-Account)';
    _this.subscriptionManagementLocked = 'gesperrt';
    _this.subscriptionManagementLockedReasonPaymentEscalated = 'Zahlung nicht erhalten';
    _this.subscriptionManagementPro = 'Pro';
    _this.subscriptionManagementProAndEnterprise = 'Enterprise (Firmen-Account) und eigenen Pro Account';
    _this.subscriptionManagementSeeMore = 'mehr Details anzeigen';
    _this.subscriptionOrderTitle = 'Bestellung';
    _this.subscriptionPaymentAllPricesWithoutSalesTaxes = 'Alle Preise zzgl. der ges. Umsatzsteuer.';
    _this.subscriptionPaymentMethodMonthly = 'Monatlich abgerechnet';
    _this.subscriptionPaymentMethodYearly = 'Jährlich abgerechnet';
    _this.subscriptionPaymentPerUserAndMonth = 'pro Nutzer und Monat';
    _this.subscriptionPaymentUserAndYear = 'Nutzer und Jahr';
    _this.subscriptionPossibilitiesDescriptionBasic = 'Für bis zu 5 Projekte \n2 Projektrollen \nStandardbericht\n 250 MB Speicherplatz';
    _this.subscriptionPossibilitiesDescriptionEnt = 'Unbegrenzte Projekte & Pläne \nUnbegrenzte Tickets & Fotos \nIndividuelle Berichte \nIndividuelle Projektrollen';
    _this.subscriptionPossibilitiesDescriptionPro = 'Unbegrenzte Projekte & Tickets \nBericht mit eigenem Logo \n200' + ' GB Speicherplatz pro Projekt \nverschiedene Projektrollen';
    _this.subscriptionPriceDescriptionBasic = 'Dauerhaft kostenfrei';
    _this.subscriptionPriceDescriptionEnt = 'Jetzt Angebot einholen';
    _this.subscriptionPriceDescriptionPro = 'pro Nutzer und Monat bei jährlicher Zahlungsweise | 59€ pro Nutzer und Monat bei monatlicher Zahlungsweise';
    _this.subscriptionThankYou = 'Vielen Dank';
    _this.subscriptionThankYouContinue = 'weiter';
    _this.subscriptionThankYouText = 'Wir von upmesh sagen DANKE für das Upgrade Ihres Abos. Ab sofort können sie' + ' sich auf noch mehr Funktionen und einen erweiterten Datenspeicher freuen. Die Rechnung senden wir Ihnen per' + ' E-Mail zu.';
    _this.subscriptionTypeBasic = 'Basic';
    _this.subscriptionTypeEnt = 'Enterprise';
    _this.subscriptionTypePro = 'Pro';
    _this.subscriptionViewTitle = 'upmesh Preismodell';
    _this.support = 'Support';
    _this.syncCommands = 'Änderungen übertragen';
    _this.syncOnLogin = 'Konto wird geladen...';
    _this.systemFields = 'Systemfelder';
    _this.tag = 'Tag';
    _this.tagAbbrucharbeiten = 'Abbrucharbeiten';
    _this.tagAbdichtung = 'Abdichtung';
    _this.tagAbwasserinstallation = 'Abwasserinstallation';
    _this.tagAnsicht = 'Ansicht';
    _this.tagAntennenanlagen = 'Antennenanlagen';
    _this.tagArchitekt = 'Architekt';
    _this.tagArchitektur = 'Architektur';
    _this.tagArmaturen = 'Armaturen';
    _this.tagAufzüge = 'Aufzüge';
    _this.tagAusfuehrungsplanung = 'Ausführungsplanung';
    _this.tagAusführungsplanung = 'Ausführungsplanung';
    _this.tagAußen = 'Außen';
    _this.tagAußenanlagen = 'Außenanlagen';
    _this.tagBauausfuehrung = 'Bauausführung';
    _this.tagBauherr = 'Bauherr';
    _this.tagBaustelleneinrichtung = 'Baustelleneinrichtung';
    _this.tagBauüberwachtung = 'Bauüberwachtung';
    _this.tagBeleuchtung = 'Beleuchtung';
    _this.tagBelüftung = 'Belüftung';
    _this.tagBestand = 'Bestand';
    _this.tagBeton = 'Beton';
    _this.tagBodenbeläge = 'Bodenbeläge';
    _this.tagBrandschutz = 'Brandschutz';
    _this.tagDG = 'DG';
    _this.tagDachabdichtung = 'Dachabdichtung';
    _this.tagDachbedeckung = 'Dachbedeckung';
    _this.tagDetail = 'Detail';
    _this.tagDämmung = 'Dämmung';
    _this.tagEG = 'EG';
    _this.tagEbene = 'Ebene';
    _this.tagElektrik = 'Elektrik';
    _this.tagElektrotechnik = 'Elektrotechnik';
    _this.tagEntwurfsplanung = 'Entwurfsplanung';
    _this.tagEntwässerung = 'Entwässerung';
    _this.tagErdarbeiten = 'Erdarbeiten';
    _this.tagErdbohrungen = 'Erdbohrungen';
    _this.tagEstrich = 'Estrich';
    _this.tagFassaden = 'Fassaden';
    _this.tagFenster = 'Fenster';
    _this.tagFernmeldeleitungen = 'Fernmeldeleitungen';
    _this.tagFernmeldetechnik = 'Fernmeldetechnik';
    _this.tagFliesen = 'Fliesen';
    _this.tagFundamente = 'Fundamente';
    _this.tagGalabau = 'Galabau';
    _this.tagGarage = 'Garage';
    _this.tagGasinstallation = 'Gasinstallation';
    _this.tagGenehmigung47Behörde = 'Genehmigung/Behörde';
    _this.tagGenehmigungBehoerde = 'Genehmigung/Behörde';
    _this.tagGenehmigungsplanung = 'Genehmigungsplanung';
    _this.tagGerüst = 'Gerüst';
    _this.tagGroup = 'Gruppe';
    _this.tagGroupBauteil = 'Bauteil';
    _this.tagGroupDisziplin = 'Disziplin';
    _this.tagGroupGeschoss = 'Geschoss';
    _this.tagGroupGewerke = 'Gewerke';
    _this.tagGroupPhase = 'Phase';
    _this.tagGroupPlanart = 'Planart';
    _this.tagGroupSonstiges = 'Sonstiges';
    _this.tagGrundlagenermittlung = 'Grundlagenermittlung';
    _this.tagGrundriss = 'Grundriss';
    _this.tagGutachter = 'Gutachter';
    _this.tagHaupt = 'Haupt';
    _this.tagHaustechnik = 'Haustechnik';
    _this.tagHeiztechnik = 'Heiztechnik';
    _this.tagHeizung = 'Heizung';
    _this.tagHochspannung = 'Hochspannung';
    _this.tagHolzbau = 'Holzbau';
    _this.tagIT45Technik = 'IT-Technik';
    _this.tagKG = 'KG';
    _this.tagKlempner = 'Klempner';
    _this.tagKlima47Lüftung = 'Klima/Lüftung';
    _this.tagKläranlagen = 'Kläranlagen';
    _this.tagKorrosionsschutz = 'Korrosionsschutz';
    _this.tagKüchentechnik = 'Küchentechnik';
    _this.tagLageplan = 'Lageplan';
    _this.tagLandschaftsbau = 'Landschaftsbau';
    _this.tagMaler = 'Maler';
    _this.tagMaurerwerk = 'Maurerwerk';
    _this.tagMeldeanlagen = 'Meldeanlagen';
    _this.tagMetallbau = 'Metallbau';
    _this.tagMitwirkung_bei_der_Vergabe = 'Mitwirkung bei der Vergabe';
    _this.tagNeben = 'Neben';
    _this.tagNiederspannungsanlagen = 'Niederspannungsanlagen';
    _this.tagNutzung = 'Nutzung';
    _this.tagOG = 'OG';
    _this.tagParksysteme = 'Parksysteme';
    _this.tagPflanzen = 'Pflanzen';
    _this.tagPutz47Stuck = 'Putz/Stuck';
    _this.tagReinigung = 'Reinigung';
    _this.tagRohrleitungen = 'Rohrleitungen';
    _this.tagRollläden = 'Rollläden';
    _this.tagRueckbau = 'Rückbau';
    _this.tagRückbau = 'Rückbau';
    _this.tagSanitär = 'Sanitär';
    _this.tagSanitärinstallation = 'Sanitärinstallation';
    _this.tagSchema = 'Schema';
    _this.tagSchlussdokumentation = 'Schlussdokumentation';
    _this.tagSchnitt = 'Schnitt';
    _this.tagSchutzmassnahmen = 'Schutzmassnahmen';
    _this.tagSonderplaner = 'Sonderplaner';
    _this.tagSonnenschutz = 'Sonnenschutz';
    _this.tagSprechanlagen = 'Sprechanlagen';
    _this.tagStahlbau = 'Stahlbau';
    _this.tagStatiker = 'Statiker';
    _this.tagTG = 'TG';
    _this.tagTapezierarbeiten = 'Tapezierarbeiten';
    _this.tagTiefbau = 'Tiefbau';
    _this.tagTischler = 'Tischler';
    _this.tagTragwerksplanung = 'Tragwerksplanung';
    _this.tagTrockenbau = 'Trockenbau';
    _this.tagVergabe = 'Vergabe';
    _this.tagVerglasung = 'Verglasung';
    _this.tagVermessungstechnik = 'Vermessungstechnik';
    _this.tagVorbereitung_der_Vergabe = 'Vorbereitung der Vergabe';
    _this.tagVorentwurfsplanung = 'Vorentwurfsplanung';
    _this.tagVorplanung = 'Vorplanung';
    _this.tagWandplan = 'Wandplan';
    _this.tagWasserhaltung = 'Wasserhaltung';
    _this.tagWasserinstallation = 'Wasserinstallation';
    _this.tagWerkstatt_und_Montageplanung = 'Werkstatt- und Montageplanung';
    _this.tagWintergärten = 'Wintergärten';
    _this.tagZimmerer = 'Zimmerer';
    _this.tags = 'Tags';
    _this.tagsAddGroup = 'Neue Tag-Gruppe';
    _this.tagsAddGroupHelperText = 'Bestehende Gruppe auswählen oder neue erstellen';
    _this.tagsAddGroupLabel = 'gehört zur Tag-Gruppe';
    _this.tagsAddLabel = 'Tag Bezeichnung';
    _this.tagsAddNewToGroup = '"{{tag}}" als neuen Tag zur Gruppe hinzufügen';
    _this.tagsAddTagGroupTooMany = 'Maximale Anzahl an Gruppen erreicht';
    _this.tagsAddTagTagAlreadyExists = 'Tag existiert bereits';
    _this.tagsAddTagTitle = 'Tag hinzufügen';
    _this.tagsAddTitle = 'Tag hinzufügen';
    _this.tagsDelete = 'Tag "{tagName}" und alle Verknüfungen wirklich löschen?';
    _this.tagsEditTagTitle = 'Tag ändern';
    _this.tagsErrorAlreadyExists = 'Tag existiert bereits';
    _this.tagsErrorColorRequired = 'Gruppenfarbe wird benötigt';
    _this.tagsErrorDbError = 'Datenbank Fehler';
    _this.tagsErrorGroupNotFound = 'Taggruppe nicht gefunden';
    _this.tagsErrorGroupRequired = 'Taggruppe wird benötigt';
    _this.tagsErrorNameRequired = 'Tag Name benötigt';
    _this.tagsErrorNameToLong = 'Tagname zu lang';
    _this.tagsErrorProjectNotFound = 'Projekt nicht gefunden';
    _this.tagsErrorProjectRequired = 'Projekt benötigt';
    _this.tagsGroupErrorAlreadyExists = 'Taggruppe existiert bereits';
    _this.tagsGroupErrorColorRequired = 'Eine farbe wird für die Taggrupe benötigt';
    _this.tagsGroupErrorDbError = 'Datenbankfehler';
    _this.tagsGroupErrorNameRequired = 'Taggruppenname wird benötigt';
    _this.tagsGroupErrorNameToLong = 'Taggruppenname zu lang';
    _this.tagsGroupErrorNotColor = 'Keine Farbe';
    _this.tagsSaveErrorExists = 'Tag "{tagName}" bereits vorhanden';
    _this.tagsTaggroupColor = 'Gruppenfarbe';
    _this.tagsTitle = 'Tagverwaltung';
    _this.task = 'Tätigkeit';
    _this.taskActive = 'Aktiv';
    _this.taskDescription = 'Bezeichnung';
    _this.taskFollowUpRequiredIfFollowUp2 = 'Folgelohnart 1 wird benötigt, wenn Folgelohnart 2 vorhanden ist';
    _this.taskFollowUpWageType1 = 'Folgelohnart 1';
    _this.taskFollowUpWageType2 = 'Folgelohnart 2';
    _this.taskHasAbsencesItems = 'Die Tätigkeit hat erfasste Abwesenheiten und kann deswegen nicht gelöscht werden';
    _this.taskHasTimeTrackedItems = 'Die Tätigkeit hat erfasste Zeiten und kann deswegen nicht gelöscht werden';
    _this.taskId = 'Tätigkeit';
    _this.taskInactive = 'Inaktiv';
    _this.taskMark = 'Kennzeichen';
    _this.taskProductive = 'Produktiv';
    _this.taskStatus = 'Status';
    _this.taskType = 'Tätigkeitsart';
    _this.taskTypeEducation = 'Weiterbildung';
    _this.taskTypeHoliday = 'Urlaub';
    _this.taskTypeOther = 'Sonstiges';
    _this.taskTypeOvertimeReduction = 'Überstundenabbau';
    _this.taskTypeShortTimeWork = 'Kurzarbeit';
    _this.taskTypeSick = 'Krank';
    _this.taskTypeWinterFailure = 'Winterausfall';
    _this.taskTypeWinterFailureViaSubstituteFund = 'Winterausfall über Ersatzkasse';
    _this.taskTypeWork = 'Arbeitszeit';
    _this.taskWageType = 'Lohnart';
    _this.taskWageTypeRequiredIfFollowUp = 'Lohnart wird benötigt, wenn Folgelohnart vorhanden ist';
    _this.tasks = 'Tätigkeiten';
    _this.team = 'Team';
    _this.teamAddMember = 'Teammitglied hinzufügen';
    _this.teamAddPeople = 'Personen einladen';
    _this.teamAddPeoplePlaceholder = 'E-Mail-Adresse';
    _this.teamAddPeopleText = 'Laden Sie Nutzer per E-Mail zu Ihrem Projekt ein:';
    _this.teamChangeRole = 'Rolle ändern';
    _this.teamDeleteInvitation = 'Einladung verwerfen';
    _this.teamFilterHeader = 'Projektmitglieder filtern';
    _this.teamInvitationPending = 'Einladung ausstehend';
    _this.teamInvite = 'einladen';
    _this.teamLeaveProject = 'Projekt verlassen';
    _this.teamMemberActive = 'Aktiv';
    _this.teamMemberInvited = 'Eingeladen';
    _this.teamMemberRemoved = 'Entfernt';
    _this.teamMemberStatus = 'Status';
    _this.teamNoMember = 'Keine Projektmitglieder';
    _this.teamNoMemberHintCompany = 'Fügen Sie sich selbst über den + Button unten als Projektleiter hinzu';
    _this.teamNoMemberText = 'Fügen Sie jetzt Teammitglieder hinzu um in diesem Projekt zu arbeiten';
    _this.teamNoMemberTextCompany = 'Projektmitglieder sind erst sichtbar, wenn Sie Mitglied im Projekt sind';
    _this.teamNoSearchResultHint = 'Kein Projektmitglied stimmt mit Ihrer Suche überein';
    _this.teamNoSearchResultText = 'Keine Projektmitglieder gefunden';
    _this.teamRemoveInvitation = 'Einladung zurückziehen';
    _this.teamRemoveInvitationAlert = 'Möchten Sie die Einladung wirklich zurückziehen?';
    _this.teamRemovePeople = 'Möchten Sie das Teammitglied "{{username}}" wirklich entfernen?';
    _this.teamRemovePeopleMe = 'Möchten Sie das Projekt wirklich verlassen?';
    _this.teamRemoveUser = 'Mitglied entfernen';
    _this.teamRemoveUserShowTickets = 'Zugewiesene Tickets anzeigen';
    _this.teamResendInvitation = 'Erneut senden';
    _this.teamResendSuccess = 'Die E-Mail wurde erfolgreich versendet!';
    _this.teamRolesBasedOn = 'Basiert auf: ';
    _this.teamRolesFreeTooltip = 'Rolle für alle Lizenznehmer';
    _this.teamRolesPaidOnlyTooltip = 'Rolle nur für Pro oder Enterprise Lizenznehmer';
    _this.teamSearchMembers = 'Projektmitglieder suchen';
    _this.testLang = 'test';
    _this.thumb = 'Vorschau';
    _this.ticket = 'Ticket';
    _this.ticketChangePlanSelectPlanAbort = 'abbrechen';
    _this.ticketChangePlanSelectPlanChangePlan = 'Plan ändern';
    _this.ticketChangePlanSelectPlanSave = 'Position speichern';
    _this.ticketChangePlanSelectPlanTitle = 'Wählen Sie einen Plan aus:';
    _this.ticketChangeTypeWarningRemovedFieldValuesText = 'Die bereits ausgefüllten Felder “{{fields}}” werden gelöscht';
    _this.ticketChangeTypeWarningRemovedFieldValuesTitle = 'Typ wirklich ändern?';
    _this.ticketClient = 'Auftraggeber';
    _this.ticketClientAddress = 'Auftraggeber Adresse';
    _this.ticketClientEmail = 'Auftraggeber E-Mail';
    _this.ticketClientPhone = 'Auftraggeber Telefon';
    _this.ticketComments = 'Kommentare';
    _this.ticketCopyFileToTicketErrorOffline = 'Die Datei ist zur Zeit Offline nicht verfügbar.';
    _this.ticketDetailsCommentDeleted = 'Dieser Kommentar wurde gelöscht.';
    _this.ticketDetailsCopiedLink = 'Kopiert!';
    _this.ticketDetailsDueDate = 'Fälligkeitsdatum';
    _this.ticketDetailsDuplicateTicket = 'Duplizieren';
    _this.ticketFieldAddress = 'Baustellenadresse';
    _this.ticketFiles = 'Dateien';
    _this.ticketFilter = 'Tickets filtern';
    _this.ticketFilterAddTeammember = 'Hinzufügen';
    _this.ticketFilterApprovedBy = 'Prüfer';
    _this.ticketFilterArchived = 'Archivierte Tickets anzeigen';
    _this.ticketFilterAssignedTo = 'Zugewiesener';
    _this.ticketFilterCompletionTill = 'Fällig bis';
    _this.ticketFilterCreatedBy = 'Ersteller';
    _this.ticketFilterDeleted = 'Gelöschte Tickets anzeigen';
    _this.ticketFilterHideSubTickets = 'Sub-Tickets verbergen';
    _this.ticketFilterLastModifiedBy = 'Letzter Bearbeiter';
    _this.ticketFilterMyTickets = 'Meine Tickets';
    _this.ticketFilterNotAssignedTickets = 'Nicht gesetzt';
    _this.ticketFilterPersonRoles = 'Rolle';
    _this.ticketFilterTicketHierarchyAll = 'Alle Tickets';
    _this.ticketFilterTicketHierarchyLabel = 'Ticket Hierarchie';
    _this.ticketFilterTicketHierarchyOnlyMain = 'Nur Haupttickets';
    _this.ticketFilterTicketHierarchyOnlySub = 'Nur Subtickets';
    _this.ticketFilterWatchedBy = 'Beobachter';
    _this.ticketHistory = 'Verlauf';
    _this.ticketImportAndExportMenu = 'Import und Export';
    _this.ticketImportedInformation = 'Diese Informationen wurden aus BPS Bau importiert';
    _this.ticketLayoutCopyFromCompany = 'Aus Firmenvorlagen kopieren';
    _this.ticketLayoutCopyFromProject = 'Aus Projekten kopieren';
    _this.ticketLayoutCopyFromProjectTitle = 'Wählen Sie das zu kopierende Layout';
    _this.ticketLayoutDiscardChangesQuestion = 'Nicht gespeicherte Inhalte vorhanden. Inhalte verwerfen?';
    _this.ticketLayoutDuplicateFieldName = 'Doppelte Bezeichnungen eines Feldes sind nicht möglich: {{label}}';
    _this.ticketLayoutEditApprover = 'Prüfer';
    _this.ticketLayoutEditAssignee = 'Zugewiesenen';
    _this.ticketLayoutEditText = 'Diese Feld darf zusätzlich zum Recht “Alle Tickets bearbeiten” geändert werden vom';
    _this.ticketLayoutEditTypes = 'Tickettypen zuweisen';
    _this.ticketLayoutEditTypesWarningDescription = 'Das Layout aller Tickets mit folgenden Typen wird geändert: {{types}}';
    _this.ticketLayoutEditTypesWarningTitle = 'Zugewiesene Typen wirklich ändern?';
    _this.ticketLayoutOptionsDateRange = 'Datumsbereich';
    _this.ticketLayoutOptionsDateWithTime = 'Mit Uhrzeit';
    _this.ticketLayoutOptionsDefaultValue = 'Standardwert';
    _this.ticketLayoutOptionsDescription = 'Beschreibung (optional)';
    _this.ticketLayoutOptionsDividerDescription = 'Beschreibung';
    _this.ticketLayoutOptionsDividerTitle = 'Titel';
    _this.ticketLayoutOptionsIdentifier = 'Technischer Name';
    _this.ticketLayoutOptionsIdentifierHelperText = 'Zugriff auf die Werte des Feldes in Word Vorlagen, nur a-z verwenden';
    _this.ticketLayoutOptionsLabel = 'Name';
    _this.ticketLayoutOptionsListItems = 'Optionen';
    _this.ticketLayoutOptionsMultiline = 'Mehrzeilig';
    _this.ticketLayoutOptionsNumberDelimiter = 'Trennzeichen';
    _this.ticketLayoutOptionsNumberDezimals = 'Nachkommastellen';
    _this.ticketLayoutOptionsNumberMax = 'Maximum (optional)';
    _this.ticketLayoutOptionsNumberMin = 'Minimum (optional)';
    _this.ticketLayoutOptionsNumberUnit = 'Einheit (optional)';
    _this.ticketLayoutOptionsNumberUnitHelperText = 'z.B. €, $, m, %';
    _this.ticketLayoutOptionsProgressDefaultNotInRange = 'Standardwert muss im Wertebereich liegen';
    _this.ticketLayoutOptionsProgressEnd = 'Ende';
    _this.ticketLayoutOptionsProgressEndSmallerThanStart = 'Ende kann nicht kleiner als Start sein';
    _this.ticketLayoutOptionsProgressStart = 'Start';
    _this.ticketLayoutOptionsProgressStepWidth = 'Schrittweite (optional)';
    _this.ticketLayoutOptionsProgressStepWidthBelowOne = 'Schrittweite muss größer als 0 sein';
    _this.ticketLayoutOptionsTileEdit = 'Feld "{{title}}" bearbeiten';
    _this.ticketLayoutOptionsTileNew = 'Feld erstellen';
    _this.ticketLayoutRemoveFieldQuestion = 'Möchten Sie das Feld "{{title}}" wirklich löschen?';
    _this.ticketLayoutTitleChange = 'Ticketlayout "{{title}}" bearbeiten';
    _this.ticketLayoutTitleCreate = 'Ticketlayout erstellen';
    _this.ticketLayoutTitleCreateNew = 'Neues Ticketlayout erstellen';
    _this.ticketLayouts = 'Ticketlayouts';
    _this.ticketMappingDialogAction = 'Übernehmen';
    _this.ticketMappingDialogNew = 'Neu';
    _this.ticketMappingDialogOld = 'Vorhanden';
    _this.ticketMappingDialogText = 'Positionieren Sie die Tickets auf dem neuen Plan an den gleichen Stellen wie im vorhandenen Plan. ' + 'Ziehen Sie dafür die Tickets auf die entsprechenden Positionen im neuen Plan.';
    _this.ticketMappingDialogTextNoTickets = 'Möchten Sie die neue Planversion übernehmen?';
    _this.ticketMappingDialogTitle = 'Ticket Verortung aktualisieren';
    _this.ticketMappingDialogTitleNoTickets = 'Planversion übernehmen';
    _this.ticketNoAccessRight = 'Sie haben keine Berechtigung (mehr) für den Zugriff auf das Ticket';
    _this.ticketReadViewDescription = 'Beschreibung';
    _this.ticketReadViewDueDate = 'Fälligkeitsdatum';
    _this.ticketReadViewTitle = 'Tickettitel';
    _this.ticketReportByTemplate = 'Vorlagen';
    _this.ticketReportDefault = 'Standardbericht';
    _this.ticketState = 'Ticket Status';
    _this.ticketStateChangeTitle = 'Status "{{originalState}}" bearbeiten';
    _this.ticketStateCustom = 'Eigener';
    _this.ticketStateOrder = 'Nr.';
    _this.ticketStateOriginal = 'Original';
    _this.ticketStatusInvalid = 'Ungültiger Ticketstatus';
    _this.ticketSubordinate = 'Untergeordnet';
    _this.ticketSuperordinate = 'Übergeordnet';
    _this.ticketType = 'Tickettyp';
    _this.ticketTypeAddendum = 'Nachtrag';
    _this.ticketTypeClaim = 'Nachtrag';
    _this.ticketTypeClientRequest = 'Bauherrenwunsch';
    _this.ticketTypeConditionAssessment = 'Zustandsfeststellung';
    _this.ticketTypeDefect = 'Mangel';
    _this.ticketTypeDocumentation = 'Dokumentation';
    _this.ticketTypeIndication = 'Hinweis';
    _this.ticketTypeInventory = 'Bestandsaufnahme';
    _this.ticketTypeNotSelected = 'Kein Typ';
    _this.ticketTypeNote = 'Hinweis';
    _this.ticketTypeObstruction = 'Behinderung';
    _this.ticketTypeOutstandingWork = 'Restleistung';
    _this.ticketTypeTask = 'Aufgabe';
    _this.ticketTypeWarrantyDeficiency = 'Gewährleistungsmangel';
    _this.ticketTypes = 'Tickettypen';
    _this.ticketTypesShort = 'Typen';
    _this.tickets = 'Tickets';
    _this.ticketsAddFile = 'Datei zum Ticket {{ticketTitle}} hinzufügen?';
    _this.ticketsAddFiles = '{{counted}} Dateien zum Ticket {{ticketTitle}} hinzufügen?';
    _this.ticketsAddImage = 'Bild zum Ticket hinzufügen?';
    _this.ticketsAddImages = '{{counted}} Bilder zum Ticket hinzufügen?';
    _this.ticketsAddTicket = 'Ticket erstellen';
    _this.ticketsCalendarMoveNoDateError = 'Das Ticket hat kein Datumsfeld';
    _this.ticketsCalendarMoveWhichDate = 'Welches Datum soll geändert werden?';
    _this.ticketsCraftNotSelected = 'Kein Gewerk';
    _this.ticketsCreateNewTicketQuestion = 'Ein neues Ticket erstellen?';
    _this.ticketsDetails = 'Details';
    _this.ticketsDetailsActivities = 'Aktivitäten';
    _this.ticketsDetailsAddAttachment = 'Anhang hinzufügen';
    _this.ticketsDetailsAddComment = 'Kommentar hinzufügen';
    _this.ticketsDetailsAddDescription = 'Beschreibung hinzufügen';
    _this.ticketsDetailsAddWatcher = 'Beobachter hinzufügen';
    _this.ticketsDetailsChangeCommentError = 'Fehler beim Bearbeiten des Ticket- Kommentars.';
    _this.ticketsDetailsCompletionOn = 'Fällig am';
    _this.ticketsDetailsCopyLinkToTicket = 'Link kopieren';
    _this.ticketsDetailsCraft = 'Gewerk';
    _this.ticketsDetailsDescription = 'Beschreibung';
    _this.ticketsDetailsEditableAddTitle = 'Titel hinzufügen';
    _this.ticketsDetailsErrorGenericIssue = 'Fehler beim Bearbeiten des Tickets.';
    _this.ticketsDetailsErrorStatusChange = 'Sie haben keine Berechtigung, den Ticketstatus in "{{newState}}" zu ändern.';
    _this.ticketsDetailsGetCommentError = 'Fehler beim Abrufen der Ticket- Kommentare.';
    _this.ticketsDetailsLastEdit = 'Letzte Bearbeitung';
    _this.ticketsDetailsMap = 'Ausführungsadresse';
    _this.ticketsDetailsNoDescription = 'Keine Beschreibung';
    _this.ticketsDetailsNumber = 'Ticketnummer';
    _this.ticketsDetailsObserver = 'Beobachter';
    _this.ticketsDetailsOpenFileStorage = 'aus Projektablage kopieren';
    _this.ticketsDetailsOpenFiles = 'Dateien hinzufügen';
    _this.ticketsDetailsPlan = 'Planzugehörigkeit';
    _this.ticketsDetailsRemoveTicketLocation = 'Ticketverortung entfernen';
    _this.ticketsDetailsSendCommentError = 'Fehler beim Erzeugen des Ticket- Kommentars.';
    _this.ticketsDetailsState = 'Status';
    _this.ticketsDetailsStateNoStatus = 'Kein Status';
    _this.ticketsDetailsStatechecked = 'Fertig';
    _this.ticketsDetailsStateclosed = 'Zu prüfen';
    _this.ticketsDetailsStatenoStatus = 'Kein Status';
    _this.ticketsDetailsStateopen = 'Offen';
    _this.ticketsDetailsStateprocessing = 'In Bearbeitung';
    _this.ticketsDetailsTags = 'Tags';
    _this.ticketsDetailsTitle = 'Titel';
    _this.ticketsDetailsTitleApprover = 'Prüfer';
    _this.ticketsDetailsTitleAssignedToUser = 'Zugewiesen an';
    _this.ticketsDetailsTitleNoApproverAssignedToUser = 'Kein Prüfer';
    _this.ticketsDetailsTitleNotAssignedToUser = 'Nicht zugewiesen';
    _this.ticketsDetailsTitlePlaceholder = 'Kein Titel';
    _this.ticketsDetailsType = 'Typ';
    _this.ticketsDetailsTypeDefect = 'Mangel';
    _this.ticketsDetailsViewChangeToCalendarView = 'Kalender-Ansicht';
    _this.ticketsDetailsViewChangeToCanban = 'Canban-Ansicht';
    _this.ticketsDetailsViewChangeToGantt = 'Gantt-Diagrammansicht';
    _this.ticketsDetailsViewChangeToListView = 'Listenansicht';
    _this.ticketsDetailsViewChangeToTableView = 'Tabellenansicht';
    _this.ticketsDetailsWatchNow = 'Ich beobachte nicht';
    _this.ticketsDetailsWatching = 'Ich beobachte';
    _this.ticketsDetailsWriteComment = 'Kommentieren...';
    _this.ticketsFilterEndBeforeStart = 'Ende vor Start';
    _this.ticketsFilterInTwoWeeks = 'In 2 Wochen';
    _this.ticketsFilterPersons = 'Personen';
    _this.ticketsFilterStartOrEndNotSet = 'Ende oder Anfang nicht gesetzt';
    _this.ticketsFilterTimePeriod = 'Änderungszeitraum';
    _this.ticketsFilterToday = 'Heute';
    _this.ticketsFilterTomorrow = 'Morgen';
    _this.ticketsNotFoundDescription = 'Tickets erstellen';
    _this.ticketsNotFoundDescriptionAllProjects = 'Alle Tickets an einem Ort';
    _this.ticketsNotFoundDueToFilter = 'Keine Tickets gefunden...';
    _this.ticketsNotFoundDueToFilterDescription = 'Führen Sie eine neue Suche durch oder benutzen Sie die Filterfunktion.';
    _this.ticketsNotFoundHint = 'Wählen Sie einen Plan aus, um darauf Tickets zu erstellen oder fügen Sie Tickets' + ' ohne Verortung hinzu, indem Sie auf den + Button drücken';
    _this.ticketsNotFoundHintAllProjects = 'Hier finden Sie alle Tickets über alle Projekt an einem Ort. Neue Tickets' + ' erstellen Sie direkt im jeweiligen Projekt';
    _this.ticketsNotFoundHintSplitView = 'Erstellen Sie ein Ticket, indem Sie auf den + Button drücken';
    _this.ticketsShowPlan = 'Plan anzeigen';
    _this.ticketsShowTickets = 'Tickets anzeigen';
    _this.ticketsSortBy = 'Sortieren nach';
    _this.ticketsWithoutCompletionOn = 'Tickets ohne Datum';
    _this.till = 'bis';
    _this.timeTracking = 'Zeiterfassung';
    _this.timeTrackingAddTimeTrack = 'Zeit erfassen';
    _this.timeTrackingBulkChangesChangeCostCenter = 'Kostenstelle ändern';
    _this.timeTrackingBulkChangesChangeEnd = 'Endzeit ändern';
    _this.timeTrackingBulkChangesChangePause = 'Pausenzeit ändern';
    _this.timeTrackingBulkChangesChangeProjekt = 'Projekt ändern';
    _this.timeTrackingBulkChangesChangeStart = 'Startzeit ändern';
    _this.timeTrackingBulkChangesChangeStatus = 'Status ändern';
    _this.timeTrackingBulkChangesChangeTask = 'Tätigkeit ändern';
    _this.timeTrackingBulkChangesChangingTickets = 'Ändere Zeiterfassung {{count}} von {{sum}}.';
    _this.timeTrackingBulkChangesConfirmationDialogHeader = 'Zeiterfassungen bearbeiten';
    _this.timeTrackingBulkChangesConfirmationDialogHeaderDelete = 'Zeiterfassungen löschen';
    _this.timeTrackingBulkChangesConfirmationDialogText = 'Wollen Sie diese {{count}} Zeiterfassungen wirklich bearbeiten?';
    _this.timeTrackingBulkChangesConfirmationDialogTextDelete = 'Wollen Sie diese {{count}} Zeiterfassungen wirklich löschen?';
    _this.timeTrackingBulkChangesNoRights = '{{count}} Zeiterfassungseinträge konnten wegen fehlender Berechtigung nicht geändert werden';
    _this.timeTrackingBulkChangesSelectAll = 'Alle auswählen';
    _this.timeTrackingBulkChangesSelectNone = 'Keine auswählen';
    _this.timeTrackingCSVexportButtonExport = 'Exportieren';
    _this.timeTrackingCSVexportCostCenter = 'Kostenstelle/Kostenträger';
    _this.timeTrackingCSVexportDate = 'Datum';
    _this.timeTrackingCSVexportEnde = 'Ende';
    _this.timeTrackingCSVexportHours = 'Stunden';
    _this.timeTrackingCSVexportHoursOrValue = 'Menge';
    _this.timeTrackingCSVexportIncludeAbsence = 'Abwesenheiten einbinden';
    _this.timeTrackingCSVexportLockText = 'Einträge als "exportiert" kennzeichnen?';
    _this.timeTrackingCSVexportNote = 'Notizen';
    _this.timeTrackingCSVexportPause = 'Pause';
    _this.timeTrackingCSVexportProject = 'Projekt';
    _this.timeTrackingCSVexportStaffNumber = 'Personalnummer';
    _this.timeTrackingCSVexportStart = 'Start';
    _this.timeTrackingCSVexportTask = 'Tätigkeit';
    _this.timeTrackingCSVexportTaskDescription = 'Tätigkeit - Bezeichnung';
    _this.timeTrackingCSVexportText = 'Es werden durch die aktuellen Filtereinstellungen {{count}} Einträge exportiert. Sie können den Filter nutzen, um' + ' die Auswahl zu ändern.';
    _this.timeTrackingCSVexportUnit = 'Einheit';
    _this.timeTrackingCSVexportUser = 'Mitarbeiter';
    _this.timeTrackingCSVexportWageType = 'Lohnart';
    _this.timeTrackingCapture = 'Erfassen';
    _this.timeTrackingCaptureNewWorktime = 'Neue Arbeitszeit erfassen';
    _this.timeTrackingCaptureTime = 'Zeit erfassen';
    _this.timeTrackingChangeFoundCollision = 'Es gibt kollidierende Einträge';
    _this.timeTrackingChangeProject = 'Projekt auswählen';
    _this.timeTrackingChangeSaveAnyway = 'speichern';
    _this.timeTrackingChangeShowCollision = 'Einträge anzeigen';
    _this.timeTrackingChangeStatus = 'Status ändern';
    _this.timeTrackingChangeTimeTrack = 'Zeiterfassung ändern';
    _this.timeTrackingCurrentPause = 'pausiert {{currentPause}}';
    _this.timeTrackingDeleteEntryQuestion = 'Möchten Sie diesen Eintrag wirklich löschen?';
    _this.timeTrackingEndAuto = 'Automatisch beendet durch Routine';
    _this.timeTrackingEntryLocked = 'Der Eintrag ist für Veränderungen gesperrt';
    _this.timeTrackingErrorEndsBeforeStart = 'Endzeit vor Startzeit';
    _this.timeTrackingErrorEndsStartDiff = 'Die Gesamtzeit darf nicht größer als 24 Stunden sein';
    _this.timeTrackingExportAddisonCompanyInvalid = 'Die Firmennummer "{{error}}" ist ungültig für einen Addison-Export. (maximal 7 Ziffern).';
    _this.timeTrackingExportAddisonCompanyNumber = 'Firmennummer';
    _this.timeTrackingExportAddisonCompanyNumberNeeded = 'Für den Addison-Export wird eine Firmennummer benötigt.';
    _this.timeTrackingExportDatevAdditionalFields = 'Für den LODAS-Export wird eine MandantenNr und eine BeraterNr benötigt.';
    _this.timeTrackingExportDatevBeraterInvalid = 'Die Beraternummer "{{error}}" ist ungültig für einen LODAS-Export. (mindestens 4 maximal 7 Stellen)';
    _this.timeTrackingExportDatevBeraterNrNeeded = 'BeraterNr';
    _this.timeTrackingExportDatevLohnAccountingDate = 'Abrechnungdatum';
    _this.timeTrackingExportDatevLohnBeraterInvalid = 'Die BeraterNr "{{error}}" ist ungültig. (Wertebereich von 1000 bis 9999999.)';
    _this.timeTrackingExportDatevLohnIniFileDownload = 'INI-Datei herunterladen';
    _this.timeTrackingExportDatevLohnMandantInvalid = 'Die MandantenNr "{{error}}" ist ungültig. (Wertebereich von 1 bis 99999.)';
    _this.timeTrackingExportDatevLohnPersAsCompanyPers = 'Personalnummer als betriebliche Personalnummer kennzeichnen';
    _this.timeTrackingExportDatevMandantenInvalid = 'Die MandantenNr "{{error}}" ist ungültig für einen LODAS-Export. (mindestens eine maximal 5 Stellen)';
    _this.timeTrackingExportDatevMandantenNrNeeded = 'MandantenNr';
    _this.timeTrackingExportExcelActualTime = 'Ist Zeit';
    _this.timeTrackingExportExcelDiffTime = 'Differenz';
    _this.timeTrackingExportExcelFilename = 'Stundenliste';
    _this.timeTrackingExportExcelNoEntries = 'Keine Einträge zum Exportieren vorhanden.';
    _this.timeTrackingExportExcelNotOnline = 'Das Exportieren der Zeiterfassungseinträge ist nur Online möglich.';
    _this.timeTrackingExportExcelOverview = 'Übersicht';
    _this.timeTrackingExportExcelRecords = 'Erfassungseinträge';
    _this.timeTrackingExportExcelStaffNumber = 'Personal-Nr';
    _this.timeTrackingExportExcelSumAsDecimal = 'Gesamt:';
    _this.timeTrackingExportExcelTargetTime = 'Soll Zeit';
    _this.timeTrackingExportExcelTooltip = 'Excel-Export der Zeiterfassungstabelle';
    _this.timeTrackingExportNovalineKndNrHelper = 'Kunden-Nr.';
    _this.timeTrackingExportOptionsAddison = 'Addison-Export';
    _this.timeTrackingExportOptionsBRZ = 'BRZ-Export';
    _this.timeTrackingExportOptionsCSV = 'CSV-Export';
    _this.timeTrackingExportOptionsDatev = 'LODAS-Export';
    _this.timeTrackingExportOptionsGDI = 'GDI-Export';
    _this.timeTrackingExportOptionsLohnUndGehalt = 'Datev Lohn und Gehalt';
    _this.timeTrackingExportOptionsNovaline = 'Novaline-Export';
    _this.timeTrackingExportTitle = 'Exportieren';
    _this.timeTrackingExportType = 'Exporttyp';
    _this.timeTrackingExportWarningText = 'Einige Kennzeichen sind ungültig und wurden im Export angepasst:';
    _this.timeTrackingFilter = 'Erfassungseinträge filtern';
    _this.timeTrackingFilterDateRange = 'Zeitraum';
    _this.timeTrackingFilterDay = 'Vom';
    _this.timeTrackingFilterDayTo = 'Bis';
    _this.timeTrackingFilterLastCalcMonth = 'Letzten Abrechnungsmonat';
    _this.timeTrackingFilterLastMonth = 'Letzten Monat';
    _this.timeTrackingFilterLastWeek = 'Letzte Woche';
    _this.timeTrackingFilterLastYear = 'Letztes Jahr';
    _this.timeTrackingFilterStatus = 'Status';
    _this.timeTrackingFilterStatusAll = 'Alle';
    _this.timeTrackingFilterStatusExported = 'exportiert';
    _this.timeTrackingFilterStatusLocked = 'gesperrt';
    _this.timeTrackingFilterStatusRecorded = 'erfasst';
    _this.timeTrackingFilterStatusRunning = 'laufend';
    _this.timeTrackingFilterTask = 'Tätigkeiten filtern';
    _this.timeTrackingFilterThisCalcMonth = 'Diesen Abrechnungsmonat';
    _this.timeTrackingFilterThisMonth = 'Diesen Monat';
    _this.timeTrackingFilterThisWeek = 'Diese Woche';
    _this.timeTrackingFilterThisYear = 'Dieses Jahr';
    _this.timeTrackingFilterToday = 'Heute';
    _this.timeTrackingFilterYesterday = 'Gestern';
    _this.timeTrackingHoursShort = 'Std';
    _this.timeTrackingLock = 'Für andere sperren';
    _this.timeTrackingMinutesShort = 'Min';
    _this.timeTrackingMyTimes = 'Meine Zeiten';
    _this.timeTrackingNoDataHint = 'Erstellen Sie neue Zeiterfassungseinträge, indem Sie auf den + Button drücken';
    _this.timeTrackingNoDataText = 'Keine Zeiten erfasst';
    _this.timeTrackingNoProjectOrCostCenter = 'Kein Projekt und keine Kostenstelle vorhanden';
    _this.timeTrackingNotInRecordingPeriod = 'Das Startdatum liegt nicht im möglichen Erfassungszeitraum';
    _this.timeTrackingNotes = 'Notizen';
    _this.timeTrackingPause = 'Tätigkeit pausieren';
    _this.timeTrackingPlay = 'Tätigkeit wiederaufnehmen';
    _this.timeTrackingRecorder = 'Erfasser';
    _this.timeTrackingShort = 'Zeiten';
    _this.timeTrackingSince = 'seit';
    _this.timeTrackingStatus = 'Status';
    _this.timeTrackingStopTimer = 'Beenden';
    _this.timeTrackingSum = 'Gesamt';
    _this.timeTrackingTaskOrProject = 'Tätigkeit/Projekt wechseln';
    _this.timeTrackingUnlock = 'entsperren';
    _this.timeTrackingViewChangeCalandar = 'Kalenderansicht';
    _this.timeTrackingViewChangeHideMissing = 'Fehlende Erfassungen ausblenden';
    _this.timeTrackingViewChangeList = 'Listenansicht';
    _this.timeTrackingViewChangeShowMissing = 'Fehlende Erfassungen einblenden';
    _this.timeframe = 'Zeitraum';
    _this.timetrackingErrorNoEnd = 'Endzeit wird benötigt';
    _this.timetrackingErrorNoStart = 'Startzeit wird benötigt';
    _this.timetrackingsRunningHeadline = 'Laufende Zeiterfassungen';
    _this.toStartSite = 'Zur Startseite';
    _this.today = 'Heute';
    _this.tokenAlreadyExpired = 'Der Token ist bereits abgelaufen';
    _this.tokenError = 'Zugang abgelaufen';
    _this.tomorrow = 'Morgen';
    _this.tooltipAddTicketWithPlanAffiliation = 'Neues Ticket mit Planzugehörigkeit erstellen';
    _this.tooltipBackToTicketSite = 'Zurück zur Ticket- Übersicht';
    _this.tooltipCreateMap = 'Karte erstellen';
    _this.tooltipCreateNewProject = 'Neues Projekt erstellen';
    _this.tooltipDown = 'Runter';
    _this.tooltipEditPlanDetails = 'Plan- Details bearbeiten';
    _this.tooltipEditProjectDetails = 'Projektdetails bearbeiten';
    _this.tooltipEditTicketComment = 'Ticketkommentar bearbeiten';
    _this.tooltipEditTicketDescription = 'Ticketbeschreibung bearbeiten';
    _this.tooltipEditTicketTitle = 'Tickettitel bearbeiten';
    _this.tooltipOpenCamera = 'Kamera öffnen';
    _this.tooltipOpenFiling = 'Dateien';
    _this.tooltipPlanZoomIn = 'Plan vergrößern';
    _this.tooltipPlanZoomOut = 'Plan verkleinern';
    _this.tooltipTicketsDetailsSend = 'Ticketkommentar abschicken';
    _this.tooltipUp = 'Hoch';
    _this.tooltipUploadPlan = 'Pläne hochladen';
    _this.tooltopFilterProjects = 'Projekte filtern';
    _this.totalNumberOfEmployees = 'Gesamtzahl Mitarbeiter';
    _this.totalNumberOfHours = 'Gesamtzahl Stunden';
    _this.trackingType = 'Erfassungstyp';
    _this.trackingTypeAbsence = 'Abwesenheit';
    _this.trackingTypeExtraPay = 'Zulage';
    _this.trackingTypeTime = 'Zeit';
    _this.type = 'Art';
    _this.unarchiveTicket = 'Ticket reaktivieren';
    _this.unarchiveTicketShort = 'reaktivieren';
    _this.unauthorized = 'nicht autorisiert';
    _this.undefined = '?';
    _this.unsavedChangesInDialog = 'Änderungen wurden nicht gespeichert.';
    _this.upTicket = 'Übergeordnetes Ticket';
    _this.updateNeededButton = 'Jetzt aktualisieren';
    _this.updateNeededText = 'Es wird ein Update für die Serververbindung benötigt. Bitte aktualisieren Sie die App.';
    _this.updateNeededTitle = 'Update benötigt';
    _this.upgradeProject = 'Upgrade';
    _this.upgradeProjectConfirmationMessageEnterprise = 'Sind Sie sicher, dass Sie das Projekt zu einem Firmen-Projekt (Enterprise)' + ' upgraden möchten?\nDann gehört das Projekt ab sofort Ihrer Firma.\nDas Upgrade kann nicht rückkängig gemacht' + ' werden!';
    _this.upgradeProjectConfirmationMessageEnterpriseOrPro = 'Möchten Sie das Projekt zu einem Pro-Projekt oder zu einem' + ' Firmen-Projekt upgraden?\nDas Firmen-Projekt (Enterprise) gehört dann ab sofort der Firma.\nDas Upgrade kann' + ' nicht rückgängig gemacht werden';
    _this.upgradeProjectConfirmationMessagePro = 'Sind Sie sicher, dass Sie das Projekt zu einem Pro-Projekt upgraden' + ' möchten?\n Das Upgrade kann nicht rückgängig gemacht werden!';
    _this.upgradeProjectConfirmationToEnterpriseButton = 'Firmen-Projekt';
    _this.upgradeProjectConfirmationToProButton = 'Pro-Projekt';
    _this.upgradeProjectQuestion = 'Sie müssen das Projekt upgraden, um diese Funktion nutzen zu können. Das Upgrade kann' + ' im Dashboard durchgeführt werden. Wollen Sie zum Dashboard wechseln?';
    _this.upgradeProject_enterprise = 'Upgrade zu Firmen-Projekt';
    _this.upgradeProject_pro = 'Upgrade zu Pro-Projekt';
    _this.uploadCompanyBackGroundLogo = 'Hintergrundbild hochladen';
    _this.uploadCompanyLogo = 'Firmenlogo hochladen';
    _this.uploadFiles = 'Datei(en) hochladen';
    _this.uploadFolder = 'Ordner hochladen';
    _this.uploadNewLogo = 'Neu hochladen';
    _this.uploadPending = 'Upload ausstehend';
    _this.upmeshPartner = 'Partner';
    _this.use = 'Anwenden';
    _this.user = 'Benutzer';
    _this.userAdress = 'Benutzeradressen';
    _this.userAlreadyAssignedToOneCompany = 'Der Benutzer ist bereits in einer anderen Firma gelistet';
    _this.userAlreadyDeleted = 'Benutzer bereits entfernt';
    _this.userDetailsBackToUser = 'Mitarbeiter infos anzeigen';
    _this.userDetailsQrCodeHelp = 'vcard anzeigen';
    _this.userDetailsQrDownload = 'vcard herunterladen';
    _this.userId = 'Benutzer';
    _this.userMailNotFound = 'E-Mail-Adresse nicht gefunden';
    _this.userMails = 'Benutzer Mails';
    _this.userNotFound = 'Benutzer nicht gefunden';
    _this.userSettings = 'Benutzereinstellungen';
    _this.userWithNoAccountCantGetFullLicense = 'Einem Benutzer ohne Account kann keine Volllizenz zugeordnet werden';
    _this.username = 'Benutzername';
    _this.validFrom = 'Gültig ab';
    _this.videos = 'Videos';
    _this.watcher = 'Beobachter';
    _this.watcherIamNotWatching = 'Beobachten';
    _this.watcherIamWatching = 'Beobachtung beenden';
    _this.watcherMemberAreWatching = 'Dieses Ticket wird beobachtet von:';
    _this.watcherMemberEdit = 'Beobachter bearbeiten';
    _this.weather = 'Wetter';
    _this.website = 'Webseite';
    _this.week = 'Woche';
    _this.weeks = `{{count}} Wochen`;
    _this.welcome = 'Willkommen';
    _this.whatsNewBugs = 'Problembehebungen';
    _this.whatsNewFeatures = 'Neuerungen';
    _this.whatsNewShow = 'Neuerungen anzeigen';
    _this.whatsNewTitle = 'Neues in Version {{version}}';
    _this.widgetIframeInvalidUrl = 'Bitte geben sie eine gültige URL ein';
    _this.widgetIframeTitle = 'Titel';
    _this.widgetIframeUrl = 'Url';
    _this.workingTimeModel = 'Arbeitszeitmodell';
    _this.workingTimeModelAdd = 'Arbeitszeitmodell hinzufügen';
    _this.workingTimeModelAddSection = 'Zeitabschnitt hinzufügen';
    _this.workingTimeModelBillingDate = 'Beginn des Abrechnungszeitraums:';
    _this.workingTimeModelCantDeleteDefault = 'Das Standard Arbeitszeitmodell kann nicht gelöscht werden';
    _this.workingTimeModelCantDeleteUsed = 'Das Arbeitszeitmodell kann nicht gelöscht werden solange es einem aktiven Benutzer zugewiesen ist oder ein' + ' gelöschter Benutzer noch Zeiten erfasst hat. Benutzer: {{users}}';
    _this.workingTimeModelEdit = 'Arbeitszeitmodell bearbeiten';
    _this.workingTimeModelMark = 'Kennzeichen';
    _this.workingTimeModelNoSectionsWithSameValidDate = 'Es darf kein Zeitabschnitt mit der selben Gültigkeit geben (Abschnitt {{section1}} und Abschnitt {{section2}})';
    _this.workingTimeModelPostRecordingInDays = 'Nacherfassen innnerhalb Tagen ermöglichen';
    _this.workingTimeModelPostRecordingInDaysText = 'Nacherfassen möglich innnerhalb des letzten Tages';
    _this.workingTimeModelPostRecordingInDaysText_0 = 'Keine Nacherfassen möglich';
    _this.workingTimeModelPostRecordingInDaysText_plural = 'Nacherfassen möglich innnerhalb der letzten {{count}} Tage';
    _this.workingTimeModelPostRecordingInDaysText_zero = 'Keine Nacherfassen möglich';
    _this.workingTimeModelPostRecordingInDaysTooLarge = 'Der Zeitraum für das Nacherfassen ist zu groß';
    _this.workingTimeModelSectionRequired = 'Es wird mindestens ein Zeitabschnitt benötigt';
    _this.workingTimeModelSetAsDefault = 'als Standard setzen';
    _this.workingTimeModels = 'Arbeitszeitmodelle';
    _this.workingTimeModelsPause = 'Pause';
    _this.workingTimeModelsStart = 'Start';
    _this.workingTimeModelsStop = 'Ende';
    _this.workingTimeModelsTimes = 'Zeiten';
    _this.year = 'Jahr';
    _this.yes = 'Ja';
    _this.yesterday = 'Gestern';
    _this.zoomAndMoveToDefinePlanSnapshot = 'Zoomen und verschieben um Planausschnitt zu definieren';
    _this.zoomError = 'Fehler beim Ändern der Zoomstufe.';
    _this.zoomIn = 'vergrößern';
    _this.zoomOut = 'verkleinern';
    return _this;
  }
  (0, _inherits2.default)(MessagesDe, _MessagesDeTheme);
  return (0, _createClass2.default)(MessagesDe);
}(_MessagesDe.MessagesDe);