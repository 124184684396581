"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConfigHandler = void 0;
var Config_1 = require("upmesh-core/build/src/Config");
var ConfigHandler = function (_Config_1$ConfigHandl) {
  function ConfigHandler() {
    (0, _classCallCheck2.default)(this, ConfigHandler);
    return _callSuper(this, ConfigHandler, arguments);
  }
  (0, _inherits2.default)(ConfigHandler, _Config_1$ConfigHandl);
  return (0, _createClass2.default)(ConfigHandler, null, [{
    key: "config",
    get: function get() {
      if (ConfigHandler._instance5 == null) {
        ConfigHandler._instance5 = ConfigHandler.createDefaultConfig();
        Config_1.ConfigHandler.setConfig(ConfigHandler._instance5);
      }
      return ConfigHandler._instance5;
    }
  }, {
    key: "setConfig",
    value: function setConfig(config) {
      ConfigHandler._instance5 = config;
      Config_1.ConfigHandler.setConfig(config);
    }
  }, {
    key: "createDefaultConfig",
    value: function createDefaultConfig() {
      var defaultConfig = {
        db: 'nedb',
        JWT_TOKEN_SECRET: 'cdKFAUfbwqeui239038ubnvcDSKbfg239ß1238',
        JWT_EXP_DAYS: 60,
        BCRYPT_SALT_ROUNDS: 12,
        cacheSize: '200MB',
        REDIS_PORT: 30634,
        REDIS_HOST: '127.0.0.1',
        REDIS_PASS: 'GoizrRSqRfdSQNirh9Oo',
        inMemoryDB: true,
        dbName: 'jest',
        webServerUrl: 'http://127.0.0.1:3077/support/api',
        startSwagger: false,
        eventServerUrl: 'http://127.0.0.1:3016/api',
        startsWithTestData: false,
        GOOGLE_CLIENT_ID: '621430492376-2k4s8d0gou8pr84lq01hce41blmkj95r.apps.googleusercontent.com',
        GOOGLE_SECRET: 'AIzaSyBBhCkti9zOgpbSgRBUSOTPE7s2mIcnPJo',
        authURL: 'http://127.0.0.1:3012/api',
        appUrlShort: 'upmesh.de',
        appUrl: 'http://127.0.0.1:8085',
        companyName: 'upmesh',
        companyNameFull: 'upmesh UG',
        supportMail: 'moin@upmesh.de',
        MONGO_URI: 'authUser42124:BuvTSVykQUS1AR8kmiRi@127.0.0.1/acc',
        cors: true,
        onlyWebsocketTransport: true,
        MAIL_SMTP_HOST: 'w0156da7.kasserver.com',
        MAIL_SMTP_PORT: 587,
        MAIL_STMP_USE_SSL: false,
        MAIL_NAME: '"upmesh" <noreply@upmesh.de>',
        MAIL_SMTP_USER: 'm04c2599',
        authServerUrl: 'http://127.0.0.1:3012/api',
        osmstaticUrl: 'http://osmstatic:3000',
        MAIL_SMTP_REPLYMAIL: 'moin@upmesh.de',
        MAIL_SMTP_PASSWORD: 'LYBlbTW52c20KRYiNcWF',
        fileHandler: 'node',
        fileHandlerPath: './files',
        maxUploadSizeInBytes: 104857600,
        version: '0.0.0',
        environment: 'development',
        serverName: 'support-core',
        S3_ACCESS_KEY_ID: '',
        S3_SECRET_ACCESS_KEY: '',
        S3_ENDPOINT: '',
        sendMails: true,
        billwerkUrl: 'https://sandbox.billwerk.com',
        billwerkUser: '5e5cd7a84de08410c00e063d',
        billwerkPW: '4f425296e7d4f78e89c4a4b4719ad4a4',
        accessTokenSecret: '12vb2348tcfKUHZGasdfFGBHG(iuhb985vbz',
        cronCacheClean: '',
        leaderElection: true,
        string_Short: 64,
        string_Medium: 256,
        string_Large: 512,
        string_Huge: 8192,
        tracing: true,
        updateLastLoginOnLoginWithToken: true
      };
      return ConfigHandler.getEnvirementConfig(defaultConfig);
    }
  }]);
}(Config_1.ConfigHandler);
exports.ConfigHandler = ConfigHandler;