var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CheckboxListItem = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _ThemeManager = require("../ThemeManager");
var _Checkbox = require("./Checkbox");
var _ListItem = require("./ListItem");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var CheckboxListItem = exports.CheckboxListItem = function (_PureComponent) {
  function CheckboxListItem(props) {
    var _this;
    (0, _classCallCheck2.default)(this, CheckboxListItem);
    _this = _callSuper(this, CheckboxListItem, [props]);
    _this.onPressText = function (_e) {
      if (_this.checkbox != null && !_this.props.disabled) {
        _this.checkbox.setValue(!_this.checkbox.getValue());
      }
    };
    _this.setCheckboxRef = function (ref) {
      _this.checkbox = ref;
    };
    _this.state = {
      value: !!props.initValue
    };
    return _this;
  }
  (0, _inherits2.default)(CheckboxListItem, _PureComponent);
  return (0, _createClass2.default)(CheckboxListItem, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        title = _this$props.title,
        onPressText = _this$props.onPressText,
        listItemProps = _this$props.listItemProps,
        accessibilityLabel = _this$props.accessibilityLabel,
        initValue = _this$props.initValue,
        disabled = _this$props.disabled,
        dense = _this$props.dense;
      var onPressAll = onPressText != null ? undefined : this.onPressText;
      return (0, _jsxRuntime.jsx)(_ListItem.ListItem, Object.assign({
        height: dense ? 32 : undefined
      }, listItemProps, {
        thumbnail: {
          thumbnail: (0, _jsxRuntime.jsx)(_Checkbox.Checkbox, Object.assign({
            initValue: initValue,
            ref: this.setCheckboxRef
          }, this.props, {
            accessibilityLabel: accessibilityLabel != null ? accessibilityLabel : title
          })),
          width: 40
        },
        textColor: disabled ? _ThemeManager.ThemeManager.style.black42 : _ThemeManager.ThemeManager.style.defaultTextColor,
        onPress: onPressAll,
        title: title
      }));
    }
  }, {
    key: "blur",
    value: function blur() {}
  }, {
    key: "clear",
    value: function clear() {}
  }, {
    key: "focus",
    value: function focus() {}
  }, {
    key: "getId",
    value: function getId() {
      return '';
    }
  }, {
    key: "getValue",
    value: function getValue() {
      return this.checkbox.getValue();
    }
  }, {
    key: "setValue",
    value: function setValue(value) {
      return this.checkbox.setValue(value);
    }
  }]);
}(_react.PureComponent);