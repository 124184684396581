var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChipContainer = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _Border = require("../Border");
var _Ripple = require("../utils/Ripple");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ChipContainer = exports.ChipContainer = function (_PureComponent) {
  function ChipContainer() {
    (0, _classCallCheck2.default)(this, ChipContainer);
    return _callSuper(this, ChipContainer, arguments);
  }
  (0, _inherits2.default)(ChipContainer, _PureComponent);
  return (0, _createClass2.default)(ChipContainer, [{
    key: "getBorder",
    value: function getBorder(chipContainer) {
      var _this$props = this.props,
        borderProps = _this$props.borderProps,
        outlined = _this$props.outlined;
      if (!outlined) {
        return chipContainer;
      }
      return (0, _jsxRuntime.jsx)(_Border.Border, Object.assign({}, borderProps, {
        borderRadius: 16,
        style: {
          overflow: 'hidden'
        },
        children: chipContainer
      }));
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props2 = this.props,
        onPress = _this$props2.onPress,
        disabled = _this$props2.disabled,
        onMouseEnter = _this$props2.onMouseEnter,
        onMouseLeave = _this$props2.onMouseLeave,
        backgroundColor = _this$props2.backgroundColor,
        button = _this$props2.button;
      var chipContainer;
      chipContainer = (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          backgroundColor: backgroundColor,
          padding: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(4),
          height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(32),
          flexDirection: 'row',
          alignItems: 'center',
          overflow: 'hidden'
        },
        children: this.props.children
      });
      if (onPress != null) {
        chipContainer = (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
          onPress: onPress,
          disabled: disabled || onPress == null,
          onMouseEnter: onMouseEnter,
          onMouseLeave: onMouseLeave,
          style: {
            height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(32),
            borderRadius: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16)
          },
          children: chipContainer
        });
      }
      if (button != null) {
        chipContainer = (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            backgroundColor: backgroundColor,
            flexDirection: 'row',
            height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(32),
            borderRadius: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16),
            alignItems: 'center'
          },
          children: [chipContainer, button]
        });
      }
      chipContainer = this.getBorder(chipContainer);
      chipContainer = (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          marginVertical: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(4),
          marginLeft: 0,
          marginRight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(8),
          alignSelf: 'flex-start',
          overflow: 'hidden',
          borderRadius: 16
        },
        children: chipContainer
      });
      return chipContainer;
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        backgroundColor: _ThemeManager.ThemeManager.style.chipDefaultBg,
        outlined: true,
        disabled: false
      };
    }
  }]);
}(_react.PureComponent);