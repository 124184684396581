var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchBar = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _I18n = require("../i18n/I18n");
var _ThemeManager = require("../ThemeManager");
var _Icon = require("./Icon");
var _MaterialText = require("./text/MaterialText");
var _MaterialTextEditable = require("./text/MaterialTextEditable");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var SearchBar = exports.SearchBar = function (_PureComponent) {
  function SearchBar(props) {
    var _this;
    (0, _classCallCheck2.default)(this, SearchBar);
    _this = _callSuper(this, SearchBar, [props]);
    _this.lastFocusChange = Date.now();
    _this.setField = function (ref) {
      _this.field = ref;
    };
    _this.blurNow = function () {
      if (_this.field != null && Date.now() - _this.lastFocusChange > 250) {
        _this.field.blur();
      }
    };
    _this.clear = function () {
      _this.setState({
        searchString: ''
      }, function () {
        if (_this.props.searchOnChange != null) {
          _this.props.searchOnChange('');
        }
        if (_this.props.searchOnChanged != null) {
          _this.props.searchOnChanged('');
        }
      });
    };
    _this.onBlur = function () {
      _this.lastFocusChange = Date.now();
      if (_this.blurTimeOut != null) {
        clearTimeout(_this.blurTimeOut);
      }
      _this.blurTimeOut = setTimeout(function () {
        _this.setState({
          focused: false
        }, function () {
          var onBlur = _this.props.onBlur;
          if (onBlur) onBlur();
        });
      }, 100);
    };
    _this.onFocus = function () {
      if (_this.blurTimeOut != null) {
        clearTimeout(_this.blurTimeOut);
      }
      _this.lastFocusChange = Date.now();
      _this.setState({
        focused: true
      }, function () {
        var onFocus = _this.props.onFocus;
        if (onFocus) onFocus();
      });
    };
    _this.searchAction = function (e) {
      var searchAction = _this.props.searchAction;
      if (searchAction != null) {
        searchAction(e);
      }
      if (_this.field != null && Date.now() - _this.lastFocusChange > 250) {
        _this.field.focus();
      }
    };
    _this.searchOnChange = function (t) {
      if (_this.props.searchOnChange != null) {
        _this.props.searchOnChange(t);
      }
      _this.setState({
        searchString: t
      });
    };
    _this.state = {
      searchString: props.searchBarValue != null ? props.searchBarValue : '',
      focused: false
    };
    return _this;
  }
  (0, _inherits2.default)(SearchBar, _PureComponent);
  return (0, _createClass2.default)(SearchBar, [{
    key: "blur",
    value: function blur() {
      var _this$field;
      (_this$field = this.field) == null || _this$field.blur();
      this.setState({
        focused: false
      });
    }
  }, {
    key: "focus",
    value: function focus() {
      var _this$field2;
      (_this$field2 = this.field) == null || _this$field2.focus();
      this.setState({
        focused: true
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props = this.props,
        searchBarPlaceholder = _this$props.searchBarPlaceholder,
        textColor = _this$props.textColor,
        iconColor = _this$props.iconColor,
        hoverColor = _this$props.hoverColor,
        searchIcon = _this$props.searchIcon,
        filterIcon = _this$props.filterIcon,
        filterAction = _this$props.filterAction,
        searchOnChanged = _this$props.searchOnChanged,
        maxWidth = _this$props.maxWidth,
        backgroundColor = _this$props.backgroundColor,
        tooltip = _this$props.tooltip,
        accessibilityLabel = _this$props.accessibilityLabel,
        onSubmitEditing = _this$props.onSubmitEditing,
        hideClearButton = _this$props.hideClearButton,
        small = _this$props.small;
      var _this$state = this.state,
        searchString = _this$state.searchString,
        focused = _this$state.focused;
      var showBackButton = focused && searchString.length !== 0;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          maxWidth: maxWidth != null && maxWidth > 0 ? maxWidth : undefined,
          flexDirection: 'row',
          alignItems: 'center',
          height: small ? 32 : 36,
          padding: 0,
          overflow: 'hidden',
          borderRadius: small ? 16 : 18,
          backgroundColor: backgroundColor != null ? backgroundColor : _ThemeManager.ThemeManager.style.searchBarBackground
        },
        children: [searchIcon != null ? showBackButton ? (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: "arrow-left",
          color: iconColor != null ? iconColor : _ThemeManager.ThemeManager.style.defaultIconColor,
          onPress: this.blurNow,
          toolTip: tooltip != null ? tooltip : _I18n.I18n.m.getMessage('back'),
          outerSize: small ? 32 : 36
        }) : (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: "magnify",
          color: iconColor != null ? iconColor : _ThemeManager.ThemeManager.style.defaultIconColor,
          onPress: this.searchAction,
          toolTip: _I18n.I18n.m.getMessage('search'),
          accessibilityLabel: accessibilityLabel,
          outerSize: small ? 32 : 36
        }) : null, (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            flex: 1,
            height: small ? 32 : 36,
            flexDirection: 'column',
            justifyContent: 'center'
          },
          collapsable: false,
          children: (0, _jsxRuntime.jsx)(_MaterialTextEditable.MaterialTextEditable, {
            accessibilityLabel: accessibilityLabel,
            ref: this.setField,
            placeholder: searchBarPlaceholder != null ? searchBarPlaceholder : '',
            underlineColor: "transparent",
            rippleColor: "transparent",
            hoverColor: hoverColor != null ? hoverColor : _ThemeManager.ThemeManager.style.materialTextHoverColor,
            style: {
              width: '100%',
              height: small ? 32 : 36,
              padding: 0,
              paddingLeft: this.props.onlyAppBar != null ? 24 : 0,
              overflow: 'visible'
            },
            autoFocus: false,
            onFocus: this.onFocus,
            onBlur: this.onBlur,
            onChange: this.searchOnChange,
            onChanged: searchOnChanged,
            onSubmitEditing: onSubmitEditing,
            materailTextProps: {
              numberOfLines: 1,
              type: _MaterialText.MaterialTextTypes.Body1,
              color: textColor != null && textColor.length > 0 ? textColor : _ThemeManager.ThemeManager.style.headerTitleColor
            },
            children: searchString
          })
        }), !hideClearButton && searchString.length > 0 ? (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: "close",
          color: iconColor != null ? iconColor : _ThemeManager.ThemeManager.style.defaultIconColor,
          onPress: this.clear,
          toolTip: _I18n.I18n.m.getMessage('clear'),
          outerSize: small ? 32 : 36
        }) : null, filterIcon === true ? (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: "filter",
          color: iconColor != null ? iconColor : _ThemeManager.ThemeManager.style.defaultIconColor,
          onPress: filterAction,
          toolTip: _I18n.I18n.m.getMessage('clear'),
          outerSize: small ? 32 : 36
        }) : null]
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        maxWidth: 512,
        searchBarPlaceholder: `${_I18n.I18n.m.getMessage('searchNoun')}...`,
        searchIcon: true
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.searchBarValue !== prevState.searchString) {
        return {
          searchString: nextProps.searchBarValue == null ? '' : nextProps.searchBarValue
        };
      }
      return null;
    }
  }]);
}(_react.PureComponent);