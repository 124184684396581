var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AutoComplete = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../../upmesh-support/node_modules/react-native-web");
var uuid = _interopRequireWildcard(require("uuid"));
var _ScrollHandler = require("../../scroll/ScrollHandler");
var _AutoCompleteEvents = require("./AutoCompleteEvents");
var _jsxRuntime = require("../../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var AutoComplete = exports.AutoComplete = function (_PureComponent) {
  function AutoComplete(props) {
    var _this;
    (0, _classCallCheck2.default)(this, AutoComplete);
    _this = _callSuper(this, AutoComplete, [props]);
    _this.onScroll = function (_y) {
      _this.createAutoComplete(_this.props);
    };
    _this.setTarget = function (r) {
      _this.target = r;
    };
    _this.onLayout = function (_e) {
      _this.createAutoComplete();
    };
    _this.state = {
      autoCompleteSuggestions: []
    };
    return _this;
  }
  (0, _inherits2.default)(AutoComplete, _PureComponent);
  return (0, _createClass2.default)(AutoComplete, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.props.scrollEvent != null) {
        this.props.scrollEvent.scrollEvent.attach(this.onScroll);
      }
      _ScrollHandler.ScrollHandler.disableAll();
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(nextprops, prevState) {
      if (prevState.autoCompleteSuggestions !== nextprops.autoCompleteSuggestions) {
        this.createAutoComplete(nextprops);
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.props.scrollEvent != null) {
        this.props.scrollEvent.scrollEvent.detach(this.onScroll);
      }
      if (this.autoCompleteId != null && this.autoCompleteId.length > 0) {
        _AutoCompleteEvents.AutoCompleteEvents.closeEvent.post(this.autoCompleteId);
      }
      _ScrollHandler.ScrollHandler.enableAll();
    }
  }, {
    key: "render",
    value: function render() {
      var style = this.props.style;
      var s = [style, {
        zIndex: 0,
        opacity: 0
      }];
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        onLayout: this.onLayout,
        style: s,
        collapsable: false,
        ref: this.setTarget
      });
    }
  }, {
    key: "createAutoComplete",
    value: function createAutoComplete() {
      var _this2 = this;
      var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.props;
      if (!this.target) {
        setTimeout(function () {
          return _this2.createAutoComplete;
        }, 12);
        return;
      }
      if (this.autoCompleteId == null) {
        this.autoCompleteId = uuid.v1();
      }
      if (this.target) {
        this.target.measure(function (_x, _y, width, height, pageX, pageY) {
          if (pageX != null && pageY != null && width != null && height != null) {
            _AutoCompleteEvents.AutoCompleteEvents.openEvent.post({
              width: width,
              height: height,
              x: pageX,
              y: pageY,
              id: _this2.autoCompleteId,
              onPressAutoComplete: props.onPressAutoComplete,
              listData: _this2.state.autoCompleteSuggestions,
              scrollEvent: props.scrollEvent,
              fiHeight: props.fiHeight
            });
          } else {
            setTimeout(function () {
              return _this2.createAutoComplete;
            }, 12);
          }
        });
      }
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      var newState = prevState != null ? prevState : {
        autoCompleteSuggestions: []
      };
      if (prevState.autoCompleteSuggestions !== nextProps.autoCompleteSuggestions) {
        newState.autoCompleteSuggestions = nextProps.autoCompleteSuggestions;
      }
      return newState;
    }
  }]);
}(_react.PureComponent);