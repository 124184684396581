var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PanoramaImage = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _reactPannellum = _interopRequireDefault(require("react-pannellum"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var PanoramaImage = exports.PanoramaImage = function (_PureComponent) {
  function PanoramaImage() {
    (0, _classCallCheck2.default)(this, PanoramaImage);
    return _callSuper(this, PanoramaImage, arguments);
  }
  (0, _inherits2.default)(PanoramaImage, _PureComponent);
  return (0, _createClass2.default)(PanoramaImage, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        size = _this$props.size,
        image = _this$props.image;
      return (0, _jsxRuntime.jsx)(_reactPannellum.default, {
        style: {
          width: size.width,
          height: size.height
        },
        imageSource: image,
        id: image,
        sceneId: image,
        type: "equirectangular",
        config: {
          autoLoad: true,
          showControls: false
        }
      });
    }
  }]);
}(_react.PureComponent);