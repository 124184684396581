var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FabActions = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../ThemeManager");
var _Icon = require("./Icon");
var _MaterialText = require("./text/MaterialText");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var FabActions = exports.FabActions = function (_PureComponent) {
  function FabActions(props) {
    var _this;
    (0, _classCallCheck2.default)(this, FabActions);
    _this = _callSuper(this, FabActions, [props]);
    _this.animate = new _reactNativeWeb.Animated.Value(0);
    _this.state = {
      animation: _this.createAnimations()
    };
    return _this;
  }
  (0, _inherits2.default)(FabActions, _PureComponent);
  return (0, _createClass2.default)(FabActions, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;
      setTimeout(function () {
        _reactNativeWeb.Animated.timing(_this2.animate, {
          toValue: 1,
          duration: 195,
          useNativeDriver: _reactNativeWeb.Platform.OS !== 'web'
        }).start();
      }, this.props.delay);
    }
  }, {
    key: "createAnimations",
    value: function createAnimations() {
      var transform = [{
        scale: this.animate.interpolate({
          inputRange: [0, 1],
          outputRange: [0.8, 1]
        })
      }];
      return {
        transform: transform,
        opacity: this.animate
      };
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props = this.props,
        text = _this$props.text,
        icon = _this$props.icon,
        iconColor = _this$props.iconColor,
        backgroundColor = _this$props.backgroundColor,
        small = _this$props.small,
        iconMoon = _this$props.iconMoon;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.Animated.View, {
        style: _reactNativeWeb.StyleSheet.flatten([{
          flexDirection: 'row',
          overflow: 'visible',
          alignItems: 'center',
          alignContent: 'flex-end',
          justifyContent: 'flex-end',
          paddingRight: small ? 0 : 8,
          paddingBottom: 8
        }, this.state.animation]),
        children: [text ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            paddingRight: 8
          },
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              backgroundColor: '#414345',
              paddingRight: 8,
              paddingLeft: 8,
              paddingTop: 4,
              paddingBottom: 4,
              borderRadius: _ThemeManager.ThemeManager.style.borderRadius
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              color: _ThemeManager.ThemeManager.style.white87,
              type: _MaterialText.MaterialTextTypes.Body1,
              numberOfLines: 1,
              showToolTipOnCutText: true,
              children: text
            })
          })
        }) : null, (0, _jsxRuntime.jsx)(_Icon.Icon, {
          elevation: 2,
          radius: 20,
          iconIconMoon: iconMoon,
          icon: icon,
          iconSize: 24,
          outerSize: 40,
          color: iconColor,
          toolTip: "",
          onPress: this.props.onPress,
          backgroundColor: backgroundColor
        })]
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        delay: 1,
        small: false,
        icon: '',
        onPress: undefined,
        iconColor: _ThemeManager.ThemeManager.style.white87,
        backgroundColor: _ThemeManager.ThemeManager.style.brandPrimary,
        text: '',
        iconMoon: false
      };
    }
  }]);
}(_react.PureComponent);