var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultErrorHandler = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _Alert = require("materialTheme/build/src/theme/components/Alert");
var _ContainedButton = require("materialTheme/build/src/theme/components/button/ContainedButton");
var _Dialog = require("materialTheme/build/src/theme/components/Dialog");
var _ProOnlyDialogContent = require("materialTheme/build/src/theme/components/ProOnlyDialogContent");
var _LoadingEvents = require("materialTheme/build/src/theme/routing/LoadingEvents");
var _RouterControl = require("materialTheme/build/src/theme/routing/RouterControl");
var _Routing = require("materialTheme/build/src/theme/routing/Routing");
var _ThemeManager = require("materialTheme/build/src/theme/ThemeManager");
var _ErrorReporter = require("odatarepos/build/src/reporting/ErrorReporter");
var _react = _interopRequireDefault(require("../../../../upmesh-support/node_modules/react"));
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _RemoveProjectUser = require("upmesh-core/build/src/client/commands/user/RemoveProjectUser");
var _ProjectEntity = require("upmesh-core/build/src/client/query/entities/ProjectEntity");
var _UpmeshClient = require("upmesh-core/build/src/client/UpmeshClient");
var _CommandReadModels = require("upmesh-core/build/src/server/webserver/commands/CommandReadModels");
var _UserOrCompany = require("upmesh-core/build/src/server/webserver/UserOrCompany");
var _I18n = require("../i18n/I18n");
var _jsxRuntime = require("../../../../upmesh-support/node_modules/react/jsx-runtime");
var maxSizeReachedImage = require("../assets/img/maxProjectSizeReached.png");
var DefaultErrorHandler = exports.DefaultErrorHandler = function () {
  function DefaultErrorHandler() {
    (0, _classCallCheck2.default)(this, DefaultErrorHandler);
  }
  return (0, _createClass2.default)(DefaultErrorHandler, null, [{
    key: "getDefaultErrorMessages",
    value: function getDefaultErrorMessages(e) {
      var i18n = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _I18n.I18n.m;
      var reportError = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var errorMessage = typeof e === 'string' ? e : JSON.stringify(e);
      if (errorMessage === 'tokenError' || e['messageCode'] != null && e['messageCode'] === 'tokenError') {
        _AuthClient.AuthClient.instance.validateToken('on Token Error').catch(function (err) {
          return void 0;
        });
      }
      if (Array.isArray(e)) {
        errorMessage = '';
        e.forEach(function (err) {
          errorMessage += DefaultErrorHandler.getDefaultErrorMessages(err);
        });
        return errorMessage;
      }
      if (typeof e['0'] === 'object') {
        errorMessage = '';
        for (var err in e) {
          if (err !== 'message') errorMessage += `${DefaultErrorHandler.getDefaultErrorMessages(e[err], i18n, reportError)}\n`;
        }
        return errorMessage;
      }
      if (e['messageCode'] != null && i18n != null) {
        errorMessage = i18n.getMessage(e['messageCode'], e['messageData']);
        if ((e['messageCode'] === 'badRequest' || e['messageCode'] === 'internalServer') && e['message'] != null) {
          errorMessage += `: ${e['message']}`;
        }
      } else if (e['message'] != null) {
        errorMessage = e['message'];
      } else if (Array.isArray(e)) {
        errorMessage = '';
        e.forEach(function (err) {
          return errorMessage += `${DefaultErrorHandler.getDefaultErrorMessages(err, i18n)}\n`;
        });
      }
      if (reportError) {
        _ErrorReporter.ErrorReporter.sendReport({
          data: e,
          type: 'error',
          subject: errorMessage
        });
      }
      return errorMessage;
    }
  }, {
    key: "showDefaultErrorAlert",
    value: function showDefaultErrorAlert(e) {
      var i18n = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _I18n.I18n.m;
      var reportError = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var text = DefaultErrorHandler.getDefaultErrorMessages(e, i18n, reportError);
      _Routing.Routing.instance.alert.post({
        text: text
      });
    }
  }, {
    key: "getProjectErrors",
    value: function () {
      var _getProjectErrors = (0, _asyncToGenerator2.default)(function* (projectId) {
        var _Alert$instance;
        var showAlert = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        var callbackBeforeAlert = arguments.length > 2 ? arguments[2] : undefined;
        var i18n = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _I18n.I18n.m;
        if (projectId == null || projectId.length === 0) return true;
        var buttons = [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
          title: _I18n.I18n.m.getMessage('close'),
          onPress: (_Alert$instance = _Alert.Alert.instance) == null ? void 0 : _Alert$instance.close,
          full: false,
          backgroundColor: "transparent",
          textColor: _ThemeManager.ThemeManager.style.brandPrimary
        }, "okButton")];
        try {
          yield _ProjectEntity.ProjectEntity.checkProjectStatusAndGeneralAccess(projectId, _CurrentUser.CurrentUser.userId);
          return false;
        } catch (e) {
          var errorMessage = typeof e === 'string' ? e : JSON.stringify(e);
          if (typeof e !== 'string') {
            if (e['messageCode'] != null) {
              if (e['messageCode'] === 'projectLocked') {
                var project = yield _UpmeshClient.UpmeshClient.instance.modals.project.getById(projectId);
                if (_CurrentUser.CurrentUser.userId === project.creator) {
                  errorMessage = i18n.getMessage('projectLockedOwner');
                } else {
                  var owner = yield _UserOrCompany.UserOrCompany.getById(project.creator);
                  errorMessage = i18n.getMessage('projectLocked', {
                    ownerName: owner.getFullName()
                  });
                  buttons.unshift((0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                    title: _I18n.I18n.m.getMessage('teamLeaveProject'),
                    onPress: DefaultErrorHandler.leaveProject(projectId),
                    full: false,
                    backgroundColor: "transparent",
                    textColor: _ThemeManager.ThemeManager.style.brandPrimary
                  }, "leaveButton"));
                }
              } else if (e['messageCode'] === 'projectMaximumSizeReached') {
                showAlert = false;
                _RouterControl.RouterControl.instance.openUnRoutedDialog({
                  scrollable: true,
                  contentPadding: false,
                  showCloseIcon: true,
                  content: (0, _jsxRuntime.jsx)(_ProOnlyDialogContent.ProOnlyDialogContent, {
                    headline: i18n.getMessage('maxSizeReached'),
                    description: i18n.getMessage('maxSizeReachedText'),
                    buttonText: i18n.getMessage('maxSizeReachedUpgradeButton'),
                    buttonFunction: function buttonFunction() {
                      var _Dialog$instance;
                      (_Dialog$instance = _Dialog.Dialog.instance) == null || _Dialog$instance.close();
                      _Routing.Routing.instance.goTo('/subscriptionPlanView');
                    },
                    imageSource: maxSizeReachedImage
                  })
                });
              } else if (e['messageCode'] === 'projectErrorProOrEnterpriseNeededForRole') {
                var _project = yield _UpmeshClient.UpmeshClient.instance.modals.project.getById(projectId);
                var ownerName = '';
                if (_project.creator.startsWith('company')) {
                  var companyId = _project.creator.slice(7);
                  var company = yield _UpmeshClient.UpmeshClient.instance.modals.company.getById(companyId);
                  ownerName = company.company;
                } else {
                  var _owner = yield _UserOrCompany.UserOrCompany.getById(_project.creator);
                  ownerName = _owner.getFullName();
                }
                errorMessage = i18n.getMessage('projectErrorProOrEnterpriseNeededForRole', {
                  ownerName: ownerName
                });
              } else {
                errorMessage = i18n.getMessage(e['messageCode']);
              }
            } else if (e['message'] != null) {
              errorMessage = e['message'];
            }
          }
          if (callbackBeforeAlert != null) {
            callbackBeforeAlert(e);
          }
          if (errorMessage === 'forbidden' || e['messageCode'] != null && e['messageCode'] === 'forbidden') {
            if (typeof _CommandReadModels.CommandReadModels.project['reSync'] === 'function') {
              _CommandReadModels.CommandReadModels.project['reSync']({
                filter: `id eq '${projectId}'`,
                deleteBefore: true
              });
            }
          }
          if (errorMessage === 'tokenError' || e['messageCode'] != null && e['messageCode'] === 'tokenError') {
            yield _AuthClient.AuthClient.instance.validateToken('on Token Error');
          }
          if (showAlert) {
            requestAnimationFrame(function () {
              _Routing.Routing.instance.alert.post({
                text: errorMessage,
                buttons: buttons
              });
            });
          }
          return true;
        }
      });
      function getProjectErrors(_x) {
        return _getProjectErrors.apply(this, arguments);
      }
      return getProjectErrors;
    }()
  }]);
}();
DefaultErrorHandler.leaveProject = function (projectId) {
  return function (_e) {
    var _Alert$instance2;
    (_Alert$instance2 = _Alert.Alert.instance) == null || _Alert$instance2.close(function () {
      _UpmeshClient.UpmeshClient.instance.modals.projectUser.get({
        filter: `projectId eq '${projectId}' and userId eq '${_CurrentUser.CurrentUser.userId}'`
      }).then(function (pu) {
        if (pu.length === 1) {
          _LoadingEvents.LoadingEvents.instance.startLoading();
          var r = new _RemoveProjectUser.RemoveProjectUser({
            force: true
          }, pu[0].id);
          return r.execute();
        }
        throw new Error(`user not in project ${projectId}, ${pu.length}`);
      }).then(function () {
        _LoadingEvents.LoadingEvents.instance.stopLoading();
        if (_AuthClient.AuthClient.instance != null) _AuthClient.AuthClient.instance.startSync().catch(function (err) {
          return void 0;
        });
        _Routing.Routing.instance.goTo('/');
      }).catch(function (err) {
        _LoadingEvents.LoadingEvents.instance.stopLoading();
      });
    });
  };
};